import { Document, Page } from "react-pdf";
import { ButtonSquad } from "../../../../../core/components";
import { ButtonType } from "../../../../../core/interfaces/ButtonSquadInterface";
import { pdfjs } from "react-pdf";
import { useEffect, useState } from "react";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { AppDispatch, RootState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import SidebarMenu from "./SidebarMenuPDF";
import { ReadBook } from "../../../../../store/interfaces/book/interfaceBook";
import { Column } from "../../../../../core/components/Column";
import { Row } from "../../../../../core/components/Row";
import { TextComponent } from "../../../../../core/components/TextComponent";
import { SizedBox } from "../../../../../core/components/SizedBox";
import { readBookById } from "../../../../../store/slices/book/thunks";
import { useNavigate } from "react-router-dom";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const ReadBookContainer = () => {
  const navigate = useNavigate();
  const pageNumberStorage =
    parseInt(localStorage.getItem("pageNumber") as string) || 1;
  const [currentVersion, setCurrentVersion] = useState<string | null>(localStorage.getItem("version_id"));
  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(pageNumberStorage);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const { readBook, selectedBook } = useSelector(
    (state: RootState) => state.book
  );
  const dispatch: AppDispatch = useDispatch();

  useEffect(() => {
    if (selectedBook) {
      dispatch(
        readBookById({
          bookId: selectedBook?.id as string,
          versionId: currentVersion || selectedBook?.versions[0].version_id as string,
        })
      );
      setCurrentVersion(currentVersion || selectedBook?.versions[0].version_id as string);
      localStorage.setItem("version_id", currentVersion || selectedBook?.versions[0].version_id as string);
    }
  }, [selectedBook]);

  const handleReadBook = (version_id: string) => {
    dispatch(
      readBookById({
        bookId: readBook?.id as string,
        versionId: version_id as string,
      })
    );
    setCurrentVersion(version_id);
    localStorage.setItem("version_id", version_id);
  };
  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handlePageChange = (newPage: number) => {
    setPageNumber(newPage + 1);
    localStorage.setItem("pageNumber", (newPage + 1).toString());
  };
  return (
    <Column
      crossAxisAlignment="center"
      className="relative w-full bg-gray-50  min-h-screen overflow-x-hidden"
    >
      <div className="w-full  md:p-0 flex h-full flex-col">
        <div className="bg-gray-900 w-full">
          <Row
            crossAxisAlignment="center"
            mainAxisAlignment="between"
            className="px-8"
          >
            <TextComponent variant="h4" className="py-6 text-white">
              {readBook?.title}
            </TextComponent>
            <Row flexible={true} crossAxisAlignment="center">
              <Row className="mr-2" flexible={true}>
                {selectedBook?.versions.map((version) => (
                  <div
                    className={`flex flex-col items-center justify-center h-14 w-14 p-3 ${
                      currentVersion === version.version_id ? "bg-gray-300" : ""
                    } hover:bg-gray-300 cursor-pointer`}
                    onClick={() => handleReadBook(version.version_id)}
                  >
                    <img
                      className="h-14 w-14 object-cover rounded-full"
                      src={version.version_image}
                    />
                  </div>
                ))}
              </Row>
              <ButtonSquad
                buttonType={ButtonType.OrangeSmall}
                label="Cerrar"
                onClick={() => navigate(-1)}
              />
            </Row>
          </Row>
        </div>
        <Row>
          <div className="md:w-3/4 lg:w-1/4 h-screen max-[810px]:hidden overflow-auto overflow-x-hidden">
            <SidebarMenu
              data={readBook as ReadBook}
              pageNumber={pageNumber}
              setPageNumber={handlePageChange}
            />
          </div>
          <Column crossAxisAlignment="center" className="flex w-full">
            <SizedBox className="h-6" />
            <Column className="rounded-md">
              <Document
                className="w-full rounded-md"
                file={readBook?.versions[0].version_url as string}
                onLoadSuccess={onDocumentLoadSuccess}
                onMouseEnter={() => setIsHovering(true)}
                onMouseLeave={() => setIsHovering(false)}
              >
                <Page
                  scale={1.1}
                  pageNumber={pageNumber}
                  className="shadow-xl rounded-md relative object-cover w-full z-10"
                />
                <div
                  className={`${
                    isHovering ? "" : ""
                  } flex w-max ml-auto mr-auto relative z-20 bottom-10 divide-x`}
                >
                  <button
                    className={`text-white py-3 px-4 rounded-s-lg ${
                      pageNumber === 1
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-orange-500"
                    }`}
                    onClick={() => {
                      setPageNumber(Math.max(1, pageNumber - 1));
                      localStorage.setItem(
                        "pageNumber",
                        (pageNumber - 1).toString()
                      );
                    }}
                  >
                    Anterior
                  </button>
                  <label className="text-white py-3 px-4 bg-orange-500">
                    {pageNumber} de {numPages}
                  </label>
                  <button
                    className={`text-white py-3 px-4 rounded-e-lg ${
                      pageNumber === numPages
                        ? "bg-gray-400 cursor-not-allowed"
                        : "bg-orange-500"
                    } `}
                    onClick={() => {
                      setPageNumber(
                        Math.min(numPages as number, pageNumber + 1)
                      );
                      localStorage.setItem(
                        "pageNumber",
                        (pageNumber + 1).toString()
                      );
                    }}
                  >
                    Siguiente
                  </button>
                </div>
              </Document>
            </Column>
          </Column>
        </Row>
      </div>
    </Column>
  );
};

export default ReadBookContainer;
