import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useEffect } from "react";
import { startGettingMeals } from "../../../../store/slices/food/thunks";
import MealCardSkeleton from "./MealCardSkeleton";
import MealCard from "./MealCard";
import { Column } from "../../../../core/components/Column";
import { TextComponent } from "../../../../core/components/TextComponent";
import { SizedBox } from "../../../../core/components/SizedBox";
import { Row } from "../../../../core/components/Row";
import { ButtonSquad } from "../../../../core/components";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";
import { useNavigate } from "react-router-dom";
import { categories } from "../../../../core/constants/constatns";
import { onSelectType } from "../../../../store/slices/food/foodSlice";

export const MealsList = () => {
  const { meals, is_loading_meals } = useSelector(
    (state: RootState) => state.food
  );

  const dispatch: AppDispatch = useDispatch();

  // const today = new Date();

  // useEffect(() => {
  //   dispatch(startGettingMeals({ date: today }));
  // }, []);
  const navigate = useNavigate();
  const toRecipe=(index:number)=>{
    dispatch(onSelectType(index+1))
    navigate("/recipes")
  }

  return (
    <Column>
      <TextComponent className="text-md font-bold">
        Comidas diarias
      </TextComponent>
      <SizedBox className="h-6"/>
      {
        categories.map((e,index)=>{
          const foods=meals.filter((e)=>e.type==(index+1).toString());
          return (
            <div key={index}>
              <Row mainAxisAlignment="between">
                <TextComponent variant="h5" className="text-md font-medium">
                  {e}
                </TextComponent>
                <ButtonSquad label="Agregar" buttonType={ButtonType.RoseSmall} onClick={()=>toRecipe(index)}/>
              </Row>
              {is_loading_meals
              ? Array.from({ length: 2 }).map((_, index) => (
                  <MealCardSkeleton key={index} />
                ))
              : foods.map((meal,index) => <MealCard key={index} meal={meal} />)}
              <SizedBox className="h-6"/>
            </div>
          )
        })
      }
    </Column>
  );
};
