import { useDispatch, useSelector } from "react-redux"
import { BlueButton } from "../../../../core/components/ButtonBlue"
import { Column } from "../../../../core/components/Column"
import { Row } from "../../../../core/components/Row"
import { SizedBox } from "../../../../core/components/SizedBox"
import { AppDispatch, RootState } from "../../../../store/store"
import { getAllRedeems, redeemCode } from "../../../../store/slices/redeem/thunks"
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik"
import { RedeemFormValues, redeemValidationSchema } from "../interfaces/RedeemInterface"
import { CustomInput } from "../../../../core/components/CustomInput"
import { useAlert } from "../../../hooks/useAlert"
import { getBooksByUser } from "../../../../store/slices/book/thunks"
import { getCourseByUser } from "../../../../store/slices/course/thunks"
import { ButtonSquad } from "../../../../core/components"
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface"
const initialValues: RedeemFormValues = {
    code: '',
};
export const StepFinishScreen =() =>{
    const { showAlert } = useAlert();
    const { redeems,loading} = useSelector((state: RootState) => state.redeem);
    const dispatch: AppDispatch = useDispatch();
    const handleSubmit = (values: RedeemFormValues, { setSubmitting }: FormikHelpers<RedeemFormValues>) => {  
        dispatch(redeemCode(values.code)).then( (response) => {
            if (response.meta.requestStatus === 'fulfilled') {
            dispatch(getAllRedeems());
            dispatch(getBooksByUser());
            dispatch(getCourseByUser());
            showAlert({
                message: 'Código canjeado exitosamente',
                type: "success"
            });
            }else{
                showAlert({
                    message: 'Código erroneo',
                    type: "error"
                });
            }
        })
        setSubmitting(false);
    }
    return <Formik
    initialValues={initialValues}
    validationSchema={redeemValidationSchema}
    validateOnBlur
    validateOnChange={false}
    onSubmit={handleSubmit}
>
{({ handleChange:handleForm, handleBlur }) => ((
<Form>
       <Column className="mx-6 mt-12">
        <div className="h3">
            Información
        </div>
        <SizedBox className="h-4" />
        <div>
        Si tienes compras realizadas en la plataforma anterior, puedes sincronizar tus datos desde aquí, también estará disponible desde el menu de configuraciones
        </div>
        <SizedBox className="h-4" />
        <div className="h3">
            Canjear o Recuperar tu compra
        </div>
        <SizedBox className="h-4" />
        <div>
            Ingrese el código que le proporcionó el vendedor. Luego tendrás acceso al contenido que han vendido.
        </div>
        <SizedBox className="h-4" />
        <Column>
            <Field
                as={CustomInput}
                label="Código de canje"
                name="code"
                hintText=''
                onChange={handleForm}
                onBlur={handleBlur}
                required
            />
            <ErrorMessage name="code" component="div" className="text-red-500" />
        </Column>
        <SizedBox className="h-4" />
        <ButtonSquad  buttonType={ButtonType.OrangeFull} loading={loading} className="w-80 " label="Canjear" onClick={()=>{}} />
        <SizedBox className="h-6" />
        {
            redeems.length>0&&(
                <div>
                    Contenido desbloqueado
                </div>
            )
        }
        <SizedBox className="h-6" />
        {
            redeems.map((e)=>(
            <Row className="mt-4">
            <img src={e.product.image} className="container-gray w-2"/>
            <SizedBox className="w-3" />
            <Row>
                <Column>
                    <div>
                        {
                            e.product.title
                        }
                    </div>
                    <div>
                        {
                            e.product.type
                        }
                    </div>
                    </Column>
                </Row>
            </Row>
            ))
        }
       </Column>
    </Form>
  )
)}
</Formik>
}

