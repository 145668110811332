import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const PrivateRoutes = () => {

  const { token, status } = useSelector((state: RootState) => state.auth);

  return (token && status === "authenticated") ? <Outlet /> : <Navigate to="/auth/login" replace />;
}

export default PrivateRoutes