interface HeartIconProps {
  height?: number;
  width?: number;
  liked?: boolean;
  onClick?: () => void;
}

export const HeartIcon = ({
  height = 6,
  width = 6,
  liked = false,
  onClick = () => {},
}: HeartIconProps) => {
  return (
    <svg
      className={`cursor-pointer h-${height} w-${width} ${
        liked ? "text-red-600" : "text-white"
      }`}
      fill="currentColor"
      viewBox="0 0 24 24"
      stroke="rgb(220 38 38 / 1)"
      strokeWidth="2"
      onClick={onClick}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M20.84 4.61a5.5 5.5 0 00-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 00-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 000-7.78z"
      />
    </svg>
  );
};
