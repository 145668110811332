import React from "react";
import SearchIcon from "@heroicons/react/24/solid/MagnifyingGlassIcon";
import { InputSearchType } from "../interfaces/InputSearchInterface";
import CircularProgress from "../../squad-fit/components/CircularProgress";
import CloseIcon from "../../assets/icons/CloseIcon";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputType: InputSearchType;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isLoading?: boolean;
  clearText?: boolean;
  clearAction?: () => void;
}

export const InputSquadSearch = ({
  inputType,
  value,
  onChange,
  onKeyUp,
  isLoading,
  clearText,
  clearAction,
  ...props
}: Props) => {
  return (
    <div className="realitive flex items-center text-gray-400 focus-within:text-gray-600">
      <SearchIcon className="h-5 w-5 absolute ml-3" />
      <input
        className={`pr-3 pl-10 py-2 border-2 border-gray-80 rounded-lg ${inputType} placeholder:text-gray-400 px-3`}
        placeholder="Buscar"
        value={value}
        onChange={onChange}
        onKeyUp={onKeyUp}
        {...props}
      />
      {isLoading && <CircularProgress styleProps={{ marginLeft: "225px" }} />}
      {clearText && (
        <div className="relative right-9 cursor-pointer p-1 hover:bg-slate-200 rounded-lg" onClick={clearAction}>
          <CloseIcon />
        </div>
      )}
    </div>
  );
};
