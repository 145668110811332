import { Navigate, Route, Routes } from 'react-router-dom'
import { AuthRoutes } from '../squad-fit/auth/routes/AuthRoutes'
import { SquadFitRoutes } from '../squad-fit/routes/SquadFitRoutes'
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { PublicSquadLayout } from '../squad-fit/layout/PublicSquatLayout';
import { NewLandingScreen } from '../squad-fit/pages/home/NewLandingScreen';

export const AppRouter = () => {

  const { status } = useSelector((state: RootState) => state.auth);

  return (
    <Routes>
      {
        status === 'not-authenticated' 
        ? <Route path='/auth/*' element={ <AuthRoutes /> }/>

        : <Route path='/*' element={ <SquadFitRoutes /> }/>
      }
       {
        status === 'not-authenticated' &&
        <Route element={<PublicSquadLayout />}>
          <Route path="/" element={ <NewLandingScreen /> }/>
        </Route>
       }

      <Route path="/*" element={<Navigate to="/" />} />
    </Routes>
  )
}
