import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../../../core/components/ButtonBlue";
import { Column } from "../../../../core/components/Column";
import { SizedBox } from "../../../../core/components/SizedBox";
import { useEffect } from "react";
import { startGettingUserInfo, startGettingUserInfoNoLoading } from "../../../../store/slices/auth/thunks";
import { AppDispatch } from "../../../../store/store";
import { useDispatch } from "react-redux";


export const OnBoardingFinishScreen = () =>{
    const dispatch: AppDispatch = useDispatch();
    const navigate = useNavigate();
    const toHome=()=>{
        dispatch(startGettingUserInfoNoLoading());
        navigate('/');
    }
    
    return (
        <Column className=" md:block md:fixed top-0 left-0 bg-white md:w-1/4 h-screen shadow-2xl p-7">
            <div className="h2 font-bold font-sans text-gradient">
                Squat Fit
            </div>
            <SizedBox className="h-14"/>
            <img src="/Group.png" className="h-72 w-64 place-self-center" alt=""/>
            <SizedBox className="h-14"/>
            <div className="text-center font-bold h3">
                Felicitaciones!
            </div>
            <SizedBox className="h-6"/>
            <div className="text-center h4 text-[#61788A]">
            Tu cuenta ya está configurada, ahora puedes adentrarte al maravilloso mundo de squat fit
            </div>
            <SizedBox className="h-6"/>
            <BlueButton className="w-full place-self-center" label="Ir al home" onClick={toHome} />
        </Column>
    );
}