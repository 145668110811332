export const usePasswordPolicy = () => {
  const regExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

  const hasUpperCase = (password: string) => {
    return /[A-Z]/.test(password);
  }

  const hasLowerCase = (password: string) => {
    return /[a-z]/.test(password);
  }

  const hasNumber = (password: string) => {
    return /\d/.test(password);
  }

  const hasSpecialCharacter = (password: string) => {
    return /[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]/.test(password);
  }

  const hasMinLength = (password: string) => {
    return password.length >= 8;
  }

  const validatePassword = (password: string) => {
    return regExp.test(password);
  }

  const validatePasswordHasEqual = (password: string, password2: string) => {
    return password === password2;
  }

  return { hasLowerCase, hasUpperCase, hasNumber, hasSpecialCharacter, hasMinLength, validatePassword, validatePasswordHasEqual };
}
