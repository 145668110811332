import React from 'react';
import ContactForm from './components/ContactFormComponent';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';
import { Coach } from '../../../store/interfaces/coach/interfaceCoach';
interface ContactProps {
  coach: Coach;
}
const ContactScreen= ({coach}:ContactProps) => {
    const { username, email } = useSelector((state: RootState) => state.auth);
  return (
    <div className="min-h-screen flex items-center justify-center">
      <ContactForm initialEmail={email} coach={coach} initialUsername={username} initialSubject={`Asesoría con ${coach.firstName}`} />
    </div>
  );
};

export default ContactScreen;
