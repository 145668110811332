import { useCallback, useEffect } from "react";
import { TextComponent } from "./TextComponent";
import { Column } from "./Column";
import { Row } from "./Row";

interface ModalSimpleProps {
    children?: React.ReactNode,
    title?: string,
    titleHeader?: string,
    actionExecuted: boolean,
    variant?: string,
    onClose: () => void,
    onAction?: () => void,
    icon?: JSX.Element,
    next?: boolean,
    enableButton?: boolean,
}

export function ModalSimple({ children, title, titleHeader, actionExecuted, variant = "primary", onClose, onAction, icon, next, enableButton = true }: ModalSimpleProps) {

    const modalAction = useCallback(() => {
        onClose();
    }, [onClose]);


    useEffect(() => {
        if (actionExecuted) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [actionExecuted]);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (!actionExecuted || event.key !== 'Escape') return;
            modalAction();
        };
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [modalAction, actionExecuted]);

    if (!actionExecuted) return null;

    return (
        <dialog className="w-full h-screen bg-[#000000db] fixed flex justify-center items-center top-0 left-0 z-50">
            <article className={`relative bg-white border-2 border-blueSquat1 rounded-xl ${variant === "primary"
                ? "sm:w-[40rem] max-sm:h-full max-sm:w-full max-sm:overflow-y-auto"
                : "w-4/5 rounded-lg sm:w-[26rem]"}`}
            >
                {variant === "primary" ?
                    <>
                        <header className={`flex ${titleHeader ? 'justify-between' : 'justify-end'} bg-primary text-white px-4 py-2 items-center max-sm:hidden`}>
                            {titleHeader}
                            <button className="text-2xl text-gray-400" onClick={modalAction}>✖</button>
                        </header>
                    </>
                    :
                    <div className="absolute transform left-1/2 -translate-x-1/2 -top-10">
                        {icon}
                    </div>
                }
                <Column
                    mainAxisAlignment="center"
                >
                    <Column
                        mainAxisAlignment="start"
                        crossAxisAlignment="center"
                        className={`m-8 ${variant === "primary" ? "mt-0 max-sm:mb-0" : "text-center pt-5"} max-sm:h-full`}
                    >
                        <TextComponent variant="h2" className="font-bold text-primary text-center">
                            {title}
                        </TextComponent>
                        <div className="mt-3 flex flex-col gap-3 text-gray-500 max-sm:h-full sm:max-h-96 sm:overflow-y-auto">
                            {children}
                        </div>

                    </Column>
                </Column>
            </article>
        </dialog>
    );
}