import ColoseIcon from '@heroicons/react/24/outline/XMarkIcon';
import { LabelSquad } from '../../../../core/components';
import { Recipe } from '../../../../store/interfaces/food/recipeInterface';

interface ModalProps {
  isOpen: boolean;
  recipe: Recipe
  onClose: () => void;
}

export const MyRecipesModal = ({ isOpen, onClose, recipe }: ModalProps) => {
  return (
    <div 
      className={`modal ${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-20 z-50`}
    >
      <div className="modal-dialog mx-auto my-20 rounded-lg bg-white w-[calc(100vw-900px)] h-[calc(100vh-150px)]">
        <div className='flex justify-end'>
          <button 
            className="text-gray-500 hover:text-gray-700 mr-1 mt-1" 
            onClick={() => {
              onClose()
            }}
          >
            <ColoseIcon className="h-6 w-6" />
          </button>
        </div>
        <div className="flex flex-col w-full h-full">
          <div className="flex flex-col items-center justify-center mt-6">
            <img src={recipe.image} className="object-cover w-64 h-64 bg-gray-300 rounded-lg" />
            <label className="text-lg font-medium mt-2">{recipe.name}</label>
            <p className="text-sm font-light mt-1">{recipe.name}</p>
            <label className="text-sm font-light mt-1">{recipe.kcal} Kcal</label>
          </div>
        </div>
      </div>
    </div>
  )
}
