import React from 'react'
import { Column } from './Column';
import { TextComponent } from './TextComponent';
interface BarProps {
    percent: number;
    label?: string ;
    subtitle?: string ;
  }
export const BarComponentStep = ({percent,label,subtitle}:BarProps) => {
  return (
    <Column>
        <TextComponent variant='h5'>{label}</TextComponent>
        <div className="h-2 w-full max-w-80 bg-[#CCD6EB] rounded-full relative mt-3 mb-3">
            <div className={`absolute bottom-0 bg-[#0045FC] rounded-full h-full`} style={{ width: `${percent}%` }}></div>
        </div>
        <TextComponent variant='h6'>{subtitle}</TextComponent>
    </Column>
  )
}
