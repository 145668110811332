
interface CardProps {
  img: string;
}

const staticImage = "https://static.vecteezy.com/system/resources/previews/004/141/669/non_2x/no-photo-or-blank-image-icon-loading-images-or-missing-image-mark-image-not-available-or-image-coming-soon-sign-simple-nature-silhouette-in-frame-isolated-illustration-vector.jpg";

export const ImageProgressCard = ({img}: CardProps) => {
  return (
    <div className="">
      <img src={img ?? staticImage} alt="Featured Image 1" className="w-[320px] h-[230px] object-cover rounded-lg shadow-lg"/>
    </div> 
  )
}
