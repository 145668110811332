import SendIcon from "../../../../../assets/icons/SendIcon";

interface ChatInputBoxProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  sendMsg: () => void;
}

const ChatInputBox = ({ value, onChange, sendMsg }: ChatInputBoxProps) => {

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      sendMsg();
    }
  };

  return (
    <div className="flex flex-row justify-between w-full py-2 pl-5 pr-3 rounded-full border-solid border border-gray-400 text-sm">
      <input
        type="text"
        placeholder="Escribe un mensaje aquí..."
        className="w-full outline-none"
        value={value}
        onChange={onChange}
        onKeyDown={handleKeyDown}
      />
      <button className="ml-2" onClick={sendMsg}>
        <SendIcon />
      </button>
    </div>
  );
};

export default ChatInputBox;
