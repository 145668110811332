import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store/store';
import { Column } from '../../../core/components/Column';
import { TextComponent } from '../../../core/components/TextComponent';
import MyCourseSmallCard from './components/MyCourses/MyCourseSmallCard';
import RecomendCourseSmallCard from './components/MyCourses/RecomedCourseSmallCard';
import { Course } from '../../../store/interfaces/course/interfaceCourse';
import MyCourseCardSkeleton from './components/MyCourses/MyCourseCardSkeleton';
import { useEffect } from 'react';
import { getAllCourses, getCourseByUser } from '../../../store/slices/course/thunks';
import { Row } from '../../../core/components/Row';
import DropdownMenu from '../../../core/components/MenuItem';
import { useNavigate } from 'react-router-dom';

export const MyCoursesScreen = () => {
  const navigate = useNavigate();
  const { courseByUser, allCourses, is_loading_course_by_user, is_loading_all_courses } = useSelector(
    (state: RootState) => state.course
  );
  const handleFilter = (course: Course) => {
    return (
      course.id !==
      courseByUser.find((courseUser) => courseUser.id === course.id)?.id
    );
  };
  const dispatch: AppDispatch = useDispatch();
  useEffect(() => {
    if (allCourses.length > 0) return;
    dispatch(getAllCourses());
  }, [allCourses]);

  useEffect(() => {
    if (courseByUser.length > 0) return;
    dispatch(getCourseByUser());
  }, [courseByUser]);
  const handleRedeem = () => {
    navigate('/redeem');
  };
  
  const handleFAQ = () => {
    navigate('/faqs');
  };
  
  const menuItems = [
    { label: "Canjear una compra", onClick: handleRedeem },
    { label: "Preguntas frecuentes", onClick: handleFAQ },
  ];

  return (
    <Column className='pb-16'>
      <Row mainAxisAlignment='between' className='bg-gray-900 px-12 py-6'>
        <TextComponent variant="h1" className="font-bold text-white">Cursos</TextComponent>
        <DropdownMenu title="" menuItems={menuItems} />
      </Row>
      <div className=' max-w-5xl'></div>
      {
        courseByUser.length>0&&<Column className='0' crossAxisAlignment='center'>
        <div className='pb-6 pt-6 px-12 w-full  bg-red-30'>
          <TextComponent variant="h1" className="font-bold">Mis cursos</TextComponent>
        </div>
        <div className='px-12 w-full  bg-red-30'>
          <div
            className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6"
          >
            {is_loading_course_by_user ? (
              Array.from({ length: 4 }).map((_, index) => (
                <MyCourseCardSkeleton key={index} />
              ))
            ) : (
              courseByUser.map((course) => (
                <MyCourseSmallCard key={course.id} course={course} />
              ))
            )}
          </div>
        </div>
      </Column>
      }
      <Column className='0' crossAxisAlignment='center'>
        <div className='pb-6 pt-6 px-12 w-full  bg-red-30'>
          <TextComponent variant="h1" className="font-bold">Recomendados</TextComponent>
        </div>
        <div className='pb-16 px-12 w-full  bg-red-30'>
          <div
            className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-6"
          >
            {is_loading_all_courses ? (
              <div className="flex animate-pulse mt-10 w-80 h-48 bg-slate-200 rounded-lg"></div>
            ) : (
              allCourses
                .filter(handleFilter).map((course) => (
                  <RecomendCourseSmallCard key={course.id} course={course} />
                ))
            )}
          </div>
        </div>
      </Column>
    </Column>
  )
}
