import { WarningIcon } from "../../../../../assets/icons/WarningIcon";

interface ModalProps {
  showModal: boolean;
  onConfirmItem: () => void;
  onCloseModal: () => void;
  typeDelete: boolean;
}

export const WarningModal = ({ showModal, onConfirmItem, onCloseModal, typeDelete }: ModalProps) => {
  return (
    <div className={`fixed z-50 inset-0 overflow-y-auto ${showModal ? 'block' : 'hidden'}`} aria-labelledby="modal-headline" role="dialog" aria-modal="true">
      <div className="flex items-end max-[640px]:items-center justify-center min-h-screen pt-2 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="w-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <WarningIcon />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">{ typeDelete ? 'Borrar publicación' : 'Cerrar Sesión' }</h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    { 
                      typeDelete === true
                      ? '¿Estás seguro de que quieres eliminar este post?'
                      : '¿Estás seguro de que deseas cerrar sesión?'
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button onClick={onConfirmItem} type="button" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">{ typeDelete  ? 'Eliminar' : 'Aceptar'}</button>
            <button onClick={onCloseModal} type="button" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">Cancelar</button>
          </div>
        </div>
      </div>
    </div>
  )
}
