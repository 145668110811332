
interface IconPops {
  width: string
  height: string
}

export const VictoryIcon = ({height, width}: IconPops) => {
  return (
    <div>
      <img src="/landing/icon victory.png" alt="Fitness" className={`rounded-lg h-${height} w-${width}`} />
    </div>
  )
}
