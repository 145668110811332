interface CustomTermsProps {
  title: string;
  subtitle: string;
}
type TermsComponentProps = CustomTermsProps & React.HTMLAttributes<HTMLDivElement>;
export const TermsComponent = ({ title, subtitle,...props}:TermsComponentProps) => {
  return (
    <div id="" className="flex flex-col items-start" {...props}>
      <h2 className="text-2xl font-semibold">{title}</h2>
      <div className="h-4"></div>
      <p className="text-sm text-gray-600">{subtitle}</p>
    </div>
  );
};
