import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { startCreatingMeal, startCreatingRecipe, startGettingByCategory, startGettingCategories, startGettingDailyKcal, startGettingFriendRecipes, startGettingMeals, startGettingRecipes, startGettingWeeklyKcal, startUpdatingRecipe } from './thunks'
import { Recipe } from '../../interfaces/food/recipeInterface';

export interface FriendRecipe {
  id: string;
  name: string;
  recipe_category_id: string;
  recipe_category_name: string;
  kcal: string;
  description: string;
  image: string;
  created_at: string;
}

export interface Meals {
  id: string,
  recipe: string,
  category: string,
  kcal: string,
  image: string,
  date: string
}

export interface Categories {
  id: string,
  name: string
}

export interface FoodState {
  recipe: Recipe[],
  is_loading_recipe: boolean,
  meals: Recipe[],
  is_loading_meals: boolean,
  friendRecipe: Recipe[],
  categories: Categories[],
  is_loading_categories: boolean,
  achieved_weekly_kcal: number[],
  is_loading_achieved_weekly_kcal: boolean,
  dayli_kcal: number,
  updateRecipe: Recipe,
  type: number;
}

const recipeInitBody: Recipe = {

}

const initialState: FoodState = {
  recipe: [],
  is_loading_recipe: false,
  meals: [],
  is_loading_meals: false,
  friendRecipe: [],
  categories: [],
  is_loading_categories: false,
  achieved_weekly_kcal: [],
  is_loading_achieved_weekly_kcal: false,
  dayli_kcal: 0,
  type: 1,
  updateRecipe: recipeInitBody
}

export const foodSlice = createSlice({
  name: 'food',
  initialState,
  reducers: {
    sumDailyKcal: (state, {payload}) => {
      state.dayli_kcal += payload
    },
    onUpdateRecipe: (state, { payload }) => {
      state.updateRecipe = payload;
    },
    onSelectType: (state, { payload }) => {
      state.type = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(startCreatingRecipe.fulfilled, (state, action) => {
        state.recipe.push(action.payload);
      })
      .addCase(startCreatingRecipe.pending, (state) => {
        state.is_loading_recipe = true;
      })
      .addCase(startCreatingRecipe.rejected, (state) => {
        state.is_loading_recipe = false;
      })
      .addCase(startGettingRecipes.pending, (state) => {
        state.is_loading_recipe = true;
      })
      .addCase(startGettingRecipes.fulfilled, (state, action) => {
        state.recipe = action.payload;
        state.is_loading_recipe = false;
      })
      .addCase(startGettingRecipes.rejected, (state) => {
        state.is_loading_recipe = false;
      })
      .addCase(startGettingFriendRecipes.fulfilled, (state, action) => {
        state.friendRecipe = action.payload;
      })
      .addCase(startCreatingMeal.fulfilled, (state, action) => {
        state.meals.push(action.payload);
        state.is_loading_meals = false;
      })
      .addCase(startCreatingMeal.pending, (state) => {
        state.is_loading_meals = true;
      })
      .addCase(startCreatingMeal.rejected, (state) => {
        state.is_loading_meals = false;
      })
      .addCase(startUpdatingRecipe.fulfilled, (state, action) => {
        state.recipe = state.recipe.map(reci => {
          if (reci.id === action.payload.id) {
            return action.payload;
          }
          return reci;
        })
        state.updateRecipe = recipeInitBody;
      })
      .addCase(startUpdatingRecipe.pending, (state) => {
        state.is_loading_recipe = true;
      })
      .addCase(startUpdatingRecipe.rejected, (state) => {
        state.is_loading_recipe = false;
      })
      .addCase(startGettingMeals.pending, (state) => {
        state.is_loading_meals = true;
      })
      .addCase(startGettingMeals.fulfilled, (state, action: PayloadAction<Recipe[]>) => {
        state.updateRecipe = recipeInitBody;
        state.meals = action.payload;
        state.is_loading_meals = false;
      })
      .addCase(startGettingMeals.rejected, (state) => {
        state.is_loading_meals = false;
      })
      .addCase(startGettingCategories.pending, (state) => {
        state.is_loading_categories = true;
      })
      .addCase(startGettingCategories.fulfilled, (state, action) => {
        state.categories = action.payload;
        state.is_loading_categories = false;
      })
      .addCase(startGettingCategories.rejected, (state) => {
        state.is_loading_categories = false;
      })
      .addCase(startGettingByCategory.fulfilled, (state, action) => {
        state.recipe.map(res => res.id === action.payload);
      })
      .addCase(startGettingDailyKcal.fulfilled, (state, action) => {
        state.dayli_kcal = action.payload.achieved_kcal;
      })
      .addCase(startGettingWeeklyKcal.pending, (state) => {
        state.is_loading_achieved_weekly_kcal = true;
      })
      .addCase(startGettingWeeklyKcal.fulfilled, (state, action) => {
        state.achieved_weekly_kcal = action.payload.achieved_weekly_kcal;
        state.is_loading_achieved_weekly_kcal = false;
      })
      .addCase(startGettingWeeklyKcal.rejected, (state) => {
        state.is_loading_achieved_weekly_kcal = false;
      });
  }
})

export const { sumDailyKcal, onUpdateRecipe,onSelectType } = foodSlice.actions
export default foodSlice.reducer 


