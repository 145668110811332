import { useState } from "react";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { markVideoAsViewed, updateView } from "../../store/slices/course/thunks";
import { OnProgressProps } from "react-player/base";
import useLastVideoSeen from "./useLastVideoSeen";

const useVideoContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const { HandleSetLastVideoSeen } = useLastVideoSeen();
  const [isReady, setIsReady] = useState(false);
  const { courseSelected, lastVideoSeen } = useSelector(
    (state: RootState) => state.course
  );

  const onReady = (player: ReactPlayer) => {
    if (!courseSelected) return;
    if (!isReady) {
      const view = courseSelected.videos.find(
        (video) => video.video_id === lastVideoSeen
      )?.views;

      const lastMinute = view?.last_minute ?? "";

      if (lastMinute === "") return;
      const [minutes, seconds] = lastMinute.split(":");
      const time = parseInt(minutes) * 60 + parseInt(seconds);
      player.seekTo(time);
      setIsReady(true);
    }
  };

  const calculateLastMinute = (playedSeconds: number) => {
    const minutes = Math.floor(playedSeconds / 60);
    const seconds = Math.floor(playedSeconds % 60);
    return `${minutes < 10 ? "0" + minutes : minutes}:${
      seconds < 10 ? "0" + seconds : seconds
    }`;
  };

  
  const handleProgress = (state: OnProgressProps) => {
    if (state.playedSeconds < 1) return;
    dispatch(
      updateView({
        videoId: lastVideoSeen,
        lastMinute: calculateLastMinute(state.playedSeconds),
      })
    );
  };

  const handleEnded = () => {
    dispatch(markVideoAsViewed({ videoId: lastVideoSeen }));
    const indexCurrentVideo = courseSelected?.videos.findIndex(
      (video) => video.video_id === lastVideoSeen
    );

    if (indexCurrentVideo === (courseSelected?.videos.length as number) - 1) {
      return;
    } else {
      HandleSetLastVideoSeen(
        courseSelected?.videos[(indexCurrentVideo as number) + 1]
          .video_id as string
      );
    }
  };

  return { onReady, handleProgress, handleEnded, isReady, setIsReady };

}

export default useVideoContainer