import { useRef, useState } from "react"
import { ButtonSquad, LabelSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { useNavigate } from "react-router-dom";
import { startResetUserPassword } from "../../../store/slices/auth/thunks";
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft";
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight";

const initialState: any = {
  "one": "",
  "two": "",
  "three": "",
  "four": ""
};

export const EmailCodeScreen = () => {

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const [code, setCode] = useState(initialState);

  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);

  const handleOnChange = (value: string, nextInputRef: React.RefObject<HTMLInputElement>) => {
    if (value.length === 1 && nextInputRef.current) {
      nextInputRef.current.focus();
    }
  };

  const onSendCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const finalCode: string = code.join('');
    const email = localStorage.getItem("userEmail")!;
    if (email !== null) {
      dispatch(startResetUserPassword({email, code: finalCode})).then(res => {
        if (res.meta.requestStatus === "fulfilled") {
          navigate('/auth/message');
        }
      });
    }
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('text');
    setCode({
      "one": pasteData.charAt(0).toUpperCase(),
      "two": pasteData.charAt(1).toUpperCase(),
      "three": pasteData.charAt(2).toUpperCase(),
      "four": pasteData.charAt(3).toUpperCase()
    })
    input4Ref.current?.focus();
    (input4Ref.current as HTMLInputElement).value = pasteData.charAt(3).toUpperCase();
  };

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        <img src="/logo_color.png" className="h-40 max-w-full" alt=""/>
        <div className="justify-start items-start">
          <LabelSquad children="Ingrese el código de seguridad"/>
        </div>
        <form onSubmit={onSendCode}>
          <div className="flex flex-row mt-4 ">
            <input 
              ref={input1Ref}
              className="w-14 h-14 border-2 focus:ring-1 items-center justify-center text-center text-lg border-gray-80 rounded-lg" 
              maxLength={1}
              type="text"
              onPaste={handlePaste}
              value={code["one"]}
              onChange={(e) => {
                setCode((prev: any) => ({
                  ...prev,
                  "one": e.target.value.toUpperCase()
                }))
                handleOnChange(e.target.value, input2Ref);
              }}
            />
            <input 
              ref={input2Ref}
              className="w-14 h-14 border-2 items-center justify-center text-center text-lg border-gray-80 rounded-lg ml-8" 
              maxLength={1}
              value={code["two"]}
              type="text"
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  input1Ref.current?.focus();
                }
              }}
              onChange={(e) => {
                setCode((prev: any) => ({
                  ...prev,
                  "two": e.target.value.toUpperCase()
                }))
                handleOnChange(e.target.value, input3Ref);
              }}
            />
            <input 
              ref={input3Ref}
              className="w-14 h-14 border-2 items-center justify-center text-center text-lg border-gray-80 rounded-lg ml-8" 
              maxLength={1}
              value={code["three"]}
              type="text"
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  input2Ref.current?.focus();
                }
              }}
              onChange={(e) => {
                setCode((prev: any) => ({
                  ...prev,
                  "three": e.target.value.toUpperCase()
                }))
                handleOnChange(e.target.value, input4Ref);
              }}
            />
            <input 
              ref={input4Ref}
              className="w-14 h-14 border-2 items-center justify-center text-center text-lg border-gray-80 rounded-lg ml-8" 
              maxLength={1}
              value={code["four"]}
              type="text"
              onKeyDown={(e) => {
                if (e.key === "Backspace") {
                  setCode((prev: any) => ({
                    ...prev,
                    "four": ""
                  }))
                  input3Ref.current?.focus();
                }
              }}
              onChange={(e) => setCode((prev: any) => ({
                ...prev,
                "four": e.target.value.toUpperCase()
              }))}
            />
          </div>
          <div className="mt-6">
            <ButtonSquad
              buttonType={ButtonType.OrangeSmall}
              label="Confirmar"
              type="submit"
              onClick={() => setCode(initialState)}
            />
          </div>
        </form>
      </div>
      <div className="max-[800px]:hidden">
        <BackgroundTopLeft />
        <BackgroundBottomRight />
      </div>
    </div>
  )
}
