import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { deletePost, getPostComments, startCreatingNewPost, startGettingPostFrom, startGettingPosts, startUpdatingPost } from './thunks'
import { DeletePostPayload, UpdatePostPayload, CreateCommentResponse, PostFromUser, FriendsPost, CommentsList, Like } from '../../interfaces/social/interfacePost';
import { newLike } from '../social/thunks';

export interface PostState {
  postSelected: PostFromUser
  postFromUser: PostFromUser[]
  is_loading_post_from_user: boolean,
  postFeed: FriendsPost[],
  commentList: CommentsList[],
  is_loading_new_post: boolean,
  is_loading_post_feed: boolean,
  is_loading_comments: boolean,
  is_view_comments: boolean,
}

const postInitBody: PostFromUser = {
  id: '',
  user_id: '',
  post_text: '',
  image_url: '',
  username: '',
  profile_picture: '',
  created_at: '',
  like_count: '',
  comment_count: '',
  liked: false
}

const initialState: PostState = {
  postSelected: postInitBody,
  postFromUser: [],
  is_loading_post_from_user: false,
  postFeed: [],
  commentList: [],
  is_loading_new_post: false,
  is_loading_post_feed: false,
  is_loading_comments: false,
  is_view_comments: false
}

const addNextPage = (prevState: FriendsPost[], nextPosts: FriendsPost[]) => {
  if (prevState.length === 0) {
    return nextPosts;
  }

  const nextPost = nextPosts[0];
  if (prevState.some((post) => post.id === nextPost.id)){
    return prevState;
  } else {
    return prevState.concat(nextPosts);
  }
}

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setPostSelected: (state, action: PayloadAction<PostFromUser>) => {
      state.postSelected = action.payload
    },
    setViewComment: (state, action) => {
      state.is_view_comments = action.payload
    },
    cleanPostSelected: (state) => {
      state.postSelected = postInitBody;
    },
    addNewComment: (
      state,
      action: PayloadAction<{
        comment: CreateCommentResponse,
        user: { username: string,  profile_picture: string}
      }>
    ) => {
      state.commentList = [...state.commentList, {
        comment_id: action.payload.comment.id,
        comment_text: action.payload.comment.comment_text,
        created_at: action.payload.comment.created_at,
        profile_picture: action.payload.user.profile_picture,
        username: action.payload.user.username,
        user_id: action.payload.comment.user_id,
      }];
      state.postSelected = state.postSelected.id !== "" && state.postSelected.id === action.payload.comment.post_id
        ? {
            ...state.postSelected,
            comment_count: (parseInt(state.postSelected.comment_count) + 1).toString()
          }
        : state.postSelected;

      state.postFeed = state.postFeed.map((post) => {
        if (post.id === action.payload.comment.post_id) {
          return {...post, comment_count: (parseInt(post.comment_count) + 1).toString()}
        }
        return post;
      })
    },
    clearComments: (state) => {
      state.commentList = [];
    },
    clearPostsFrom: (state) => {
      state.postFromUser = [];
    },
    setNewLike: (state, action: PayloadAction<Like>) => {
      state.postFeed = state.postFeed.map((post) => {
        if (post.id === action.payload.post_id && post.liked !== action.payload.liked) {
          return {
            ...post,
            liked: action.payload.liked,
            like_count: action.payload.liked ? (parseInt(post.like_count) + 1).toString() : (parseInt(post.like_count) - 1).toString()
          }
        }
        return post;
      })
      state.postFromUser = state.postFromUser.map((post) => {
        if (post.id === action.payload.post_id && post.liked !== action.payload.liked) {
          return {
            ...post,
            liked: action.payload.liked,
            like_count: action.payload.liked ? (parseInt(post.like_count) + 1).toString() : (parseInt(post.like_count) - 1).toString()
          }
        }
        return post;
      })
      state.postSelected = state.postSelected.id !== "" && state.postSelected.id === action.payload.post_id && state.postSelected.liked !== action.payload.liked
        ? {
          ...state.postSelected,
          liked: action.payload.liked,
          like_count: action.payload.liked ? (parseInt(state.postSelected.like_count) + 1).toString() : (parseInt(state.postSelected.like_count) - 1).toString()
        }
        : state.postSelected;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(startCreatingNewPost.fulfilled, (state, action) => {
        state.postFromUser.push(action.payload);
        state.is_loading_new_post = false;
      })
      .addCase(startCreatingNewPost.pending, (state) => {
        state.is_loading_new_post = true;
      })
      .addCase(startGettingPosts.pending, (state) => {
        state.is_loading_post_feed = true;
      })
      .addCase(startGettingPosts.fulfilled, (state, action: PayloadAction<FriendsPost[]>) => {
        state.postFeed = addNextPage(state.postFeed, action.payload);
        state.is_loading_post_feed = false;
      })
      .addCase(startGettingPosts.rejected, (state) => {
        state.is_loading_post_feed = false;
      })
      .addCase(startGettingPostFrom.pending, (state) => {
        state.is_loading_post_from_user = true;
      })
      .addCase(startGettingPostFrom.fulfilled, (state, action) => {
        state.postFromUser = action.payload;
        state.is_loading_post_from_user = false;
      })
      .addCase(startGettingPostFrom.rejected, (state) => {
        state.is_loading_post_from_user = false;
      })
      .addCase(startUpdatingPost.fulfilled, (state, { payload }: PayloadAction<UpdatePostPayload>) => {
        state.postFromUser = state.postFromUser.map<PostFromUser>(post => post.id === payload.id
          ? ({...post, post_text : payload.post_text })
          : post
        )
      })
      .addCase(deletePost.fulfilled, (state, { payload }: PayloadAction<DeletePostPayload>) => {
        state.postFromUser = state.postFromUser.filter((post) => post.id !== payload.id)
      })
      .addCase(getPostComments.fulfilled, (state, action) => {
        state.commentList = action.payload;
        state.is_loading_comments = false;
      })
      .addCase(getPostComments.rejected, (state) => {
        state.is_loading_comments = false;
      })
      .addCase(getPostComments.pending, (state) => {
        state.is_loading_comments = true;
      })
      .addCase(newLike.fulfilled, (state, action: PayloadAction<Like>) => {
        state.postFeed = state.postFeed.map((post) => {
          if (post.id === action.payload.post_id && post.liked !== action.payload.liked) {
            return {
              ...post,
              liked: action.payload.liked,
              like_count: action.payload.liked ? (parseInt(post.like_count) + 1).toString() : (parseInt(post.like_count) - 1).toString()
            }
          }
          return post;
        })
        state.postFromUser = state.postFromUser.map((post) => {
          if (post.id === action.payload.post_id && post.liked !== action.payload.liked) {
            return {
              ...post,
              liked: action.payload.liked,
              like_count: action.payload.liked ? (parseInt(post.like_count) + 1).toString() : (parseInt(post.like_count) - 1).toString()
            }
          }
          return post;
        })
        state.postSelected = state.postSelected.id !== "" && state.postSelected.id === action.payload.post_id && state.postSelected.liked !== action.payload.liked
          ? {
            ...state.postSelected,
            liked: action.payload.liked,
            like_count: action.payload.liked ? (parseInt(state.postSelected.like_count) + 1).toString() : (parseInt(state.postSelected.like_count) - 1).toString()
          }
          : state.postSelected;
      })
  }
})

export const { setPostSelected, setViewComment, clearPostsFrom, cleanPostSelected, addNewComment, clearComments, setNewLike } = postSlice.actions
export default postSlice.reducer