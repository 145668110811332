import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import ChatInputBox from "./ChatInputBox";
import HeaderChat from "./HeaderChat";
import OtherMessage from "./OtherMessage";
import OwnMessage from "./OwnMessage";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import {
  UpdateMessageViewed,
  addMessage,
} from "../../../../../socket-clients/social";
import CircularProgress from "../../../../components/CircularProgress";
import { getHistoryOldestMessage } from "../../../../../store/slices/social/thunks";
import { DateTime } from "luxon";
import { HistoryMessage } from "../../../../../store/interfaces/social/interfaceSocial";
import MessageSentedLabel from "./MessageSentedLabel";

const ChatContainer = () => {
  const dispatch: AppDispatch = useDispatch();
  const { uid: user_id } = useSelector((state: RootState) => state.auth);
  const { historyMessage, chatSelected, is_loading_history_message } =
    useSelector((state: RootState) => state.social);

  const [newMessage, setNewMessage] = useState<string>("");
  const [buffer, setBuffer] = useState<string[]>([]);
  const scrollMessages = useRef<HTMLDivElement | null>(null);
  const [isOnTop, setIsOnTop] = useState(false);
  const [isOnBottom, setIsOnBottom] = useState(false);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (scrollMessages.current) {
        if (
          scrollMessages.current !== null &&
          !isOnBottom &&
          historyMessage.length > 0 &&
          !is_loading_history_message
        ) {
          scrollMessages.current.scrollTop =
            scrollMessages.current.scrollHeight -
            scrollMessages.current.clientHeight;
          setIsOnBottom(true);
        }
        if (
          scrollMessages.current.scrollTop === 0 &&
          !isOnTop &&
          historyMessage.length > 0
        ) {
          dispatch(
            getHistoryOldestMessage({
              friend_id: chatSelected?.friend_id as string,
              oldestMessageSentAt: historyMessage[0].sent_at,
            })
          ).then((res) => {
            if (
              res.meta.requestStatus === "fulfilled" &&
              res.payload.length === 0
            ) {
              setIsOnTop(true);
            }
            if (scrollMessages.current && res.payload.length > 0) {
              scrollMessages.current.scrollTop =
                scrollMessages.current.clientHeight;
            }
          });
        }
      }
    };

    scrollMessages.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollMessages.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isOnTop, isOnBottom, is_loading_history_message]);

  useLayoutEffect(() => {
    if (scrollMessages.current !== null) {
      if (
        scrollMessages.current !== null &&
        !isOnBottom &&
        historyMessage.length > 0 &&
        !is_loading_history_message
      ) {
        scrollMessages.current.scrollTop =
          scrollMessages.current.scrollHeight -
          scrollMessages.current.clientHeight;
      }
    }
  }, [is_loading_history_message]);

  useEffect(() => {
    if (
      chatSelected !== null &&
      historyMessage.some(
        (message) => message.friend_id === chatSelected.friend_id
      ) &&
      historyMessage.filter(
        (message) => !message.read && message.sender_id !== user_id
      ).length > 0
    ) {
      const timer = setTimeout(() => {
        setBuffer(
          historyMessage
            .filter((message) => !message.read && message.sender_id !== user_id)
            .map((message) => message.message_id)
        );
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [historyMessage]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (buffer.length > 0) {
        UpdateMessageViewed(
          buffer,
          chatSelected?.user_id as string,
          chatSelected?.friend_id as string
        );
        setBuffer([]);
      }
    }, 2000);

    return () => clearTimeout(interval);
  }, [buffer]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const sendMsg = () => {
    addMessage(
      chatSelected?.user_id as string,
      chatSelected?.friend_id as string,
      newMessage
    );
    setNewMessage("");
  };

  const diff = (
    prevMessage: HistoryMessage,
    currentMessage: HistoryMessage
  ) => {
    const diffHours = DateTime.fromJSDate(new Date(currentMessage.sent_at))
      .diff(DateTime.fromJSDate(new Date(prevMessage.sent_at)), ["hours"])
      .toObject().hours;

    return diffHours !== undefined ? diffHours >= 1 : false;
  };

  return (
    <div className="flex flex-col divide-y h-full">
      <HeaderChat
        user_id={chatSelected?.user_id as string}
        name={chatSelected?.username as string}
        profileImage={chatSelected?.profile_picture as string}
      />
      <div className="flex flex-col pt-5 justify-between h-full">
        <div className="basis-11/12">
          <div
            ref={scrollMessages}
            className="flex flex-col p-4"
            style={{
              gap: 5,
              overflow: "auto",
              overflowX: "hidden",
              height: "calc(100vh - 280px)",
              maxHeight: "calc(100vh - 280px)",
            }}
          >
            {!is_loading_history_message || historyMessage.length > 0 ? (
              <>
                {is_loading_history_message ? (
                  <div className="flex justify-center py-2">
                    <div
                      className="w-5 h-5 border-2 border-blue-500 rounded-full animate-spin"
                      style={{ borderTopColor: "transparent" }}
                    ></div>
                  </div>
                ) : null}
                {historyMessage.map((message, index) =>
                  message.receiver_id !== user_id ? (
                    <>
                      {index === 0 ? (
                        <MessageSentedLabel sent_at={message.sent_at} />
                      ) : null}
                      {diff(
                        index === 0
                          ? historyMessage[0]
                          : historyMessage[index - 1],
                        message
                      ) ? (
                        <MessageSentedLabel sent_at={message.sent_at} />
                      ) : null}
                      <OwnMessage
                        key={message.message_id}
                        id={message.message_id}
                        message={message.message_text}
                        sent_at={message.sent_at}
                        is_viewed={message.read}
                      />
                    </>
                  ) : (
                    <>
                      {index === 0 ? (
                        <MessageSentedLabel sent_at={message.sent_at} />
                      ) : null}
                      {diff(
                        index === 0
                          ? historyMessage[0]
                          : historyMessage[index - 1],
                        message
                      ) ? (
                        <MessageSentedLabel sent_at={message.sent_at} />
                      ) : null}
                      <OtherMessage
                        key={message.message_id}
                        id={message.message_id}
                        message={message.message_text}
                        sent_at={message.sent_at}
                      />
                    </>
                  )
                )}
              </>
            ) : (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
        <div className="basis-1/12 p-4 flex flex-row">
          <ChatInputBox
            value={newMessage}
            onChange={handleChange}
            sendMsg={sendMsg}
          />
        </div>
      </div>
    </div>
  );
};

export default ChatContainer;
