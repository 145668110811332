import { useAlert } from '../../../hooks/useAlert';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import { ButtonSquad } from '../../../../core/components';
import { ButtonType } from '../../../../core/interfaces/ButtonSquadInterface';
import { Coach } from '../../../../store/interfaces/coach/interfaceCoach';
const API_BREVO = process.env.REACT_APP_BREVO_API_KEY;
interface ContactFormProps {
  initialEmail: string;
  initialUsername: string;
  initialSubject: string;
  coach: Coach;
}

// Definir los tipos para los valores del formulario

const ContactForm = ({ initialEmail, initialUsername, initialSubject, coach }:ContactFormProps) => {
  const [num1, setNum1] = useState(Math.floor(Math.random() * 10) + 1);
  const [num2, setNum2] = useState(Math.floor(Math.random() * 10) + 1);
  interface FormValues {
    name: string;
    country: string;
    email: string;
    username: string;
    subject: string;
    goal: string;
    description: string;
    privacyPolicy: boolean;
    dataUsage: boolean;
    captcha: string;
  }
  
  // Validación con Yup
  const validationSchema = Yup.object({
    name: Yup.string().required('El nombre es obligatorio'),
    country: Yup.string().required('El país de residencia es obligatorio'),
    email: Yup.string().email('Email inválido').required('El email es obligatorio'),
    goal: Yup.string().required('El objetivo es obligatorio'),
    description: Yup.string().required('La descripción es obligatoria'),
    privacyPolicy: Yup.bool().oneOf([true], 'Debes aceptar la política de privacidad'),
    dataUsage: Yup.bool().oneOf([true], 'Debes aceptar el uso de datos personales'),
    captcha: Yup.string()
        .required('Debes completar el captcha')
        .test('is-correct-captcha', 'Captcha incorrecto', (value) => {
          const correctSum = num1 + num2;
          return value === String(correctSum);
        }), // Validar el captcha en base a la suma
  });
  
  // Valores iniciales del formulario
  const initialValues: FormValues = {
    name: '',
    subject: initialSubject,
    country: '',
    email: initialEmail, // Predefinido y no editable
    username: initialUsername, // Predefinido y no editable
    goal: '',
    description: '',
    privacyPolicy: false,
    dataUsage: false,
    captcha: '',
  };

  // Manejar el envío del formulario
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const handleSubmit = (values: FormValues) => {
    const emailTemplate = `
    <!DOCTYPE html>
    <html>
    <head>
      <meta charset="UTF-8">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <style>
        body { font-family: Arial, sans-serif; background-color: #f4f4f4; margin: 0; padding: 0; -webkit-font-smoothing: antialiased; }
        .email-container { max-width: 600px; margin: 0 auto; background-color: #ffffff; padding: 20px; border-radius: 8px; box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1); }
        h1 { color: #333333; }
        p { color: #555555; line-height: 1.6; }
        .email-header, .email-footer { background-color: #007BFF; color: #ffffff; text-align: center; padding: 10px; border-radius: 8px 8px 0 0; }
        .email-footer { border-radius: 0 0 8px 8px; font-size: 12px; }
        .data-section { background-color: #f9f9f9; padding: 10px; border-radius: 8px; margin-bottom: 10px; }
        .data-section strong { color: #333333; }
      </style>
    </head>
    <body>
      <div class="email-container">
        <div class="email-header">
          <h1>Formulario de Contacto</h1>
        </div>

        <p>Se ha enviado un nuevo mensaje desde el formulario de contacto. Aquí están los detalles:</p>

        <div class="data-section">
          <p><strong>Nombre:</strong> ${values.name}</p>
          <p><strong>País de residencia:</strong> ${values.country}</p>
          <p><strong>Email:</strong> ${values.email}</p>
          <p><strong>Objetivo:</strong> ${values.goal}</p>
          <p><strong>Descripción:</strong> ${values.description}</p>
          <p><strong>Aceptación de política de privacidad:</strong> ${values.privacyPolicy ? 'Sí' : 'No'}</p>
          <p><strong>Aceptación del uso de datos:</strong> ${values.dataUsage ? 'Sí' : 'No'}</p>
          <p><strong>Captcha:</strong> ${values.captcha}</p>
        </div>

        <div class="email-footer">
          <p>Este correo se generó automáticamente desde el formulario de contacto de la web.</p>
        </div>
      </div>
    </body>
    </html>
  `;

    // Parámetros del email
    const emailData = {
      sender: { name: values.username??values.name, email: values.email },
      to: [{ email: "hola@squatfit.es", name: "Squat fit" }],
      subject: initialSubject,
      htmlContent: emailTemplate,
    };

    // Enviar correo usando la API de Brevo
    axios.post('https://api.brevo.com/v3/smtp/email', emailData, {
      headers: {
        'api-key': API_BREVO,
        'Content-Type': 'application/json',
      },
    })
    .then((_) => {
      showAlert({
        type: "success",
        message: "Email enviado exitosamente!",
      });
      navigate('/');
    })
    .catch((_) => {
      showAlert({
        type: "error",
        message: "Error al enviar el email.",
      });
    });
  };

  return (
    <div className="max-w-md mx-auto my-8 bg-white p-8 border border-gray-300 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold mb-6">Formulario de Contacto</h2>

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {() => (
          <Form>
            {/* Campo de Nombre */}
            <div className="mb-4">
              <label htmlFor="name" className="block text-gray-700 font-semibold mb-2">
                Tu nombre
              </label>
              <Field
                type="text"
                id="name"
                name="name"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
                placeholder="Tu nombre"
              />
              <ErrorMessage name="name" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Campo de País de residencia */}
            <div className="mb-4">
              <label htmlFor="country" className="block text-gray-700 font-semibold mb-2">
                País de residencia
              </label>
              <Field
                type="text"
                id="country"
                name="country"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
                placeholder="País de residencia"
              />
              <ErrorMessage name="country" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Campo de Email (readonly) */}
            <div className="mb-4">
              <label htmlFor="email" className="block text-gray-700 font-semibold mb-2">
                Tu email
              </label>
              <Field
                type="email"
                id="email"
                name="email"
                readOnly
                className="w-full px-4 py-2 border border-gray-300 rounded-md bg-gray-100 cursor-not-allowed"
              />
              <ErrorMessage name="email" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Campo de Objetivo (select) */}
            <div className="mb-4">
              <label htmlFor="goal" className="block text-gray-700 font-semibold mb-2">
                Tu objetivo
              </label>
              <Field
                as="select"
                id="goal"
                name="goal"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
              >
                <option value="">Selecciona tu objetivo</option>
                <option value="Perder Grasa">Perder Grasa</option>
                <option value="Ganar Músculo">Ganar Músculo</option>
                <option value="Mejorar Rendimiento">Mejorar Rendimiento</option>
                <option value="Recomposición">Recomposición</option>
              </Field>
              <ErrorMessage name="goal" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Campo de Descripción */}
            <div className="mb-4">
              <label htmlFor="description" className="block text-gray-700 font-semibold mb-2">
                Preguntas o Comentarios que le quieras hacer a {coach.firstName}
              </label>
              <Field
                as="textarea"
                id="description"
                name="description"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
                placeholder="Escribe aquí tu mensaje"
                rows={4}
              />
              <ErrorMessage name="description" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Casilla de aceptación de la Política de Privacidad */}
            <div className="mb-4">
              <Field type="checkbox" id="privacyPolicy" name="privacyPolicy" />
              <label htmlFor="privacyPolicy" className="ml-2">
                He leído y acepto la <a href="/privacy-policy" className="text-blue-500">Política de Privacidad</a>
              </label>
              <ErrorMessage name="privacyPolicy" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Casilla de aceptación del uso de datos */}
            <div className="mb-4">
              <Field type="checkbox" id="dataUsage" name="dataUsage" />
              <label htmlFor="dataUsage" className="ml-2">
                Acepto que mis datos personales sean empleados para las finalidades comerciales por medios electrónicos
              </label>
              <ErrorMessage name="dataUsage" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Captcha simple */}
            <div className="mb-4">
              <label htmlFor="captcha" className="block text-gray-700 font-semibold mb-2">
                Captcha: {num1} + {num2} =
              </label>
              <Field
                type="text"
                id="captcha"
                name="captcha"
                className="w-16 px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring"
              />
              <ErrorMessage name="captcha" component="div" className="text-red-500 text-sm" />
            </div>

            {/* Botón de Enviar */}
            <ButtonSquad
            full={true}
            label='Enviar'
            buttonType={ButtonType.BlueFull}
              type="submit"
              className="w-full bg-blue-500 text-white font-semibold py-2 px-4 rounded-md hover:bg-blue-600"
            />
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ContactForm;
