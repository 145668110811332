import { Navigate, Outlet, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getBooksByUser } from "../../store/slices/book/thunks";
import { UserBook } from "../../store/interfaces/book/interfaceBook";
import { useEffect, useState } from "react";


const ValidateBookPurchased = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { userBooks } = useSelector((state: RootState) => state.book);
  const [userHasBook, setUserHasBook] = useState<UserBook | null>(null);
  const [loadComponent, setLoadComponent] = useState(false);

  useEffect(() => {
    if (userBooks.length === 0) {
      dispatch(getBooksByUser()).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          const book = (result.payload as UserBook[]).find((book: UserBook) => book.id === id);
          setUserHasBook(book ?? null);
          setLoadComponent(true);
        }
      });
    } else {
      const book = userBooks.find((book: UserBook) => book.id === id);
      setUserHasBook(book ?? null);
      setLoadComponent(true);
    }
  }, []);

  return loadComponent ? (userHasBook ? <Outlet /> : <Navigate to="/mis-compras" />) : null;
}

export default ValidateBookPurchased