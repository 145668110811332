import { useState } from 'react';
import { ReactComponent as ChevronUp } from '../../../../assets/icons/chevron_up.svg';
import { ReactComponent as ChevronDown } from '../../../../assets/icons/chevron_down.svg';
import { Row } from '../../../../core/components/Row';

interface FaqComponentProps {
  title: string;
  color?: string;
  subtitle: string;
  borderBottom?: boolean;
  borderTop?: boolean;
}

export const FaqComponent = ({ title, subtitle, borderBottom = true, borderTop = true,color='text-roseSquat' }:FaqComponentProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div
      className={`bg-[#f8fbfb] mb-4 rounded-t-${borderTop ? 'lg' : 'none'} border border-gray-200 shadow-sm rounded-b-${borderBottom ? 'lg' : 'none'} p-4`}
    >
      <Row
        mainAxisAlignment='between'
        className="cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className={`font-semibold ${color} text-lg`}>{title}</h2>
        {
          isOpen
            ?<ChevronUp className='w-4 h-4'/>
            :<ChevronDown className='w-4 h-4'/>
        }
      </Row>
      {isOpen && (
        <div className="mt-4">
          <hr className="border-gray-300 mb-4" />
          <p className="text-gray-600" style={{ whiteSpace: 'pre-wrap' }}>{subtitle}</p>
        </div>
      )}
    </div>
  );
};
