import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  ChatSelected,
  HistoryMessage,
  ListChats,
  SearchMessage,
} from "../../interfaces/social/interfaceSocial";
import {
  getChatList,
  getHistoryMessage,
  getHistoryOldestMessage,
  searchMessage,
  setMessageViewed,
  setNewMessage,
} from "./thunks";

interface SocialState {
  listChats: ListChats[];
  is_loading_chat_list: boolean;
  historyMessage: HistoryMessage[];
  is_loading_history_message: boolean;
  searchMessage: SearchMessage[];
  is_loading_search_message: boolean;
  chatSelected: ChatSelected | null;
  showListChats: boolean;
}

const initialState: SocialState = {
  listChats: [],
  is_loading_chat_list: false,
  historyMessage: [],
  is_loading_history_message: false,
  searchMessage: [],
  is_loading_search_message: false,
  chatSelected: null,
  showListChats: false,
};

const pushChatOnTop = (listChats: ListChats[], payload: ListChats) => {
  const chat = listChats.find((chat) => chat.friend_id === payload.friend_id);
  if (!chat) return [payload, ...listChats];

  const editedChat: ListChats = {
    ...chat,
    message_text: payload.message_text,
    sent_at: payload.sent_at,
    read: payload.read,
    unread_messages: payload.unread_messages,
  };
  return [
    editedChat,
    ...listChats.filter((chat) => chat.friend_id !== payload.friend_id),
  ];
};

const sortHistoryMessages = (historyMessages: HistoryMessage[]) => {
  return historyMessages.sort((a, b) => {
    const dateA = new Date(a.sent_at).getTime();
    const dateB = new Date(b.sent_at).getTime();
    return dateA - dateB;
  });
};

export const socialSlice = createSlice({
  name: "social",
  initialState,
  reducers: {
    setSelectedChat: (state, action: PayloadAction<ChatSelected>) => {
      state.chatSelected = action.payload;
      localStorage.setItem("chatSelected", JSON.stringify(action.payload));
    },
    setShowListChats: (state, action: PayloadAction<boolean>) => {
      state.showListChats = action.payload;
    },
    cleanSelectedChat: (state) => {
      state.chatSelected = null;
      localStorage.removeItem("chatSelected");
    },
    cleanHistoryMessage: (state) => {
      state.historyMessage = [];
    },
    searchListChat: (state, action: PayloadAction<string>) => {
      if (action.payload === "") return;
      if (
        state.listChats.length > 0
        && (JSON.parse(localStorage.getItem("originalListChats") || "[]") as ListChats[]).length === 0
      ) localStorage.setItem("originalListChats", JSON.stringify(state.listChats));
      state.listChats = (JSON.parse(localStorage.getItem("originalListChats") || "[]") as ListChats[]).filter((chat) =>
        chat.username.includes(action.payload)
      );
    },
    clearSearchListChat: (state) => {
      state.listChats = JSON.parse(
        localStorage.getItem("originalListChats") || "[]"
      );
      localStorage.removeItem("originalListChats");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        setNewMessage,
        (
          state,
          action: PayloadAction<{ chat: ListChats; message: HistoryMessage }>
        ) => {
          const payload = action.payload;
          state.listChats = pushChatOnTop(state.listChats, payload.chat);
          state.historyMessage =
            state.chatSelected?.friend_id === payload.chat.friend_id
              ? [...state.historyMessage, payload.message]
              : state.historyMessage;
        }
      )
      .addCase(
        setMessageViewed,
        (state, action: PayloadAction<HistoryMessage[]>) => {
          state.historyMessage = state.historyMessage.map((chat) => {
            const message = action.payload.find(
              (message) => message.message_id === chat.message_id
            );
            return message ? { ...chat, read: message.read } : chat;
          });
          state.listChats = state.listChats.map((chat) => {
            const message = action.payload.find(
              (message) => message.friend_id === chat.friend_id
            );
            return message
              ? {
                  ...chat,
                  unread_messages: message.read ? "0" : chat.unread_messages,
                }
              : chat;
          });
        }
      )
      .addCase(getChatList.pending, (state) => {
        state.is_loading_chat_list = true;
      })
      .addCase(
        getChatList.fulfilled,
        (state, action: PayloadAction<ListChats[]>) => {
          state.listChats = action.payload;
          state.is_loading_chat_list = false;
        }
      )
      .addCase(getChatList.rejected, (state) => {
        state.listChats = [];
        state.is_loading_chat_list = false;
      })
      .addCase(getHistoryMessage.pending, (state) => {
        state.is_loading_history_message = true;
      })
      .addCase(
        getHistoryMessage.fulfilled,
        (state, action: PayloadAction<HistoryMessage[]>) => {
          state.historyMessage = sortHistoryMessages(action.payload);
          state.is_loading_history_message = false;
        }
      )
      .addCase(getHistoryMessage.rejected, (state) => {
        state.historyMessage = [];
        state.is_loading_history_message = false;
      })
      .addCase(getHistoryOldestMessage.pending, (state) => {
        state.is_loading_history_message = true;
      })
      .addCase(
        getHistoryOldestMessage.fulfilled,
        (state, action: PayloadAction<HistoryMessage[]>) => {
          state.historyMessage =
            action.payload.length > 0
              ? sortHistoryMessages([
                  ...action.payload,
                  ...state.historyMessage,
                ])
              : state.historyMessage;
          state.is_loading_history_message = false;
        }
      )
      .addCase(getHistoryOldestMessage.rejected, (state) => {
        state.is_loading_history_message = false;
      })
      .addCase(searchMessage.pending, (state) => {
        state.is_loading_search_message = true;
      })
      .addCase(
        searchMessage.fulfilled,
        (state, action: PayloadAction<SearchMessage[]>) => {
          state.searchMessage = action.payload;
          state.is_loading_search_message = false;
        }
      )
      .addCase(searchMessage.rejected, (state) => {
        state.searchMessage = [];
        state.is_loading_search_message = false;
      });
  },
});

export const {
  cleanSelectedChat,
  cleanHistoryMessage,
  setSelectedChat,
  setShowListChats,
  searchListChat,
  clearSearchListChat,
} = socialSlice.actions;
export default socialSlice.reducer;
