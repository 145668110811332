const UserHasNoCoursesCard = () => {
  return (
    <div
      className="flex flex-col items-center w-full h-[calc(30vh)]"
    >
      <div className="flex flex-col justify-center h-full">
        <label className="text-center text-gray-500">Aquí podrás ver todos tus cursos</label>
      </div>
    </div>
  );
};

export default UserHasNoCoursesCard;
