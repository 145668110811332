import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { CalorieChart } from "./components/CalorieChart";
import { LatestOffers } from "./components/LatestOffers";
import { Recomendation } from "./components/Recomendation";
import { LatestPost } from "./components/LatestPost";
import {
  startGettingRecipes,
  startGettingWeeklyKcal,
} from "../../../store/slices/food/thunks";
import { getAllBooks, getBooksByUser } from "../../../store/slices/book/thunks";
import {
  getAllCourses,
  getCourseByUser,
} from "../../../store/slices/course/thunks";
import { startGettingPosts } from "../../../store/slices/post/thunks";
import { getAllRedeems } from "../../../store/slices/redeem/thunks";
import { getRecipesPartialSystem, getRecipesSystem } from "../../../store/slices/recipe/thunks";
import LeadingHomeScreen from "./LeadingHomeScreen";
import { NewLandingScreen } from "./NewLandingScreen";

export const HomeScreen = () => {
  return (<LeadingHomeScreen/>)
  const dispatch: AppDispatch = useDispatch();
  const { books, userBooks } = useSelector((state: RootState) => state.book);
  const { allCourses, courseByUser } = useSelector(
    (state: RootState) => state.course
  );

  const { postFeed } = useSelector((state: RootState) => state.post);
  const { redeems,loading } = useSelector((state: RootState) => state.redeem);
  const { recipesSystem,loading:loadingRecipe,recipesPartial } = useSelector((state: RootState) => state.recipe);
  const [isPostFeedFetch, setIsPostFeedFetch] = useState(false);
  const [isBookFetch, setIsBookFetch] = useState(false);
  const [isAllCourseFetch, setIsAllCourseFetch] = useState(false);
  const [isCourseByUserFetch, setIsCourseByUserFetch] = useState(false);

  useEffect(() => {
    dispatch(startGettingRecipes());
    dispatch(startGettingWeeklyKcal(new Date()));
    dispatch(getRecipesSystem());
    dispatch(getRecipesPartialSystem());
  }, []);

  useEffect(() => {
    if (books.length === 0 && !isBookFetch) {
      dispatch(getAllBooks());
      setIsBookFetch(true);
    }
  }, [books]);

  useEffect(() => {
    if (redeems.length === 0&&!loading) {
      dispatch(getAllRedeems());
    }
  }, []);

  useEffect(() => {
    if (userBooks.length === 0) dispatch(getBooksByUser());
  }, []);

  useEffect(() => {
    if (allCourses.length > 0 && isAllCourseFetch) return;
    dispatch(getAllCourses());
    setIsAllCourseFetch(true);
  }, [allCourses]);

  useEffect(() => {
    if (courseByUser.length > 0 && isCourseByUserFetch) return;
    dispatch(getCourseByUser());
    setIsCourseByUserFetch(true);
  }, [courseByUser]);

  useEffect(() => {
    if (postFeed.length === 0 && !isPostFeedFetch) {
      dispatch(startGettingPosts({ limit: "10", page: "1" }));
      setIsPostFeedFetch(true);
    }
  }, [postFeed]);

  return (
    <div className="flex flex-col h-screen">
      <div
        className={`grid max-[950px]:grid-cols-1 max-[1200px]:grid-cols-1 min-[1201px]:grid-cols-2 gap-4 divide-x`}
      >
        <div className="flex flex-col justify-start items-start max-[1200px]:items-center divide-y pl-10 pr-10">
          <div className="flex w-full pb-20 justify-center items-center pt-5">
            <CalorieChart />
          </div>
          <div className="flex w-full pb-20 justify-center items-center pt-5">
            <LatestOffers />
          </div>
        </div>

        <div className="flex flex-col justify-start items-start max-[1200px]:items-center divide-y pl-10 pr-10">
          {courseByUser.length < allCourses.length && (
            <div className="flex w-full pb-20 justify-center items-center pt-5">
              <Recomendation />
            </div>
          )}
          <div className="flex w-full pb-20 justify-center items-center pt-5">
            <LatestPost />
          </div>
        </div>

        <div className="flex flex-col md:hidden pl-10 pt-5 w-full pr-10">
          <div className="bg-orange-500 h-full w-full rounded-xl"></div>
        </div>
      </div>
    </div>
  );
};
