interface ButtonSquadXSmallProps {
  label?: string;
  onClick?: () => void;
}

export const ButtonSquadXSmall = ({ label, onClick }: ButtonSquadXSmallProps) => {
  return (
    <div>
      <button
        className={`bg-orange-500 hover:bg-orange-400 text-white max-[560px]:text-xs text-sm rounded-lg max-[560px]:w-24 max-[560px]:h-8 w-32 h-8`}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};
