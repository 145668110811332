// ReviewElementModel.ts
export interface ReviewElementModel {
    id: string;
    rate: number;
    reviewText: string;
    createdAt: Date;
}

export function createReviewElementModel(
    id: string,
    rate: number,
    reviewText: string,
    createdAt: Date
): ReviewElementModel {
    return { id, rate, reviewText, createdAt };
}

export function copyReviewElementModel(
    model: ReviewElementModel,
    updates: Partial<ReviewElementModel>
): ReviewElementModel {
    return { ...model, ...updates };
}

export function reviewElementModelFromJson(json: any): ReviewElementModel {
    return {
        id: json["id"],
        rate: json["rate"],
        reviewText: json["review_text"],
        createdAt: new Date(json["created_at"])
    };
}

export function reviewElementModelToJson(model: ReviewElementModel): any {
    return {
        id: model.id,
        rate: model.rate,
        review_text: model.reviewText,
        created_at: model.createdAt.toISOString()
    };
}

export function reviewElementModelFromRawJson(str: string): ReviewElementModel {
    return reviewElementModelFromJson(JSON.parse(str));
}

export function reviewElementModelToRawJson(model: ReviewElementModel): string {
    return JSON.stringify(reviewElementModelToJson(model));
}

export interface ReviewModel {
    courseId: string;
    reviews: ReviewElementModel[];
    avgRate: number;
}

export function createReviewModel(
    courseId: string,
    reviews: ReviewElementModel[],
    avgRate: number
): ReviewModel {
    return { courseId, reviews, avgRate };
}

export function copyReviewModel(
    model: ReviewModel,
    updates: Partial<ReviewModel>
): ReviewModel {
    return { ...model, ...updates };
}

export function reviewModelFromJson(json: any): ReviewModel {
    return {
        courseId: json["course_id"],
        reviews: json["reviews"].map((x: any) => reviewElementModelFromJson(x)),
        avgRate: json["avg_rate"]
    };
}

export function reviewModelToJson(model: ReviewModel): any {
    return {
        course_id: model.courseId,
        reviews: model.reviews.map(reviewElementModelToJson),
        avg_rate: model.avgRate
    };
}

export function reviewModelFromRawJson(str: string): ReviewModel {
    return reviewModelFromJson(JSON.parse(str));
}

export function reviewModelToRawJson(model: ReviewModel): string {
    return JSON.stringify(reviewModelToJson(model));
}
