import CryptoJS from 'crypto-js';

const secretKey = process.env.REACT_APP_SECRET_KEY as string; 

export const useEncrypt = () => {
  
  const encryptPassword = (password: string) => {
    const encrypted = CryptoJS.AES.encrypt(password, secretKey).toString();
    localStorage.setItem('encryptedPassword', encrypted);
    return encrypted;
  };
  
  const decryptPassword = (encryptedPassword: string) => {
    const decrypted = CryptoJS.AES.decrypt(encryptedPassword, secretKey).toString(CryptoJS.enc.Utf8);
    return decrypted;
  };

  return { encryptPassword, decryptPassword };
}

