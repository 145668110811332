const CardChatSkeleton = () => {
  return (
    <div className="flex flex-row justify-between content-center mt-3 py-2 px-2 rounded-xl hover:bg-gray-200">
      <div className="flex flex-row">
        <div className="flex animate-pulse w-12 h-12 bg-slate-200 rounded-full"></div>
        <div className="flex flex-col pl-4 gap-1">
          <label className="text-base font-bold">
            <div className="flex animate-pulse w-20 h-5 bg-slate-200 rounded-md"></div>
          </label>
          <label
            className={`text-xs text-ellipsis whitespace-nowrap overflow-hidden w-44 "text-gray-400`}
          >
            <div className="flex animate-pulse w-28 h-4 bg-slate-200 rounded-md"></div>
          </label>
        </div>
      </div>
      <div className="flex self-center">
        <label className="text-xs text-right text-gray-400">
          <div className="flex animate-pulse w-20 h-5 bg-slate-200 rounded-md"></div>
        </label>
      </div>
    </div>
  );
};

export default CardChatSkeleton;
