
interface SwitchProps {
  plan: string;
  setPlan: React.Dispatch<React.SetStateAction<string>>;
}

export const SwitchPlan = ({plan, setPlan}: SwitchProps) => {
  return (
    <div className="w-[300px] mx-8 shadow max-w-sm rounded-3xl h-12 mt-4 flex p-1 relative items-center bg-blue-100">
      <div className="w-full flex font-medium justify-center cursor-pointer" onClick={() => setPlan('Básico')}>
        <button>Básico</button>
      </div>
      <div className="w-full flex font-medium justify-center cursor-pointer" onClick={() => setPlan('Pro')}>
        <button>Pro</button>
      </div>
      <span 
        className={`bg-white shadow text-blueSquat1 font-medium flex items-center justify-center w-1/2 rounded-full h-10 transition-all top-[4px] absolute cursor-pointer ${
          plan === 'Básico' ? 'left-1' : 'right-1'
        }`}
      >
        {plan}
      </span>
    </div>
  )
}
