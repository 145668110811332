import { HeartIcon } from "../../../../../assets/icons/HeartIcon";
import { AppDispatch, RootState } from "../../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { addNewComment, cleanPostSelected, clearComments, setNewLike } from "../../../../../store/slices/post/PostSlice";
import { InputComment } from "./InputComment";
import { useEffect, useState } from "react";
import { CommentConatiner } from "./CommentConatiner";
import { createNewComment } from "../../../../../store/slices/post/thunks";
import { CreateCommentResponse } from "../../../../../store/interfaces/social/interfacePost";
import { newLike } from "../../../../../store/slices/social/thunks";
import CircularProgress from "../../../../components/CircularProgress";
import { BottomText } from "../BottomText";
import CloseIcon from "../../../../../assets/icons/CloseIcon";

interface ModalProps {
  post_id: string;
  isOpen: boolean;
  onClose: () => void;
}

export const ViewPostModal = ({ isOpen, onClose }: ModalProps) => {

  const dispatch: AppDispatch = useDispatch();

  const [newComment, setNewComment] = useState<string>("");

  const { postSelected, commentList, is_loading_comments } = useSelector((state: RootState) => state.post);
  const { username, profilePicture, uid } = useSelector((state: RootState) => state.auth);
  const [like, setLike] = useState<boolean>(postSelected.liked);
  const [oldLike, setOldLike] = useState<boolean>(postSelected.liked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewComment(e.target.value);
  };

  useEffect(() => {
    if (oldLike !== like) {
      const timer = setTimeout(() => {
        dispatch(newLike(postSelected.id)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setOldLike(like);
          }
        });
      }, 2000);
      return () => clearTimeout(timer);     
    }
  }, [like]);

  useEffect(() => {
    if (postSelected.id !== "") {
      setLike(postSelected.liked);      
    }
  }, [postSelected])
  

  const handleComment = () => {
    dispatch(createNewComment({postId: postSelected.id, commentText: newComment})).then(
      (res) => {
        if (res.meta.requestStatus === "fulfilled") {
          dispatch(addNewComment({
            comment: res.payload as CreateCommentResponse,
            user: {
              username,
              profile_picture: profilePicture as string
            }
          }));
        }
      }
    );
    setNewComment('');
  }

  const handleLike = () => {
    setLike(!like)
    dispatch(
      setNewLike({ post_id: postSelected.id, user_id: uid, liked: !like })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(clearComments());
    }
  }, [])
  

  return (
    <div
      className={`modal ${
        isOpen ? "block" : "hidden"
      } fixed top-0 left-0 w-full h-full bg-gray-700 bg-opacity-20 z-50`}
    >
      <div className="modal-dialog mx-auto max-[800px]:overflow-auto max-[800px]:h-[calc(100vh-150px)] my-20 text-black bg-white max-[590px]:w-[calc(80vw)] max-[800px]:w-[calc(60vw)] max-[1200px]:w-[calc(80vw)] w-[calc(100vw-400px)] lg:w-[60vw] 2xl:w-[50vw] h-[calc(100vh-150px)]">
        <div className="flex flex-row max-[800px]:flex-col w-full h-full">
          <div className="flex xl:flex-row flex-col sm:gap-2">
            <img src={postSelected.image_url} className="object-cover max-w-[1100px] w-full h-full" />
          </div>
          <div className="flex flex-col overflow-auto overflow-x-hidden justify-between p-6 h-full w-[600px] max-[800px]:w-full">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between pb-2">
                <div className="flex flex-row items-center">
                  <img src={postSelected.profile_picture ?? profilePicture} className="object-cover w-10 h-10 rounded-full border-2 border-gray-100" />
                  <label className="text-sm font-normal pl-2">{postSelected.username ?? username}</label>
                </div>
                <div>
                  <div
                    className="text-gray-500 hover:text-gray-700 p-1 cursor-pointer"
                    onClick={() => {
                      dispatch(cleanPostSelected())
                      dispatch(clearComments());
                      onClose();
                    }}
                  >
                    <CloseIcon />
                  </div>
                </div>
              </div>
              <BottomText children={postSelected.post_text} limit={100} />
              <div className="flex justify-start items-center border-t p-2">
                <HeartIcon height={4} width={4} liked={like} onClick={handleLike} /> <p className="pl-1 text-xs">{postSelected.like_count ?? 0}</p>
                <p className="text-slate-500 text-xs pl-4">
                  {postSelected.comment_count ?? 0} { postSelected.comment_count === "1" ? "comentario" : "comentarios"}
                </p>
              </div>
              <div className="border-t overflow-y-auto overflow-x-hidden h-[calc(100vh-380px)] pb-4">
                {
                  is_loading_comments === true
                  ? <div className="relative top-20 left-32">
                      <CircularProgress />
                    </div>
                  : commentList.map((com) => (
                      <CommentConatiner 
                        username={com.username}
                        text={com.comment_text}
                        picture={com.profile_picture}
                        dateComent={com.created_at}
                        limit={60}
                      />
                    ))
                }
              </div>
            </div>
            <div>
              <InputComment 
                onComment={handleComment}
                onChange={handleChange}
                value={newComment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
