import { useSelector } from "react-redux";
import CheckIcon from "../../../../../assets/icons/CheckIcon";
import { Video } from "../../../../../store/interfaces/course/interfaceCourse";
import { RootState } from "../../../../../store/store";
import useLastVideoSeen from "../../../../hooks/useLastVideoSeen";

interface VideoItemProps {
  video: Video;
  index: number;
}

const VideoItem = ({ video, index }: VideoItemProps) => {
  const { HandleSetLastVideoSeen } = useLastVideoSeen();
  const { lastVideoSeen } = useSelector((state: RootState) => state.course);

  return (
    <div
      className={`flex flex-row justify-between px-10 py-2 ${
        lastVideoSeen === video.video_id ? "bg-gray-200" : ""
      } hover:bg-gray-200 cursor-pointer`}
      onClick={() => HandleSetLastVideoSeen(video.video_id)}
    >
      <div className="flex flex-row items-center gap-5">
        <label>{index + 1}</label>
        <div className="flex flex-col items-start">
          <div className="flex flex-row gap-2">
            <label>{video.video_title}</label>
            <div className={`${video?.views?.is_viewed ? "bg-orange-500" : "bg-orange-300"} w-5 h-5 rounded-full flex items-center justify-center text-white`}>
              <CheckIcon />
            </div>
          </div>
          <label className="text-xs text-gray-400">
            Video - {video.video_duration} min
          </label>
        </div>
      </div>
    </div>
  );
};

export default VideoItem;
