import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useState } from "react";
import { Row } from "../../core/components/Row";
import Footer from "../../core/components/Footer";
import { ChatButton } from "../../core/components/floating-chat/ChatButton";
import WindowChat from "../../core/components/floating-chat/WindowChat";
import FloatingChat from "../../core/components/floating-chat/FloatingChat";
import { ButtonSquad } from "../../core/components";
import { ButtonType } from "../../core/interfaces/ButtonSquadInterface";

const borderBottom: any = {
  "/": "#FF690B",
  "/today": "#d9688f",
  "/my-courses": "#710a97",
  "/asesorias": "#3932C0",
  "/libros": "#1a1aec",
}

export const PublicSquadLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { subData } = useSelector((state: RootState) => state.coach);
  const [isOpen, setIsOpen] = useState(false);


  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  return(
    <div className="max-h-screen">
      <header className={`flex sticky z-20 top-0 bg-white w-full items-center justify-between whitespace-nowrap border-b-[3px] border-solid border-b-[${borderBottom[location.pathname] ?? "#e8f3ef"}] px-10 py-3`}>
        <Row >
          <img src="/logo_horizontal_color.png" className="h-12 hidden md:block place-self-center" alt=""/>
          <img src="/logo_color.png" className="h-12 block md:hidden place-self-center" alt=""/>
        </Row>
        <Row crossAxisAlignment="center" className="flex-1 hidden md:flex gap-8">
          
          <Row className="gap-3">
            <ButtonSquad buttonType={ButtonType.BlueFull} label="Acceder" onClick={()=>navigate('auth/login')} />
            <ButtonSquad buttonType={ButtonType.OrangeFull} label="Registro" onClick={()=>navigate('auth/register')}/>
          </Row>
        </Row>
      </header>
      <div className="">
        <Outlet/>
      </div>
      <Footer/>
        {
          subData.status === 'ACTIVE' &&
          <ChatButton onOpenModal={!isOpen ? handleOpen : handleClose} />
        }
        <WindowChat
          isOpen={isOpen}
          onClose={handleClose}
          component={<FloatingChat />}
        />
      </div>
  );
};
