import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { Column } from "../../../core/components/Column";
import { TextComponent } from "../../../core/components/TextComponent";
import { SizedBox } from "../../../core/components/SizedBox";
import { useNavigate } from "react-router-dom";
import { categories } from "../../../core/constants/constatns";
import { Row } from "../../../core/components/Row";
export const RecipiesSystemScreen = () => {
  const { recipesPartial,
    recipesSystem } = useSelector(
    (state: RootState) => state.recipe
  );
  const navigate = useNavigate();
  const toRecipe=(id:string)=>{
    navigate(`/recipe/${id}`)
  }
  return (
    <Column crossAxisAlignment="center" className="relative w-full  min-h-screen overflow-x-hidden">
        <div className="w-full md:w-3/4 px-12 md:p-0 flex h-full grow flex-col">
      <TextComponent variant="h1" className="text-md font-bold">
        Recetas
      </TextComponent>
      <SizedBox className="h-6"/>
      <Row className="gap-4">
      {
        categories.map((e,index)=>(
          <button key={index} className="bg-softGray rounded-full py-2 px-3 text-sm">
            {e}
          </button>
        ))
      }
      </Row>
      <SizedBox className="h-6"/>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
      {
        recipesSystem.length===0?
        recipesPartial.map((e,index)=>(
          <div key={index} className="cursor-pointer" onClick={()=>toRecipe(e.id??'')}>
            <img src={e.image} className="object-cover rounded-md  h-40 w-full" />
            <TextComponent variant="h5" className="font-bold">
              {
                e.name
              }
            </TextComponent>
            <TextComponent variant="h5" className="text-fadedAsh">
              {
                e.timeToPrepare
              }
            </TextComponent>
            <TextComponent variant="h5" className="text-roseSquat">
              {
                e.kcal
              }
              kcal
            </TextComponent>
          </div>
        )):recipesSystem.map((e,index)=>(
          <div key={index} className="cursor-pointer" onClick={()=>toRecipe(e.id??'')}>
            <img src={e.image} className="object-cover rounded-md  h-40 w-full" />
            <TextComponent variant="h5" className="font-bold">
              {
                e.name
              }
            </TextComponent>
            <TextComponent variant="h5" className="text-fadedAsh">
              {
                e.timeToPrepare
              }
            </TextComponent>
            <TextComponent variant="h5" className="text-roseSquat">
              {
                e.kcal
              }
              kcal
            </TextComponent>
          </div>
        ))
      }
       </div>
       </div>
    </Column>
  );
};
