import { useSelector } from "react-redux";
import { MetaIcon } from "../../../../assets/icons/MetaIcon"
import { GoalBar } from "./GoalBar"
import { RootState } from "../../../../store/store";
import { ProgressCircle } from "@tremor/react";
import { TextComponent } from "../../../../core/components/TextComponent";
import { SizedBox } from "../../../../core/components/SizedBox";
import { Row } from "../../../../core/components/Row";
import { CalorieChart } from "../../home/components/CalorieChart";
import { Column } from "../../../../core/components/Column";

export const CalorieCard = () => {

  const { configKcal } = useSelector((state: RootState) => state.auth);
  const { dayli_kcal } = useSelector((state: RootState) => state.food);

  const porcent = (dayli_kcal * 100) / (configKcal??1)
  return (
    <div>
      <Row responsiveCol={true} crossAxisAlignment="start" className="gap-6">
        <div className="w-full">
        <TextComponent variant="h1" className="font-bold">
          Diario
        </TextComponent>
        <SizedBox className="h-4"/>
        <GoalBar percent={porcent} rest={configKcal-dayli_kcal}/>
        </div>
      </Row>
    </div>
  )
}
