import { useState } from "react";

interface TextProps {
  children: string
  limit: number
}

export const BottomText = ({children, limit}: TextProps) => {

  const [onReadMore, setOnReadMore] = useState(false);
  
  const handleReadMore = () => {
    setOnReadMore(prev => !prev)
  }
  

  return (
    <div className="pt-2 pb-4 text-xs font-normal ml-1 break-words">
      {onReadMore ?  children : children.substring(0, limit)}
      {
        children.length > 200 
        ? <button className="text-gray-500 font-medium ml-1" onClick={handleReadMore}>{onReadMore ? 'Ver menos' : 'Ver más'}</button>
        : <div></div>
      }
      
    </div>
  )
}
