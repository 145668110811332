import { useEffect, useState } from "react";
import { EditUserImage } from "./components/EditUserImage";
import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { useForm } from "../../hooks/useForm";
import { InputType } from "../../../core/interfaces/InputSquadInterface";
import { useAlert } from "../../hooks/useAlert";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { startUpdatingUserInfo } from "../../../store/slices/auth/thunks";
import { UpdateUserPayload } from "../../../store/interfaces/auth/interfaceAuth";
import { DatePicker, DatePickerValue } from "@tremor/react";


export const EditProfileScreen = () => {

  const [imageProfile, setImageProfile] = useState<File | null>(null);
  const { firstName, email, lastName, birth, configKcal, description, uid, profilePicture } = useSelector((state: RootState) => state.auth);
  const { onInputChange, onTextChange, formState } = useForm({
    firstName, lastName, email, description, birth, kcal: configKcal
  });

  const [birthday, setBirthday] = useState<Date | null>(null);

  const { showAlert } = useAlert();

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  useEffect(() => {
    if (birth) setBirthday(new Date(birth??''))
  }, [birth])
  

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; 
    if (file) {
      setImageProfile(file); 
    }
  }

  const handleDateChange = (date: DatePickerValue) => {
    if (date === null) return;
    if (date === undefined) return;
    setBirthday(new Date(date?.toISOString() as string));
  };

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();    
    
    let payload: any = {
      id: uid,
      firstName: formState.firstName,
      lastName: formState.lastName,
      email: formState.email,
      description: formState.description,
      birth: birthday?.toISOString() as string,
      config_kcal_goal: formState.kcal,
      profile_picture: imageProfile as File
    }

    const cleanEmptyBody = Object.keys(payload as any).reduce((acc: any, key: string) => {
      if (payload[key] !== '' && payload[key] !== null && payload[key] !== undefined) {
        acc[key] = payload[key];
      }
      return acc;
    }, {});

    dispatch(startUpdatingUserInfo({userId: uid, userInfo: cleanEmptyBody})).then( (response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        showAlert({
          message: 'Cambios guardados con éxito!',
          type: "success"
        });
      }
      navigate('/profile');
    })
  }

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col items-center justify-center pt-10">
        <EditUserImage profilePicture={imageProfile ?? profilePicture} handleFileChange={handleFileChange} />

        <form onSubmit={onSubmit}>
          <div className="flex flex-col items-center justify-center">
            <div className="flex flex-row max-[770px]:flex-col justify-center mt-16">
              <div>
                <LabelSquad children="Nombre"/>
                <InputSquad 
                  inputType={InputType.Medium} 
                  value={formState.firstName}
                  name="firstName"
                  onChange={onInputChange}
                />
              </div>
              <div className="md:ml-4 max-[770px]:mt-2">
                <LabelSquad children="Apellido"/>
                <InputSquad 
                  inputType={InputType.Medium} 
                  value={formState.lastName}
                  name="lastName"
                  onChange={onInputChange}
                />
              </div>
            </div>
            <div className="flex flex-row max-[770px]:flex-col justify-center mt-4">
              <div>
                <LabelSquad children="Email"/>
                <InputSquad 
                  inputType={InputType.Medium}
                  value={formState.email}
                  name="email"
                  type="text"
                  onChange={onInputChange}
                />
              </div>
              <div className="md:ml-4 max-[770px]:mt-2 w-72">
                <LabelSquad children="Cumpleaños"/>
                <DatePicker
                  className="border border-gray-200 rounded-lg text-slate-900"
                  value={birthday as Date}
                  placeholder="Selecciona tu cumpleaños"
                  displayFormat="dd/MM/yyyy"
                  onValueChange={handleDateChange}
                  enableYearNavigation
                  enableClear={false}
                />
              </div>
            </div>
            <div className="mt-4">
              <LabelSquad children="Meta de calorias"/>
              <input
                className="border-2 border-gray-80 rounded-lg h-10 max-[770px]:w-[290px] w-[600px] placeholder:text-gray-400 px-3"
                onChange={onInputChange}
                value={formState.kcal}
                name="kcal"
                type="number"
              />
            </div>
            <div className="mt-4">
              <LabelSquad children="Biografía"/>
              <textarea 
                className="border-2 border-gray-80 text-sm rounded-lg  max-[770px]:w-[290px] w-[600px] py-2 px-2"
                placeholder="Inserte una biografía..."
                name="description"
                value={formState.description}
                onChange={onTextChange}
                style={{ resize: "none" }}
              >
              </textarea>
            </div>
            <div className="mt-10 pb-6 w-40">
              <ButtonSquad full={true} type="submit" buttonType={ButtonType.OrangeFull} label="Guardar"/>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}
