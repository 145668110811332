import CloseIcon from "../../../assets/icons/CloseIcon";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  component: any;
}

const WindowChat = ({ isOpen, onClose, component }: ModalProps) => {
  return (
    <div className={`modal ${isOpen ? 'block' : 'hidden'} fixed right-32 bottom-8 max-[430px]:right-8 max-[430px]:bottom-24 z-50`}>
      <div className="modal-dialog mx-auto my-12 bg-white p-6 rounded-2xl shadow-xl border w-[360px] h-[calc(100vh-200px)] max-h-[530px] max-[430px]:h-[calc(100vh-150px)] max-[390px]:w-[320px]">
        <div className="absolute">
          <div className="relative left-[295px] max-[390px]:left-[255px] rounded-lg text-black hover:bg-slate-200 cursor-pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
        <div className="flex max-w-[60rem] h-full">{component}</div>
      </div>
    </div>
  );
};

export default WindowChat;
