import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";

export const getCoachChatList = createAsyncThunk(
  "chat/getChatList",
  async () => {
  const response = await squatFitApi.get("/admin-panel/chats", {
    params: {
      from: 'user'
    }
  });

  return response.data;
});

export const getCoachHistoryMessage = createAsyncThunk(
  "chat/getHistoryMessage",
  async (payload: any) => {
    const response = await squatFitApi.get("/admin-panel/messages", {
      params: payload,
    });

    return response.data;
  }
);

export const getCoachHistoryOldestMessage = createAsyncThunk(
  "chat/getHistoryOldestMessage",
  async (payload: any) => {
    const response = await squatFitApi.get("/admin-panel/messages", {
      params: payload,
    });

    return response.data;
  }
);

export const setNewMessageCoach = createAction(
  "chat/setNewMessage",
  (payload: { chat: any; message: any }) => ({
    payload: payload,
  })
);

export const setMessageViewedCoach = createAction(
  "chat/setMessageViewed",
  (payload: any[]) => ({
    payload: payload,
  })
);