import { useDispatch, useSelector } from "react-redux";
import CheckIcon from "../../../../../assets/icons/CheckIcon";
import { AppDispatch, RootState } from "../../../../../store/store";
import { TestModel } from "../../../../../store/interfaces/course/testInterface";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getAnswers } from "../../../../../store/slices/course/thunks";

interface TestItemProps {
  test: TestModel;
  index: number;
}

const TestItem = ({ test, index }: TestItemProps) => {
  const { lastVideoSeen, savedAnswers } = useSelector((state: RootState) => state.course);
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getAnswers({testId:test.id??''}))
  }, [])
  
  return (
    <div
      className={`flex flex-row justify-between px-10 py-2 ${
        lastVideoSeen === test.id ? "bg-gray-200" : ""
      } hover:bg-gray-200 cursor-pointer`}
      onClick={() =>savedAnswers.length>0 ?navigate('/test-result'):  navigate('/test')}
    >
      <div className="flex flex-row items-center gap-5">
        <label>{index + 1}</label>
        <div className="flex flex-col items-start">
          <div className="flex flex-row gap-2">
            <label>Test</label>
            <div className={`${savedAnswers.length>0 ? "bg-orange-500" : "bg-orange-300"} w-5 h-5 rounded-full flex items-center justify-center text-white`}>
              <CheckIcon />
            </div>
          </div>
          <label className="text-xs text-gray-400">
            {test.questions.length} preguntas
          </label>
        </div>
      </div>
    </div>
  );
};

export default TestItem;
