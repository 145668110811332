import SendIcon from "../../../../../assets/icons/SendIcon"

interface CommentInputProps {
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onComment: () => void;
}

export const InputComment = ({onChange, value, onComment}: CommentInputProps) => {

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      onComment();
    }
  };

  return (
    <div className="flex flex-row items-center w-[calc(20vw)] max-[1023px]:w-[calc(26vw)] max-[800px]:w-[calc(54vw)] max-[590px]:w-[calc(70vw)] py-2 pr-2 text-sm border-2 border-gray-80 rounded-full">
      <input 
        type="text"
        placeholder="Escribe tu comentario..."
        className={`pl-4 mr-3 w-full outline-none placeholder:text-gray-400`}
        onChange={onChange}
        value={value}
        onKeyDown={handleKeyDown}
      />
      <button 
        className="max-[800px]:relative right-0 mr-1" 
        onClick={() => {
          onComment()
        }}
      >
        <SendIcon height="24" width="24" />
      </button>
    </div>
  )
}
