import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { MealPayload, RecipePayload, WeeklyKcalPayload } from "../../interfaces/food/interfaceFood";
import { parseRecipes } from "../../interfaces/food/recipeInterface";


//Get total kcal per week
export const startGettingWeeklyKcal = createAsyncThunk(
  'recipe/getWeeklyKcal',
  async (date: Date) => {
    const response = await squatFitApi.get<WeeklyKcalPayload>('/recipe/weekly-total-kcal', { params: {
      date: date.toISOString()
    }})

    return response.data;
  }
)

// Get total kcal per day
export const startGettingDailyKcal = createAsyncThunk(
  'recipe/getDailyKcal',
  async({ date }: {date: Date}, { rejectWithValue }) => {

    try {
      const response = await squatFitApi.get('/recipe/daily-total-kcal', { params: {
        date: date.toISOString()
      }})
  
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
)

// Create new recipe
export const startCreatingRecipe = createAsyncThunk(
  'recipe/createRecipe',
  async({ name, description, recipe_category_id, kcal, file }: RecipePayload) => {

    const formData = new FormData();

    formData.append('name', name);
    formData.append('description', description);
    formData.append('recipe_category_id', recipe_category_id);
    formData.append('kcal', kcal);
    formData.append('file', file);

    const response = await squatFitApi.post('/recipe/create', formData)

    return response.data;
  }
)

// Update a recipe
export const startUpdatingRecipe = createAsyncThunk(
  'recipe/update',
  async({recipe, recipe_id}: {recipe: RecipePayload, recipe_id: string}) => {

    const formData = new FormData();

    formData.append('name', recipe.name);
    formData.append('description', recipe.description);
    formData.append('recipe_category_id', recipe.recipe_category_id);
    formData.append('kcal', recipe.kcal);
    formData.append('file', recipe.file);

    const response = await squatFitApi.put('/recipe/update', formData, { params: { id: recipe_id }})
    
    return response.data;
  }
)

// Get all recipes
export const startGettingRecipes = createAsyncThunk(
  'recipe/getRecipes',
  async() => {
    const response = await squatFitApi.get('/recipe/all')

    return response.data;
  }
)

// Add register meal
export const startCreatingMeal = createAsyncThunk(
  'recipe/createMeal',
  async({ recipe_id, date,type }: MealPayload) => {
    const response = await squatFitApi.post('/recipe/meal', { recipe_id, date,type })

    return response.data;
  }
)

// Get all meals
export const startGettingMeals = createAsyncThunk(
  'recipe/getMeals',
  async({ date }: {date: Date}) => {
    const response = await squatFitApi.get('/recipe/meals', { params: {
      date: date.toISOString()
    }})

    return parseRecipes(response.data);
  }
)

// Get meals categories
export const startGettingCategories = createAsyncThunk(
  'recipe/getCategories',
  async() => {
    const response = await squatFitApi.get('/recipe/categories')

    return response.data;
  }
)

// Get meals by category
export const startGettingByCategory = createAsyncThunk(
  'recipe/getMealByCategory',
  async() => {
    const response = await squatFitApi.get('/recipe/by-category')

    return response.data;
  }
)

// Get recipes by user
export const startGettingFriendRecipes = createAsyncThunk(
  'recipe/by-user',
  async(userId: string) => {
    const response = await squatFitApi.get('/recipe/by-user', {params: {user_id: userId}})

    return response.data;
  }
)


