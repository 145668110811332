import { useState } from "react";
import { PostIcon } from "../../../../assets/icons/PostIcon";
import { RecipesIcon } from "../../../../assets/icons/RecipesIcon";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import {
  cleanPostSelected,
  setPostSelected,
} from "../../../../store/slices/post/PostSlice";
import { ViewPostModal } from "../../social/components/post/ViewPostModal";
import { MyRecipeCard } from "./MyRecipeCard";
import { MyPostCardSkeleton } from "./MyPostCardSkeleton";

export const TabProfileUser = () => {
  const { postFromUser, postSelected, is_loading_post_from_user } = useSelector(
    (state: RootState) => state.post
  );
  const { friendRecipe } = useSelector((state: RootState) => state.food);

  const dispatch: AppDispatch = useDispatch();

  const [tabToggle, setTabToggle] = useState("first");

  const [isImageOpen, setIsImageOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setIsImageOpen(true);
  };

  const handleCloseModal = () => {
    dispatch(cleanPostSelected());
    setIsImageOpen(false);
  };

  return (
    <div className="flex flex-col">
      <div className="mx-auto">
        <ul id="tabs" className="inline-flex w-full px-1 justify-center">
          <button
            className={`px-12 py-2 -mb-px font-semibold ${
              tabToggle === "first"
                ? "text-gray-800 border-t-2 border-orange-400"
                : "opacity-50"
            }`}
            onClick={() => setTabToggle("first")}
          >
            <PostIcon />
          </button>
          <button
            className={`px-12 py-2 font-semibold ${
              tabToggle === "second"
                ? "text-gray-800 border-t-2 border-orange-400"
                : "opacity-50"
            }`}
            onClick={() => setTabToggle("second")}
          >
            <RecipesIcon />
          </button>
        </ul>

        <div id="tab-contents">
          <div
            id="first"
            className={`p-4 ${tabToggle !== "first" && "hidden"}`}
          >
            <div className="grid grid-cols-4 max-[1180px]:grid-cols-3 max-[740px]:grid-cols-2 gap-2">
              {is_loading_post_from_user
                ? Array.from({ length: 4 }).map((_, index) => (
                    <MyPostCardSkeleton key={index} />
                  ))
                : postFromUser.map((p, index) => (
                    <img
                      key={index}
                      src={p.image_url}
                      className="h-52 w-52 object-cover max-[560px]:w-40 max-[560px]:h-40"
                      onClick={() => {
                        dispatch(setPostSelected(p));
                        handleOpen();
                      }}
                    />
                  ))}
              <ViewPostModal
                post_id={postSelected.id}
                isOpen={isImageOpen}
                onClose={handleCloseModal}
              />
            </div>
          </div>
          <div
            id="second"
            className={`p-4 ${tabToggle !== "second" && "hidden"}`}
          >
            <div className="flex flex-row justify-start">
              <div className="grid grid-cols-4 max-[1180px]:grid-cols-3 max-[740px]:grid-cols-2 max-h-full overflow-hidden">
                {friendRecipe
                  .map((recipe, index) => (
                    <MyRecipeCard key={index} recipe={recipe} />
                  ))
                  .reverse()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
