const MessageIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 12C7.5 11.1716 8.1014 10.5 8.84328 10.5H21.1567C21.8986 10.5 22.5 11.1716 22.5 12C22.5 12.8284 21.8986 13.5 21.1567 13.5H8.84328C8.1014 13.5 7.5 12.8284 7.5 12ZM7.5 16.5C7.5 15.6714 8.01439 15 8.64894 15H15.3511C15.9856 15 16.5 15.6714 16.5 16.5C16.5 17.3283 15.9856 18 15.3511 18H8.64894C8.01439 18 7.5 17.3283 7.5 16.5ZM10.9999 0.443727L10.6456 0.523784C5.62893 1.65718 1.68989 5.51269 0.480495 10.4786C-0.162562 13.119 -0.159544 15.9005 0.485864 18.5507C1.65466 23.3499 5.0169 27.3523 9.53269 29.3407L10.176 29.6085L10.4496 29.7105C12.9221 30.558 15.6426 29.5009 16.7665 27.1732L16.848 26.9895L18.4498 26.9906C23.7007 26.9906 28.3106 23.3137 29.5566 18.1973C30.1478 15.7697 30.1478 13.2374 29.5566 10.8098L29.4154 10.2303C28.2431 5.41659 24.4247 1.67916 19.5619 0.580483L18.9564 0.443715C16.3378 -0.147907 13.6185 -0.147907 10.9999 0.443727ZM18.2954 3.36997L18.9008 3.50674C22.6552 4.35498 25.5985 7.23587 26.5006 10.9403L26.6418 11.5198C27.1194 13.4809 27.1194 15.5263 26.6418 17.4875C25.721 21.2685 22.3081 23.9906 18.4498 23.9906H16.7641C15.6821 23.9906 14.6967 24.6015 14.2099 25.552L14.1134 25.7608C13.6805 26.8055 12.4387 27.2866 11.2984 26.8264L11.1105 26.7506C7.26291 25.198 4.37984 21.8614 3.40067 17.8408C2.86859 15.656 2.8661 13.3614 3.3953 11.1884C4.3345 7.33198 7.39845 4.333 11.3067 3.45002L11.661 3.36996C13.8444 2.87668 16.112 2.87668 18.2954 3.36997Z"
        fill="#040415"
        fillOpacity="0.4"
      />
    </svg>
  );
};

export default MessageIcon;
