
export const Footer = () => {
  return (
    <footer className="bg-orange-500 py-12 text-white">
      <div className="container mx-auto text-center">
        <h3 className="text-xl font-bold mb-4">Suscríbete a nuestra newsletter</h3>
        <form className="flex justify-center">
          <input 
            type="email" 
            placeholder="Tu email" 
            className="p-2 rounded-l-lg w-64" 
          />
          <button className="bg-gray-950 p-2 rounded-r-lg hover:bg-gray-900">Suscribirme</button>
        </form>
        <div className="mt-8">
          <p>© 2024 SQUATFIT. Todos los derechos reservados.</p>
        </div>
      </div>
    </footer>
  );
}
