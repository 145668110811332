import { DateTime } from "luxon";
import { ListChats } from "../../../../../store/interfaces/social/interfaceSocial";
import { useEffect, useState } from "react";

interface CardChatProps {
  chat: ListChats;
  onClick: () => void;
}

const CardChat = ({ chat, onClick }: CardChatProps) => {
  const [diffTime, setdiffTieme] = useState("");

  useEffect(() => {
    setTimeout(() => {
      setdiffTieme(diffNow());
    }, 1000);
  }, [chat.sent_at]);

  const diffNow = () => {
    const dateMessage = DateTime.fromISO(chat.sent_at);

    const diff = DateTime.now().diff(dateMessage, [
      "months",
      "weeks",
      "days",
      "hours",
      "minutes",
      "seconds",
    ]);

    if (Math.round(diff.weeks) < 4 && Math.round(diff.weeks) > 0) {
      return `hace ${Math.round(diff.weeks)} semanas`;
    }

    if (Math.round(diff.days) <= 7 && Math.round(diff.days) > 0) {
      return `hace ${Math.round(diff.days)} días`;
    }

    if (Math.round(diff.hours) < 24 && Math.round(diff.hours) > 0) {
      return `hace ${Math.round(diff.hours)} horas`;
    }

    if (Math.round(diff.minutes) < 60 && Math.round(diff.minutes) > 0) {
      return `hace ${Math.round(diff.minutes)} minutos`;
    }

    if (Math.round(diff.seconds) < 60 && Math.round(diff.seconds) > 0) {
      return `hace ${Math.round(diff.seconds)} segundos`;
    }

    return `hace ${Math.round(diff.months)} meses`;
  };

  return (
    <div
      className="flex flex-row justify-between content-center mt-3 py-2 px-2 rounded-xl hover:bg-gray-200 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-row">
        <img
          src={
            chat.profile_picture || chat.profile_picture !== ""
              ? chat.profile_picture
              : "https://via.placeholder.com/50"
          }
          alt="profile"
          className="rounded-full w-12 h-12 object-cover"
        />
        <div className="flex flex-col pl-4">
          <div className="flex flex-row">
            <label className="text-base font-bold">{chat.username}</label>
            {parseInt(chat.unread_messages) > 0 && (
              <label
                className={`flex items-center justify-center text-xs font-bold text-white rounded-full bg-green-500 h-5 w-5 ml-2`}
              >
                {chat.unread_messages}
              </label>
            )}
          </div>
          <label
            className={`text-xs text-ellipsis whitespace-nowrap overflow-hidden w-44 ${
              parseInt(chat.unread_messages) > 0
                ? "font-bold text-gray-500"
                : "text-gray-400"
            }`}
          >
            {chat.message_text}
          </label>
        </div>
      </div>
      <div className="flex self-center">
        <label className="text-xs text-right text-gray-400">{diffTime}</label>
      </div>
    </div>
  );
};

export default CardChat;
