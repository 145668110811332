import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { InputType } from "../../../core/interfaces/InputSquadInterface"
import { useDispatch } from "react-redux"
import { AppDispatch } from "../../../store/store"
import { startLoginUserWithEmailPassword } from "../../../store/slices/auth/thunks"
import { useEncrypt } from "../../hooks/useEncrypt"
import { useState } from "react"
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft"
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight"

export const TemporalPassScreen = () => {

  const dispatch: AppDispatch = useDispatch();

  const [ password, setPassword ] = useState('');

  const { encryptPassword } = useEncrypt();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const email = localStorage.getItem("userEmail")!;
    encryptPassword(password);
    dispatch(startLoginUserWithEmailPassword({email, password, type: "local"}));
  }

  const handdleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        <img src="/logo_color.png" className="h-40 max-w-full" alt="Logo Squat Fit"/>
        <form onSubmit={onSubmit}>
          <div className="mt-4">
            <LabelSquad children="Contraseña temporal"/>
            <InputSquad 
              inputType={InputType.Medium}
              value={password}
              name="password"
              type="password"
              onChange={handdleChange}
            />
          </div>
          <div className="mt-4">
            <ButtonSquad
              full={true}
              buttonType={ButtonType.OrangeFull}
              label="Siguiente"
            />
          </div>
        </form>
      </div>
      <div className="max-[800px]:hidden">
        <BackgroundTopLeft />
        <BackgroundBottomRight />
      </div>
    </div>
  )
}
