
export enum ButtonType {
  BlueSmall,
  BlueFull,
  OrangeSmall,
  RoseSmall,
  PurpleSmall,
  PurpleFull,
  RoseFull,
  OrangeLarge,
  OrangeFull,
  OrangeExpanded,
  Black,
  Default,
}
