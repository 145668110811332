import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { setSelectedBook } from "../../../store/slices/book/bookSlice";
import ReadBookContainer from "./components/book/ReadBookContainer";
import { UserBook } from "../../../store/interfaces/book/interfaceBook";

const ReadBookScreen = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { userBooks } = useSelector(
    (state: RootState) => state.book
  );

  useEffect(() => {
    if (userBooks.length > 0) {
      const book = userBooks.find((book) => book.id === id);
      dispatch(setSelectedBook(book as UserBook));
    }
  }, []);

  return <ReadBookContainer />
};

export default ReadBookScreen;
