import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import {
  Course,
  CourseByUser,
  CreateOrderParams,
  View,
  WatchVideoParams,
} from "../../interfaces/course/interfaceCourse";
import { TestAnswerModel, testAnswerModelFromJson, TestModel, testModelFromJson } from "../../interfaces/course/testInterface";
import { ReviewModel, reviewModelFromJson } from "../../interfaces/course/reviewInterface";

export const getAllCourses = createAsyncThunk(
  "course/getAllCourses",
  async () => {
    const resp = await squatFitApi.get<Course[]>("/course/all");

    return resp.data;
  }
);

export const getCourseByUser = createAsyncThunk(
  "course/getCourseByUser",
  async () => {
    const resp = await squatFitApi.get<CourseByUser[]>(`/course/by-user`);

    return resp.data;
  }
);

export const watchVideo = createAsyncThunk(
  "course/watchVideo",
  async ({ videoId, courseId }: WatchVideoParams) => {
    const resp = await squatFitApi.get<string>(`/course/watch-video`, {
      params: {
        video_id: videoId,
        course_id: courseId,
      },
    });

    return resp.data;
  }
);

export const createOrderWithPaypal = createAsyncThunk(
  "course/createOrderWithPaypal",
  async ({ courseId }: CreateOrderParams) => {
    const resp = await squatFitApi.post<string>(
      `/course/create-order-in-paypal`,
      {
        course_id: courseId,
      }
    );

    return resp.data;
  }
);

export const createOrderWithStripe = createAsyncThunk(
  "course/createOrderWithStripe",
  async ({ courseId }: CreateOrderParams) => {
    const resp = await squatFitApi.post<string>(
      `/course/create-checkout-session`,
      {
        course_id: courseId,
      }
    );

    return resp.data;
  }
);

export const updateView = createAsyncThunk(
  "course/updateView",
  async ({ videoId, lastMinute }: { videoId: string; lastMinute: string }) => {
    const resp = await squatFitApi.put<View>(
      `/course/update-view`,
      {
        last_minute: lastMinute,
      },
      { params: { video_id: videoId } }
    );

    return resp.data;
  }
);

export const markVideoAsViewed = createAsyncThunk(
  "course/markVideoAsViewed",
  async ({ videoId }: { videoId: string }) => {
    const resp = await squatFitApi.put<View>(
      `/course/mark-viewed`,
      {},
      {
        params: {
          video_id: videoId,
        },
      }
    );

    return resp.data;
  }
);

export const getTests = createAsyncThunk(
  "course/getTests",
  async ({ courseId }: { courseId: string }) => {
    const resp = await squatFitApi.get(
      `/course/test`,
      {
        params: {
          course_id: courseId,
        },
      }
    );
    return testModelFromJson(resp.data);
  }
);
export const getAnswers = createAsyncThunk(
  "course/getAnswers",
  async ({ testId }: { testId: string }) => {
    const resp = await squatFitApi.get(
      `/course/user-answer`,
      {
        params: {
          test_id: testId,
        },
      }
    );
    return resp.data.map((e:any)=>(testAnswerModelFromJson(e)));
  }
);
export const getCourseReview = createAsyncThunk(
  "course/getCourseReview",
  async ({ courseId }: { courseId: string }) => {
    const resp = await squatFitApi.get(
      `/course/review`,
      {
        params: {
          course_id: courseId,
        },
      }
    );
    return reviewModelFromJson(resp.data);
  }
);
export const createCourseReview = createAsyncThunk(
  "course/createCourseReview",
  async ({ courseId,text,rate }: { courseId: string, text:string, rate:string }) => {
    await squatFitApi.post(
      `/course/review`,
      {
        review_text:text,
        rate:rate,
      },
      {
        params: {
          course_id:courseId,
        },
      }
    );

    return;
  }
);
export const saveUserAnswer = createAsyncThunk(
  "course/saveUserAnswer",
  async ({ id,resp }: { id: string,  resp: any},{dispatch}) => {
    const response = await squatFitApi.post(
      `/course/user-answer`,
      {
        answers:resp
      },
      {
        params: {
          test_id: id,
        },
      }
    );
    const result = await dispatch(getAnswers({testId:id}));
    return result.payload;
  }
);
