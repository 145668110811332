import { useState } from 'react';
import { ReactComponent as ChevronUp } from '../../assets/icons/chevron_up.svg';
import { ReactComponent as ChevronDown } from '../../assets/icons/chevron_down.svg';
import { TextComponent } from './TextComponent';
import Category from '../interfaces/Category';

interface DropdownProps {
  options: Category[];
  small?:boolean;
  selectedOption: Category|null;
  label?: string;
  className?: string;
  onSelect: (option: Category) => void;
  required?: boolean;

}

export const Dropdown = (
{
    options,
    selectedOption,
    className = '',
    label,
    small=true,
    onSelect,
    required
}:DropdownProps
) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleSelect = (option: Category) => {
    onSelect(option);
    setIsOpen(false);
  };

  return (
    <div className={`relative max-h-6 inline-block text-left ${className}`}>
      {
        label?
        <TextComponent variant='h5'>
          {label}
          {required && <span className="text-red-500">*</span>}
        </TextComponent>
        :<></>
      }
      <div
        className={`flex items-center justify-between  ${small?'p-2 w-64':'p-4 w-full'}  border rounded-lg cursor-pointer bg-white`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className={`${options.some((item) => item.label === selectedOption?.label) ? 'font-bold text-blue':''}`}>{selectedOption?.label}</span>
        {
          isOpen
            ?<ChevronUp className='w-4 h-4'/>
            :<ChevronDown className='w-4 h-4'/>
        }
      </div>
      {isOpen && (
        <div className={`absolute z-10 w-full mt-2 bg-white border rounded-lg shadow-lg max-h-28 overflow-y-auto`}>
          {options
          
          .map((option) => (
            <div
              key={option.value}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100`}
              onClick={() => handleSelect(option)}
            >
              {option.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

