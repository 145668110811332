import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { CreateCommentResponse, NewPostPayload } from "../../interfaces/social/interfacePost";

// Create new post in my feed
export const startCreatingNewPost = createAsyncThunk(
  "social/createPost",
  async({postText, file}: NewPostPayload) => {

  const formData = new FormData();

  formData.append('postText', postText);
  formData.append('file', file);

  const response = await squatFitApi.post("/social/createPost", formData);

  return response.data;
});


// Get all post from people I follow
export const startGettingPosts = createAsyncThunk(
  "social/postsFeed",
  async({limit, page}: {limit: string, page: string}) => {
    const response = await squatFitApi.get("/social/postsFeed", { params: {
      limit,
      page 
    }})

    return response.data;
  }
)

// Get all post from user
export const startGettingPostFrom = createAsyncThunk(
  "social/postsByUserId",
  async(userId: string) => {
    const response = await squatFitApi.get("/social/postsByUserId", {params: {userId}})
    
    return response.data;
  }
)

// Update a post from my profile
export const startUpdatingPost = createAsyncThunk(
  "social/updatePost",
  async({postId, postText}: { postId: string, postText: string}) => {
    const response = await squatFitApi.put("/social/updatePost", {post_text: postText}, { params: {post_id: postId}})

    return response.data;
  }
) 

// Delete post from my profile
export const deletePost = createAsyncThunk(
  "social/deletePost",
  async(postId: string) => {
    const response = await squatFitApi.delete("/social/deletePost", { params: {post_id: postId} } )

    return response.data;
  }
)

//Get comments from my post
export const getPostComments = createAsyncThunk(
  "social/comments",
  async(postId: string) => {
    const response = await squatFitApi.get("/social/comments", { params: {postId: postId}})

    return response.data;
  }
)

//Create new comment
export const createNewComment = createAsyncThunk(
  "social/createComment",
  async({postId, commentText}: { postId: string, commentText: string}) => {
    const response = await squatFitApi.post<CreateCommentResponse>("/social/createComment", {commentText, postId})

    return response.data;
  }
)