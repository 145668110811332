import { createAsyncThunk } from "@reduxjs/toolkit";
import { setToken, squatFitApi } from "../../../api/SquatFitAPI";
import { UserResponse } from "../../../squad-fit/auth/data/model/UserModel";
import {
  LoginPayload,
  LoginResponse,
  RegisterPayload,
  ResetPassPayload,
  UpdatePassPayload,
  UpdateUserPayload,
} from "../../interfaces/auth/interfaceAuth";
import { checkingCredentials, onLogout } from "./authSlice";

// Register User
export const registerUserWithEmailPassword = createAsyncThunk(
  "auth/registerUser",
  async ({ email, password, username }: RegisterPayload) => {
    const response = await squatFitApi.post<UserResponse>("/user/register", {
      email,
      password,
      username,
    });

    if (response.status === 201) {
      const resp = await squatFitApi.post<LoginResponse>("/user/login", { username: email, password, type: "local" });

      localStorage.setItem("userToken", resp.data.token);

      setToken(resp.data.token);

      return resp.data;
    }

    return { token: "", status_login: null };
  }
);

// Login User
export const startLoginUserWithEmailPassword = createAsyncThunk(
  "auth/loginUser",
  async ({ email, password, type, encrypt_google_access }: LoginPayload, { rejectWithValue }) => {
    try {

      const data = { username: email, password, type, encrypt_google_access };
      let payload: {[key: string]: string} = {};

      Object.entries(data).map(([key, value]: [string, any]) => {
        if (value === null || value === "") return;
        payload[key] = value;
      })

      const response = await squatFitApi.post<LoginResponse>("/user/login", payload);

      localStorage.setItem("userToken", response.data.token);

      setToken(response.data.token);

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.status);
    }
  }
);

// Request reset password
export const startRecoveryUserPassword = createAsyncThunk(
  "auth/recoveryPassword",
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const response = await squatFitApi.get<boolean>(
        "/user/request-reset-password",
        {
          params: { email },
        }
      );
      localStorage.setItem("userEmail", email);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Send reset password code
export const startResetUserPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ email, code }: ResetPassPayload) => {
    const response = await squatFitApi.put("/user/reset-password", {
      user_email: email,
      code,
    });
    return response.data;
  }
);

// Update password
export const startUpdateUserPassword = createAsyncThunk(
  "auth/temporalPass",
  async ({ temporalPass, newPass }: UpdatePassPayload, { rejectWithValue }) => {
    const params = new URLSearchParams();
    params.append("old_password", temporalPass);
    params.append("new_password", newPass);

    try {
      const response = await squatFitApi.put(
        "/user/update-temporal-password",
        params,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      localStorage.removeItem("encryptedPassword");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get user info
export const startGettingUserInfo = createAsyncThunk(
  "auth/getUserInfo",
  async () => {
    const response = await squatFitApi.get<UserResponse>('/user/info');
    return response.data;
  }
);
export const startGettingUserInfoNoLoading = createAsyncThunk(
  "auth/getUserInfoNoLoading",
  async () => {
    const response = await squatFitApi.get<UserResponse>('/user/info');
    return response.data;
  }
);

// Update user info
export const startUpdatingUserInfo = createAsyncThunk(
  'user/update',
  async({userId, userInfo}: {userId: string, userInfo:object}) => {
    
    const formData = new FormData();

    Object.entries(userInfo).map(([key, value]: [string, string]) => {
      if (value === null) return;
      if (key === "profile_picture") {
        formData.append('file', value);
      } else {
        formData.append(key, value);
      }
    })

    const response = await squatFitApi.put('/user/update', formData, { params: {user_id: userId}});
    return response.data;
  }
);

// Check user status
export const checkingAuth = createAsyncThunk(
  "auth/checkingAuth",
  async (_, { dispatch }) => {
    dispatch(checkingCredentials());
  }
);

// Logout user
export const startLogout = createAsyncThunk(
  "auth/logout",
  async (_, { dispatch }) => {
    dispatch(onLogout());
    dispatch({ type: "DESTROY_SESSION" });
  }
);

export const renewToken = createAsyncThunk(
  "auth/renewToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await squatFitApi.get("/user/renew-token");
      localStorage.setItem("userToken", response.data.token);
      setToken(response.data.token);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const requestDeleteAccount = createAsyncThunk(
  "user/request-remove-account",
  async() => {
    const response = await squatFitApi.get('/user/request-remove-account');

    return response.data;
  }
);

export const deleteAccount = createAsyncThunk(
  "user/account",
  async(otp: string) => {
    const response = await squatFitApi.delete('/user/account', {
      params: {
        otp
      }
    });

    return response.data;
  }
);
