import { useNavigate } from "react-router-dom";
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight";
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft";
import CheckComponent from "../../../assets/background/CheckComponent";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { useEffect, useState } from "react";

const PurchaseConfirmedScreen = () => {

  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [showDOM, setShowDOM] = useState(false);

  useEffect(() => {
    if (!params.get("course_id") && !params.get("book_id") && !params.get("suscription_plan_id") && !params.get("calculator_id")) {
      navigate("/home", { replace: true });
    } else {
      setShowDOM(true);
    }
  }, []);

  const goTo = () => {
    const course_id = params.get("course_id");
    const book_id = params.get("book_id");
    const suscription_plan_id = params.get("suscription_plan_id");
    const calculator_id = params.get("calculator_id");

    if (course_id) return navigate(`/watch-course/${course_id}`, { replace: true, state: { from: "course" }});
    if (book_id) return navigate(`/read-book/${book_id}`, { replace: true, state: { from: "book" }});
    if (suscription_plan_id) return navigate("/asesorias", { replace: true });
    if (calculator_id) return navigate("/calculator", { replace: true });
  };

  const labelButton = (): string => {
    if (params.get("course_id")) return "Ir al Curso";
    if (params.get("book_id")) return "Ir al Libro";
    if (params.get("suscription_plan_id")) return "Ir a la Suscripción";
    if (params.get("calculator_id")) return "Ir a la Calculadora";
    return "";
  }

  return showDOM ? (
    <div className="flex flex-col w-screen h-screen">
      <div className="flex flex-col items-center justify-center w-full h-full gap-2">
        <div className="mb-10">
          <CheckComponent />
        </div>
        <h1 className="text-4xl font-bold text-orange-500">Felicitaciones</h1>
        <p className="text-black">
          Tu compra ha sido confirmada, ¡gracias por confiar en nosotros!
        </p>
        <div className="mt-10">
          <ButtonSquad
            className="px-10"
            buttonType={ButtonType.OrangeFull}
            label={labelButton()}
            onClick={goTo}
            full
          />
        </div>
      </div>
      <BackgroundTopLeft />
      <BackgroundBottomRight />
    </div>
  ): null;
};

export default PurchaseConfirmedScreen;
