interface AboutComponentProps {
  title: string;
  image: string;
  url: string;
}

const AboutComponent = ({ title, image, url }:AboutComponentProps) => {
  const handleClick = () => {
    const fullUrl = url.includes('youtube')
      ? "https://www.youtube.com/c/MariaSquatFit1"
      : `https://www.instagram.com/${url}`;

    window.open(fullUrl, '_blank');
  };
  let imagePath;
  try {
    imagePath = require(`../../../../assets/images/${image}.jpeg`);
  } catch (e) {
    console.error(`Image not found: ${image}`);
    imagePath = null;
  }

  return (
    <div className="flex items-center cursor-pointer" onClick={handleClick}>
      <div className="w-14 h-14 overflow-hidden rounded-full">
        <img src={imagePath} alt={title} className="w-full h-full object-cover" />
      </div>
      <div className="ml-2">
        <div className="text-lg font-bold">{title}</div>
        <div className="text-sm text-gray-500">@{url}</div>
      </div>
    </div>
  );
};

export default AboutComponent;
