import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../../../store/store";
import {
  getHistoryMessage,
} from "../../../../../store/slices/social/thunks";

import CardChat from "./CardChat";
import { InputSquadSearch } from "../../../../../core/components";
import { InputSearchType } from "../../../../../core/interfaces/InputSearchInterface";

import "../../style/social_style.css";
import NoChats from "./NoChats";
import {
  HistoryMessage,
  ListChats,
} from "../../../../../store/interfaces/social/interfaceSocial";
import {
  clearSearchListChat,
  searchListChat,
  setSelectedChat,
  setShowListChats,
} from "../../../../../store/slices/social/socialSlice";
import useDebounce from "../../../../hooks/useDebounce";
import { ArrowRight } from "../../../../../assets/icons/ArrowRight";
import useScreen from "../../../../hooks/useScreen";
import { UpdateMessageViewed } from "../../../../../socket-clients/social";
import CardChatSkeleton from "./CardChatSkeleton";

const ListChatsComponent = () => {
  const dispatch: AppDispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { isCellphoneScreen, isSmallScreen } = useScreen();

  const { uid: user_id } = useSelector((state: RootState) => state.auth);
  const { chatSelected, listChats, is_loading_chat_list } = useSelector(
    (state: RootState) => state.social
  );

  const debounceValue = useDebounce(search, 500);

  const calculateUnreadMessages = (list: HistoryMessage[]) => {
    let ids: string[] = [];
    list.forEach((message) => {
      if (!message.read && message.receiver_id === user_id) {
        ids.push(message.message_id);
      }
    });

    return ids;
  };

  useEffect(() => {
    if (chatSelected)
      dispatch(getHistoryMessage({ friend_id: chatSelected.friend_id })).then(
        (res) => {
          if (res.meta.requestStatus === "fulfilled") {
            const unreadMessages = calculateUnreadMessages(res.payload);
            if (unreadMessages.length > 0) {
              UpdateMessageViewed(
                unreadMessages,
                chatSelected?.user_id as string,
                chatSelected?.friend_id as string
              );
            }
          }
        }
      );
  }, [chatSelected]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const clearSeltedChat = () => {
    dispatch(setShowListChats(false));
  };

  const handleClickCardChat = (chat: ListChats) => {
    if (chatSelected?.friend_id === chat.friend_id) return;
    dispatch(
      setSelectedChat({
        friend_id: chat.friend_id,
        profile_picture: chat.profile_picture,
        username: chat.username,
        user_id: chat.user_id,
      })
    );
  };

  useEffect(() => {
    if (debounceValue.length > 0) {
      dispatch(searchListChat(debounceValue));
    } else {
      dispatch(clearSearchListChat());
    }
  }, [debounceValue]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        {isCellphoneScreen || isSmallScreen ? (
          <button
            className="rotate-180 self-center pl-5"
            onClick={clearSeltedChat}
          >
            <ArrowRight width="30" height="30" />
          </button>
        ) : null}
        <InputSquadSearch
          inputType={
            isSmallScreen ? InputSearchType.Normal : InputSearchType.Large
          }
          value={search}
          onChange={handleOnChange}
          clearText={search.length > 0}
          clearAction={() => setSearch("")}
        />
      </div>
      <div className="max-height-chat">
        {is_loading_chat_list && listChats.length === 0 ? (
          Array.from({ length: 2 }).map((_, index) => (
            <CardChatSkeleton key={index} />
          ))
        ) : listChats.length === 0 ? (
          <NoChats />
        ) : (
          listChats.map((chat, index) => (
            <CardChat
              key={index}
              chat={chat}
              onClick={() => handleClickCardChat(chat)}
            />
          ))
        )}
      </div>
    </div>
  );
};

export default ListChatsComponent;
