import { useEffect, useState } from "react";
import { HeartIcon } from "../../../../../assets/icons/HeartIcon";
import {
  setNewLike,
  setPostSelected,
} from "../../../../../store/slices/post/PostSlice";
import { ViewPostModal } from "./ViewPostModal";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { DateTime } from "luxon";
import { FriendsPost } from "../../../../../store/interfaces/social/interfacePost";
import { newLike } from "../../../../../store/slices/social/thunks";
import { BottomText } from "../BottomText";
import { getPostComments } from "../../../../../store/slices/post/thunks";

interface PostProps {
  post: FriendsPost;
}

export const PostCard = ({ post }: PostProps) => {
  const [diffTime, setdiffTieme] = useState("");

  const [openComments, setOpenComments] = useState(false);
  const [like, setLike] = useState<boolean>(post.liked);
  const [oldLike, setOldLike] = useState<boolean>(post.liked);
  const { uid } = useSelector((state: RootState) => state.auth);

  const dispatch: AppDispatch = useDispatch();

  const handleOpenComments = () => {
    setOpenComments(true);
  };
  const handleCloseComments = () => {
    setOpenComments(false);
  };

  useEffect(() => {
    setdiffTieme(diffNow());
  }, []);

  useEffect(() => {
    if (oldLike !== like) {
      const timer = setTimeout(() => {
        dispatch(newLike(post.id)).then((res) => {
          if (res.meta.requestStatus === "fulfilled") {
            setOldLike(like);
          }
        });
      }, 2000);
      return () => clearTimeout(timer);     
    }
  }, [like]);

  const diffNow = () => {

    const parsedDate = new Date(post.created_at);
    const currentDate = new Date();
    const differenceInSeconds = Math.floor((currentDate.getTime() - parsedDate.getTime()) / 1000);
  
    if (differenceInSeconds == 1) {
      return `hace ${differenceInSeconds} segundo`;
    }

    if (differenceInSeconds < 60) {
      return `hace ${differenceInSeconds} segundos`;
    }
  
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    if (differenceInMinutes == 1) {
      return `hace ${differenceInMinutes} minuto`;
    }
  
    if (differenceInMinutes < 60) {
      return `hace ${differenceInMinutes} minutos`;
    }
  
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours == 1) {
      return `hace ${differenceInHours} hora`;
    }
  
    if (differenceInHours < 24) {
      return `hace ${differenceInHours} horas`;
    }
  
    const differenceInDays = Math.floor(differenceInHours / 24);
    if (differenceInDays == 1) {
      return `hace ${differenceInDays} día`;
    }
  
    if (differenceInDays < 7) {
      return `hace ${differenceInDays} días`;
    }

    if (differenceInDays == 7) {
      return `hace 1 semana`;
    }
  
    if (differenceInDays < 30) {
      const weeksDifference = Math.floor(differenceInDays / 7);
      return `hace ${weeksDifference} semanas`;
    }
  
    const weeksDifference = Math.ceil(differenceInDays / 7);
    return `hace ${weeksDifference} semanas`;
  }

  const handleAddLike = () => {
    setLike(!like);
    dispatch(
      setNewLike({ post_id: post.id, user_id: uid, liked: !like })
    );
  };

  return (
    <div className="flex justify-center items-center w-full">
      <div className="text-black border-b border-grey-200 mx-5 space-y-6 md:p-10 p-5 lg:w-[550px] 2xl:w-[650px]">
        <div className="flex space-x-4 items-center ">
          {post.profile_picture !== "" || post.profile_picture !== null ? (
            <div className="">
              <img
                alt="avatar"
                src={post.profile_picture}
                className="rounded-full w-12 h-12 object-cover"
              />
            </div>
          ) : (
            <img src="https://biiainsurance.com/wp-content/uploads/2015/05/no-image.jpg" className="w-12 h-12 object-cover rounded-full" />
          )}
          <div className="space-y-1">
            <div className="flex space-x-2 items-center">
              <h2 className="text-slate-700">{post.username}</h2>
            </div>
            <p className="text-xs text-slate-400">{diffTime}</p>
          </div>
        </div>

        <div>
          <BottomText children={post.post_text} limit={80} />
        </div>

        <div className="flex justify-center items-center w-full">
          <div className="overflow-hidden rounded-xl col-span-3 max-h-[20rem] max-w-[400px]">
            <img
              className="h-full w-full rounded-xl object-cover"
              src={post.image_url}
              alt=""
            />
          </div>
        </div>

        <div className="flex justify-start pt-5">
          <div onClick={handleAddLike}>
            <HeartIcon height={5} width={5} liked={like} />
          </div>
          <p className="pl-1 text-sm">{post.like_count ?? 0}</p>

          <div className="text-slate-500 text-sm pl-5">
            <p
              className="hover:text-gray-700 cursor-pointer"
              onClick={() => {
                dispatch(getPostComments(post.id))
                dispatch(setPostSelected(post));
                handleOpenComments();
              }}
            >
              {post.comment_count}{" "}
              {post.comment_count === "1" ? "comentario" : "comentarios"}
            </p>
          </div>
          <ViewPostModal post_id={post.id} isOpen={openComments} onClose={handleCloseComments} />
        </div>
      </div>
    </div>
  );
};
