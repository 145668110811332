import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { CalculatorResponse } from "../../interfaces/shopping/interfaceShopping";

export const startCalculatingIMC = createAsyncThunk(
  "calculator/imc",
  async () => {
    const response = await squatFitApi.post<CalculatorResponse>(
      "/calculator/imc"
    );

    return response.data;
  }
);

export const startGettingUserCalculator = createAsyncThunk(
  "calculator/get",
  async () => {
    const response = await squatFitApi.get("/calculator/get");

    return response.data;
  }
);

export const createOrderWithPaypal = createAsyncThunk(
  "calculator/createOrderWithPaypal",
  async () => {
    const resp = await squatFitApi.post<string>(
      `/calculator/create-order-in-paypal`
    );

    return resp.data;
  }
);

export const createOrderWithStripe = createAsyncThunk(
  "calculator/createOrderWithStripe",
  async () => {
    const resp = await squatFitApi.post<string>(
      `/calculator/create-checkout-session`
    );

    return resp.data;
  }
);
