import ArrowUpTrayIcon from "@heroicons/react/24/outline/ArrowUpTrayIcon";

interface Props {
  image: File | null | undefined;
  setImage: React.Dispatch<React.SetStateAction<File | null | undefined>>;
}

export const UploadImage = ({image, setImage}: Props) => {

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImage(e.target.files[0]);
    }
  };

  return (
    <div className="flex w-full">
      <label className="cursor-pointer w-full">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="hidden"
          />
        {
          image == null 
          ? <div className="flex flex-col items-center justify-center w-full h-72 border-2 border-dashed border-gray-300 rounded-lg">
              <ArrowUpTrayIcon className="h-16 w-10" />
            </div>
          : <img src={URL.createObjectURL(image)} className="w-72 h-72 rounded-lg object-contain"/>
        }
      </label>
    </div>
  )
}
