import { Column } from "../../../core/components/Column";
import { Row } from "../../../core/components/Row";
import { SizedBox } from "../../../core/components/SizedBox";
import { useEffect, useState } from "react";
import PreparationWidget from "./components/PreparationSteps";
import { TextComponent } from "../../../core/components/TextComponent";
import NutritionWidget from "./components/NutritionWidget";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { Recipe } from "../../../store/interfaces/food/recipeInterface";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import {
  startCreatingMeal,
  startGettingDailyKcal,
  startGettingMeals,
  startGettingWeeklyKcal,
} from "../../../store/slices/food/thunks";
import { useAlert } from "../../hooks/useAlert";
import BlurDiv from "../../../core/components/BlurDiv";

export const RecipeScreen = () => {
  const { showAlert } = useAlert();
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { recipesPartial, recipesSystem } = useSelector(
    (state: RootState) => state.recipe
  );
  const { is_loading_meals, type } = useSelector(
    (state: RootState) => state.food
  );
  const [isChecked, setIsChecked] = useState(false);
  const [isCheckedTopping, setIsCheckedTopping] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleCheckboxToppingsChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsCheckedTopping(event.target.checked);
  };

  const { id } = useParams<{ id: string }>();
  const [recipe, setRecipe] = useState<Recipe | null>(null);

  useEffect(() => {
    const recip =
      recipesSystem.find((e) => e.id == id) ??
      recipesPartial.find((e) => e.id == id);
    if (recip) {
      setRecipe(recip);
    }
  }, [recipesSystem, recipesPartial]);

  const addRecipe = () => {
    const today = new Date();
    dispatch(
      startCreatingMeal({
        recipe_id: recipe?.id ?? "",
        date: today,
        type: type,
      })
    ).then((resp) => {
      if (resp.meta.requestStatus === "fulfilled") {
        dispatch(startGettingWeeklyKcal(new Date()));
        dispatch(startGettingMeals({ date: today }));
        dispatch(startGettingDailyKcal({ date: today }));
        showAlert({
          type: "success",
          message: "Comida agregada",
        });
      } else {
        showAlert({
          type: "error",
          message: "Ha ocurrido un error, inténtelo más tarde",
        });
      }
    });
  };

  const handleNavigate = () => {
    navigate("/compras/book-info");
  }

  return (
    <Column
      crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden"
    >
      <div className="w-full md:w-3/4 px-12 md:p-0 flex h-full grow flex-col">
        <Column className="relative rounded-md flex min-h-[280px] w-2/4 gap-6 items-start justify-end px-4 pb-10">
          <img
            src={recipe?.image}
            alt={recipe?.name}
            className="absolute inset-0 w-full h-full rounded-md object-cover z-0"
            style={{ filter: "brightness(0.7)" }}
          />
          <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/10 to-black/60 z-0"></div>

          <TextComponent
            variant="h1"
            className="relative text-white font-black z-10"
          >
            {recipe?.name}
          </TextComponent>
          {recipe?.ingredients?.length === 0 ? (
            <ButtonSquad
              label={`Desbloquear`}
              buttonType={ButtonType.RoseFull}
              onClick={handleNavigate}
              className=" z-10"
            />
          ) : (
            <ButtonSquad
              onClick={addRecipe}
              loading={is_loading_meals}
              buttonType={ButtonType.RoseFull}
              label="Agregar"
              className=" z-10"
            />
          )}
        </Column>

        <SizedBox className="h-4" />
        <TextComponent variant="h6" className="text-[#876363]">
          {recipe?.kcal} kcal · {recipe?.timeToCook}
        </TextComponent>
        <SizedBox className="h-5" />
        <div className="h4 font-bold">Información básica</div>
        <SizedBox className="h-5" />
        <div className="Container bg-[#E5E8EB]" style={{ height: "1.5px" }} />
        <SizedBox className="h-4" />
        <Row responsiveCol={true} mainAxisAlignment="between">
          <Column className="">
            <div className="h6 text-[#876363] pb-1">Tiempo de preparación</div>
            <div className="h6">{recipe?.timeToPrepare}</div>
          </Column>
          <Column className="pb-4">
            <div className="h6 text-[#876363] pb-1">Tiempo de cocción</div>
            <div className="h6">{recipe?.timeToCook}</div>
          </Column>
        </Row>
        <hr />
        <Column className="pt-4 pb-4">
          <div className="h6 text-[#876363] pb-1">Calorías</div>
          <div className="h6">{recipe?.kcal} kcal</div>
        </Column>
        <hr />
        <SizedBox className="h-4" />
        <BlurDiv blurAmount={recipe?.ingredients?.length === 0 ? 10 : 0}>
          <TextComponent variant="h5" className="font-bold">
            Materiales
          </TextComponent>
          {recipe?.materials?.map((e,index) => (
            <Column key={index}>
              <div className="h5">{e.name}</div>
              <SizedBox className="h-1" />
              <div className="h6 text-[#455CA1]">Link américa</div>
              <div className="h6 text-[#455CA1]">Link europa</div>
              <SizedBox className="h-3" />
            </Column>
          ))}
          <Row responsiveCol={true}>
            <Column className="w-full md:w-1/2 gap-2">
              <div className="h4 font-bold">Ingredients</div>
              <SizedBox className="h-3" />
              {recipe?.ingredients?.map((e,index) => (
                <Row key={index} className="mb-4 gap-3">
                  <input
                    type="checkbox"
                    id="ingredientCheckbox"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    className="form-checkbox h-5 w-5 text-blue-600 border-2 mt-1"
                  />

                  <Column>
                    <TextComponent variant="h6">{e.title}</TextComponent>
                    <TextComponent variant="h7">{e.subtitle}</TextComponent>
                  </Column>
                </Row>
              ))}

              <SizedBox className="h-6" />
              <Column>
                <div className="h4 font-bold">Para los toppings</div>
                <SizedBox className="h-3" />
                {recipe?.toppings?.map((e,index) => (
                  <Row key={index} className="mb-4 gap-3">
                    <input
                      type="checkbox"
                      id="ingredientCheckbox"
                      checked={isCheckedTopping}
                      onChange={handleCheckboxToppingsChange}
                      className="form-checkbox h-5 w-5 text-blue-600 border-2 mt-1"
                    />

                    <Column>
                      <TextComponent variant="h6">{e.title}</TextComponent>
                      <TextComponent variant="h7">{e.subtitle}</TextComponent>
                    </Column>
                  </Row>
                ))}
              </Column>
            </Column>
            <Column className="w-full md:w-1/2">
              <div>Preparacion</div>
              <SizedBox className="h-6" />
              <div className="flex flex-col">
                {recipe?.preparation?.map((e, index) => (
                  <PreparationWidget
                    key={index}
                    text={e.name ?? ""}
                    bottomLineColor="#CCD6EB"
                  />
                ))}
              </div>
            </Column>
          </Row>
          <SizedBox className="h-5" />
          <div className="h4 font-bold">Valor nutricional</div>
          <SizedBox className="h-5" />
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            <NutritionWidget
              title="Energía"
              subtitle={recipe?.nutritionalValue?.calories?.toString() ?? ""}
            />
            <NutritionWidget
              title="Carbos"
              subtitle={
                recipe?.nutritionalValue?.carbohydrates?.toString() ?? ""
              }
            />
            <NutritionWidget
              title="Proteína"
              subtitle={recipe?.nutritionalValue?.proteins?.toString() ?? ""}
            />
            <NutritionWidget
              title="Grasas"
              subtitle={recipe?.nutritionalValue?.fats?.toString() ?? ""}
            />
          </div>
        </BlurDiv>
        <SizedBox className="h-5" />
      </div>
    </Column>
  );
};
