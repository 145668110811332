import { ButtonSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { UploadImage } from "./components/UploadImage";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { onFinishMonth, onStartForm, uploadAdviceImage } from "../../../store/slices/coach/thunks";
import { useState } from "react";
import { ImageProgressCard } from "./components/ImageProgressCard";
import { CheckBoxAdvice } from "./components/CheckBoxAdvice";
import { TextComponent } from "../../../core/components/TextComponent";
import { Column } from '../../../core/components/Column';
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import { SubData } from "../../../store/interfaces/coach/interfaceCoach";
import { Row } from "../../../core/components/Row";
import Doc from "../../../assets/images/doc.png";
import Revision from "../../../assets/images/revision.png";
import Form from "../../../assets/images/form.png";
import PNG from "../../../assets/images/png.png";
import Check from "../../../assets/images/check.png";
import { getFileDescription } from "../../../core/constants/constatns";
import { onStartAdvice } from "../../../store/slices/coach/CoachSlice";
import { useAlert } from "../../hooks/useAlert";
export const CoachScreen = () => {

  const { subData } = useSelector((state: RootState) => state.coach);

  return (
    <Routes>
    <Route element={ <Layout />}>
      <Route path="/" element={<PresentationScreen coach={subData} />} />
      <Route path="progreso" element={<ProgressScreen coach={subData} />} />
      <Route path="documents" element={<DocumentsScreen coach={subData} />} />
      <Route path="documents/upload/:id/request/:requestId" element={<UploadProgressScreen/>} />
      <Route path="documents/doc/:docId/form/:formId" element={<SendFormScreen/>} />
    </Route>
    </Routes>
    )
  
}

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const isInPresentationRoute = location.pathname==='/asesorias';
  const isInProgressRoute = location.pathname==='/asesorias/progreso';
  const isInDocumentsRoute = location.pathname.startsWith('/asesorias/documents');
  return (
    <div
      className={`flex flex-col md:flex-row`}
    >
      <div className={`min-h-screen gap-3 border border-gray-400 bg-gray-100 overflow-auto z-10 py-2`}>
      <TextComponent variant="h4" className="px-8 py-6">
        Contenido de la asesoría
      </TextComponent>
      <hr/>
      <div
        className={`flex flex-row justify-between px-10 py-2 ${
          isInPresentationRoute ? "bg-gray-200" : ""
        } hover:bg-gray-200 cursor-pointer`}
        onClick={() => {navigate('/asesorias')}}
      >
        <div className="flex flex-row items-center gap-5">
          <div className="flex flex-col items-start">
            <div className="flex flex-row gap-2">
              <label>Presentación</label>
            </div>
            {/* <label className="text-xs text-gray-400">
              Video -
            </label> */}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row justify-between px-10 py-2 ${
          isInProgressRoute ? "bg-gray-200" : ""
        } hover:bg-gray-200 cursor-pointer`}
        onClick={() => {navigate('/asesorias/progreso')}}
      >
        <div className="flex flex-row items-center gap-5">
          <div className="flex flex-col items-start">
            <div className="flex flex-row gap-2">
              <label>Progreso</label>
            </div>
            {/* <label className="text-xs text-gray-400">
              Video -
            </label> */}
          </div>
        </div>
      </div>
      <div
        className={`flex flex-row justify-between px-10 py-2 ${
          isInDocumentsRoute ? "bg-gray-200" : ""
        } hover:bg-gray-200 cursor-pointer`}
        onClick={() => {navigate('/asesorias/documents')}}
      >
        <div className="flex flex-row items-center gap-5">
          <div className="flex flex-col items-start">
            <div className="flex flex-row gap-2">
              <label>Documentos</label>
            </div>
            {/* <label className="text-xs text-gray-400">
              Video -
            </label> */}
          </div>
        </div>
      </div>
    </div>

<Outlet/>
    
    </div>
  )
}


export const PresentationScreen = (coach:{coach:SubData}) => {
  const { docs,is_loading_form } = useSelector((state: RootState) => state.docsAdvice);
  const { subData } = useSelector((state: RootState) => state.coach);
  const documents=((docs.length>0??false)? docs?.[0].forms??[]:[]).filter((e)=>(e.form_type?.name=='Deportivo'&&e.is_delivered==false)||(e.form_type?.name=='Nutricional'&&e.is_delivered==false));
  const dispatch: AppDispatch = useDispatch();
  const handleClick = () => {
    dispatch(onStartForm()).then((res) => {
      dispatch(onStartAdvice())
    });
  }
  return (
    <div
      className={`flex flex-col w-full`}
    >
      <div className="bg-gray-900">
        <TextComponent variant="h4" className="px-8 py-6 text-white">
          Presentación
        </TextComponent>
      </div>
      
      <Column className="mx-6">
     
      <div
        className={`flex flex-col mt-3 gap-1 `}
      >
        <label className="font-semibold text-xl">{coach.coach.adviser_firstName} {coach.coach.adviser_lastName}</label>
        <Row>
        <img src={coach.coach.adviser_profile_picture} className="border h-64 w-[300px] rounded-xl mr-6 object-cover object-top"/>
          <label className="text-sm text-gray-500">
            {coach.coach.adviser_description}
          </label>
        </Row>
      </div>
      <div
        className={`flex flex-col mt-3 gap-2 mb-6`}
      >
        {
          subData.status_advice?.status_advice=='Faltan Datos'||subData.status_advice?.status_advice==null?<>
          <TextComponent variant="h3" className="py-6">
            Información
          </TextComponent>
          <label className="text-sm text-gray-500 pb-3">
            Para comenzar la asesoría, deberá completar una breve encuesta. La encuesta es obligatoria, una vez que la respondas, presiona el botón “completar encuesta”.
          </label>
          {
            documents.map((e,index)=>(
              <ButtonSquad key={index} buttonType={ButtonType.BlueSmall} label={e.form_type?.name} onClick={()=>window.open(e.form_link??'', '_blank')} />
            ))
          }
          <ButtonSquad loading={is_loading_form} buttonType={ButtonType.OrangeFull} label="Completar formularios" onClick={handleClick} />
          </>:<>
          <TextComponent variant="h3" className="py-6">
            Información
          </TextComponent>
          <label className="text-sm text-gray-500 pb-3">
            Una vez completado los formularios, el equipo te enviará los documentos correspondientes para comenzar tu asesoría en un tiempo maximo de 5 días
          </label>
          </>
        }
      </div>
      </Column>
    </div>
  )
}
export const ProgressScreen = (coach:{coach:SubData}) => {
  return (
    <div
      className={`flex flex-col w-full`}
    >
      <div className="bg-gray-900">
        <TextComponent variant="h4" className="px-8 py-6 text-white">
          Progreso
        </TextComponent>
      </div>
      <Column className="mx-6">
        <div
          className={`flex flex-col mt-3 gap-1 `}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 pt-8 pb-10 overflow-auto">
          {
            coach.coach.progress.filter((p) => p.id !== null).map((p,index) => (
              <ImageProgressCard key={index} img={p.image} />
            ))
          }
        </div>
        </div>
        <div
          className={`flex flex-col mt-3 gap-2 mb-6`}
        >
        </div>
      </Column>
    </div>
  )
}
export const DocumentsScreen = (coach:{coach:SubData}) => {
  const { docs } = useSelector((state: RootState) => state.docsAdvice);
  const navigate = useNavigate();
  return (
    <div
      className={`flex flex-col w-full`}
    >
      <div className="bg-gray-900">
        <TextComponent variant="h4" className="px-8 py-6 text-white">
          Documentos
        </TextComponent>
      </div>
      <Column className="mx-6">
      <div
        className={`flex flex-col mt-3 gap-1 `}
      >
        <label className="font-semibold text-xl">Aprende a trabajar</label>
          <label className="text-sm text-gray-500">
            Cada mes, proporcionaremos un nuevo conjunto de materiales de estudio. Para acceder a los materiales, deberá completar una breve encuesta. La encuesta es obligatoria, una vez que la respondas, presiona el botón “completar encuesta”.
          </label>
      </div>
      <div
        className={`flex flex-col mt-3 mb-6 gap-6`}
      >
        {
          docs.map((e,index)=>(
            <Column key={index}>
              <TextComponent variant="h2">
                {e.period}
              </TextComponent>
              <div className="px-3">
                {
                  (e.files??[]).map((file,index)=>(
                    <Row key={index} crossAxisAlignment="center" className="gap-3 mt-4 cursor-pointer" onClick={()=>window.open(file.doc_url, '_blank')}>
                      <img
                        className='object-cover w-6 h-6'
                        src={file.doc_type?.name==null? PNG:Doc}
                        alt="book"
                      />
                      <Column>
                        <TextComponent variant="h5" >
                          {file.doc_type?.name??'Progreso'}
                        </TextComponent>
                        <TextComponent variant="h7" >
                          {getFileDescription(file.doc_type?.id)}
                        </TextComponent>
                      </Column>
                    </Row>
                  ))
                }
              </div>
              <div className="px-3">
                {
                  e.request_progress&&(
                    <Row crossAxisAlignment="center">
                      <Row crossAxisAlignment="center" className="gap-3 mt-4 cursor-pointer" onClick={()=>{navigate(`/asesorias/documents/upload/${e.period_id}/request/${e.request_progress?.id}`)}}>
                        <img
                          className='object-cover w-6 h-6'
                          src={Revision}
                          alt="book"
                        />
                        <Column>
                          <TextComponent variant="h5" >
                            {'Revision'}
                          </TextComponent>
                          <TextComponent variant="h7" >
                            Sube tu progreso mensual
                          </TextComponent>
                        </Column>
                      </Row>
                      {
                        e.request_progress.is_delivered===true&&<TextComponent variant="h7" >
                          Enviado
                      </TextComponent>
                      }
                    </Row>
                  )
                }
                {
                  (e.forms?.filter((form)=>(form.form_type?.name!='Deportivo')&&(form.form_type?.name!='Nutricional')))?.map((form,index)=>(
                    <Row key={index} crossAxisAlignment="center">
                      <Row crossAxisAlignment="center" className="gap-3 mt-4 cursor-pointer" onClick={()=>{navigate(`/asesorias/documents/doc/${e.period_id}/form/${form.id}`)}}>
                        <img
                          className='object-cover w-6 h-6'
                          src={Form}
                          alt="book"
                        />
                        <Column>
                          <TextComponent variant="h5" >
                            {'Formulario'}
                          </TextComponent>
                          <TextComponent variant="h7" >
                            Responde el formulario del término de mes
                          </TextComponent>
                        </Column>
                      </Row>
                      {
                        form.is_delivered===true&&<TextComponent variant="h7" >
                          Enviado
                      </TextComponent>
                      }
                      
                    </Row>
                  ))
                }
                {
                  (e.request_progress?.progress)?.map((form,index)=>(
                    <Row key={index} crossAxisAlignment="center">
                      <Row crossAxisAlignment="center" className="gap-3 mt-4 cursor-pointer" onClick={()=>window.open(form.image, '_blank')}>
                        <img
                          className='object-cover w-6 h-6'
                          src={Check}
                          alt="book"
                        />
                        <Column>
                          <TextComponent variant="h5" >
                            {'Resumen'}
                          </TextComponent>
                          <TextComponent variant="h7" >
                            Tu resumen de progreso mensual
                          </TextComponent>
                        </Column>
                      </Row>

                      
                    </Row>
                  ))
                }
              </div>
            </Column>
          ))
        }
      </div>
      </Column>
    </div>
  )
}
export const UploadProgressScreen = () => {
  const navigate = useNavigate();
  const { docs,is_loading_image } = useSelector((state: RootState) => state.docsAdvice);
  const { id, requestId } = useParams();
  const selectedDoc = docs.find((doc) => doc.period_id === id);
  const progress = selectedDoc?.request_progress;
  const [Image, setImage] = useState<File | null>();
  const dispatch: AppDispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const { showAlert } = useAlert();
  const booleanAsString = isChecked.toString();
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleClick = () => {
    if (Image !== null) {
      dispatch(uploadAdviceImage({file: Image as File, isPrivate: booleanAsString,request_progress_id:requestId??'',periodId:id??''})).then((resp) => {
        if (resp.meta.requestStatus === "fulfilled") {
          showAlert({
            type: "success",
            message: "Formulario cargado",
          });
          navigate(-1);
        } else {
          showAlert({
            type: "error",
            message: "Ha ocurrido un error, inténtelo más tarde",
          });
        }
      });
    }
  }
  return (
    <div
      className={`flex flex-col w-full`}
    >
      <div className="bg-gray-900">
        <TextComponent variant="h4" className="px-8 py-6 text-white">
          Revision
        </TextComponent>
      </div>
      <Column className="mx-6">
      <div
        className={`flex flex-col mt-3 gap-1 `}
      >
        <label className="font-semibold text-xl">Instrucciones</label>
          <label className="text-sm text-gray-500">
            Sube una foto de tu progreso mensual en donde se vea tu cuerpo completo, además no te olvides de completar el formulario de progreso mensual
          </label>
      </div>
      <div
        className={`flex flex-col mt-3 mb-6`}
      >
        <Row className="bg-graySquat1 py-3 px-5 gap-6">
          <TextComponent variant="h5" >
            Estado de la entrega
          </TextComponent>
          <TextComponent variant="h5" >
          {
              progress?.is_delivered===true?'Entregado':'No entregado'
            }
          </TextComponent>
        </Row>
        <Row className="bg-graySquat2 py-3 px-5 gap-6">
          <TextComponent variant="h5" >
            Fecha de publicación
          </TextComponent>
          <TextComponent variant="h5" >
            {progress?.created_at?.toString().split('T')[0]??''}
          </TextComponent>
        </Row>
        <hr/>
        {
           progress?.is_delivered===false?<Column className="pt-6 pb-6 w-full">
           <div className="pb-8">
             <label className="text-sm text-gray-400 font-normal">Subir una imagen</label>
             <UploadImage image={Image} setImage={setImage} />
           </div>
           {
             Image &&
             <CheckBoxAdvice isChecked={isChecked} handleCheckboxChange={handleCheckboxChange} />
           }
           <div className="flex items-end w-[100px] pt-6">
             <ButtonSquad full={true} buttonType={ButtonType.OrangeFull} label="Enviar" onClick={handleClick} />
           </div>
         </Column>:<div className="flex items-end w-[100px] pt-12">
            <ButtonSquad loading={is_loading_image} full={true} buttonType={ButtonType.OrangeFull} label="Volver" onClick={()=>navigate(-1)} />
          </div>
        }
          
      </div>
      </Column>
    </div>
  )
}
export const SendFormScreen = () => {
  const navigate = useNavigate();
  const { docs,is_loading_month } = useSelector((state: RootState) => state.docsAdvice);
  const { docId, formId } = useParams();
  const selectedDoc = docs.find((doc) => doc.period_id === docId);
  const selectedForm = selectedDoc?.forms?.find((form) => form.id === formId);
  const dispatch: AppDispatch = useDispatch();
  const { showAlert } = useAlert();
  const handleClick = () => {
    dispatch(onFinishMonth({id:selectedForm?.id??'',periodId:docId??''})).then((resp) => {
      if (resp.meta.requestStatus === "fulfilled") {
        showAlert({
          type: "success",
          message: "Formulario cargado",
        });
        navigate(-1);
      } else {
        showAlert({
          type: "error",
          message: "Ha ocurrido un error, inténtelo más tarde",
        });
      }
    });
  }
  return (
    <div
      className={`flex flex-col w-full`}
    >
      <div className="bg-gray-900">
        <TextComponent variant="h4" className="px-8 py-6 text-white">
          Revision
        </TextComponent>
      </div>
      <Column className="mx-6">
      <div
        className={`flex flex-col mt-3 gap-1 `}
      >
        <label className="font-semibold text-xl">Instrucciones</label>
          <label className="text-sm text-gray-500">
            Sube una foto de tu progreso mensual en donde se vea tu cuerpo completo, además no te olvides de completar el formulario de progreso mensual
          </label>
      </div>
      <div
        className={`flex flex-col mt-3 mb-6`}
      >
        <Row className="bg-graySquat1 py-3 px-5 gap-6">
          <TextComponent variant="h5" >
            Estado de la entrega
          </TextComponent>
          <TextComponent variant="h5" >
            {
              selectedForm?.is_delivered===true?'Entregado':'No entregado'
            }
          </TextComponent>
        </Row>
        <Row className="bg-graySquat2 py-3 px-5 gap-6">
          <TextComponent variant="h5" >
            Fecha de publicación
          </TextComponent>
          <TextComponent variant="h5" >
            {selectedForm?.created_at?.toString().split('T')[0]??''}
          </TextComponent>
        </Row>
        <hr/>
        {
          selectedForm?.is_delivered===false?<Column className="pt-6 pb-6 w-full">
          <div className="pb-8">
            <label className="text-sm text-gray-400 font-normal">Completar formulario</label>
            <div className="flex flex-col items-center justify-center w-full h-52 border-2 border-dashed border-gray-300 rounded-lg">

            <ButtonSquad buttonType={ButtonType.BlueSmall} label={'Abrir formulario'} onClick={()=>window.open(selectedForm?.form_link??'', '_blank')} />
            </div>
          </div>
          <div className="flex items-end w-[100px]">
            <ButtonSquad loading={is_loading_month} full={true} buttonType={ButtonType.OrangeFull} label="Finalizar" onClick={handleClick} />
          </div>
        </Column>:<div className="flex items-end w-[100px] pt-12">
            <ButtonSquad loading={is_loading_month} full={true} buttonType={ButtonType.OrangeFull} label="Volver" onClick={()=>navigate(-1)} />
          </div>
        }
          
      </div>
      </Column>
    </div>
  )
}
