import * as Yup from 'yup';


export interface Step1FormValues {
    lastName: string;
    firstName: string;
    phone_number: string;
    email: string;
    birth: string;
    description: string;
  }
const today = new Date();
const minDate = new Date();
minDate.setFullYear(today.getFullYear() - 1);
const maxDate = new Date();
maxDate.setFullYear(today.getFullYear() - 120);
export const step1ValidationSchema = Yup.object({
    lastName: Yup.string().required('Ingrese un apellido'),
    firstName: Yup.string().required('Ingrese nombre'),
    phone_number: Yup.string().required('Ingrese teléfono'),
    email: Yup.string().email('Ingrese un email válido').required('Email is required'),
    description: Yup.string().required('Ingrese un texto'),
  }
);
  