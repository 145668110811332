import React, { useEffect, useState } from "react";
import { Column } from "../../../core/components/Column";
import { Row } from "../../../core/components/Row";
import { TextComponent } from "../../../core/components/TextComponent";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Coach } from "../../../store/interfaces/coach/interfaceCoach";
import { coachs, faqsAsesorias } from "../../../core/constants/constatns";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import BeforeAfterComponent from "../home/components/BeforeAfterComponent";
import { FaqComponent } from "../profile/components/FaqComponent";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { onSelectCoach } from "../../../store/slices/coach/CoachSlice";
import {
  getAdviceByUser,
  getAllSubs,
} from "../../../store/slices/coach/thunks";
import ContactScreen from "../profile/ContactScreen";

const HamletPresentationScreen = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { subData } = useSelector((state: RootState) => state.coach);
  const [coach, setCoach] = useState<Coach | null>(null);

  useEffect(() => {
    // window.scrollTo(0, 0);
    const find = coachs.find((e) => e.id == id);
    if (find) {
      setCoach(find);
    }
  }, []);

  useEffect(() => {
    if (subData.adviser_id === "") dispatch(getAdviceByUser());
  }, []);

  const dispatch: AppDispatch = useDispatch();
  const handleClick = () => {
    window.location.hash = '#contact';
  };

  const handleNavigate = () => {
    navigate("/asesorias");
  };
  return (
    <Column
      crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden"
    >
      <div className="w-full md:w-3/4 px-12 md:p-0 flex h-full grow flex-col">
        <Column className="layout-content-container flex-1">
          <Column
            className="min-h-[480px] gap-6 bg-cover bg-center bg-no-repeat items-start justify-end px-4 pb-10 "
            style={{
              backgroundPosition: "right 0px",
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url(${coach?.profile_picture})`,
            }}
          >
            <TextComponent variant="h1" className="text-white font-black">
              {coach?.firstName} {coach?.lastName}
            </TextComponent>
            <TextComponent variant="h2" className="text-white">
              Tu entrenadora físico personal
            </TextComponent>
            <div className="pt-10">
              {subData.adviser_id === "" ? (
                <ButtonSquad
                  label="Contactar"
                  buttonType={ButtonType.BlueFull}
                  onClick={handleClick}
                />
              ) : (
                <ButtonSquad
                  label="Ir a mis asesorías"
                  buttonType={ButtonType.BlueFull}
                  onClick={handleNavigate}
                />
              )}
            </div>
          </Column>
          <TextComponent variant="h1" className="font-bold text-left pb-3 pt-6">
            {coach?.firstName} {coach?.lastName}
          </TextComponent>
          <TextComponent variant="h4" className="pb-3 pt-1">
            {coach?.mision}
          </TextComponent>
          <Row className="gap-4" responsiveCol={true}>
            <div className="rounded-full min-w-32 min-h-32 max-w-32 max-h-32">
              <img
                src={coach?.profile_picture}
                className="object-cover rounded-full min-w-32 min-h-32 max-w-32 max-h-32"
              />
            </div>
            <Column mainAxisAlignment="center">
              <TextComponent className="font-bold">
                {coach?.firstName}
              </TextComponent>
              <TextComponent
                variant="h4"
                className="text-fadedAsh text-base font-normal leading-normal"
              >
                {coach?.achievements?.map((e,index) => (
                  <div key={index}>{e.title}, </div>
                ))}
              </TextComponent>
            </Column>
          </Row>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
            {coach?.clients?.map((e,index) => (
              <BeforeAfterComponent
                key={index}
                beforeImage={e.beforeImage}
                afterImage={e.afterImage}
                description={e.description}
              />
            ))}
          </div>
          <TextComponent variant="h2" className="font-bold pt-5">
            ¿Que Incluye?
          </TextComponent>
          <TextComponent variant="h3" className="pb-3 ">
            Lo que Necesitas para Alcanzar el Objetivo
          </TextComponent>
          {coach?.objectives?.map((e,index) => (
            <Row key={index} className="items-stretch justify-between gap-4 rounded-xl">
              <Column className="flex-[2_2_0px] gap-4">
                <Column className="gap-1">
                  <TextComponent className="text-blueSquat1 text-base font-bold ">
                    {e.title}
                  </TextComponent>
                  <TextComponent className="text-fadedAsh text-sm font-normal leading-normal">
                    {e.subTitle}
                  </TextComponent>
                </Column>
              </Column>
              <div
                className="w-full hidden md:block bg-center bg-no-repeat aspect-video bg-cover rounded-xl flex-1"
                // style={{
                //   backgroundImage:
                //     'url("https://cdn.usegalileo.ai/stability/19da72b7-cec4-44d7-8fcf-2a362cf25bbf.png")',
                // }}
              ></div>
            </Row>
          ))}
          <Row mainAxisAlignment="between" id="contact" responsiveCol={true}>
            <Column className="gap-4">
                <Column className="gap-1">
                  <TextComponent variant="h2" className="font-bold py-5">
                  ¿Cómo funciona?
                  </TextComponent>
                  <TextComponent variant="h6" className="text-fadedAsh text-sm font-normal leading-normal">
                    <li>Entender los mecanismos del crecimiento muscular y la pérdida de grasa.</li>
                    <li>Identificar estrategias para ganar músculo y perder grasa según  tu biotipo.</li>
                    <li>Aprender a compatibilizar la pérdida de grasa con tu estilo de vida.</li>
                    <li>Aumentar tu fuerza a través de la dieta y el ejercicio.</li>
                  </TextComponent>
                </Column>
              </Column>
            <ContactScreen coach={coachs.find((e) => e.id == id)!} />
          </Row>
          <TextComponent variant="h2" className="font-bold pb-3 pt-5">
            Preguntas frecuentes
          </TextComponent>
          {faqsAsesorias.map((e,index) => (
            <FaqComponent key={index} color={'text-blueSquat1'} title={e.title} subtitle={e.subtitle} />
          ))}
          <div className="flex px-4 py-3">
            <ButtonSquad
              label="Comprar asesoría"
              buttonType={ButtonType.BlueFull}
            ></ButtonSquad>
          </div>
        </Column>
      </div>
    </Column>
  );
};

export default HamletPresentationScreen;
