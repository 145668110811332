export interface TestAnswerModel {
    id: string;
    testId: string;
    userId: string;
    question: QuestionModel;
    answer: AnswerModel;
    createdAt: Date;
}

export function createTestAnswerModel(
    id: string,
    testId: string,
    userId: string,
    question: QuestionModel,
    answer: AnswerModel,
    createdAt: Date
): TestAnswerModel {
    return { id, testId, userId, question, answer, createdAt };
}

export function copyTestAnswerModel(
    model: TestAnswerModel,
    updates: Partial<TestAnswerModel>
): TestAnswerModel {
    return { ...model, ...updates };
}

export function testAnswerModelFromJson(json: any): TestAnswerModel {
    return {
        id: json["id"],
        testId: json["test_id"],
        userId: json["user_id"],
        question: questionModelFromJson(json["question"]),
        answer: answerModelFromJson(json["answer"]),
        createdAt: new Date(json["created_at"])
    };
}

export function testAnswerModelToJson(model: TestAnswerModel): any {
    return {
        id: model.id,
        test_id: model.testId,
        user_id: model.userId,
        question: questionModelToJson(model.question),
        answer: answerModelToJson(model.answer),
        created_at: model.createdAt.toISOString()
    };
}
export interface TypeTestModel {
    id: string;
    name: string;
}

export function createTypeTestModel(
    id: string,
    name: string
): TypeTestModel {
    return { id, name };
}

export function copyTypeTestModel(
    model: TypeTestModel,
    updates: Partial<TypeTestModel>
): TypeTestModel {
    return { ...model, ...updates };
}

export function typeTestModelFromJson(json: any): TypeTestModel {
    return { id: json["id"], name: json["name"] };
}

export function typeTestModelToJson(model: TypeTestModel): any {
    return { id: model.id, name: model.name };
}

export interface AnswerModel {
    id: string;
    answer: string;
    correct: boolean;
    type: TypeTestModel;
}

export function createAnswerModel(
    id: string,
    answer: string,
    correct: boolean,
    type: TypeTestModel
): AnswerModel {
    return { id, answer, correct, type };
}

export function copyAnswerModel(
    model: AnswerModel,
    updates: Partial<AnswerModel>
): AnswerModel {
    return { ...model, ...updates };
}

export function answerModelFromJson(json: any): AnswerModel {
    return {
        id: json["id"],
        answer: json["answer"],
        correct: json["correct"] ?? false,
        type: typeTestModelFromJson(json["answer_type"])
    };
}

export function answerModelToJson(model: AnswerModel): any {
    return {
        id: model.id,
        answer: model.answer,
        correct: model.correct
    };
}

export interface QuestionModel {
    id: string;
    question: string;
    answers: AnswerModel[];
}

export function createQuestionModel(
    id: string,
    question: string,
    answers: AnswerModel[]
): QuestionModel {
    return { id, question, answers };
}

export function copyQuestionModel(
    model: QuestionModel,
    updates: Partial<QuestionModel>
): QuestionModel {
    return { ...model, ...updates };
}

export function questionModelFromJson(json: any): QuestionModel {
    return {
        id: json["id"],
        question: json["question"],
        answers: json["answers"]?.map((e: any) => answerModelFromJson(e)) || []
    };
}

export function questionModelToJson(model: QuestionModel): any {
    return {
        id: model.id,
        question: model.question,
        answers: model.answers.map(answerModelToJson)
    };
}


export interface TestModel {
    id: string;
    courseId: string;
    questions: QuestionModel[];
}

export function createTestModel(
    id: string,
    courseId: string,
    questions: QuestionModel[]
): TestModel {
    return { id, courseId, questions };
}

export function copyTestModel(
    model: TestModel,
    updates: Partial<TestModel>
): TestModel {
    return { ...model, ...updates };
}

export function testModelFromJson(json: any): TestModel {
    return {
        id: json["id"],
        courseId: json["course_id"],
        questions: json["questions"]?.map((x: any) => questionModelFromJson(x)) || []
    };
}

export function testModelToJson(model: TestModel): any {
    return {
        id: model.id,
        course_id: model.courseId
    };
}
