import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Row } from '../../../../core/components/Row';
import { TextComponent } from '../../../../core/components/TextComponent';

interface TabBarProps {
  tabs: { text: string, path: string }[];
}
const list=['books','tools','cursos','asesoria','canje'];
const TabBar: React.FC<TabBarProps> = ({ tabs }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (path: string,) => {
    navigate(path);
  };
  return(
    <Row mainAxisAlignment='center' className="border-b border-[#cfdbe7]">
      {tabs.map((tab,index) => (
        <a 
          key={index}
          onClick={() => handleClick(tab.path)}
          className={`flex cursor-pointer flex-col items-center justify-center border-b-[3px] ${location.pathname.includes(tab.path) ?'border-b-[#1380ec]':''}  text-[#0d141b] gap-2 pb-[7px] pt-2.5 flex-1`}>
        <TextComponent variant="h4" className="text-[#0d141b] text-sm font-bold leading-normal tracking-[0.015em]">{tab.text}</TextComponent>
      </a>
      ))}
    </Row>
  );
};

export default TabBar;
