import { useDispatch, useSelector } from "react-redux";
import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { InputType } from "../../../core/interfaces/InputSquadInterface";
import { AppDispatch, RootState } from "../../../store/store";
import {
  startCreatingRecipe,
  startUpdatingRecipe,
} from "../../../store/slices/food/thunks";
import { useForm } from "../../hooks/useForm";
import { RecipeImage } from "./components/RecipeImage";
import { useRecipe } from "../../hooks/useRecipe";
import { useEffect, useState } from "react";
import { OptionIcon } from "../../../assets/icons/OptionIcon";
import { ArrowRight } from "../../../assets/icons/ArrowRight";
import { useNavigate } from "react-router-dom";
import { useAlert } from "../../hooks/useAlert";
import { RecipePayload } from "../../../store/interfaces/food/interfaceFood";
import CircularProgress from "../../components/CircularProgress";

const formData = {
  name: "",
  kcal: "",
  description: "",
  recipe_category_id: "",
  image: ""
};

export const RecipeRegister = () => {
  const { categories, updateRecipe, is_loading_recipe } = useSelector(
    (state: RootState) => state.food
  );
  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();
  const { showAlert } = useAlert();

  const { formState, onInputChange, onTextChange, setFormState } =
    useForm(formData);
  const { recipePicture, handleFileChange, catSelected, setCatSelected } =
    useRecipe();
  const [isOpen, setisOpen] = useState(false);

  // useEffect(() => {
  //   if (updateRecipe) {
  //     setFormState(updateRecipe);
  //     setCatSelected(updateRecipe.recipe_category_id);
  //   }
  // }, [updateRecipe]);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const formRecipe: RecipePayload = {
      name: formState.name,
      description: formState.description,
      kcal: formState.kcal,
      file: recipePicture as File,
      recipe_category_id: catSelected,
    };

    if (Object.values(formRecipe).some((value) => value === "")) {
      showAlert({
        message: "Asegurate de completar todos los campos.",
        type: "error",
      });
    } else {
      if (Object.values(updateRecipe).every((value) => value !== "")) {
        const recipe: RecipePayload = {
          name: formState.name,
          description: formState.description,
          kcal: formState.kcal,
          file: recipePicture as File,
          recipe_category_id: catSelected,
        };
        // dispatch(
        //   startUpdatingRecipe({ recipe, recipe_id: updateRecipe.id })
        // ).then((response) => {
        //   if (response.meta.requestStatus === "fulfilled") {
        //     showAlert({
        //       message: "Cambios guardados correctamente.",
        //       type: "success",
        //     });
        //     navigate("/today");
        //   }
        // });
      } else {
        dispatch(
          startCreatingRecipe({
            name: formState.name,
            description: formState.description,
            recipe_category_id: catSelected,
            kcal: formState.kcal,
            file: recipePicture as File,
          })
        ).then((response) => {
          if (response.meta.requestStatus === "fulfilled") {
            showAlert({
              message: "Nueva receta creada con éxito!",
              type: "success",
            });
            navigate("/today");
          }else if (response.meta.requestStatus === "rejected") {
            showAlert({
              message: "Error. No se pudo crear la receta",
              type: "error",
            });
            navigate("/today");
          }
        });
      }
    }
  };

  return (
    <div className="flex flex-row max-[870px]:flex-col">
      {
        is_loading_recipe === true
        ? <div className="relative left-[500px] top-44">
            <CircularProgress styleProps={{width: 40, height: 40}} />
          </div>
        : <>
            <RecipeImage
              recipePicture={recipePicture ?? updateRecipe.image}
              handleFileChange={handleFileChange} />
            <form onSubmit={onSubmit}>
              <div className="flex flex-col items-center min-[870px]:ml-44">
                <div className="mt-12">
                  <LabelSquad children="Nombre" />
                  <InputSquad
                    inputType={InputType.Medium}
                    value={formState.name}
                    name="name"
                    onChange={onInputChange} />
                </div>
                <div className="mt-4">
                  <LabelSquad children="Calorias" />
                  <InputSquad
                    inputType={InputType.Medium}
                    value={formState.kcal}
                    name="kcal"
                    type="number"
                    onChange={onInputChange} />
                </div>
                <div className="mt-4">
                  <LabelSquad children="Descripción" />
                  <textarea
                    className="border-2 border-gray-80 text-sm rounded-lg w-72 py-2 px-2"
                    placeholder="Inserte una descripción"
                    name="description"
                    value={formState.description}
                    onChange={onTextChange}
                    style={{ resize: "none" }}
                  ></textarea>
                </div>
                <div className="flex flex-col relative justify-center w-72 h-12 mt-6 rounded-lg">
                  <button
                    type="button"
                    onClick={() => setisOpen((prev) => !prev)}
                    className="flex items-center justify-between text-sm"
                  >
                    <div className="flex row items-center">
                      <OptionIcon height="40" width="40" />
                      {categories.find((element) => element.id === catSelected)
                        ?.name ?? "Tipo de comida"}
                    </div>
                    {!isOpen ? (
                      <div>
                        <ArrowRight />
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </button>
                  {isOpen && (
                    <div className="flex flex-col absolute left-44 bottom-0 bg-white border-2 border-orange-300 rounded-xl p-2 w-48">
                      {categories.map((cat, i) => (
                        <div
                          key={i}
                          onClick={() => {
                            setCatSelected(cat.id);
                            setisOpen(false);
                          } }
                          className="flex w-full items-center hover:bg-orange-100 hover:rounded-lg p-2 cursor-pointer"
                        >
                          <OptionIcon height="28" width="28" />
                          <h3 className="pl-4">{cat.name}</h3>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-10 mb-5">
                  <ButtonSquad
                    type="submit"
                    buttonType={ButtonType.OrangeFull}
                    label="Guardar" />
                </div>
              </div>
            </form>
          </>
      }
    </div>
  );
};
