import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { getCoachChatList, getCoachHistoryMessage, getCoachHistoryOldestMessage, setMessageViewedCoach, setNewMessageCoach } from './thunks';

export interface ChatState {
  listChats: any[];
  historyMessage: any[];
  chatSelected: any;
  is_loading_chat_list: boolean;
  is_loading_history_message: boolean;
  showListChats: boolean;
}

const initialState: ChatState = {
  listChats: [],
  historyMessage: [],
  chatSelected: null,
  is_loading_chat_list: false,
  is_loading_history_message: false,
  showListChats: false,
}

const pushChatOnTop = (listChats: any[], payload: any) => {
  const chat = listChats.find((chat) => chat.chat_id === payload.chat_id);
  if (!chat) return [payload, ...listChats];

  const editedChat = {
    ...chat,
    message: payload.message,
    sended_at: payload.sended_at,
    read: payload.read,
    unread_messages: payload.unread_messages,
  };
  return [
    editedChat,
    ...listChats.filter((chat) => chat.chat_id !== payload.chat_id),
  ];
};

const sortHistoryMessages = (historyMessages: any[]) => {
  return historyMessages.sort((a, b) => {
    const dateA = new Date(a.sended_at).getTime();
    const dateB = new Date(b.sended_at).getTime();
    return dateA - dateB;
  });
};

export const ChatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setSelectedChat: (state, action: PayloadAction<any>) => {
      state.chatSelected = action.payload;
      localStorage.setItem("chatSelected", JSON.stringify(action.payload));
    },
    setShowListChats: (state, action: PayloadAction<boolean>) => {
      state.showListChats = action.payload;
    },
    cleanSelectedChat: (state) => {
      state.chatSelected = null;
      localStorage.removeItem("chatSelected");
    },
    cleanHistoryMessage: (state) => {
      state.historyMessage = [];
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(
      setNewMessageCoach,
      (
        state,
        action: PayloadAction<{ chat: any; message: any }>
      ) => {
        const payload = action.payload;
        state.listChats = pushChatOnTop(state.listChats, payload.chat);
        state.historyMessage =
          state.chatSelected?.chat_id === payload.chat.chat_id && state.historyMessage.every((message) => message.id !== payload.message.id)
            ? [...state.historyMessage, payload.message]
            : state.historyMessage;
      }
    )
    .addCase(
      setMessageViewedCoach,
      (state, action: PayloadAction<any[]>) => {
        state.historyMessage = state.historyMessage.map((chat) => {
          const message = action.payload.find(
            (message) => message.message_id === chat.message_id
          );
          return message ? { ...chat, read: message.read } : chat;
        });
        state.listChats = state.listChats.map((chat) => {
          const message = action.payload.find(
            (message) => message.chat_id === chat.chat_id
          );
          return message
            ? {
                ...chat,
                unread_messages: message.read ? "0" : chat.unread_messages,
              }
            : chat;
        });
      }
    )
    .addCase(getCoachChatList.pending, (state) => {
      state.is_loading_chat_list = true;
    })
    .addCase(
      getCoachChatList.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.listChats = action.payload;
        state.is_loading_chat_list = false;
      }
    )
    .addCase(getCoachChatList.rejected, (state) => {
      state.listChats = [];
      state.is_loading_chat_list = false;
    })
    .addCase(getCoachHistoryMessage.pending, (state) => {
      state.is_loading_history_message = true;
    })
    .addCase(
      getCoachHistoryMessage.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.historyMessage = sortHistoryMessages(action.payload);
        state.is_loading_history_message = false;
      }
    )
    .addCase(getCoachHistoryMessage.rejected, (state) => {
      state.historyMessage = [];
      state.is_loading_history_message = false;
    })
    .addCase(getCoachHistoryOldestMessage.pending, (state) => {
      state.is_loading_history_message = true;
    })
    .addCase(
      getCoachHistoryOldestMessage.fulfilled,
      (state, action: PayloadAction<any[]>) => {
        state.historyMessage =
          action.payload.length > 0 && state.historyMessage.every((message) => message.id !== action.payload[action.payload.length - 1]?.id)
            ? sortHistoryMessages([
                ...action.payload,
                ...state.historyMessage,
              ])
            : state.historyMessage;
        state.is_loading_history_message = false;
      }
    )
    .addCase(getCoachHistoryOldestMessage.rejected, (state) => {
      state.is_loading_history_message = false;
    })
  }
})

export const {
  cleanSelectedChat,
  cleanHistoryMessage,
  setSelectedChat,
  setShowListChats,
} = ChatSlice.actions;
export default ChatSlice.reducer