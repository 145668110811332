import React, { useEffect, useState } from "react";
import { Row } from "../../../../core/components/Row";
import { ButtonSquad } from "../../../../core/components";
import { Column } from "../../../../core/components/Column";
import { TextComponent } from "../../../../core/components/TextComponent";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";
import { coachs } from "../../../../core/constants/constatns";
import BeforeAfterComponent from "../../home/components/BeforeAfterComponent";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import {
  createOrderWithStripe,
  startGettingUserCalculator,
  createOrderWithPaypal,
} from "../../../../store/slices/shopping/thunks";
import PurchaseBy, { OrderType } from "../../../components/PurchaseBy";
import { useNavigate } from "react-router-dom";

const CalculatorInfoScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const {
    userCalculator,
    is_loading_order_with_paypal,
    is_loading_order_with_stripe,
  } = useSelector((state: RootState) => state.shopp);
  const [isOpenModalByCalculator, setIsOpenModalByCalculator] =
    useState<boolean>(false);

  useEffect(() => {
    if (!userCalculator) dispatch(startGettingUserCalculator());
  }, []);

  const createOrder = (type: OrderType) => {
    if (type === "stripe") {
      dispatch(createOrderWithStripe()).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          window.open(result.payload as string, "_blank");
        }
      });
    } else {
      dispatch(createOrderWithPaypal()).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          window.open(result.payload as string, "_blank");
        }
      });
    }
  };

  return (
    <Column
      crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden mt-6"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
        <TextComponent
          variant="h2"
          className="font-bold text-purpleSquat1 pb-3 pt-5 text-center"
        >
          Cálculo de Calorías Diarias
        </TextComponent>
        <TextComponent className=" font-normal leading-normal pb-3 pt-1 text-center">
          Utilice esta herramienta para conocer su IMC y obtener asesoramiento
          personalizado
        </TextComponent>
        <Column mainAxisAlignment="center" className="gap-10  py-10">
          <Column className="gap-6">
            <Column className="gap-4">
              <TextComponent
                variant="h2"
                className="text-purpleSquat1 font-bold max-w-[720px]"
              >
                Características clave
              </TextComponent>
              <TextComponent className=" font-normal leading-normal max-w-[720px]">
                Nuestra calculadora de IMC proporciona más que solo su IMC. Le
                brinda consejos personalizados y recomendaciones de ingesta
                calórica diaria.
              </TextComponent>
            </Column>
            {isOpenModalByCalculator && (
              <div className="flex relative justify-center h-full min-[768px]:w-1/2 max-[768px]:w-full">
                <PurchaseBy
                  className={`absolute z-30 bottom-[10px] bg-white rounded-xl p-2 w-2/3 max-[767px]:w-full shadow-xl`}
                  createOrder={createOrder}
                  is_loading_with_paypal={is_loading_order_with_paypal}
                  is_loading_with_stripe={is_loading_order_with_stripe}
                />
              </div>
            )}
            <ButtonSquad
              onClick={()=>navigate("/calculator")}
              buttonType={ButtonType.PurpleFull}
              label="Calcular"
            />
          </Column>
          <Row className="gap-3 grid grid-cols-2">
            <Column className="gap-3 rounded-lg border border-[#d1e6e0] bg-[#f8fbfb] p-4">
              <Column className="gap-1">
                <TextComponent
                  variant="h3"
                  className="text-purpleSquat1 font-bold"
                >
                  Asesoramiento personalizado
                </TextComponent>
                <TextComponent
                  variant="h4"
                  className="text-fadedAsh text-sm font-normal leading-normal"
                >
                  Obtenga consejos personalizados según su IMC.
                </TextComponent>
              </Column>
            </Column>
            <Column className="gap-3 rounded-lg border border-[#d1e6e0] bg-[#f8fbfb] p-4">
              <Column className="gap-1">
                <TextComponent
                  variant="h3"
                  className="text-purpleSquat1 font-bold"
                >
                  Recomendaciones de ingesta calórica
                </TextComponent>
                <TextComponent
                  variant="h4"
                  className="text-fadedAsh text-sm font-normal leading-normal"
                >
                  Sepa cuánto comer para sus objetivos.
                </TextComponent>
              </Column>
            </Column>
          </Row>
        </Column>
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-6">
          {coachs[0]?.clients?.map((e,index) => (
            <BeforeAfterComponent
              key={index}
              beforeImage={e.beforeImage}
              afterImage={e.afterImage}
              description={e.description}
            />
          ))}
        </div>
      </div>
    </Column>
  );
};

export default CalculatorInfoScreen;
