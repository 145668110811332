import React from 'react';

interface GoalRadioTileProps<T> {
  title: string;
  subtitle?: string;
  value: T;
  groupValue: T;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const GoalRadioTile = <T,>({ title, subtitle, value, groupValue, onChange }: GoalRadioTileProps<T>) => {
  return (
    <label className="flex items-center p-4 border-b border-gray-200 cursor-pointer">
      <input
        type="radio"
        className="form-radio text-orange-500 mr-3 appearance-none h-2 w-2 border border-gray-300 rounded-full checked:bg-orange-500 checked:border-transparent focus:outline-double"
        value={String(value)}
        checked={groupValue === value}
        onChange={onChange}
      />
      <div>
        <div className="text-sm text-start font-medium">{title}</div>
        {subtitle && <div className="text-sm text-start text-gray-500">{subtitle}</div>}
      </div>
    </label>
  );
};

export default GoalRadioTile;
