import { useEffect, useState } from "react";
import { AppRouter } from "./router/AppRouter";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "./store/store";
import { renewToken, startGettingUserInfo, startLogout } from "./store/slices/auth/thunks";
import { setToken } from "./api/SquatFitAPI";
import { toast } from "react-toastify";
import FriendRequestNotify from "./squad-fit/components/FriendRequestNotify";
import useFriendRequest from "./squad-fit/hooks/useFriendRequest";
import { connectToServer } from "./socket-clients/social";
import { cleanFriendRequest } from "./store/slices/social/friendSlice";
import { useNavigate } from "react-router-dom";
import CircularProgress from "./squad-fit/components/CircularProgress";
import { startGettingDailyKcal, startGettingMeals, startGettingRecipes, startGettingWeeklyKcal } from "./store/slices/food/thunks";
import { getRecipesPartialSystem, getRecipesSystem } from "./store/slices/recipe/thunks";
import { getAllBooks, getBooksByUser } from "./store/slices/book/thunks";
import { getAllRedeems } from "./store/slices/redeem/thunks";
import { getAllCourses, getCourseByUser } from "./store/slices/course/thunks";
import { startGettingPosts } from "./store/slices/post/thunks";
import { connectToCoachServer } from "./socket-clients/server";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const dispatch: AppDispatch = useDispatch();
  const { onAccept, onReject } = useFriendRequest();
  const navigate = useNavigate();

  const { token } = useSelector((state: RootState) => state.auth);
  const { friendRequest } = useSelector((state: RootState) => state.friend);

  useEffect(() => {
    if (friendRequest !== null && friendRequest.status === "pending") {
      toast(
        <FriendRequestNotify
          sender={friendRequest.sender}
          onAccept={() => onAccept(friendRequest.sender.id)}
          onReject={() => onReject(friendRequest.sender.id)}
        />,
        {
          position: "top-right",
          autoClose: false,
          draggable: false,
          onClose: () => dispatch(cleanFriendRequest()),
          toastId: friendRequest.sender.id,
        }
      );
    }
  }, [friendRequest]);

  useEffect(() => {
    dispatch(getAllCourses());
    dispatch(getAllBooks());
    
    dispatch(getRecipesPartialSystem());
    let userToken = token ?? localStorage.getItem("userToken");
    if (userToken) {
      setToken(userToken);
      dispatch(renewToken()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          userToken = res.payload.token as string;
          connectToServer(userToken, dispatch);
          connectToCoachServer(userToken, dispatch);
          dispatch(startGettingRecipes());
          dispatch(getRecipesSystem());
          dispatch(startGettingWeeklyKcal(new Date()));
          dispatch(startGettingMeals({ date: new Date() }));
          dispatch(startGettingDailyKcal({ date: new Date() }));
          dispatch(getAllRedeems());
          dispatch(getBooksByUser());
          dispatch(startGettingPosts({ limit: "10", page: "1" }));
          dispatch(getCourseByUser());
          dispatch(startGettingUserInfo()).then(() => {
            setIsLoading(false);
          });
        } else if (res.meta.requestStatus === "rejected") {
          localStorage.clear();
          dispatch(startLogout());
          window.location.reload();
        }
      }).catch(() => {
        navigate("/auth/login");
      });
    } else {
      setIsLoading(false);
    }
  }, []);






  return !isLoading 
    ? <AppRouter /> 
    : <div className="flex items-center justify-center h-screen">
        <CircularProgress styleProps={{ width: 34, height: 34 }} />
      </div>;
}

export default App;
