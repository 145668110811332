import { DateTime } from "luxon";

interface OtherMessageProps {
  message: string;
  id: string;
  sent_at: string;
}

const OtherMessage = ({ message, id, sent_at }: OtherMessageProps) => {
  return (
    <div
      id={id}
      className="flex flex-row bg-[#7F7F7F] text-white gap-2 px-3 py-1 rounded-xl w-max mb-1 max-w-64"
    >
      <label className="w-full break-words max-w-48 text-sm text-left">
        {message}
      </label>
      <div className="flex justify-end items-end">
        <label className="text-right text-xs">
          {DateTime.fromISO(sent_at).toFormat("T")}
        </label>
      </div>
    </div>
  );
};

export default OtherMessage;
