import { useSelector } from 'react-redux';
import { Column } from '../../core/components/Column'
import { Row } from '../../core/components/Row'
import { TextComponent } from '../../core/components/TextComponent'
import { RootState } from '../../store/store';
import { useEffect, useState } from 'react';
import { Recipe } from '../../store/interfaces/food/recipeInterface';
import { useNavigate } from 'react-router-dom';

export const PreviewRecipe = () => {
    const { recipesPartial,
        recipesSystem } = useSelector(
        (state: RootState) => state.recipe
    );
    const [recipe, setRecipe] = useState<Recipe | null>(null);
    useEffect(() => {
        if(recipesPartial.length!==0){
            setRecipe(recipesPartial[0]);
        }
    }, [recipesSystem,recipesPartial]);
    const navigate = useNavigate();
    const toRecipe=(id:string)=>{
        navigate(`/recipe/${id}`)
    }
  return (
    <>
        <TextComponent variant="h2" className="font-bold pb-3 pt-5">Vista previa de una receta</TextComponent>
        <div className="py-4">
            <Row responsiveCol={true} className="items-stretch justify-between gap-4 rounded-xl bg-[#f8fbfb] p-4 shadow-md">
            <Column className="gap-4 w-full">
                <Column className="gap-1">
                <TextComponent variant='h3' className="text-orange font-bold">{recipe?.name}</TextComponent>
                <TextComponent variant="h4" className="text-fadedAsh  leading-tight">Preparación: {recipe?.timeToPrepare} | Cocción: {recipe?.timeToCook} | Kcal: {recipe?.kcal}</TextComponent>
                </Column>
                <button
                onClick={()=>toRecipe(recipe?.id??'')}
                className="flex cursor-pointer items-center justify-center overflow-hidden rounded-xl h-8 px-4 flex-row-reverse bg-[#e8f3ef] text-sm font-medium leading-normal w-fit"
                >
                <span className="truncate">Ver receta</span>
                </button>
            </Column>
            <div className='w-2/4'>

                <img className='w-full object-cover bg-center bg-no-repeat aspect-video bg-cover rounded-xl' src={recipe?.image}/>
            </div>
            {/* <div className="w-full bg-center bg-no-repeat aspect-video bg-cover rounded-xl flex-1" style={{ backgroundImage: `url(${recipe?.image})` }}></div> */}
            </Row>
        </div>
    </>
  )
}
