import { useEffect } from "react";
import useLastVideoSeen from "../../hooks/useLastVideoSeen";
import useScreen from "../../hooks/useScreen";
import ListVideos from "./components/WatchCourse/ListVideos";
import VideoContainer from "./components/WatchCourse/VideoContainer";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { useParams } from "react-router-dom";
import { getCourseByUser, getTests } from "../../../store/slices/course/thunks";
import { CourseByUser } from "../../../store/interfaces/course/interfaceCourse";
import { setCourseSelected, setLastVideoSeen } from "../../../store/slices/course/courseSilce";

const WatchCourseScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const { id } = useParams();
  const { handleLastVideoSeen } = useLastVideoSeen();
  const { isSmallScreen } = useScreen();
  const { lastVideoSeen, courseSelected, courseByUser } = useSelector((state: RootState) => state.course);

  useEffect(() => {
    dispatch(getTests({courseId:id??''}))
  }, []);
  useEffect(() => {
    if (lastVideoSeen.length > 0) return;
    handleLastVideoSeen();
  }, [lastVideoSeen]);

  useEffect(() => {
    if (!courseSelected && id || courseByUser.length === 0) {
      dispatch(getCourseByUser()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const course = (res.payload as CourseByUser[]).find((c) => c.id === id);
          if (course) {
            dispatch(setCourseSelected(course));
            dispatch(setLastVideoSeen(course.videos[0].video_id));
          }
        }
      });
    }
  }, [courseSelected]);

  return courseSelected && (
    <div
      className={`flex ${
        isSmallScreen ? "flex-col" : "flex-row"
      }`}
    >
      <VideoContainer />
      <ListVideos />
    </div>
  );
};

export default WatchCourseScreen;
