
export const AdviceCoachSkeleton = () => {
  return (
    <div className="flex flex-col w-[410px] items-center p-5">

      <div className="bg-gray-300 animate-pulse h-60 w-full rounded-xl"/>``

      <div className="pt-6">
        <h3 className="bg-gray-300 mb-2 animate-pulse w-32 h-9 rounded-xl"></h3>
        <p className="bg-gray-300 mb-2 animate-pulse w-72 h-6 rounded-xl"></p>
      </div>
      <div className="bg-gray-300 animate-pulse w-72 h-10 rounded-lg mt-10"></div>
    </div>
  )
}
