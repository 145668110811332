import { Navigate, Route, Routes } from "react-router-dom"
import { LoginScreen } from "../pages/LoginScreen"
import { RegisterScreen } from "../pages/RegisterScreen"
import { RecoverPassScreen } from "../pages/RecoverPassScreen"
import { EmailCodeScreen } from "../pages/EmailCodeScreen"
import { TemporalPassScreen } from "../pages/TemporalPassScreen"
import { EmailMessageScreen } from "../pages/EmailMessageScreen"


export const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="login" element={ <LoginScreen /> }/>
      <Route path="register" element={ <RegisterScreen /> }/>
      <Route path="recovery" element={ <RecoverPassScreen /> }/>
      <Route path="code" element={ <EmailCodeScreen /> }/>
      <Route path="message" element={ <EmailMessageScreen /> }/>
      <Route path="temp-pass" element={ <TemporalPassScreen /> }/>

      <Route path="/*" element={ <Navigate to="/" /> }/>
    </Routes>
  )
}
