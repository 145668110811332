import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authSlice from './slices/auth/authSlice'
import foodSlice from './slices/food/foodSlice'
import socialSlice from './slices/social/socialSlice'
import friendSlice from './slices/social/friendSlice'
import postSlice from './slices/post/PostSlice'
import courseSlice from './slices/course/courseSilce';
import bookSlice from './slices/book/bookSlice'
import coachSlice  from './slices/coach/CoachSlice'
import shoppingSlice from './slices/shopping/shoppingSlice'
import redeemSlice from './slices/redeem/redeemSlice'
import recipeSlice from './slices/recipe/recipeSlice'
import chatSlice from './slices/floating-chat/chatSlice'
import docsAdviceSlice from './slices/coach/docsAdviceSlice'

const combinedReducer = combineReducers({
  auth: authSlice,
  food: foodSlice,
  social: socialSlice,
  post: postSlice,
  friend: friendSlice,
  course: courseSlice,
  coach: coachSlice,
  floatingchat: chatSlice,
  book: bookSlice,
  shopp: shoppingSlice,
  redeem: redeemSlice,
  recipe: recipeSlice,
  docsAdvice: docsAdviceSlice,
})

const rootReducer = (state: any, action: any) => {
  if (action.type === 'DESTROY_SESSION') {
    state = undefined
  }
  return combinedReducer(state, action)
}

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.REACT_APP_CURRENT_ENV !== undefined && process.env.REACT_APP_CURRENT_ENV !== 'production',
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch