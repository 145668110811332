import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import CardCourse from "./CardCourse";
import { Course } from "../../../../../store/interfaces/course/interfaceCourse";
import { TextComponent } from "../../../../../core/components/TextComponent";
import { Column } from "../../../../../core/components/Column";

const RecommendedCourses = () => {
  const { allCourses, courseByUser, is_loading_all_courses } = useSelector(
    (state: RootState) => state.course
  );

  const handleFilter = (course: Course) => {
    return (
      course.id !==
      courseByUser.find((courseUser) => courseUser.id === course.id)?.id
    );
  };

  return (
    <Column className="gap-2 w-full">
      <TextComponent variant="h3" className="font-semibold">Cursos recomendados</TextComponent>
      <div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        {is_loading_all_courses ? (
          <div className="flex animate-pulse mt-10 w-80 h-48 bg-slate-200 rounded-lg"></div>
        ) : (
          allCourses
            .filter(handleFilter)
            .map((course) => <CardCourse key={course.id} course={course} />)
        )}
      </div>
    </Column>
  );
};

export default RecommendedCourses;
