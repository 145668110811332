import { EditImageIcon } from "../../../../assets/icons/EditImageIcon"

interface RecipeImageProps {
  recipePicture: File | null | string | undefined,
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const RecipeImage = ({ recipePicture, handleFileChange }:RecipeImageProps) => {

  return (
    <div className="flex flex-col items-center mt-16 min-[870px]:ml-40">
      <div className="relative">
        {
          recipePicture === null || recipePicture === undefined || recipePicture === ""
          ? <div className="h-44 w-44 bg-gray-200 rounded-full" />
          : (recipePicture instanceof File) 
            ? <img src={URL.createObjectURL(recipePicture)} className="h-44 w-44 rounded-full object-cover"/>
            : <img src={recipePicture} className="h-44 w-44 rounded-full object-cover"/>
        }
        <label className="absolute bottom-0 right-0 cursor-pointer">
          <input type="file" accept="image/*" className="hidden" onChange={handleFileChange}/>
          <EditImageIcon />
        </label>
      </div>
    </div>
  )
}
