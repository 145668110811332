import { createSlice } from '@reduxjs/toolkit'
import { createOrderWithPaypal, createOrderWithStripe, startCalculatingIMC, startGettingUserCalculator } from './thunks'
import { CalculatorResponse } from '../../interfaces/shopping/interfaceShopping';

export interface templateState {
  calculator: CalculatorResponse;
  userCalculator: any;
  is_loading_order_with_paypal: boolean;
  is_loading_order_with_stripe: boolean;
}

const initStateCal: CalculatorResponse = {
  data: [],
  tips: [],
  calories: {
    keepWeight: 0,
    loseWeight: 0,
    gainWeight: 0,
  }
}

const initialState: templateState = {
  calculator: initStateCal,
  userCalculator: null,
  is_loading_order_with_paypal: false,
  is_loading_order_with_stripe: false,
}

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    increment: (state) => {
      
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(startCalculatingIMC.fulfilled, (state, action) => {
      const payload = action.payload;
      state.calculator = payload;
    })
    .addCase(startGettingUserCalculator.fulfilled, (state, action) => {
      const payload = action.payload;
      state.userCalculator = payload;
    })
    .addCase(createOrderWithPaypal.pending, (state) => {
      state.is_loading_order_with_paypal = true;
    })
    .addCase(createOrderWithPaypal.fulfilled, (state) => {
      state.is_loading_order_with_paypal = false;
    })
    .addCase(createOrderWithPaypal.rejected, (state) => {
      state.is_loading_order_with_paypal = false;
    })
    .addCase(createOrderWithStripe.pending, (state) => {
      state.is_loading_order_with_stripe = true;
    })
    .addCase(createOrderWithStripe.fulfilled, (state) => {
      state.is_loading_order_with_stripe = false;
    })
    .addCase(createOrderWithStripe.rejected, (state) => {
      state.is_loading_order_with_stripe = false;
    })
  }
})

export const { increment } = templateSlice.actions
export default templateSlice.reducer