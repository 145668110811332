import { ButtonSquad } from "../../../core/components";
import { Column } from "../../../core/components/Column";
import { Row } from "../../../core/components/Row";
import { TextComponent } from "../../../core/components/TextComponent";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { ComprasScreen } from "./ComprasScreen";
import Calculator from "../../../assets/images/calculator.png";
import { Recomendation } from "../home/components/Recomendation";
import { useNavigate } from "react-router-dom";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { startGettingUserCalculator } from "../../../store/slices/shopping/thunks";

export const ToolsScreen = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userCalculator } = useSelector((state: RootState) => state.shopp);

  useEffect(() => {
    if (!userCalculator) dispatch(startGettingUserCalculator());
  }, []);

  const navigateCalculator = () => {
    if (userCalculator !== null) {
      navigate("/calculator");
    } else {
      navigate("/calculator-info");
    }
  };
  return (
    <Column>
      <TextComponent variant="h3" className="font-bold">
        Calculadora de calorías
      </TextComponent>
      <div className="h-2" />
      <img
        className="object-cover w-80 rounded-lg bg-orange-300 cursor-pointer"
        src={Calculator}
        onClick={navigateCalculator}
        alt="book"
      />
      <TextComponent variant="h3" className="font-bold">
        Calculadora
      </TextComponent>
      <TextComponent variant="h5">
        Estimar las necesidades calóricas diarias
      </TextComponent>
    </Column>
  );
};
