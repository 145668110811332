import axios from 'axios';

const API_URL = process.env.REACT_APP_SQUAT_FIT_API;

export const squatFitApi = axios.create({
	baseURL: API_URL,
	headers: {
		'Access-Control-Allow-Origin' : '*',
  	'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}
})

export const setToken = (token: string) => {
	squatFitApi.defaults.headers.common['Authorization'] = `Bearer ${token}`
}