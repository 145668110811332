import { Column } from './Column';
import { Row } from './Row';
import { TextComponent } from './TextComponent';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <Column>
        <Row responsiveCol={true} mainAxisAlignment='between' className="mb-8 gap6 px-12">
          <Column className="space-y-4 w-full">
            <TextComponent variant="h2" className="font-bold">Links</TextComponent>
            <a href="/faq" className="text-sm text-gray-300 hover:text-white">Preguntas Frecuentes</a>
            <a href="/terms" className="text-sm text-gray-300 hover:text-white">Términos y Condiciones</a>
            <a href="/privacy" className="text-sm text-gray-300 hover:text-white">Política de Privacidad</a>
          </Column>
          <Column className="space-y-4 w-full">
            <TextComponent variant="h2" className="font-bold md:text-end">Redes Sociales</TextComponent>
            <Row responsiveCol={true} mainAxisAlignment='end' className='gap-6'>
              <Column>
                <TextComponent variant="h3" className="font-semibold">María</TextComponent>
                <a href="https://www.instagram.com/mariasquatfit" className="text-sm text-gray-300 hover:text-white">Instagram</a>
                <a href="https://www.youtube.com/channel/UCt23LHkdJX3TJocVvxmCgOg" className="text-sm text-gray-300 hover:text-white">Youtube</a>
              </Column>
              <Column>
                <TextComponent variant="h3" className="font-semibold">Hamlet</TextComponent>
                <a href="https://www.instagram.com/hamletsquatfit" className="text-sm text-gray-300 hover:text-white">Instagram</a>
                <a href="https://www.youtube.com/c/HamletSquatFit" className="text-sm text-gray-300 hover:text-white">Youtube</a>
              </Column>
            </Row>
          </Column>
        </Row>
        <Row className="justify-between items-center border-t border-gray-700 pt-4 px-12">
          <TextComponent className="text-sm text-gray-400">&copy; 2024 Squat Fit. Todos los derechos reservados.</TextComponent>
        </Row>
      </Column>
    </footer>
  );
};

export default Footer;
