import { useNavigate } from "react-router-dom";
import { ButtonSquad } from "../../../../../core/components";
import { Column } from "../../../../../core/components/Column";
import { TextComponent } from "../../../../../core/components/TextComponent";
import { ButtonType } from "../../../../../core/interfaces/ButtonSquadInterface";

export const CallToAction = ({ handleOpen}:
  {
      handleOpen: () => void,
  }) => {
  const navigate = useNavigate();
  return (
    <section className="bg-white text-center">
      <div className="container mx-auto">
      <div className="relative h-96">
        <div className="h-40 object-cover">

        <img src="/landing/Imagen fondo2.png" className="hidden md:block object-cover h-96 w-full"/>
        </div>
        <div className="absolute inset-0 flex items-center justify-center">
          <Column crossAxisAlignment="center" className="mx-20 gap-3">
            <TextComponent variant="h2" className="text-2xl font-bold mb-4">Te ayudamos a lograr tu físico deseado</TextComponent>
            <ButtonSquad buttonType={ButtonType.BlueFull} label="Registrarme" onClick={()=>navigate('auth/register')} />
            <ButtonSquad buttonType={ButtonType.OrangeFull} label="Saber más" onClick={()=>handleOpen()} />
            {/* <button className="bg-gradient-to-r from-orange-500 to-blueSquat1 text-white px-4 py-2 rounded-lg hover:bg-orange-600 mb-4">Registrarme</button>
            <button onClick={handleOpen} className="border border-orange-500 text-orange-500 py-2 px-4 rounded-md hover:bg-orange-100">
              Saber más
            </button> */}
          </Column>
        </div>
    </div>
        
        
      </div>
    </section>
  );
}