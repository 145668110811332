import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ButtonSquad } from "../../../../core/components"
import { AppDispatch, RootState } from "../../../../store/store";
import { startCreatingMeal } from "../../../../store/slices/food/thunks";
import { sumDailyKcal } from "../../../../store/slices/food/foodSlice";
import { useAlert } from "../../../hooks/useAlert";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";
import CircularProgress from "../../../components/CircularProgress";

export const RecipeList = () => {

  const { recipe, is_loading_meals } = useSelector((state: RootState) => state.food);

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const { showAlert } = useAlert();

  const addMeal = (id: string, kcal: string) => {
    const today = new Date();
    dispatch(startCreatingMeal({recipe_id: id, date: today,type:1})).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        showAlert({
          message: 'Has añadido una de tus comidas diarias!',
          type: "info"
        });
        dispatch(sumDailyKcal(parseInt(kcal)))
      } else {
        showAlert({
          message: 'No se ha podido añadir tu comida a la lista.',
          type: "error"
        });
      }
      navigate("/today");
    });
  }

  return (
    <div className="flex flex-col mt-8">
      <div className="flex items-start ml-6">
        <label className="text-lg font-normal text-gray-500">
          Buscar receta
        </label>
      </div>
      <div className="flex flex-col">
         { recipe.map((recipe) => (
          <div className="flex flex-row justify-between ml-6 mt-6">
            <div className="flex flex-row">
              <img src={recipe.image} className="h-20 w-20 rounded-lg"/> 
              <div className="flex flex-col ml-4 justify-center">
                <label className="text-md font-normal">
                  {recipe.name}
                </label>
                <label className="text-xs font-light text-gray-500">
                  {recipe.name }
                </label>
              </div>
            </div>
            <div>
              {
                is_loading_meals === true
                ? <div className={"mr-24 mt-8"}>
                    <CircularProgress />
                  </div>
                : <div className={"mr-10 mt-8"}>
                    <ButtonSquad 
                      buttonType={ButtonType.Black} 
                      label="Agregar registro" 
                      onClick={() => {
                        addMeal(recipe.id??'', (recipe.kcal??0).toString())
                      }}
                    />
                  </div> 
              }
            </div>
          </div>
        ))}
      </div>  
    </div>
  )
}
