import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import { CalorieBar } from "../../../components/CalorieBar";
import { useEffect, useState } from "react";
import { Column } from "../../../../core/components/Column";
import { SizedBox } from "../../../../core/components/SizedBox";
import { Row } from "../../../../core/components/Row";
import { TextComponent } from "../../../../core/components/TextComponent";
import { ButtonSquad } from "../../../../core/components";
import { useNavigate } from "react-router-dom";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";

export const CalorieChart = () => {
  const [totalKcal, setTotalKcal] = useState<number>(0);
  const { achieved_weekly_kcal, is_loading_achieved_weekly_kcal } = useSelector(
    (state: RootState) => state.food
  );
  const { dailyActivity } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (achieved_weekly_kcal.length > 0) {
      achieved_weekly_kcal.forEach((kcal) => {
        setTotalKcal(totalKcal + kcal);
      });
    }
  }, [achieved_weekly_kcal]);
  const daysOfWeek = ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];
  const total=achieved_weekly_kcal.reduce((acc, curr) => acc + curr, 0)
  const navigateOnboarding = () => {
      navigate('/step1');
  };
  return (
    <Column className="gap-4" crossAxisAlignment="center">
      {
        dailyActivity==''&&<Row mainAxisAlignment="center">
          <ButtonSquad buttonType={ButtonType.OrangeFull} className="" label="Completar perfil" onClick={navigateOnboarding} />
        </Row>
      }
      
      <TextComponent variant="h1" className="font-bold text-center">
        Progreso semanal
      </TextComponent>
      <SizedBox className="h-2"/>
      <Row mainAxisAlignment="center" className="gap-4">
        {is_loading_achieved_weekly_kcal ? (
          Array.from({ length: 7 }).map((_, index) => (
            <CalorieBar index={index} key={index} height="h-0/6" />
          ))
        ) : (
          achieved_weekly_kcal.map((kcal, index) => (
            <Column key={index} crossAxisAlignment="center">
              <CalorieBar index={index}  tooltip={`Kcal: ${kcal}`} key={index} height={`${(kcal*100)/Math.max(...achieved_weekly_kcal)}%`} />
              <TextComponent variant="h4">
                {daysOfWeek[index]}
              </TextComponent>
            </Column>
          ))
        )}
      </Row>
      <TextComponent variant="h1" className="font-bold text-center">
        {`${total} Kcal`}
      </TextComponent>
      <TextComponent variant="h4" className="text-fadedAsh">
        Total calorías
      </TextComponent>
    </Column>
  );
};
