import { Column } from '../../../core/components/Column'
import { SizedBox } from '../../../core/components/SizedBox'
import { ButtonSquad, InputSquad } from '../../../core/components'
import { InputType } from '../../../core/interfaces/InputSquadInterface'
import { SelectOption } from '../../../core/interfaces/SelectSquadInterface'
import { Row } from '../../../core/components/Row'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '../../../store/store'
import { startCalculatingIMC } from '../../../store/slices/shopping/thunks'
import { useNavigate } from 'react-router-dom'
import { TextComponent } from '../../../core/components/TextComponent'
import { ButtonType } from '../../../core/interfaces/ButtonSquadInterface'

const calculateAge = (birthDate:Date) => {
  const now = new Date();
  const birth = new Date(birthDate);
  let age = now.getFullYear() - birth.getFullYear();
  const monthDifference = now.getMonth() - birth.getMonth();

  // Ajustar la edad si aún no ha llegado su cumpleaños este año
  if (monthDifference < 0 || (monthDifference === 0 && now.getDate() < birth.getDate())) {
    age--;
  }

  return age;
};
export const CalculatorScreen = () => {

  const { calculator } = useSelector((state: RootState) => state.shopp);
  const { weight, height, birth, gender } = useSelector((state: RootState) => state.auth);
  const age=calculateAge(new Date(birth??'')??(new Date()));
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const { dailyActivity } = useSelector(
    (state: RootState) => state.auth
  );
  const toEdit=()=>{
    navigate(`/edit-metrics`)
  }
  const navigateOnboarding = () => {
    navigate('/step1');
  };
  const result=parseFloat(calculator.data.length>0? calculator.data[0].imc:'0')??0;


  const handleCalculate = () => {
    dispatch(startCalculatingIMC());
  }
console.log(dailyActivity)
  return (
    <Column crossAxisAlignment="center" className="relative w-full  min-h-screen overflow-x-hidden">
      <div className="w-full md:w-3/4 px-12 md:p-0 flex h-full grow flex-col">
    <Row className='gap-6' responsiveCol={true}>
      <Column className='w-full md:w-2/4'>
        <div className='h1 font-bold'>
          Calculadora
        </div>
        <SizedBox className='h-3'/>
        <TextComponent variant='h7' className='text-[#61618A]'>
          La cantidad de calorías que debes consumir diariamente para mantenerte en forma
        </TextComponent>
        <SizedBox className='h-8'/>
        <div>
          Edad
        </div>
        {
          age
        }

        <SizedBox className='h-6'/>
        <div>
          Sexo
        </div>
        {
          gender=='male'?'Masculino':'Femenino'
        }
        <SizedBox className='h-6'/>
        <div>
          Altura
        </div>
        {
          `${height} cm`
        }
        <SizedBox className='h-6'/>
        <div>
          Peso
        </div>
        {
          `${weight} kg`
        }
        <SizedBox className='h-24'/>
        {
          dailyActivity==''||dailyActivity==null?<>
          
        <TextComponent variant='h7' className='text-[#61618A]'>
          Para poder usar la calculadora necesitamos que completes tu perfil.
        </TextComponent>
        <SizedBox className='h-3'/>
        <ButtonSquad full={true} buttonType={ButtonType.OrangeFull} className="" label="Completar perfil" onClick={navigateOnboarding} />
          </>
          :<>
          <ButtonSquad
          buttonType={ButtonType.PurpleFull} 
          full={true}
          label="Calcular" 
          onClick={() => {handleCalculate()}} 
        />
        <SizedBox className='h-6'/>
        <ButtonSquad
          buttonType={ButtonType.PurpleFull} 
          full={true} label="Editar" onClick={toEdit}
        /></>
        }
        
      </Column>
      <Column className='w-full'>
        {
          calculator.data.length > 0 &&
          <div>
            <Row>
              <div className='h2 font-bold'>
                Categoria de IMC 
              </div>
              <div className='h2 font-bold text-purpleSquat1'>
                {`: ${calculator.data[0].imc}`}
              </div>
            </Row>
            <SizedBox className='h-6'/>
              <TextComponent variant='h5' className={`${result<=18.5?'text-purpleSquat1 font-bold':''}`}>
                  Bajo peso
              </TextComponent>
              <div className='h6 text-[#455CA1]'>
                18.5 o menos
              </div>
              <SizedBox className='h-7'/>
              <TextComponent variant='h5' className={`${result>18.5&&result<=24.9?'text-purpleSquat1 font-bold':''}`}>
                Normal
              </TextComponent>
                
                <div className='h6 text-[#455CA1]'>
                  18.5 - 24.9
                </div>
              <SizedBox className='h-7'/>
              <TextComponent variant='h5' className={`${result>25&&result<=29.9?'text-purpleSquat1 font-bold':''}`}>
                Sobrepeso
              </TextComponent>
                <div className='h6 text-[#455CA1]'>
                  25 - 29.9
                </div>
              <SizedBox className='h-7'/>
              <TextComponent variant='h5' className={`${result>=30?'text-purpleSquat1 font-bold':''}`}>
                Obesidad
              </TextComponent>
                
                <div className='h6 text-[#455CA1]'>
                  30 o más
                </div>
          </div>
        }
        {
          calculator.calories.gainWeight !== 0 &&
          <div className='pt-3'>
            <div className='h2 font-bold'>
              ¿Qué puedo hacer ahora?
            </div>
            <SizedBox className='h-6'/>
            <div>
              Si quieres ganar peso debes consumir <label className='text-purpleSquat1 font-bold'>{calculator.calories.gainWeight} kcal</label> por día. 
            </div>
            <SizedBox className='h-6'/>
            <div>
              Si quieres mantener tu peso debes consumir <label className='text-purpleSquat1 font-bold'>{calculator.calories.keepWeight} kcal</label> por día. 
            </div>
            <SizedBox className='h-6'/>
            <div>
              Si quieres perder peso debes consumir <label className='text-purpleSquat1 font-bold'>{calculator.calories.loseWeight} kcal</label> por día. 
            </div>
          </div>
        }
        <SizedBox className='h-7'/>
        {
          calculator.tips.length > 0 &&
          <div>
            <div className='h2 font-bold'>
              Consejos para ti
            </div>
            <SizedBox className='h-5'/>
            {
              calculator.tips.map((tip,index) => (
                <div key={index}>
                  <div className='h5'>
                    {tip}
                  </div>
                  <SizedBox className='h-5'/>
                </div>
              ))
            }
          </div>
        }
      </Column>
    </Row>
    </div>
    </Column>
  )
}
