import { Book } from "../../store/interfaces/book/interfaceBook";
import { Coach } from "../../store/interfaces/coach/interfaceCoach"
import { Course } from "../../store/interfaces/course/interfaceCourse";
import { Faqs } from "../../store/interfaces/social/faqsInterface";

export const coachs:Coach[]=[
  {
    id: "4add70df-e9ec-4429-8711-0055f9b9e5cb",
    firstName: "María",
    lastName: "Casas",
    profile_picture: "https://storage.googleapis.com/squat-fit-storage/static/20200913191931__MG_0130-1.jpg",
    description: "Mi nombre es María Casas, soy farmacéutica graduada en la Universidad Complutense de Madrid, dietista y coach nutricional desde hace más de 5 años.",
    homeButton:"Elijo a María",
    homeDescription:"Mi nombre es María Casas, soy farmacéutica graduada en la Universidad Complutense de Madrid, dietista y coach nutricional desde hace más de 5 años. Soy formadora en nutrición clínica y deportiva, entrenamiento y suplementación en distintas plataformas de divulgación científica cómo ICNS y FitGeneration. Conmigo se aprende información de calidad, basada en evidencia científica actual siempre buscando la practicidad y sin complicaciones.",
    mision:"Quiero ayudarte a alcanzar tus objetivos de salud y estado físico, dándote las herramientas necesarias para mejorar tu alimentación y entrenamiento. También enseñandote estrategias para afrontar la dieta, tener mayor motivación así como el uso de suplementación para situaciones especiales.",
    objectives:[
        {
            title:"En la dieta",
            subTitle:"Menú flexible según tus necesidades, con pautas de macros, kcal, actividad física y suplementación",
            image:"En la dieta",
        },
        {
            title:"El entreno",
            subTitle:"Tabla de ejercicios (ya sea para gym o casa)según tu nivel, con revisión semanal según feedback y sensaciones",
            image:"En la dieta",
        },
        {
            title:"La revisión",
            subTitle:"Con tus medidas, peso y fotos iniciales hacemos cambios según tus gustos personales y objetivos.",
            image:"En la dieta",
        },
        {
            title:"El contacto",
            subTitle:"Lo valioso de mi trabajo es la parte humana. Por eso, te dejo mi email y WhatsApp para darte la mejor atención y lograr cercanía",
            image:"En la dieta",
        },
        {
            title:"Resultados",
            subTitle:"A mis +350 clientes les motiva estar en manos de una profesional en la que confían",
            image:"En la dieta",
        },
        {
            title:"Descuentos",
            subTitle:"Si eres cliente de mis asesorías disfrutas de 20% de descuento en todos los Cursos de mi",
            image:"En la dieta",
        },
    ],
    clients:[
        {
            beforeImage:"https://squatfit.es/wp-content/uploads/Ana-Bejar-antes.jpg",
            afterImage:"https://squatfit.es/wp-content/uploads/Ana-Bejar-despues.jpg",
            description:"Ana Béjar, después de 6 meses"
        },
        {
            beforeImage:"https://squatfit.es/wp-content/uploads/Diego-Villarroel-antes.jpg",
            afterImage:"https://squatfit.es/wp-content/uploads/Diego-Villarroel-despues.jpg",
            description:"Diego Villarroel, después de 5 meses"
        },
        {
            beforeImage:"https://squatfit.es/wp-content/uploads/Jesica-Soto-antes.jpg",
            afterImage:"https://squatfit.es/wp-content/uploads/Jesica-Soto-despues.jpg",
            description:"Jesica Soto, después de 7 meses"
        },
        {
            beforeImage:"https://squatfit.es/wp-content/uploads/Manuel-Lopez-antes.jpg",
            afterImage:"https://squatfit.es/wp-content/uploads/Manuel-Lopez-despues.jpg",
            description:"Manuel López, después de 7 meses"
        },
    ],
    achievements:[
        {
            title:"Licenciatura en Farmacia",
            subTitle:"Universidad Complutense de Madrid (UCM)"
        },
        {
            title:"Grado Superior en Dietética",
            subTitle:"Instituto Técnico de Estudios Profesionales (ITEP)"
        },
        {
            title:"Asesora Nutricional",
            subTitle:"+350 clientes satisfechos desde 2019"
        },
        {
            title:"+10 Seminarios de Fitness",
            subTitle:"Seminario de Fuerza e Hipertrofia 2018 (NSCA), entre otros"
        },
    ],
    testimonials: [
        {
            image: 'https://squatfit.es/wp-content/uploads/img-Elena-Fernandez.jpg',
            name: 'Elena Fernández',
            title: '"Un plan 100% personalizado"',
            testimonial: 'Primera vez en mi vida que pierdo peso comiendo lo que me gusta, sin pasar hambre. La dieta y entreno se adaptan a mis gustos y rutina diaria. Además María es simpática y exigente.'
        },
        {
            image: 'https://squatfit.es/wp-content/uploads/img-Sofia-Notare.png',
            name: 'Sofia Notare',
            title: '"He aprendido y mejorado mucho"',
            testimonial: 'La dieta que me ha puesto ha sido una que puedo seguir por el resto de mi vida. Los entrenos han sido bastante desafiantes y la mejoría ya la noto en la ropa y al mirarme al espejo.'
        },
        {
            image: 'https://squatfit.es/wp-content/uploads/img-Jesica-Soto.jpg',
            name: 'Jesica Soto',
            title: '"No pude elegir mejor"',
            testimonial: 'Sin duda volvería a elegirla mil veces más, me ha ayudado a perder 20 kg, engancharme a entrenar fuerza y a disfrutar otra vez de la vida ¡Muchísimas gracias María!'
        },
        {
            image: 'https://squatfit.es/wp-content/uploads/img-Silvia-Jimenez.jpg',
            name: 'Silvia Jiménez',
            title: '"Su calidad humana"',
            testimonial: 'María se adapta, me comprende y me motiva a continuar cuando estoy frustrada y me enseña a que no pasa nada si un día fallo. Ya me había resignado y por fin con ella veo resultados.'
        },
    ]
  },
  {
    id: "fb4cd61c-06ed-403d-a7f8-3fa9a5b5b68d",
    firstName: "Hamlet",
    lastName: "Sosa",
    profile_picture: "https://storage.googleapis.com/squat-fit-storage/static/DSC_0165.jpg",
    description: "Me llamo Hamlet Sosa, soy médico graduado en la Universidad Autónoma Dominicana con un amplio conocimiento en nutrición deportiva y entrenamiento.",
    homeButton:"Elijo a Hamlet",
    homeDescription:"Me llamo Hamlet Sosa, soy médico graduado en la Universidad Autónoma Dominicana con un amplio conocimiento en nutrición deportiva y entrenamiento. Llevo +10 años en el mundo del fitness al igual que +3 años como coach nutricional dónde he podido ayudar a cientos de personas a lograr cambiar su estilo de vida a uno más sano y sostenible. Soy chef en casa hace +10 años, así he consigo recetas ricas y también fit, las que comparto en redes a una comunidad cuyo objetivo es lograr su mejor versión.",
    mision:"Quiero ayudarte a que disfrutes de ser fit y a comer de una manera saludable y sostenible. También acompañarte en la parte más dificil de un físico ideal: mantenerlo ya que esto no es cosa de “1 mes a dieta” sino de un estilo de vida",
    achievements:[
        {
            title:"Doctor en Medicina",
            subTitle:"Universidad Autónoma Dominicana (UASD)"
        },
        {
            title:"+10 cursos de Nutrición",
            subTitle:"Nutrición y Dietética (UASD), Nutrición Clínica (UCM), entre otros"
        },
        {
            title:"Asesor nutricional",
            subTitle:"+150 clientes satisfechos desde 2021"
        },
        {
            title:"+10 Cursos de Powerlifting",
            subTitle:"En busca de la Fuerza (Powerlifting Technique), entre otros"
        },
    ],
    objectives:[
        {
            title:"En la dieta",
            subTitle:"Menú flexible según tus necesidades, con pautas de macros, kcal, actividad física y suplementación",
            image:"En la dieta",
        },
        {
            title:"El entreno",
            subTitle:"Tabla de ejercicios (ya sea para gym o casa)según tu nivel, con revisión semanal según feedback y sensaciones",
            image:"En la dieta",
        },
        {
            title:"La revisión",
            subTitle:"Con tus medidas, peso y fotos iniciales hacemos cambios según tus gustos personales y objetivos.",
            image:"En la dieta",
        },
        {
            title:"El contacto",
            subTitle:"Lo valioso de mi trabajo es la parte humana. Por eso, te dejo mi email y WhatsApp para darte la mejor atención y lograr cercanía",
            image:"En la dieta",
        },
        {
            title:"Resultados",
            subTitle:"Mis +150 clientes dicen que mi asesoría les hace todo más fácil por la motivación de estar en manos de un profesional",
            image:"En la dieta",
        },
        {
            title:"Descuentos",
            subTitle:"Si eres cliente de mis asesorías disfrutas de 20% de descuento en todos los Cursos de mi web",
            image:"En la dieta",
        },
    ],
  }
];

export const coursesLocal:Course[]=[
    {
        id: "a3d6b445-f584-448b-8f5e-2940ca7448f2",
        title: "Ganar músculo",
        contentImage:"https://squatfit.es/wp-content/uploads/img-3-GANAR-MUSCULO.png",
        subtitle: "Es difícil dar con métodos efectivos para ganar masa muscular sin caer en dinámicas que entorpezcan o incluso compliquen más el proceso. Por esto te traigo este curso para que logres ganar músculo sin el exceso de grasa corporal fruto de protocolos mal planteados.",
        price: "89.99",
        image: "https://storage.googleapis.com/squat-fit/static/Curso-ganar-musculo.jpg",
        video_presentation: "https://storage.googleapis.com/squat-fit/static/img-Ganar-masa-muscular.mp4",
        students: 1,
        tutor: {
          "id": "5644c590-4bd1-47c9-af95-2c57343cd354",
          "firstName": "María",
          "lastName": "",
          "profile_picture": ""
        },
        tutor_id:"5644c590-4bd1-47c9-af95-2c57343cd354",
        content:[
            {
                title:"1.Conceptos básicos",
                content:["Definiciones básicas y Balance energético.","Métodos para medir el gasto calórico."]
            },
            {
                title:"2.Composición corporal:",
                content:["¿Qué es? Diferencias entre sujetos.","Métodos para medirla.","Conociendo el tejido muscular."]
            },
            {
                title:"3.La proteína",
                content:["bases nutricionales y fisiología."]
            },
            {
                title:"4.Síntesis proteica",
                content:["Su fisiología.","Estrategias para aumentaria."]
            },
            {
                title:"5.Diferencias entre sujetos",
                content:["Biotipos.","Novatos y avanzados.","Mujeres y hombres.","Powerlifters y culturismo."]
            },
            {
                title:"6.Preguntas frecuentes",
                content:["Desmontando mitos."]
            },
            {
                title:"7.Protocolos nutricionales",
                content:["Volumen. Estrategias e impacto.","Necesidades en carbos, protes y grasas.","Dietas altas en grasas vs altas en carbos.","Dieta flexible y volumen","Ayuno intermitente."]
            },
            {
                title:"8.Prueba",
                content:["Elabora tu propio plan nutricional."]
            },
            {
                title:"9. Suplementación para ganar masa muscular",
                content:["Proteinas, caseinas y HMB.","Rendimiento: creatina y cafeína.","Subidores y carbohidratos."]
            },
            {
                title:"10.Prueba",
                content:["Crea tu plan de ganar músculo."]
            },
        ],
        tools:[
            {
                title:"Clases en vídeo",
                subtitle:"12+ horas de clases prácticas y 1.5 horas de clases teóricas."
            },
            {
                title:"Apuntes",
                subtitle:"Material de apoyo con fines de repasar el contenido"
            },
            {
                title:"Servicio de atención",
                subtitle:"Atención 24/7 para las preguntas que tengas acerca del curso."
            },
            {
                title:"Ponte a prueba",
                subtitle:"Al final harás un examen para obtener tu diploma"
            },
        ],
        benefits:[
            {
                title:"Objetivos que Lograrás",
                content:[
                    "Si quieres mejorar tu físico, no importa si eres novato o más avanzadoEntender los mecanismos implicados en el crecimiento muscular.",
                    "Identificar tu biotipo y establecer estrategias para ganar masa muscular de la forma más eficiente.",
                    "Conocer los distintos protocolos nutricionales de ganar peso."
                ]
            },
            {
                title:"Beneficios del Curso",
                content:[
                    "Lograr ganar peso de manera limpia según dice la evidencia científica.",
                    "Descubrir la suplementación realmente efectiva para ganar masa muscular.",
                    "Crear tu propio plan nutricional para ganar masa muscular.",
                ]
            },
        ]
      },
      {
        id: "81fbdf0f-3acf-4269-9b69-a48514df9c93",
        title: "Entrena en casa",
        contentImage:"https://squatfit.es/wp-content/uploads/img-ENTRENA-EN-CASA.png",
        subtitle: "La falta de tiempo y/o de ganas para ir al gym son un problema, y este programa es la solución. Cualquier sitio se puede convertir en tu gimnasio personal y no necesitas material ya que los ejercicios se harán con tu peso corporal.",
        price: "29.99",
        image: "https://storage.googleapis.com/squat-fit/static/Programa-entrena-en-casa.jpg",
        video_presentation: "https://storage.googleapis.com/squat-fit/static/img-Entrena-en-casa.mp4",
        students: 1,
        tutor: {
          "id": "5644c590-4bd1-47c9-af95-2c57343cd354",
          "firstName": "María",
          "lastName": "",
          "profile_picture": ""
        },
        tutor_id:"5644c590-4bd1-47c9-af95-2c57343cd354",
        tools:[
            {
                title:"Clases en vídeo",
                subtitle:"12+ horas de clases prácticas y 1.5 horas de clases teóricas."
            },
            {
                title:"Apuntes",
                subtitle:"Material de apoyo con fines de repasar el contenido"
            },
            {
                title:"Servicio de atención",
                subtitle:"Atención 24/7 para las preguntas que tengas acerca del curso."
            },
            {
                title:"Ponte a prueba",
                subtitle:"Al final harás un examen para obtener tu diploma"
            },
        ],
        benefits:[
            {
                title:"Es lo que buscas si…",
                content:[
                    "Si quieres mejorar tu físico, no importa si eres novato o más avanzado.",
                    "Si quieres entrenar fuera del gimnasio sin material.",
                    "Si no tienes tiempo para ir al gym.",
                    "Si llevas entrenando en casa un tiempo y te has estancado."
                ]
            },
            {
                title:"Objetivos a Alcanzar",
                content:[
                    "Optimizar tu composición corporal, mejorando así tu físico y tu salud.",
                    "Te ayudará tanto a ganar músculo como a perder grasa.",
                    "Potenciar tu fuerza, resistencia y movilidad.",
                    "Evitar lesiones y mejorar tu postura.",
                ]
            },
        ]
      },
      {
        id: "cc01b4d6-a5bc-423d-85fd-717afdcf8f44",
        title: "Pérdida de grasa",
        contentImage:"https://squatfit.es/wp-content/uploads/img-unnamed.png",
        subtitle: "Cuándo, cómo y qué comer, suponen un quebradero de cabeza por la gran cantidad de desinformación a nuestro alrededor. Aquí vas a aprender cómo funciona el cuerpo y cómo reacciona ante el ejercicio y la alimentación.",
        price: "89.99",
        image: "https://storage.googleapis.com/squat-fit/static/Curso-perdida-de-grasa-1.jpg",
        video_presentation: "https://storage.googleapis.com/squat-fit/static/img-Perdida-de-Grasa.mp4",
        students: 0,
        tutor: {
          "id": "5644c590-4bd1-47c9-af95-2c57343cd354",
          "firstName": "María",
          "lastName": "",
          "profile_picture": ""
        },
        tutor_id:"5644c590-4bd1-47c9-af95-2c57343cd354",
        content:[
            {
                title:"1.Conceptos básicos",
                content:["Definiciones y conceptos básicos","Los Macros: carbos, grasas y protes","Aprende a comer (casos prácticos)","Hacer la compra. Etiquetas nutricionales"]
            },
            {
                title:"2.Metabolismo y diferencias entre sujetos",
                content:["Composición corporal y biotipos.","Medición de composición corporal.","Digestión y metabolismo de macros.","Factores que aumentan el metabolismo."]
            },
            {
                title:"3.Errores frecuentes y dietas no efectivas",
                content:["Mecanismos metabólicos en el ayuno.","Dietas milagro y errores frecuentes.","Dietas: disociada | alcalina | détox.","Ejercicio: analizando dietas milagro."]
            },
            {
                title:"4.Protocolos nutricionales efectivos",
                content:["Cheat meals, refeed y diet breaks.","Protocolos dietéticos efectivos.","Dieta cetogénica | Ayuno intermitente.","IIFYM, corrigiendo el error de conceptos.","Protocolo PSMF.","Ciclado de macronutrientes y calorías.","Aumentar la termogénesis adaptativa."]
            },
            {
                title:"5.El paso a paso y suplementación especifica",
                content:["Te enseño a elaborar tu plan nutricional.","Adherencia y control del hambre.","Suplementación para perder grasa."]
            },
            {
                title:"6.Mantén resultados: evita el efecto rebote",
                content:["Errores frecuentes.","Evitar el rebote: dieta inversa."]
            },
            {
                title:"7.Prueba",
                content:["Aprende a hacer tu propia dieta."]
            }
        ],
        tools:[
            {
                title:"Clases en vídeo",
                subtitle:"12+ horas de clases prácticas y 1.5 horas de clases teóricas."
            },
            {
                title:"Apuntes",
                subtitle:"Material de apoyo con fines de repasar el contenido"
            },
            {
                title:"Servicio de atención",
                subtitle:"Atención 24/7 para las preguntas que tengas acerca del curso."
            },
            {
                title:"Ponte a prueba",
                subtitle:"Al final harás un examen para obtener tu diploma"
            },
        ],
        benefits:[
            {
                title:"Objetivos que Lograrás",
                content:[
                    "Entender el funcionamiento de tu cuerpo y cómo lograr la pérdida de grasa.",
                    "Conocer tu biotipo y la forma más eficiente de perder grasa para ti.",
                    "Identificar qué planes y prácticas nutricionales son saludables y efectivos.",
                    "Conocer cómo compatibilizar la pérdida de grasa con tu estilo de vida."
                ]
            },
            {
                title:"Beneficios del Curso",
                content:[
                    "Aprender a comer correctamente",
                    "Descubrir una manera sostenible de conservar los resultados alcanzados.",
                    "Elaborar planes nutricionales personalizados.",
                ]
            },
        ]
      }
]
export const faqsAsesorias:Faqs[]=[
    {
        title:"¿La asesoría es presencial u online?",
        subtitle:"Después de haber rellenado el cuestionario, mandar las fotografías y haber realizado el pago elplazo de entrega puede tardar de 48 a 72 horas en función del flujo de trabajo. Aun así,estaríamos hablando por chat durante todo el proceso."
    },
    {
        title: '¿La asesoría es presencial u online?',
        subtitle: 'La asesoría es 100% online por lo que cualquier persona con internet podrá acceder a ella.\nLas tablas de entrenamiento se recibirán en formato pdf y los seguimientos se harán a través de fotos y videos, por lo que mi presencia no será necesaria, una ventaja porque así no tendrás que pagar por consultas físicas que realmente no aportan nada a tu progreso, mas que perder tiempo.'
    },
      {
        title: '¿La asesoría es para mí?',
        subtitle: 'Está dirigido a ti si quieres aprender a ganar masa muscular y perder grasa con la ayuda de un profesional que te mantenga comprometido y se preocupe porque logres resultados.\nAl finalizar la asesoría no solo vas a ver cambios en tu físico sino también en tu manera de pensar y tu estilo de vida que te permitirán mantener ese cambio para siempre.'
      },
      {
        title: '¿Cuánto tardaré en recibir el plan?',
        subtitle: 'Después de haber rellenado el cuestionario, mandar las fotografías y haber realizado el pago el plazo de entrega puede tardar de 48 a 72 horas en función del flujo de trabajo. Aun así, estaríamos hablando por chat durante todo el proceso.'
      },
      {
        title: '¿Haces planes para hombres o personas mayores?',
        subtitle: 'Hago planes para hombres y mujeres de todas las edades.'
      },
      {
        title: '¿Haces dietas para celíacos/veganos/keto...?',
        subtitle: 'Pues claro. Los planes serán adaptados para vegetarianos, veganos, celíacos, intolerantes en caso de que así se precise. Por otro lado, se harán dietas altas en grasas o en carbohidratos en función de los gustos y preferencias personales.'
      },
      {
        title: '¿Sabré las calorías y los macronutrientes que necesito?',
        subtitle: 'Por supuesto. Recibirás un documento con la pauta general de tu dieta.'
      },
      {
        title: '¿Cómo sé la cantidad que tengo que comer de cada alimento del plan?',
        subtitle: 'Lo tendrás todo indicado en el menú, cuantas comidas, a que horas y cómo sustituirlas si hace falta'
      },
      {
        title: '¿En cuánto tiempo veré resultados?',
        subtitle: 'Esto dependerá al 100% de tu nivel de adherencia y compromiso con el plan. En general suele ser una media de 10-12 meses, pero los resultados pueden variar.\nHemos tenido clientes que transforman su vida en 5 meses, y otros que llevan 2 o 3 años entrando y saliendo de la asesoría sin comprometerse suficiente al cambio.'
      },
      {
        title: '¿Incluye asesoramiento sobre suplementación?',
        subtitle: 'Por supuesto. Habrá un apartado exclusivo para ti, 100% adaptado a tus necesidades y objetivos.'
      },
      {
        title: '¿Cómo me comunico contigo?',
        subtitle: 'Puedes preguntarme todas las dudas que tengas referentes al plan pautado siempre que lo necesites ya bien sea por correo o whatsapp. Personalmente, me gusta más whatsapp porque es más rápido y permite un contacto mucho más cercano, así como compartir audios y vídeos en el momento.'
      },
      {
        title: '¿Cómo funcionan las devoluciones?',
        subtitle: 'Podrás solicitar la devolución del importe en las primeras 24-48 horas posteriores al pago. Una vez pasado ese tiempo, no estará permitida la devolución.'
      },
  ]
  export const faqsCursos:Faqs[]=[
    {
      title: '¿Es un curso presencial u online?',
      subtitle: 'Todos mis cursos son 100% online por lo que cualquier persona con internet podrá acceder a ellos.'
    },
    {
      title: '¿Este curso es para mí?',
      subtitle: 'Está dirigido a todas las personas que quieran aprender a ganar masa muscular y fuerza a la vez que se pierde grasa de forma independiente, sin necesidad de contratar a ningún nutricionista. Al final del curso habrás aprendiendo a hacer dietas diseñadas para ganar masa muscular a la vez que pierdes grasa.'
    },
    {
      title: '¿Es un curso apto para mujeres? No quiero ponerme fuerte como un hombre',
      subtitle: 'Ganar masa muscular tiene muchos beneficios para tu salud y tu estética. Ganando músculo no te ves como un culturista sino más bien con la piel firme y el cuerpo tonificado, que es lo que todas buscamos.\nEs más, he preparado un apartado exclusivo para ti antes de cada clase mostrándote la aplicación práctica de cada clase. Es decir, en qué se traduce el contenido teórico que estás viendo con tus objetivos.'
    },
    {
      title: '¿Qué aprenderé en el curso?',
      subtitle: 'Contenido práctico y teórico sobre todo lo que concierne a la ganancia de masa muscular. Vamos al grano. Aquí no hay relleno con información innecesaria.'
    },
    {
      title: '¿Qué nivel debo tener para hacer el curso?',
      subtitle: 'El curso es de un nivel básico a medio-avanzado. Aun así, todo está explicado desde cero, para que incluso si eres más novato puedas entender todo a la perfección.'
    },
    {
      title: '¿Hay material de apoyo?',
      subtitle: 'Todas las clases tienen material de soporte, además tendrás las presentaciones adjuntas, por lo que no necesitaras tomar apuntes de lo que salga en pantalla.\nLos apuntes complementarán las clases en vídeo y contendrán las referencias bibliográficas de todo el contenido del curso.'
    },
    {
      title: '¿Dónde puedo ver los videos del curso?',
      subtitle: 'Si tienes acceso a internet los tienes disponibles 24/7 dentro de tu app, si no tienes internet, puedes descargarlos localmente en tu teléfono para verlos más adelante.'
    },
    {
      title: '¿Qué pasa si tengo dudas con el curso?',
      subtitle: 'Nos mandas un correo con tus preguntas, que serán respondidas en 24-48 horas. La atención será personalizada al 100%.'
    },
    {
      title: '¿Puedo hacer el curso si soy de fuera de España?',
      subtitle: 'Al ser un curso online, cualquier persona desde cualquier parte del mundo con acceso a internet puede disfrutar y aprender de los cursos.'
    },
  ]
  export const categories=['Desayuno','Merienda','Comida','Cena'];
  export const localBook:Book={
    id: "1b972b67-e5ba-476e-aaca-f683a983ea87",
    title: "La cocina squat fit",
    subtitle: "Edición Nº1",
    image: "https://storage.googleapis.com/squat-fit/static/Portada%20-%20La%20cocina%20squat%20fit.png",
    price: "20",
    versions: [
      {
        "version_id": "937a308d-e5bc-4561-81b5-2e98af9f9eaa",
        "version_title": "Europa",
        "version_image": "https://storage.googleapis.com/squat-fit/static/eu.png"
      },
      {
        "version_id": "60eb7ff2-6859-48ae-b3a0-5dea98397e58",
        "version_title": "America",
        "version_image": "https://storage.googleapis.com/squat-fit/static/us.png"
      }
    ],
    content:[
        {
            title:"Rico y saludable",
            subtitle:"+70 recetas ricas con sustituciones y adaptaciones."
        },
        {
            title:"Navegación fácil",
            subtitle:"Con descuentos en suplementos e ingredientes top."
        },
        {
            title:"Didáctico",
            subtitle:"Consejos de alimentos a elegir a la hora de hacer la compra"
        },
        {
            title:"Recetas saciantes",
            subtitle:"Raciones grandes que te dejarán lleno y satisfecho"
        },
    ],
  }

  export const getFileDescription = (docTypeId: string | undefined): string => {
    const descriptions: Record<string, string> = {
      "90224b44-8ba8-4ba6-8f28-2e921c88599a": "Plan de asesoría",
      "0f55b518-8602-49a4-af08-9a4c4d130329": "Menú con los diferentes alimentos para tu mes",
      "7fdf986a-0aac-464b-ae02-4819b4dd56e3": "Rutina con los mejores ejercicios seleccionados para ti",
    };
  
    return docTypeId ? descriptions[docTypeId] || 'Mira tu último progreso' : 'Mira tu último progreso';
  };