import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { getAdviceDocs, onFinishMonth, onStartForm, uploadAdviceImage } from './thunks'
import { DocsAdvice } from '../../interfaces/coach/docsAdviceInterface'

export interface CoachState {
  docs: DocsAdvice[]
  is_loading: boolean,
  is_loading_form:boolean,
  is_loading_month:boolean,
  is_loading_image:boolean,
}

const initialState: CoachState = {
    docs: [],
    is_loading: false,
    is_loading_form: false,
    is_loading_month: false,
    is_loading_image: false,
}

export const coachSlice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    cleanDocs: (state) => {
      state.docs = [];
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAdviceDocs.fulfilled, (state, action: PayloadAction<DocsAdvice[]>) => {
      state.docs = action.payload;
      state.is_loading = false;
    })
    .addCase(getAdviceDocs.pending, (state) => {
      state.is_loading = true;
    })
    .addCase(getAdviceDocs.rejected, (state) => {
      state.is_loading = true;
    })
    .addCase(onFinishMonth.fulfilled, (state, action: PayloadAction<{id: string; periodId: string}>) => {
      state.docs = state.docs.map(doc => {
        if ((doc?.period_id ?? '') === action.payload.periodId && doc.forms) {
            doc.forms = doc.forms.map(form => {
                if (form.id == action.payload.id) {
                    return { ...form, is_delivered: true };
                }
                return form;
            });
        }
        return doc;
    });
    
        state.is_loading_month = false;
    })
    .addCase(onFinishMonth.pending, (state) => {
      state.is_loading_month = true;
    })
    .addCase(onFinishMonth.rejected, (state) => {
      state.is_loading_month = true;
    })
    .addCase(uploadAdviceImage.fulfilled, (state, action: PayloadAction<{periodId: string}>) => {
      state.docs = state.docs.map(doc => {
        if ((doc?.period_id ?? '') === action.payload.periodId && doc.request_progress) {
            doc.request_progress = {...doc.request_progress,is_delivered:true}
        }
        return doc;
    });
    
        state.is_loading_image = false;
    })
    .addCase(uploadAdviceImage.pending, (state) => {
      state.is_loading_image = true;
    })
    .addCase(uploadAdviceImage.rejected, (state) => {
      state.is_loading_image = true;
    })
    .addCase(onStartForm.fulfilled, (state, action: PayloadAction<DocsAdvice[]>) => {
        state.docs = state.docs.map(doc => {
            if (doc.forms) {
                doc.forms = doc.forms.map(form => {
                    if ((form.form_type?.name === 'Deportivo' && form.is_delivered === false) ||
                        (form.form_type?.name === 'Nutricional' && form.is_delivered === false)) {
                        return { ...form, is_delivered: true };
                    }
                    return form;
                });
            }
            return doc;
        });
        state.is_loading_form = false;
    })
    .addCase(onStartForm.pending, (state) => {
      state.is_loading_form = true;
    })
    .addCase(onStartForm.rejected, (state) => {
      state.is_loading_form = true;
    })
  }
})

export const { cleanDocs } = coachSlice.actions
export default coachSlice.reducer