export const PostCardSkeleton = () => {
  return (
    <div className="flex justify-center items-center">
      <div className="text-black border-b border-grey-200 mx-5 space-y-6 md:p-10 p-5 lg:max-w-[550px] 2xl:max-w-[650px]">
        <div className="flex space-x-4 items-center">
          <div className="flex animate-pulse w-12 h-12 bg-slate-200 rounded-full"></div>
          <div className="space-y-1">
            <div className="flex space-x-2 items-center">
              <h2 className="text-slate-700">
                <div className="flex animate-pulse w-20 h-5 bg-slate-200 rounded-md"></div>
              </h2>
            </div>
            <p className="text-xs text-slate-400">
              <div className="flex animate-pulse w-12 h-4 bg-slate-200 rounded-md"></div>
            </p>
          </div>
        </div>
        <div>
          <p className="text-sm leading-6 text-gray-950">
            <div className="flex animate-pulse w-36 h-5 bg-slate-200 rounded-md"></div>
          </p>
        </div>
        <div className="grid grid-cols-2 col-span-2 gap-2">
          <div className="overflow-hidden rounded-xl col-span-3 max-h-[20rem]">
            <div className="flex animate-pulse w-[20rem] h-[18rem] bg-slate-200"></div>
          </div>
        </div>
        <div className="flex justify-start pt-5">
          <div className="flex animate-pulse w-28 h-5 bg-slate-200 rounded-md"></div>
        </div>
      </div>
    </div>
  );
};
