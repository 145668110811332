interface DobleChecksIconProps {
  width?: string;
  height?: string;
  color?: string;
}

const DobleChecksIcon = ({ width, height, color }: DobleChecksIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={ width ?? "24"}
      height={ height ?? "24"}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color ?? "currentColor"}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="icon icon-tabler icons-tabler-outline icon-tabler-checks"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M7 12l5 5l10 -10" />
      <path d="M2 12l5 5m5 -5l5 -5" />
    </svg>
  );
};

export default DobleChecksIcon;
