import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { parseRecipes, Recipe } from "../../interfaces/food/recipeInterface";

export const getRecipesSystem = createAsyncThunk("recipe/getRecipesSystem", async () => {
  const resp = await squatFitApi.get<Recipe[]>("/recipe/system");
  return parseRecipes(resp.data);
});

export const getRecipesPartialSystem = createAsyncThunk(
  "recipe/getRecipesPartialSystem",
  async () => {
    const resp = await squatFitApi.get<Recipe[]>(`/recipe/partial-system`);
    return parseRecipes(resp.data);
  }
);

