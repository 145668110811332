import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Friend, FriendFollowsNumber, RequestFriend, SearchFriend } from "../../interfaces/social/interfaceSocial";
import { acceptFriendRequest, followsFriend, getFriends, notifyNewFriendRequest, pendingFriendRequest, searchFriends } from "./thunks";

interface FriendState {
  searchFriends: SearchFriend[];
  is_loading_search_friends: boolean;
  friends: Friend[];
  pendingRequest: RequestFriend[];
  is_loading_friends: boolean;
  friendSelected: SearchFriend | null;
  friendFollows: FriendFollowsNumber | null;
  is_loading_friend_follows: boolean;
  friendRequest: RequestFriend | null;
}

const initialState: FriendState = {
  searchFriends: [],
  is_loading_search_friends: false,
  friends: [],
  pendingRequest: [],
  is_loading_friends: false,
  friendFollows: null,
  is_loading_friend_follows: false,
  friendSelected: null,
  friendRequest: null,
};

export const friendSlice = createSlice({
  name: "friend",
  initialState,
  reducers: {
    setFriend: (state, action: PayloadAction<Friend[]>) => {
      state.friends = action.payload;
    },
    setFriendFollows: (state, action: PayloadAction<FriendFollowsNumber>) => {
      state.friendFollows = action.payload;
    },
    setFriendSelected: (state, action: PayloadAction<SearchFriend>) => {
      state.friendSelected = action.payload;
    },
    setFriendRequest: (state, action: PayloadAction<RequestFriend>) => {
      state.friendRequest = action.payload;
    },
    cleanFriendRequest: (state) => {
      state.friendRequest = null;
    },
    cleanFriendSelected: (state) => {
      state.friendSelected = null;
    },
    cleanSearchFriends: (state) => {
      state.searchFriends = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchFriends.pending, (state) => {
        state.is_loading_search_friends = true;
      })
      .addCase(searchFriends.fulfilled, (state, action: PayloadAction<SearchFriend[]>) => {
        state.searchFriends = action.payload;
        state.is_loading_search_friends = false;
      })
      .addCase(searchFriends.rejected, (state) => {
        state.searchFriends = [];
        state.is_loading_search_friends = false;
      })
      .addCase(followsFriend.pending, (state) => {
        state.is_loading_friend_follows = true;
      })
      .addCase(followsFriend.fulfilled, (state, action: PayloadAction<FriendFollowsNumber[]>) => {
        state.friendFollows = action.payload[0];
        state.is_loading_friend_follows = false;
      })
      .addCase(followsFriend.rejected, (state) => {
        state.friendFollows = null;
        state.is_loading_friend_follows = false;
      })
      .addCase(getFriends.pending, (state) => {
        state.is_loading_friends = true;
      })
      .addCase(getFriends.fulfilled, (state, action: PayloadAction<Friend[]>) => {
        state.friends = action.payload;
        state.is_loading_friends = false;
      })
      .addCase(getFriends.rejected, (state) => {
        state.friends = [];
        state.is_loading_friends = false;
      })
      .addCase(notifyNewFriendRequest, (state, action: PayloadAction<RequestFriend>) => {
        state.friendRequest = action.payload;
      })
      .addCase(pendingFriendRequest.fulfilled, (state, action: PayloadAction<RequestFriend[]>) => {
        state.pendingRequest = action.payload;
      })
      .addCase(acceptFriendRequest.fulfilled, (state, action: PayloadAction<RequestFriend>) => {
        state.pendingRequest = state.pendingRequest.filter((request) => request.id !== action.payload.id);
      })
  }
});

export const { setFriend, setFriendFollows, setFriendSelected, setFriendRequest, cleanFriendRequest, cleanFriendSelected, cleanSearchFriends } = friendSlice.actions;
export default friendSlice.reducer;