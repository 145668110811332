import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { Book, ReadBook, UserBook } from "../../interfaces/book/interfaceBook";

export const getAllBooks = createAsyncThunk("book/getAllBooks", async () => {
  const resp = await squatFitApi.get<Book[]>("/book/all");

  return resp.data;
});

export const getBooksByUser = createAsyncThunk(
  "book/getBooksByUser",
  async () => {
    const resp = await squatFitApi.get<UserBook[]>(`/book/by-user`);

    return resp.data;
  }
);

export const readBookById = createAsyncThunk(
  "book/readBookById",
  async ({ bookId, versionId }: { bookId: string; versionId: string }) => {
    const resp = await squatFitApi.get<ReadBook>(`/book/by-id`, {
      params: { book_id: bookId, version_id: versionId },
    });

    return resp.data;
  }
);

export const createOrderWithPaypal = createAsyncThunk(
  "book/createOrderWithPaypal",
  async ({ bookId }: { bookId: string }) => {
    const resp = await squatFitApi.post<string>(
      `/book/create-order-in-paypal`,
      {
        book_id: bookId,
      }
    );

    return resp.data;
  }
);

export const createOrderWithStripe = createAsyncThunk(
  "book/createOrderWithStripe",
  async ({ bookId }: { bookId: string }) => {
    const resp = await squatFitApi.post<string>(
      `/book/create-checkout-session`,
      {
        book_id: bookId,
      }
    );

    return resp.data;
  }
);
