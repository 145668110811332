import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { startLogout } from "../../store/slices/auth/thunks";
import { useEffect, useState } from "react";
import useSearch from "../hooks/useSearch";
import useDebounce from "../hooks/useDebounce";
import { cleanSearchFriends } from "../../store/slices/social/friendSlice";
import { pendingFriendRequest } from "../../store/slices/social/thunks";
import { Row } from "../../core/components/Row";
import Footer from "../../core/components/Footer";
import { InicioIcon } from "../../assets/icons/InicioIcon";
import { DiarioIcon } from "../../assets/icons/DiarioIcon";
import { SubscriptionIcon } from "../../assets/icons/SubscriptionIcon";
import { ChatButton } from "../../core/components/floating-chat/ChatButton";
import WindowChat from "../../core/components/floating-chat/WindowChat";
import FloatingChat from "../../core/components/floating-chat/FloatingChat";
import { setSelectedChat } from "../../store/slices/floating-chat/chatSlice";
import { getAdviceByUser, getAdviceDocs } from "../../store/slices/coach/thunks";
import { log } from "console";
import { CourseIcon } from "../../assets/icons/CourseIcon";

const borderBottom: any = {
  "/": "#FF690B",
  "/today": "#d9688f",
  "/my-courses": "#710a97",
  "/asesorias": "#3932C0",
  "/libros": "#1a1aec",
}

export const SquadLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const { handleSearch } = useSearch();
  const { profilePicture } = useSelector((state: RootState) => state.auth);
  const { subData } = useSelector((state: RootState) => state.coach);
  const { listChats } = useSelector((state: RootState) => state.floatingchat);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isNotifyLoaded, setIsNotifyLoaded] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const { searchFriends, pendingRequest } = useSelector(
    (state: RootState) => state.friend
  );

  const onConfirm = () => {
    localStorage.clear();
    dispatch(startLogout());
    navigate("/auth/login");
  };

  const [searchInput, setSearchInput] = useState("");
  const debounceValue = useDebounce(searchInput, 1500);

  const handleChangeSearch = (value: string) => {
    if (!isLoading) setIsLoading(true);
    if (value.length === 0) setIsLoading(false);
    setSearchInput(value);
  };

  useEffect(() => {
    if (listChats.length > 0) dispatch(setSelectedChat(listChats[0]));
  }, [listChats])

  useEffect(() => {
    if (subData.adviser_id === '') {
      dispatch(getAdviceByUser());
      dispatch(getAdviceDocs());
    }
  }, [])

  useEffect(() => {
    if (debounceValue.length > 0) {
      handleSearch(debounceValue);
      setIsLoading(false);
    }
  }, [debounceValue]);

  useEffect(() => {
    if (searchInput.length === 0) {
      dispatch(cleanSearchFriends());
    }
  }, [searchInput]);

  useEffect(() => {
    if (pendingRequest.length === 0 && !isNotifyLoaded) {
      dispatch(pendingFriendRequest()).then(() => setIsNotifyLoaded(true));
    }
    return () => {};
  }, [pendingRequest, isNotifyLoaded]);

  const isSelected = (pathname: string) => {
    return location.pathname === pathname ? "text-gradient " : "";
  };

  const handleOpen = () => {
    setIsOpen(true);
  }

  const handleClose = () => {
    setIsOpen(false);
  }

  return(
    <div className="max-h-screen">
      <header className={`flex sticky z-20 top-0 bg-white w-full items-center justify-between whitespace-nowrap border-b-[3px] border-solid border-b-[${borderBottom[location.pathname] ?? "#e8f3ef"}] px-10 py-3`}>
        <Row >
          <img src="/logo_horizontal_color.png" className="h-12 hidden md:block place-self-center" alt=""/>
          <img src="/logo_color.png" className="h-12 block md:hidden place-self-center" alt=""/>
        </Row>
        <Row crossAxisAlignment="center" className="flex-1 hidden md:flex gap-8">
          <Row className="items-center gap-9">
            <Link
              to={"/"}
              className={`text-sm font-medium leading-normal text-orange ${isSelected(
                "/"
              )}`}
            >Inicio</Link>
            <Link
              to={"/today"}
              className={`text-sm font-medium leading-normal text-roseSquat ${isSelected(
                "/today"
              )}`}
            >Diario</Link>
            {/* <Link
              to={"/social"}
              className={`text-sm font-medium leading-norma ${isSelected(
                "/social"
              )}`}
            >Social</Link> */}
            <Link
              to={"/my-courses"}
              className={`text-sm font-medium leading-normal text-purpleSquat1 ${isSelected(
                "/my-courses"
              )}`}
            >Cursos</Link>
            
            <Link
              to={"/asesorias"}
              className={`text-sm font-medium leading-normal text-[#3932C0] ${isSelected(
                "/asesorias"
              )}`}
            >Planes</Link>
            {/* <Link
              to={"/libros"}
              className={`text-sm font-medium leading-normal text-blueSquat1 ${isSelected(
                "/libros"
              )}`}
            >Compras</Link> */}
          </Row>
          
          <div className="rounded-full w-14 h-12" >
            <Link
              to={"/profile"}
              className={`flex items-center text-sm gap-3 font-medium p-2 ${isSelected(
                "/profile"
              )}  ${isSelected("/about")} ${isSelected("/faqs")}${isSelected("/privacy")}${isSelected("/terms")} `}
            >
              {
                profilePicture !== "" && profilePicture !== null
                ? <img src={profilePicture as string} className="object-cover border border-orange-500 w-7 h-7 rounded-full" />
                : <img src="https://biiainsurance.com/wp-content/uploads/2015/05/no-image.jpg" className="object-cover border border-orange-500 h-7 w-7 rounded-full"/>
              }
            </Link>
          </div>
        </Row>
      </header>
      <div className="">
        <Outlet/>
      </div>
      <Footer/>
      <header className="flex sticky md:hidden z-20 border-t-2 bottom-0 bg-white w-full items-center justify-between whitespace-nowrap border-b border-solid border-b-[#e8f3ef] px-10 py-3">

        <Row mainAxisAlignment="between" crossAxisAlignment="center" className=" gap-8">
          <Link
            to={"/"}
          ><InicioIcon variant={isSelected("/")?"selected":"normal"}  /></Link>
          <Link
            to={"/today"}
            className={`font-medium ${isSelected(
              "/today"
            )}`}
          ><DiarioIcon variant={isSelected("/today")?"selected":"normal"} /></Link>
          <Link
            to={"/my-courses"}
            className={`font-medium ${isSelected(
              "/my-courses"
            )}`}
          ><CourseIcon variant={isSelected("/my-courses")?"selected":"normal"} /></Link>
          <Link
            to={"/asesorias"}
          ><SubscriptionIcon variant={isSelected("/asesorias")?"selected":"normal"}  /></Link>
          <div className="rounded-full w-14 h-12" >
            <Link
              to={"/profile"}
              className={`flex items-center text-sm gap-3 font-medium p-2 ${isSelected(
                "/profile"
              )}  ${isSelected("/about")} ${isSelected("/faqs")}${isSelected("/privacy")}${isSelected("/terms")} `}
            >
              {
                profilePicture !== "" && profilePicture !== null
                ? <img src={profilePicture as string} className="object-cover border border-orange-500 w-7 h-7 rounded-full" />
                : <div className="bg-gray-200 h-7 w-7 ml-14 rounded-full"/>
              }
            </Link>
          </div>
        </Row>
      </header>
        {
          subData.status === 'ACTIVE' &&
          <ChatButton onOpenModal={!isOpen ? handleOpen : handleClose} />
        }
        <WindowChat
          isOpen={isOpen}
          onClose={handleClose}
          component={<FloatingChat />}
        />
      </div>
  );
};
