import { DateTime } from "luxon";
import DobleChecksIcon from "../../../../../assets/icons/DobleChecksIcon";

interface OwnMessageProps {
  message: string;
  id: string;
  sent_at: string;
  is_viewed: boolean;
}

const OwnMessage = ({ message, id, sent_at, is_viewed }: OwnMessageProps) => {
  return (
    <div
      id={id}
      className="flex flex-row gap-2 self-end text-left rounded-xl break-words text-sm px-3 py-1 mb-1 max-w-64 bg-[#FF690B] text-white"
    >
      <label>{message}</label>
      <div className="flex flex-row justify-end items-end gap-1">
        <label className="text-right text-xs">
          {DateTime.fromISO(sent_at).toFormat("T")}
        </label>
        <DobleChecksIcon width="17" height="17" color={`${is_viewed ? "#0045fc" : "rgb(254 215 170)"}`} />
      </div>
    </div>
  );
};

export default OwnMessage;
