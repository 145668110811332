import React from "react"
import { InputType } from "../interfaces/InputSquadInterface";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  inputType: InputType;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
}

export const InputSquad = ({inputType,onBlur,onChange, ...props}: Props) => {
  return (
    <input 
      onChange={onChange}
      onBlur={onBlur}
      className={`border-2 border-gray-80 rounded-lg ${inputType} placeholder:text-gray-400 px-3`}
      {... props}
    />
  )
}
