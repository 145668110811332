import { useEffect, useState } from 'react';
import { Column } from "../../../../core/components/Column";
import { SizedBox } from '../../../../core/components/SizedBox';
import { BlueButton } from '../../../../core/components/ButtonBlue';
import { useNavigate } from 'react-router-dom';
import Category from '../../../../core/interfaces/Category';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../../store/store';
import { startUpdatingUserInfo } from '../../../../store/slices/auth/thunks';
import { useAlert } from '../../../hooks/useAlert';
import { ButtonSquad } from '../../../../core/components';
import { ButtonType } from '../../../../core/interfaces/ButtonSquadInterface';

const strengths: Category[] = [
    {label: 'Ninguno', value: '0defb34f-69be-40c2-9190-de7b9aeafe2a' },
    {label: '1 o 2 días', value: '2e2fc039-46a7-4181-a4d1-c764b48f9fbb' },
    {label: '3 o 4 días', value: 'b356f4c9-ed56-4094-90e6-691f192fce65' },
    {label: '5 o 6 días', value: '6297372a-ca9b-4355-8e83-f4c6a9c58b01' },
];

const steps: Category[] = [
    {label: 'No lo cuento', value: 'bc75e3d0-c4ee-4fb1-ac57-a11700fc4868' },
    {label: 'Menos de 2.000', value: '64852506-b28b-4c4c-8388-b2147cf33e91' },
    {label: 'Entre 2.000 y 5.000', value: '8c15192b-e7cd-4a2b-9491-7f7ff4f379f6' },
    {label: 'Entre 6.000 y 9.000', value: 'a9fcddce-28c2-4489-b2cc-ec09ba05ee09' },
    {label: 'Entre 10.000 y 14.000', value: 'db28af0c-5695-4cc5-8363-3f78581ccb53' },
];

const _sexOptions : Category[] = [
    {label: 'Masculino', value: 'male' },
    {label: 'Femenino', value: 'female' },
];

const Step2Screen = () => {
    const navigate = useNavigate();
    const [genderOption, setGenderOption] = useState<Category|null>();
    const [stepsOption, setStepsOption] = useState<Category|null>();
    const [strengthOption, setStrengthOption] = useState<Category|null>();
    const { uid,stepsPeerDay,strengthTraining,gender,isLoadingOnboarding } = useSelector((state: RootState) => state.auth);
    const dispatch: AppDispatch = useDispatch();
    const { showAlert } = useAlert();
    useEffect(() => {
        if(gender!=null){
            setGenderOption(_sexOptions.find((e)=>e.value==gender));
        }
        if(strengthTraining!=null){
            setStrengthOption(strengths.find((e)=>e.value==strengthTraining));
        }
        if(stepsPeerDay!=null){
            setStepsOption(steps.find((e)=>e.value==stepsPeerDay));
        }
    }, [])
    const handleSubmit = () => {  
        if(stepsOption==null||genderOption==null||strengthOption==null)return;
        const payload = {
          'steps_peer_day_id':stepsOption?.value,
          'gender':genderOption?.value,
          'strength_training_id':strengthOption?.value,
        }
        dispatch(startUpdatingUserInfo({userId: uid, userInfo: payload})).then( (response) => {
          if (response.meta.requestStatus === 'fulfilled') {
            showAlert({
              message: 'Cambios guardados con éxito!',
              type: "success"
            });
            navigate('/step3');
          }
        })

      }


    return (
        <Column mainAxisAlignment='center' crossAxisAlignment='center' className='h-screen'>
            <div className="place-self-center">
                <h2 className='font-bold'> Cuéntanos más sobre ti.</h2>
                <SizedBox className='h-5'/>
                <div className=" grid grid-cols-1 lg:grid-cols-3">
                    {_sexOptions.map(item => (
                        <div onClick={
                            ()=>{
                                setGenderOption(item);
                            }
                        } 
                        key={item.value} className={`container px-4 py-2 w-max border ${genderOption?.value===item.value?'bg-blue-600 text-white':''} border-gray-300 rounded-lg  mb-3`}>
                            {item.label}
                        </div>
                    ))}
                </div>

                <SizedBox className='h-5'/>
                <h2 className='font-bold'>¿Cuántos pasos das en promedio por día?</h2>
                <SizedBox className='h-5'/>
                <div className=" grid grid-cols-1 lg:grid-cols-3">
                    {steps.map(item => (
                        <div onClick={
                            ()=>{
                                setStepsOption(item);
                            }
                        } 
                        key={item.value} className={`container px-4 py-2 w-max border ${stepsOption?.value===item.value?'bg-blue-600 text-white':''} border-gray-300 rounded-lg  mb-3`}>
                            {item.label}
                        </div>
                    ))}
                </div>
                <SizedBox className='h-5'/>
                <h2 className='font-bold'>¿Con qué frecuencia entrenas fuerza?</h2>
                <SizedBox className='h-5'/>
                <div className=" grid grid-cols-1 lg:grid-cols-3">
                    {strengths.map(item => (
                        <div onClick={
                            ()=>{
                                setStrengthOption(item);
                            }
                        } 
                        key={item.value} className={`container px-4 py-2 w-max border ${strengthOption?.value===item.value?'bg-blue-600 text-white':''} border-gray-300 rounded-lg  mb-3`}>
                            {item.label}
                        </div>
                    ))}
                </div>
                <SizedBox className='h-5'/>
                {/* <h2 className='font-bold'>¿Cuantas veces entrenas por semana?</h2>
                <SizedBox className='h-5'/>
                {renderList(dailyActivity)} */}
                {/* <SizedBox className='h-5'/>
                <h2 className='font-bold'>Cual es tu objetivo</h2>
                <SizedBox className='h-5'/>
                {renderList(trainingGoal)} */}
                <SizedBox className='h-10'/>
            </div>
            <SizedBox className="h-8"/>
            <ButtonSquad loading={isLoadingOnboarding}  buttonType={ButtonType.OrangeFull} className="w-96 place-self-center" label="continuar" onClick={handleSubmit} />
        </Column>
    );
};

export default Step2Screen;

