import React, { useEffect } from "react";
import { Column } from "../../../core/components/Column";
import { Row } from "../../../core/components/Row";
import { TextComponent } from "../../../core/components/TextComponent";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { coachs, localBook } from "../../../core/constants/constatns";
import { PreviewRecipe } from "../../../assets/icons/PreviewRecipe";
import { BookButton } from "../../../assets/icons/BookButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { setSelectedBook } from "../../../store/slices/book/bookSlice";
import { getBooksByUser } from "../../../store/slices/book/thunks";

const BookPresentation: React.FC = () => {

  const dispatch: AppDispatch = useDispatch();
  const { books, userBooks, selectedBook } = useSelector((state: RootState) => state.book);

  useEffect(() => {
    if (userBooks.length === 0) dispatch(getBooksByUser());
  }, []);
  
  useEffect(() => {
    if (books.length > 0 && !selectedBook) dispatch(setSelectedBook(localBook));
  }, [books, selectedBook]);

  return (
    <Column
      crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
        <Row responsiveCol={true} crossAxisAlignment="center">
          <div className="w-2/4">
            <img
              className="w-full object-cover bg-center bg-no-repeat  bg-cover rounded-xl"
              src="/book.png"
            />
          </div>
          <Column>
            <TextComponent
              variant="h1"
              className="text-orange font-bold pb-3 pt-6"
            >
              La Cocina Squat Fit: Recetas saludables
            </TextComponent>
            <TextComponent variant="h3" className="leading-normal pb-3 pt-1">
              El libro de recetas definitivo y una herramienta básica para
              aprender a comer. El secreto para mantenerte fit está en disfrutar
              lo que comes.
            </TextComponent>
          </Column>
        </Row>
        <TextComponent variant="h2" className="font-bold pb-3 pt-5">
          Sobre los autores
        </TextComponent>
        {coachs.map((coach, index) => (
          <Row key={index} responsiveCol={true} className="gap-4 py-6">
            <div className="rounded-full min-w-32 min-h-32 max-w-32 max-h-32">
              <img
                src={coach.profile_picture}
                className="object-cover rounded-full min-w-32 min-h-32 max-w-32 max-h-32"
              />
            </div>
            <Column className="flex flex-col justify-center">
              <TextComponent
                variant="h2"
                className="text-orange font-bold leading-tight"
              >
                {coach.firstName} {coach.lastName}
              </TextComponent>
              <TextComponent
                variant="h4"
                className="text-fadedAsh font-normal leading-normal"
              >
                {coach.homeDescription}
              </TextComponent>
            </Column>
          </Row>
        ))}
        <Row className="grid grid-cols-1 md:grid-cols-4 gap-3 py-4">
          {localBook.content?.map((e,index) => (
            <Column key={index} className="gap-1 rounded-lg border border-[#d1e6e0] bg-[#f8fbfb] p-4">
              <TextComponent
                variant="h2"
                className="text-orange text-base font-bold"
              >
                {e.title}
              </TextComponent>
              <TextComponent variant="h4" className="fadedAsh">
                {e.subtitle}
              </TextComponent>
            </Column>
          ))}
        </Row>
        <PreviewRecipe />
        <div className="py-3">
          <BookButton />
          {/* <ButtonSquad
              label='Comprar ahora'
              buttonType={ButtonType.OrangeFull}
              >
              </ButtonSquad> */}
        </div>
      </div>
    </Column>
  );
};

export default BookPresentation;
