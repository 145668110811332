import { useDispatch, useSelector } from 'react-redux';
import { Column } from './Column';
import { Row } from './Row';
import { TextComponent } from './TextComponent';
import { AppDispatch, RootState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { localBook } from '../constants/constatns';
import { ButtonSquad } from '.';
import { ButtonType } from '../interfaces/ButtonSquadInterface';

export const BookOffer = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { books, userBooks, is_loading_all_books, is_loading_user_books } =
    useSelector((state: RootState) => state.book);

  const toBook = () => {
    navigate(`compras/book-info`);
  }

  if (books.length === 0) {
    return <></>;
  }

  return (
    <Column crossAxisAlignment='start'>
      <TextComponent variant="h2" className="font-bold pb-3 pt-5 text-center text-orange-600">Recomendado para ti</TextComponent>
      <div className="py-4">
        <Row responsiveCol={true} className="items-stretch justify-between gap-4 rounded-xl bg-white p-6 shadow-lg border border-gray-200">
          <Column mainAxisAlignment='between' className="gap-4 w-full">
            <Column className="gap-2">
              <TextComponent variant='h3' className="text-orange-600 font-bold">{localBook?.title}</TextComponent>
              <TextComponent variant="h4" className="text-gray-600 leading-tight">{localBook!.content![0].subtitle}</TextComponent>
            </Column>
            <ButtonSquad label="Ver libro de cocina 🍕" buttonType={ButtonType.OrangeFull} onClick={toBook} />
          </Column>
          <div className='w-2/4 hidden md:block relative'>
            <img className='w-full object-contain bg-center bg-no-repeat aspect-video bg-cover rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105' src="/book.png" alt="Book Cover"/>
          </div>
        </Row>
      </div>
    </Column>
  )
}
