import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import ReactPlayer from "react-player";

import useScreen from "../../../../hooks/useScreen";
import { AppDispatch, RootState } from "../../../../../store/store";
import useVideoContainer from "../../../../hooks/useVideoContainer";
import { watchVideo } from "../../../../../store/slices/course/thunks";
import useLastVideoSeen from "../../../../hooks/useLastVideoSeen";
import { Column } from "../../../../../core/components/Column";
import { TextComponent } from "../../../../../core/components/TextComponent";

const VideoContainer = () => {
  const { isSmallScreen } = useScreen();
  const dispatch: AppDispatch = useDispatch();
  const { handleEnded, handleProgress, onReady, setIsReady } =
    useVideoContainer();
  const { handleLastVideoSeen } = useLastVideoSeen();
  const { courseSelected, lastVideoSeen } = useSelector(
    (state: RootState) => state.course
  );
  const [videoURL, setVideoURL] = useState<string>("");

  useEffect(() => {
    if (!lastVideoSeen || !courseSelected) return;
    if (courseSelected.videos.every((video) => video.video_id !== lastVideoSeen)) {
      handleLastVideoSeen();
    }
    dispatch(
      watchVideo({
        courseId: courseSelected?.id as string,
        videoId: lastVideoSeen,
      })
    ).then((resp) => {
      if (resp.meta.requestStatus === "fulfilled") {
        setVideoURL(resp.payload as string);
      }
    });
  }, [lastVideoSeen]);

  useEffect(() => {
    if (!courseSelected) return;
    setIsReady(false);
  }, [videoURL]);

  return (
    <div
      className={`flex flex-col w-full`}
    >
      <div className="bg-gray-900">
        <TextComponent variant="h4" className="px-8 py-6 text-white">
          {courseSelected?.title} 
        </TextComponent>
      </div>
      {videoURL !== "" ? (
        <div className="flex h-screen max-h-[calc(40vh)] sm:max-h-[calc(40vh)] md:max-h-[calc(50vh)] lg:max-h-[calc(50vh)] 2xl:max-h-[calc(70vh)] rounded-lg">
          <ReactPlayer
            style={{ borderRadius: "10px", display: "flex" }}
            url={videoURL}
            controls
            width="100%"
            height="100%"
            progressInterval={3000}
            onProgress={handleProgress}
            onEnded={handleEnded}
            onReady={onReady}
            config={{
              file: {
                attributes: {
                  controlsList: "nodownload",
                },
              },
            }}
          />
        </div>
      ) : (
        <div className="flex h-screen max-h-[calc(40vh)] sm:max-h-[calc(40vh)] md:max-h-[calc(50vh)] lg:max-h-[calc(50vh)] 2xl:max-h-[calc(70vh)] rounded-lg bg-gray-300"></div>
      )}
      <Column className="mx-6">
     
      <div
        className={`flex flex-col mt-3 gap-1 `}
      >
        <label className="font-semibold text-xl">{courseSelected?.title}</label>
        <label className="text-sm text-gray-500">
          {courseSelected?.tutor.firstName}
        </label>
      </div>
      <div
        className={`flex flex-col mt-3 gap-2 mb-6`}
      >
        <p className="text-sm w-full max-w-[30rem]">
          {courseSelected?.subtitle}
        </p>
      </div>
      </Column>
    </div>
  );
};

export default VideoContainer;



