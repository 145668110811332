
export const AdviceCardSkeleton = () => {
  return (
    <div 
      className='w-[290px] bg-white border border-gray-300 shadow-md ml-5 rounded-2xl overflow-hidden'
    >
      <div className="px-6 py-4">
        <div className="bg-gray-400 mb-2 animate-pulse w-36 h-6 rounded-xl"></div>
        <div className="bg-gray-400 mb-2 animate-pulse w-44 h-6 rounded-xl mt-2"></div>
      </div>
      <div className="px-6 py-1">
        <div className="animate-pulse bg-gray-400 rounded-xl w-40 h-4 mt-2"></div>
        <div className="animate-pulse bg-gray-400 rounded-xl w-52 h-4 mt-2 mb-5"></div>
      </div>
      <div className='flex justify-center px-6 py-4 bg-gray-200'>
        <div className="animate-pulse bg-gray-400 rounded-xl w-20 h-5"></div>
      </div>
    </div>
  )
}
