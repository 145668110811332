import { Navigate, Outlet, Route, Routes, useNavigate } from "react-router-dom"
import { HomeScreen } from "../pages/home/HomeScreen"
import { CourseScreen } from "../pages/course/CourseScreen"
import { ComprasScreen } from "../pages/compras/ComprasScreen"
import { TodayScreen } from "../pages/hoy/TodayScreen"
import { SocialScreen } from "../pages/social/SocialScreen"
import { ProfileScreen } from "../pages/profile/ProfileScreen"
import { MyCoachScreen } from "../pages/mycoach/MyCoachScreen"
import { NewPasswordScreen } from "../auth/pages/NewPasswordScreen"
import PrivateRoutes from "./PrivateRoutes"
import StatusLoginRoutes from "./StatusLoginRoutes"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { SquadLayout } from "../layout/SquadLayout"
import { RecipeRegister } from "../pages/hoy/RecipeRegister"
import { RecipeList } from "../pages/hoy/components/RecipeList"
import UserProfileScreen from "../pages/profile/UserProfileScreen"
import { EditProfileScreen } from "../pages/profile/EditProfileScreen"
import WatchCourseScreen from "../pages/course/WatchCourseScreen"
import PurchaseConfirmedScreen from "../pages/purchase-confirmed/PurchaseConfirmedScreen"
import MyPurchases from "../pages/compras/MyPurchases"
import ReadBookScreen from "../pages/compras/ReadBookScreen"
import ValidateBookPurchased from "./ValidateBookPurchased"
import ValidateUserHasCourse from "./ValidateUserHasCourse"
import { CoachPlanScreen } from "../pages/mycoach/CoachPlanScreen"
import { FaqsScreen } from "../pages/profile/FaqsScreen"
import { TermsOfService } from "../pages/profile/TermsOfService"
import { PrivacyPage } from "../pages/profile/PrivacyPage"
import { AboutUs } from "../pages/profile/AboutUs"
import TabBar from "../pages/compras/components/TabBarComponent"
import { ToolsScreen } from "../pages/compras/ResumeScreen"
import { BooksScreen } from "../pages/compras/BooksScreen"
import { CoursesScreen } from "../pages/compras/CoursesScreen"
import { CalculatorScreen } from "../pages/compras/CalculatorScreen"
import { OnBoardingScreen } from "../pages/home/onboarding/OnBoardingScreen"
import { Step1Screen } from "../pages/home/onboarding/step1Screen"
import Step2Screen from "../pages/home/onboarding/Step2Screen"
import { Step3Screen } from "../pages/home/onboarding/Step3Screen"
import { StepFinishScreen } from "../pages/home/onboarding/StepFinishScreen"
import { TestCoursScreen } from "../pages/profile/testCourse/TestCourseScreen"

import { RecipiesSystemScreen } from "../pages/compras/RecipiesSystemScreen"
import { RecipeScreen } from "../pages/hoy/RecipeScreen"
import HamletPresentationScreen from '../pages/mycoach/HamletScreen';
import { Row } from "../../core/components/Row"
import { Column } from "../../core/components/Column"
import { TextComponent } from "../../core/components/TextComponent"
import CalculatorInfoScreen from "../pages/compras/components/CalculatorInfoScreen"
import { EditMetricsScreen } from "../pages/compras/EditMetricsScreen"
import { ResultTestCoursScreen } from "../pages/profile/testCourse/ResultTestCourseScreen"
import { BuyCourseMainContainer } from "../pages/course/components/BuyCourse/BuyCourseMainContainer"
import { MyCoursesScreen } from "../pages/course/MyCoursesScreen"
import BookPresentation from "../pages/mycoach/BookPresentation"

export const SquadFitRoutes = () => {

  const { statusLogin, isLoading } = useSelector((state: RootState) => state.auth);
  
  return (
    <Routes>
      <Route element={ <PrivateRoutes /> }>
        { (statusLogin !== 1 && !isLoading) && <Route path="newPass" element={ <NewPasswordScreen /> }/>}
        {
          !isLoading && <>
            <Route element={ <StatusLoginRoutes /> }>
              <Route path="purchase-confirmed" element={<PurchaseConfirmedScreen />} />
              <Route element={<SquadLayout />}>
                <Route path="/" element={ <HomeScreen /> }/>
                <Route path="course" element={ <CourseScreen /> }/>
                <Route path="my-courses" element={ <MyCoursesScreen /> }/>
                <Route path="course/:id" element={ <BuyCourseMainContainer /> }/>
                <Route element={<ValidateUserHasCourse />}>
                  <Route path="watch-course/:id" element={ <WatchCourseScreen /> }/>
                </Route>
                <Route path="compras" element={ <ComprasScreen /> }/>
                <Route path="compras/book-info" element={ <BookPresentation /> }/>
                <Route path="mis-compras" element={ <MyPurchases /> }/>
                <Route path="calculator-info" element={ <CalculatorInfoScreen /> }/>
                <Route path="edit-metrics" element={ <EditMetricsScreen /> }/>
                <Route path="/asesorias/*" element={<MyCoachScreen />} />
                <Route element={ <LayoutData />}>
                  <Route path="/tools" element={<ToolsScreen />} />
                  <Route path="/libros" element={<BooksScreen />} />
                  <Route path="/cursos" element={<CoursesScreen />} />
                  <Route path="redeem" element={ <StepFinishScreen /> }/>
                </Route>
                
                <Route element={ <ValidateBookPurchased /> }>
                  <Route path="read-book/:id" element={ <ReadBookScreen /> }/>
                </Route>
                <Route path="today" element={ <TodayScreen /> }/>
                <Route path="calculator" element={ <CalculatorScreen /> }/>
                <Route path="social" element={ <SocialScreen /> }/>
                <Route path="profile" element={ <ProfileScreen /> }/>
                <Route path="editProfile" element={ <EditProfileScreen /> }/>
                <Route path="faqs" element={ <FaqsScreen /> }/>
                <Route path="terms" element={ <TermsOfService /> }/>
                <Route path="privacy" element={ <PrivacyPage /> }/>
                <Route path="redeem" element={ <StepFinishScreen /> }/>
                <Route path="recipes" element={ <RecipiesSystemScreen /> }/>
                <Route path="about" element={ <AboutUs /> }/>
                <Route path="user-profile/:id" element={ <UserProfileScreen /> }/>
                <Route path="mycoach" element={ <MyCoachScreen /> }/>
                <Route path="test" element={ <TestCoursScreen /> }/>
                <Route path="test-result" element={ <ResultTestCoursScreen /> }/>
                <Route path="coach/:id" element={ <HamletPresentationScreen /> }/>
                <Route path="recipe/:id" element={ <RecipeScreen /> }/>
                <Route path="coach-plan/:id/:type" element={ <CoachPlanScreen /> }/>
                
                <Route path="register" element={ <RecipeRegister /> }/>
                <Route path="addMeal" element={ <RecipeList /> }/>
              </Route>
              <Route element={ <LayoutOnboarding />}>
                  <Route path="step1" element={ <Step1Screen/> }/>
                  <Route path="step2" element={ <Step2Screen/> }/>
                  <Route path="step3" element={ <Step3Screen/> }/>
                  <Route path="stepFinish" element={ <StepFinishScreen/> }/>
                </Route>
            </Route>
            <Route path="/*" element={ <Navigate to="/" /> }/>
          </>
        }
      </Route>
    </Routes>
  )
}

const LayoutData = () => {
  const tabs = [
    { text: 'Libros', path: '/libros' },
    { text: 'Cursos', path: '/cursos' },
    { text: 'Canje', path: '/redeem' },
  ];

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/compras/book-info");
  };

  return (<> 
  <Column
    crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
          <div className="flex flex-col">
              <div
                className="flex min-h-[380px] flex-col gap-6 bg-cover bg-center bg-no-repeat items-start justify-end px-4 pb-10 "
                style={{
                  backgroundPosition: 'right 0px' ,
                  backgroundImage:
                    'linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%), url("https://squatfit.es/wp-content/uploads/DSC_0135-copia2.jpg")', // Reemplaza con tu asset
                }}
              >
                <Column className="gap-2 text-left">
                  <TextComponent variant="h1" className="text-white font-black">
                    Mis compras
                  </TextComponent>
                  <TextComponent variant="h4" className="text-white">
                    Aquí podrás ver todas tus compras y canjes de productos
                  </TextComponent>
                </Column>
              </div>
            </div>
            <Row mainAxisAlignment="center" className="mx-auto p-4">
              <Column className="w-full">
                <TabBar tabs={tabs} />
                <div className="mt-4">
                  <Outlet/>
                </div>
              </Column>
            </Row>
          </div>
      </Column>
            
    
  </>)
};
const LayoutOnboarding = () => {

  return (<Row responsiveCol={true} className='h-full md:h-screen bg-gradient-to-t from-slate-100 to-slate-50 p-8 md:p-0'>
    <OnBoardingScreen />
    <div className="hidden md:block w-1/4"/>
    <main className='px-6 md:px-20 w-full md:w-3/4 p-8 md:p-0 '>
      <Outlet />
    </main>
  </Row>)
};
