import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import CardMyBook from "./CardMyBook";
import CardMyBookSkeleton from "./CardMyBookSkeleton";

const ListBookContainer = () => {
  const { userBooks, is_loading_user_books } = useSelector(
    (state: RootState) => state.book
  );

  return (
    <div
      className="flex flex-col mt-5 overflow-auto overflow-x-hidden"
      style={{ height: "calc(100vh - 140px)" }}
    >
      {is_loading_user_books && userBooks.length === 0 ? (
        Array.from({ length: 4 }).map((_, index) => (
          <CardMyBookSkeleton key={index} />
        ))
      ) : userBooks.length === 0 && !is_loading_user_books ? (
        <div className="flex justify-center mt-10">
          No tienes libros comprados
        </div>
      ) : (
        userBooks.map((book) => <CardMyBook key={book.id} book={book} />)
      )}
    </div>
  );
};

export default ListBookContainer;
