import React from 'react';
import GoalRadioTile from './GoalRatio';
import { AnswerModel } from '../../../../store/interfaces/course/testInterface';


interface GoalRadioTileProps<T> {
  title: string;
  subtitle?: string;
  value: T;
  summary?: boolean;
  answer?: AnswerModel|null;
  groupValue: T;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CustomCardProps<T> extends GoalRadioTileProps<T> {}

const CustomCard = <T,>({ title, subtitle, value, groupValue,summary=false,answer, onChange }: CustomCardProps<T>) => {
  const isSelected = groupValue === value;
  const success=answer?.correct??false;
  if(answer!=null){
    return (
      <div
        className={`p-0.5 rounded-lg shadow-sm ${
          isSelected
            ? success?'bg-green-600': 'bg-red-600'
            : 'border-2 border-gray-300'
        }`}
      >
        <div className="bg-white rounded-lg">
        <label className="flex items-center p-4 border-b border-gray-200 cursor-pointer">
          <input
            type="radio"
            className="form-radio text-orange-500 mr-3 appearance-none h-2 w-2 border border-gray-300 rounded-full checked:bg-orange-500 checked:border-transparent focus:outline-double"
            value={String(value)}
            checked={groupValue === value}
            onChange={onChange}
          />
          <div>
            <div className="text-sm text-start font-medium">{title}</div>
            {subtitle && <div className="text-sm text-start text-gray-500">{subtitle}</div>}
          </div>
        </label>
        </div>
      </div>
    );
  }
  return (
    <div
      className={`p-0.5 rounded-lg shadow-sm ${
        isSelected
          ? 'border-transparent bg-gradient-to-r from-orange-500 to-blue-500'
          : 'border-2 border-gray-300'
      }`}
    >
      <div className="bg-white rounded-lg">
        <GoalRadioTile
          title={title}
          subtitle={subtitle}
          value={value}
          groupValue={groupValue}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default CustomCard;
