import { useEffect, useMemo, useState } from 'react';

export interface FormValidations {
  [key: string]: [(value: any) => boolean, string];
}

interface UseFormResult<T> {
  formState: T;
  setFormState: React.Dispatch<React.SetStateAction<T>>;
  onTextChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onInputChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onResetForm: () => void;
  isFormValid: boolean;
}

export const useForm = <T>(initialForm: T, formValidations: FormValidations = {}): UseFormResult<T> => {
  const [formState, setFormState] = useState<T>(initialForm);
  const [formValidation, setFormValidation] = useState<FormValidations>(formValidations);

  useEffect(() => {
    createValidators();
  }, [formState]);

  const isFormValid = useMemo(() => {
    for (const formValue of Object.keys(formValidation)) {
      if (formValidation[formValue] !== null) return false;
    }
    return true;
  }, [formValidation]);

  const onInputChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = target;
  
    if (name === 'kcal') {
      if (/^\d*$/.test(value)) {
        const newValue = value.slice(0, 4);
        setFormState({
          ...formState,
          [name]: newValue
        });
      }
    } else {
      setFormState({
        ...formState,
        [name]: value
      });
    }
  };

  const onTextChange = ({ target }: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = target;
    const letras = /^[A-Za-z\s,.]*$/;

    if (letras.test(value)) {
      setFormState({
        ...formState,
        [name]: value
      });
    }
  };

  const onResetForm = () => {
    setFormState(initialForm);
  };

  const createValidators = () => {
    const formCheckedValues: FormValidations = {};

    for (const formField of Object.keys(formValidations)) {
      const [fn, errorMessage] = formValidations[formField];

      formCheckedValues[`${formField}Valid`] = [ fn, errorMessage !== null ? errorMessage : '' ];

      setFormValidation(formCheckedValues);
    }
  };

  return {
    formState,
    setFormState,
    onInputChange,
    onTextChange,
    onResetForm,
    isFormValid,
  };
};
