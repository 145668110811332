import { ButtonType } from "../interfaces/ButtonSquadInterface";
import { Loading } from "./Loading";
import { Row } from "./Row";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType: ButtonType;
  label?: string;
  small?: boolean;
  loading?: boolean;
  full?: boolean;
  className?: string;
  onClick?: () => void;
}

export const ButtonSquad = ({
  label = 'Button',
  loading = false,
  small = false,
  full = false,
  className = '',
  buttonType,
  onClick,
  ...props
}: Props) => {
  const baseClasses = "text-white rounded-md";
  const sizeClasses = full?"text-md w-full h-12":small ? "text-xs w-32 h-8" : "text-md w-full md:w-1/2 h-12";
  const loadingText = "Cargando...";
  let classes="";
  switch (buttonType) {
    case ButtonType.BlueFull:
      classes=`bg-blueSquat1 ${sizeClasses} ${className}`;
      break;
    case ButtonType.BlueSmall:
      classes=`bg-blueSquat1 text-xs w-32 h-8`;
      break;
    case ButtonType.OrangeFull:
      classes=`bg-orange ${sizeClasses} ${className}`;
      break;
    case ButtonType.RoseFull:
      classes=`bg-roseSquat ${sizeClasses} ${className}`;
      break;
    case ButtonType.PurpleFull:
      classes=`bg-purpleSquat1 ${sizeClasses} ${className}`;
      break;
    case ButtonType.RoseSmall:
      classes="bg-roseSquat text-xs w-32 h-8";
      break;
    case ButtonType.PurpleSmall:
      classes="bg-purpleSquat1 text-xs w-32 h-8";
      break;
    case ButtonType.Black:
      classes="bg-black text-xs w-32 h-8";
      break;
    case ButtonType.OrangeSmall:
      classes=`bg-orange text-xs w-32 h-8 ${className}`;
      break;
    default:
      break;
  }

  return (
    <button
      className={`${baseClasses} ${classes} px-3 hover:opacity-80 transition-colors duration-300 cursor-pointer`}
      onClick={onClick}
      {...props}
    >
     {loading ? <Row mainAxisAlignment="center"><Loading  /></Row> : label}
    </button>
  );
};
