import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { Convert } from "../../interfaces/coach/docsAdviceInterface";

// Get Advicers
export const getAllAdvicers = createAsyncThunk(
  "advice/all-advisers",
  async () => {
    const resp = await squatFitApi.get("/advice/all-advisers");

    return resp.data;
  }
);

// Get Advice by user
export const getAdviceByUser = createAsyncThunk(
  "advice/by-user",
  async () => {
    const resp = await squatFitApi.get("/advice/by-user");

    return resp.data;
  }
);

// Get all subscriptions
export const getAllSubs = createAsyncThunk(
  "advice/all-suscriptions",
  async (adviserId: string) => {
    const resp = await squatFitApi.get("/advice/all-suscriptions", {params: {adviser_id: adviserId}});

    return resp.data;
  }
);

// Create paypal sub
export const createPaypalSub = createAsyncThunk(
  "advice/create-suscription-paypal",
  async({subPlanId, type}:{subPlanId: string, type:string}) => {
    const resp = await squatFitApi.post("/advice/create-suscription-paypal", { suscription_plan_id: subPlanId,type});

    return resp.data;
  }
);

// Create stripe sub
export const createStripeSub = createAsyncThunk(
  "advice/create-suscription-stripe",
  async({subPlanId, type}:{subPlanId: string, type:string}) => {
    const resp = await squatFitApi.post("/advice/create-suscription-stripe", {suscription_plan_id: subPlanId,type});

    return resp.data;
  }
);

// Upload an image for your coach
export const uploadAdviceImage = createAsyncThunk(
  "advice/upload-image",
  async({file, isPrivate,request_progress_id,periodId}: {file: File, isPrivate: string,request_progress_id:string, periodId:string}) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("private", isPrivate);
    formData.append("request_progress_id", request_progress_id);
    await squatFitApi.post("/advice/upload-image", formData);

    return {periodId};
  }
);

export const getAdviceDocs = createAsyncThunk(
  "advice/get-docs",
  async() => {
    const resp = await squatFitApi.get("/advice/my-docs");
    return resp.data;
  }
);
export const onStartForm = createAsyncThunk(
  "advice/start-form",
  async() => {
    const resp = await squatFitApi.post("/advice/status-advice",{
      "status": "Preparando"
    });
    return resp.data;
  }
);
export const onFinishMonth = createAsyncThunk(
  "advice/finish-month",
  async({id,periodId}:{id:string,periodId:string}) => {
    await squatFitApi.post(`/advice/form-delivered?form_id=${id}`);
    return {id,periodId};
  }
);