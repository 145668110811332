import React from 'react';

interface CustomColumnProps {
  children: React.ReactNode;
  className?: string;
  mainAxisAlignment?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
  crossAxisAlignment?: 'start' | 'center' | 'end' | 'stretch';
}
type ColumnProps = CustomColumnProps & React.HTMLAttributes<HTMLDivElement>;

export const Column = ({
  children,
  className = '',
  mainAxisAlignment = 'start',
  crossAxisAlignment = 'stretch',
  ...props
}:ColumnProps) => {
  const mainAxisClasses = {
    start: 'justify-start',
    center: 'justify-center',
    end: 'justify-end',
    between: 'justify-between',
    around: 'justify-around',
    evenly: 'justify-evenly',
  };

  const crossAxisClasses = {
    start: 'items-start',
    center: 'items-center',
    end: 'items-end',
    stretch: 'items-stretch',
  };

  const mainAxisClass = mainAxisClasses[mainAxisAlignment];
  const crossAxisClass = crossAxisClasses[crossAxisAlignment];

  return (
    <div className={`flex flex-col ${mainAxisClass} ${crossAxisClass} ${className}`} {...props}>
      {children}
    </div>
  );
};

