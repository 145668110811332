import { createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import { Redeem } from "../../interfaces/book/redeem";

export const getAllRedeems = createAsyncThunk("redeem/getAllRedeems", async () => {
  const resp = await squatFitApi.get<Redeem[]>("/book/redeem-codes-by-user");
  return resp.data;
});

export const redeemCode = createAsyncThunk(
  "redeem/redeemCode",
  async (code:string) => {
    const resp = await squatFitApi.get<void>(`/book/redeem-code`, {
      params: { code: code},
    });

    return resp.data;
  }
);

