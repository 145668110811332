import React from 'react';

interface PreparationWidgetProps {
    text: string;
    bottomLineColor: string;
}

const PreparationWidget: React.FC<PreparationWidgetProps> = ({ text, bottomLineColor }) => {
    return (
        <div className="flex items-start">
            <div className="flex flex-col items-center">
                <div className="w-0.5 h-3 bg-[#CCD6EB]"></div>
                <div className="h-0.5"></div>
                <div className="w-2 h-2 bg-black rounded-full"></div>
                {bottomLineColor !== 'transparent' && (
                    <>
                        <div className="h-0.5"></div>
                        <div className="w-0.5 h-20" style={{ backgroundColor: bottomLineColor }}></div>
                    </>
                )}
            </div>
            <div className="w-4"></div>
            <div className="flex-grow">
                <div className="text-left text-lg mt-1 h5">
                    {text.split('\n').map((line, i) => (
                        <React.Fragment key={i}>
                            {line}
                            {i !== text.split('\n').length - 1 && <br />}
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PreparationWidget;
