
interface CardProps {
  imageSrc: string;
  name: string;
  title: string;
  reviewText: string;
  rating: string;
}

export const ReviewCard = ({ imageSrc, name, title, reviewText, rating }: CardProps) => {
  return (
    <div className="max-w-sm p-8 bg-gray-100 rounded-lg shadow-md text-center">
      <img
        src={imageSrc}
        alt={name}
        className="w-24 h-24 mx-auto rounded-full object-cover"
      />

      <div className="flex items-center justify-center mt-4">
        <div className="textfont-medium">{rating}</div>
        <img src="/landing/Reviews 5 stars.png" className="w-40" />
      </div>

      <h3 className="mt-4 text-lg font-semibold">{name}</h3>

      <p className="mt-1 font-bold">{title}</p>

      <p className="mt-2 text-gray-700 text-sm">{reviewText}</p>
    </div>
  );
};
