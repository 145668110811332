import { toast } from "react-toastify";

export type AlertType = "success" | "warn" | "error" | "info";

interface AlertProps {
  message: string,
  type: AlertType
}

export const useAlert = () => {
  const showAlert = ({ message, type }: AlertProps) => {
    toast[type](message, {
      position: "top-right",
      theme: "colored",
      autoClose: 4000,
      toastId: message
    });
  
    toast.update(message, { autoClose: 4000 });
  }

  return { showAlert };
}
