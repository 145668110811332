interface UserImageProps {
  profile_picture?: string;
}

export const UserImage = ({ profile_picture }: UserImageProps) => {
  return (
    <div className="h-24 w-24 md:h-36 md:w-36">
      {profile_picture ? (
        <img
          src={profile_picture}
          alt="profile"
          className="h-24 w-24 md:h-36 md:w-36  rounded-full object-cover"
        />
      ) : (
        <img src="https://biiainsurance.com/wp-content/uploads/2015/05/no-image.jpg" className="h-24 w-24 md:h-36 md:w-36  rounded-full object-cover" />
      )}
    </div>
  );
};
