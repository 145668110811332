import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateTime } from "luxon";
import { AppDispatch, RootState } from "../../../store/store";
import {
  addMessageCoach,
  UpdateMessageViewedCoach,
} from "../../../socket-clients/server";
import {
  getCoachHistoryMessage,
  getCoachHistoryOldestMessage,
} from "../../../store/slices/floating-chat/thunks";
import MessageSentedLabel from "../../../squad-fit/pages/social/components/chat/MessageSentedLabel";
import OtherMessage from "../../../squad-fit/pages/social/components/chat/OtherMessage";
import CircularProgress from "../../../squad-fit/components/CircularProgress";
import ChatInputBox from "../../../squad-fit/pages/social/components/chat/ChatInputBox";
import OwnMessage from "../../../squad-fit/pages/social/components/chat/OwnMessage";
import HeaderFloatingChat from "./HeaderFloatingChat";

const FloatingChat = () => {
  const dispatch: AppDispatch = useDispatch();
  const { uid: user_id } = useSelector((state: RootState) => state.auth);
  const { subData, advisers } = useSelector((state: RootState) => state.coach);
  const { historyMessage, is_loading_history_message } = useSelector(
    (state: RootState) => state.floatingchat
  );

  const [newMessage, setNewMessage] = useState<string>("");
  const [buffer, setBuffer] = useState<string[]>([]);
  const scrollMessages = useRef<HTMLDivElement | null>(null);
  const [isOnTop, setIsOnTop] = useState(false);
  const [isOnBottom, setIsOnBottom] = useState(false);

  const coach_id = subData.adviser_id;
  const chat_id =
    user_id > coach_id ? `${user_id}-${coach_id}` : `${coach_id}-${user_id}`;

  useEffect(() => {
    dispatch(getCoachHistoryMessage({ chat_id }));
  }, []);

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (scrollMessages.current) {
        if (
          scrollMessages.current !== null &&
          !isOnBottom &&
          historyMessage.length > 0 &&
          !is_loading_history_message
        ) {
          scrollMessages.current.scrollTop =
            scrollMessages.current.scrollHeight -
            scrollMessages.current.clientHeight;
          setIsOnBottom(true);
        }
        if (
          scrollMessages.current.scrollTop === 0 &&
          !isOnTop &&
          historyMessage.length > 0
        ) {
          dispatch(
            getCoachHistoryOldestMessage({
              chat_id,
              oldestMessageSentAt: historyMessage[0].sended_at,
            })
          ).then((res) => {
            if (
              res.meta.requestStatus === "fulfilled" &&
              historyMessage.some(
                (message) =>
                  message.id === res.payload[res.payload.length - 1]?.id
              )
            ) {
              setIsOnTop(true);
              return;
            }
            if (
              res.meta.requestStatus === "fulfilled" &&
              res.payload.length === 0
            ) {
              setIsOnTop(true);
            }
            if (scrollMessages.current && res.payload.length > 0) {
              scrollMessages.current.scrollTop =
                scrollMessages.current.clientHeight;
            }
          });
        }
      }
    };

    scrollMessages.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollMessages.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isOnTop, isOnBottom, is_loading_history_message]);

  useLayoutEffect(() => {
    if (scrollMessages.current !== null) {
      if (
        scrollMessages.current !== null &&
        !isOnBottom &&
        historyMessage.length > 0 &&
        !is_loading_history_message
      ) {
        scrollMessages.current.scrollTop =
          scrollMessages.current.scrollHeight -
          scrollMessages.current.clientHeight;
      }
    }
  }, [is_loading_history_message]);

  useEffect(() => {
    if (
      historyMessage.some((message) => message.chat_id === chat_id) &&
      historyMessage.filter(
        (message) => !message.read && message.from !== user_id
      ).length > 0
    ) {
      const timer = setTimeout(() => {
        setBuffer(
          historyMessage
            .filter((message) => !message.read && message.from !== user_id)
            .map((message) => message.message_id)
        );
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [historyMessage]);

  useEffect(() => {
    const interval = setTimeout(() => {
      if (buffer.length > 0) {
        UpdateMessageViewedCoach(buffer, chat_id);
        setBuffer([]);
      }
    }, 2000);

    return () => clearTimeout(interval);
  }, [buffer]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewMessage(e.target.value);
  };

  const sendMsg = () => {
    addMessageCoach(coach_id, chat_id, newMessage, "text");
    setNewMessage("");
  };

  const diff = (prevMessage: any, currentMessage: any) => {
    const diffHours = DateTime.fromJSDate(new Date(currentMessage.sended_at))
      .diff(DateTime.fromJSDate(new Date(prevMessage.sended_at)), ["hours"])
      .toObject().hours;

    return diffHours !== undefined ? diffHours >= 1 : false;
  };

  return (
    <div className="flex flex-col divide-y h-full w-full">
      <HeaderFloatingChat name={subData.adviser_firstName} profileImage={advisers.find((adviser) => adviser.id === subData.adviser_id)?.profile_picture || ""} />
      <div className="flex flex-col justify-between h-full w-full">
      <div className="basis-12/12 h-full max-h-[380px] max-[430px]:max-h-[380px]">
      <div
            ref={scrollMessages}
            className="flex flex-col w-full h-full p-4"
            style={{
              gap: 5,
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {!is_loading_history_message || historyMessage.length > 0 ? (
              <>
                {is_loading_history_message ? (
                  <div className="flex justify-center py-2">
                    <div
                      className="w-5 h-5 border-2 border-blue-500 rounded-full animate-spin"
                      style={{ borderTopColor: "transparent" }}
                    ></div>
                  </div>
                ) : null}
                {historyMessage.map((message, index) =>
                  message.to !== user_id ? (
                    <div key={index}>
                      {index === 0 ? (
                        <MessageSentedLabel sent_at={message.sended_at} />
                      ) : null}
                      {diff(
                        index === 0
                          ? historyMessage[0]
                          : historyMessage[index - 1],
                        message
                      ) ? (
                        <MessageSentedLabel sent_at={message.sended_at} />
                      ) : null}
                      <OwnMessage
                        id={message.message_id}
                        message={message.message}
                        sent_at={message.sended_at}
                        is_viewed={message.read}
                      />
                    </div>
                  ) : (
                    <div key={index}>
                      {index === 0 ? (
                        <MessageSentedLabel sent_at={message.sended_at} />
                      ) : null}
                      {diff(
                        index === 0
                          ? historyMessage[0]
                          : historyMessage[index - 1],
                        message
                      ) ? (
                        <MessageSentedLabel sent_at={message.sended_at} />
                      ) : null}
                      <OtherMessage
                        id={message.message_id}
                        message={message.message}
                        sent_at={message.sended_at}
                      />
                    </div>
                  )
                )}
              </>
            ) : (
              <div className="flex justify-center">
                <CircularProgress />
              </div>
            )}
          </div>
        </div>
        <div className="basis-1/12 p-1 flex flex-row">
          <ChatInputBox
            value={newMessage}
            onChange={handleChange}
            sendMsg={sendMsg}
          />
        </div>
      </div>
    </div>
  );
};

export default FloatingChat;
