interface InicioInterface{
    variant:'normal'|'selected'
}
export const InicioIcon = ({variant}:InicioInterface) => {
    if(variant=='selected'){
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.994" fillRule="evenodd" clipRule="evenodd" d="M7.5002 0.300391C8.3002 0.200391 9.0002 0.400391 9.6002 0.900391C11.3002 2.30039 12.9002 3.70039 14.5002 5.20039C14.7002 5.40039 14.8002 5.70039 14.9002 6.00039C14.9002 8.70039 14.9002 11.4004 14.9002 14.0004C14.6002 14.9004 14.0002 15.4004 13.1002 15.7004C12.2002 15.7004 11.2002 15.7004 10.3002 15.7004C9.7002 15.6004 9.4002 15.2004 9.2002 14.7004C9.2002 13.3004 9.2002 11.9004 9.2002 10.5004C8.4002 10.5004 7.7002 10.5004 6.9002 10.5004C6.9002 11.9004 6.9002 13.3004 6.9002 14.7004C6.7002 15.3004 6.4002 15.6004 5.8002 15.7004C4.9002 15.7004 3.9002 15.7004 3.0002 15.7004C2.0002 15.4004 1.4002 14.8004 1.2002 14.0004C1.2002 11.4004 1.2002 8.90039 1.2002 6.30039C1.3002 6.00039 1.4002 5.80039 1.5002 5.50039C3.2002 3.90039 4.80019 2.30039 6.60019 0.800391C6.9002 0.600391 7.2002 0.500391 7.5002 0.300391Z" fill="#FF680A"/>
        </svg>
        
        
    }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.969" fillRule="evenodd" clipRule="evenodd" d="M7.50039 0.599702C8.20039 0.499702 8.90039 0.699702 9.50039 1.0997C11.1004 2.4997 12.7004 3.7997 14.3004 5.1997C14.5004 5.3997 14.6004 5.6997 14.6004 5.9997C14.6004 8.5997 14.6004 11.1997 14.6004 13.7997C14.3004 14.6997 13.8004 15.1997 12.9004 15.3997C12.0004 15.3997 11.1004 15.3997 10.2004 15.3997C9.60039 15.2997 9.30039 14.8997 9.10039 14.3997C9.10039 12.9997 9.10039 11.6997 9.10039 10.2997C8.40039 10.2997 7.60039 10.2997 6.90039 10.2997C6.90039 11.6997 6.90039 12.9997 6.90039 14.3997C6.70039 14.8997 6.40039 15.2997 5.80039 15.3997C4.90039 15.3997 4.00039 15.3997 3.10039 15.3997C2.20039 15.1997 1.70039 14.5997 1.40039 13.7997C1.40039 11.2997 1.40039 8.7997 1.40039 6.2997C1.50039 5.9997 1.60039 5.7997 1.70039 5.4997C3.30039 4.0997 4.90039 2.4997 6.60039 0.999702C6.80039 0.799702 7.10039 0.699702 7.50039 0.599702ZM7.80039 2.0997C8.10039 2.0997 8.30039 2.1997 8.50039 2.2997C9.90039 3.4997 11.2004 4.5997 12.6004 5.7997C12.8004 5.9997 12.9004 6.0997 13.1004 6.2997C13.1004 8.6997 13.1004 10.9997 13.1004 13.3997C13.0004 13.5997 12.8004 13.7997 12.6004 13.8997C11.9004 13.8997 11.3004 13.8997 10.6004 13.8997C10.6004 12.2997 10.6004 10.6997 10.5004 9.0997C10.4004 8.8997 10.2004 8.7997 9.90039 8.6997C8.60039 8.6997 7.30039 8.6997 6.00039 8.6997C5.70039 8.6997 5.60039 8.8997 5.40039 9.0997C5.30039 10.6997 5.30039 12.2997 5.30039 13.8997C4.60039 13.8997 4.00039 13.8997 3.30039 13.8997C3.10039 13.7997 2.90039 13.6997 2.80039 13.3997C2.80039 11.0997 2.80039 8.8997 2.80039 6.5997C4.40039 5.0997 6.10039 3.5997 7.80039 2.0997Z" fill="#FF680A"/>
    </svg>

  )
}
