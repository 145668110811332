import { useEffect, useState } from "react";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { AppDispatch, RootState } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  getAdviceByUser,
  getAllAdvicers,
  getAllSubs,
} from "../../../store/slices/coach/thunks";
import { Coach } from "../../../store/interfaces/coach/interfaceCoach";
import {
  cleanPlanSelected,
  cleanSubs,
  onSelectCoach,
} from "../../../store/slices/coach/CoachSlice";
import { CoachScreen } from "./CoachScreen";
import { AdviceCoachSkeleton } from "./components/AdviceCoachSkeleton";
import { Column } from "../../../core/components/Column";
import { TextComponent } from "../../../core/components/TextComponent";
import { Row } from "../../../core/components/Row";
import { coachs } from "../../../core/constants/constatns";

export const MyCoachScreen = () => {
  const { advisers, subData, is_loading_advice_coach } = useSelector(
    (state: RootState) => state.coach
  );
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [showCoachScreen, setShowCoachScreen] = useState(false);
  const [showAdvicersComponents, setShowAdvicersComponents] = useState(false);
  const toCoach=(id:string)=>{
    navigate(`/coach/${id}`);
  }
  useEffect(() => {
    if (subData.adviser_id === "") {
      dispatch(getAdviceByUser()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          if (res.payload !== null || res.payload !== undefined) {
            setShowCoachScreen(true);
          }
        } else {
          setShowAdvicersComponents(true);
        }
      });
    } else {
      setShowCoachScreen(true);
      setShowAdvicersComponents(false);
    }
    if (advisers.length === 0) {
      dispatch(getAllAdvicers());
    }
    dispatch(cleanPlanSelected());
    dispatch(cleanSubs());
  }, []);

  const handleClick = (coach: Coach) => {
    dispatch(onSelectCoach(coach));
    dispatch(getAllSubs(coach.id));
  };

  return (
    <div>
      {showCoachScreen && <CoachScreen />}
      {showAdvicersComponents && (
        <Column className='pb-16'>
          <div className='bg-gray-900 px-12 py-6'>
            <TextComponent variant="h1" className="font-bold text-white">Asesorías</TextComponent>
          </div>
          <Column className="px-12 py-6 w-full md:w-3/4">
            {is_loading_advice_coach === true && advisers.length === 0
              ? Array.from({ length: 2 }).map((_, index) => (
                <AdviceCoachSkeleton key={index} />
              ))
              : coachs.map((coach, index) => (
                <Row key={index} responsiveCol={true} className="gap-4 py-6">
                    
                    <div className="rounded-full min-w-32 min-h-32 max-w-32 max-h-32">
                      <img src={coach.profile_picture} className="object-cover rounded-full min-w-32 min-h-32 max-w-32 max-h-32"/>
                    </div>
                    <Column className="flex flex-col justify-center">
                      <TextComponent variant="h2" className="font-bold leading-tight">
                        {coach.firstName} {coach.lastName}
                      </TextComponent>
                      <TextComponent className="text-fadedAsh text-base font-normal leading-normal">
                        {coach.homeDescription}
                      </TextComponent>
                      <ButtonSquad buttonType={ButtonType.BlueSmall} label={coach.homeButton} onClick={()=>toCoach(coach.id)} />
                    </Column>
                  </Row>
              )) }
          </Column>
        </Column>
      )}
    </div>
  );
};
