import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Book, ReadBook, UserBook } from "../../interfaces/book/interfaceBook";
import { Redeem } from "../../interfaces/book/redeem";
import { getAllRedeems, redeemCode } from "./thunks";

interface RedeemState {
  redeems: Redeem[];
  loading: boolean;
}

const initialState: RedeemState = {
  redeems: [],
  loading: false,
};

const redeemSice = createSlice({
  name: "redeem",
  initialState,
  reducers: {
    cleanRedeems: (state) => {
      state.redeems = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRedeems.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getAllRedeems.fulfilled,
        (state, action: PayloadAction<Redeem[]>) => {
          state.redeems = action.payload;
          state.loading = false;
        }
      ).addCase(getAllRedeems.rejected, (state) => {
        state.loading = false;
      })
      .addCase(redeemCode.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        redeemCode.fulfilled,
        (state) => {
          state.loading = false;
        }
      ).addCase(redeemCode.rejected, (state) => {
        state.loading = false;
      })
    ;
  },
});

export const {
  cleanRedeems,
} = redeemSice.actions;
export default redeemSice.reducer;
