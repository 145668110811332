import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { searchFriends } from "../../store/slices/social/thunks";

const useSearch = () => {
  const dispatch: AppDispatch = useDispatch();
  const { uid } = useSelector((state: RootState) => state.auth);

  const searchFriendsDispatch = (search: string) => {
    dispatch(searchFriends({ search, user_id: uid }));
  };

  const handleSearch = (search: string) => {
    searchFriendsDispatch(search);
  };

  return { handleSearch };
};

export default useSearch;
