import { InitialPage } from "./components/landing/InitialPage"
import { Features } from "./components/landing/Feauters"
import { Testimonials } from "./components/landing/Testimonials"
import { CallToAction } from "./components/landing/CallToAction"
import { Footer } from "./components/landing/Footers"
import { PromoSection } from "./components/landing/PromoSection"
import { Brands } from "./components/landing/Brands"
import { ModalSimple } from "../../../core/components/Modal"
import { useState } from "react"
import { TextComponent } from "../../../core/components/TextComponent"
import { ButtonSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { useNavigate } from "react-router-dom"

export const NewLandingScreen = () => {
  const [isOpenOptions, setIsOpenOptions] = useState<boolean>(false);
  const handleClose = () => {
    setIsOpenOptions(false);
  }
  const handleOpen = () => {
    setIsOpenOptions(!isOpenOptions);
  }
  const navigate = useNavigate();
  return (
    <>
    <ModalSimple
        title={"Ventajas de registrarte en Squat Fit:"}
        variant={"primary"}
        actionExecuted={isOpenOptions}
        onClose={handleClose}
      >
        <TextComponent variant="h4">
          Crear tu cuenta es <span className="text-orange font-semibold">GRATIS</span> y te da acceso a:
        </TextComponent>
        <TextComponent variant="h4">
          <span className="text-blueSquat1 font-semibold">Agenda</span> para contar tus comidas, macros y calorías.
        </TextComponent>
        <TextComponent variant="h4">
          <span className="text-blueSquat1 font-semibold">Calculadora</span> de tus calorías y macros diarios.
        </TextComponent>
        <TextComponent variant="h4">
          Sincronización de tus <span className="text-blueSquat1 font-semibold">pasos</span> diarios.
        </TextComponent>
        <TextComponent variant="h4">
          Una <span className="text-blueSquat1 font-semibold">comunidad</span> con la que compartir y aprender.
        </TextComponent>
        <TextComponent variant="h4">
          Biblioteca de los mejores <span className="text-blueSquat1 font-semibold">libros</span> de María o Hamlet.
        </TextComponent>
        <TextComponent variant="h4">
          <span className="text-blueSquat1 font-semibold">Cursos</span>, exámenes y apuntes para un mejor aprendizaje.
        </TextComponent>
        <TextComponent variant="h4">
          <span className="text-blueSquat1 font-semibold">Chat</span> para comunicarte con María o Hamlet.
        </TextComponent>
        <ButtonSquad buttonType={ButtonType.BlueFull} label="Registrarme" onClick={()=>navigate('auth/register')} />
    </ModalSimple>
      <div className="font-sans antialiased text-gray-900 overflow-x-hidden">
        <InitialPage />
        <Features />
        <PromoSection handleOpen={handleOpen} />
        <Testimonials />
        <CallToAction handleOpen={handleOpen} />
        <Brands />
        <Footer />
      </div>
    </>
  )
}
