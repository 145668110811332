import BackgroundBottomRightImage from "./background_bottom_right.png";

const BackgroundBottomRight = () => {
  return (
    <div className="absolute right-0 bottom-0">
      <img src={BackgroundBottomRightImage} className="w-80" />
    </div>
  );
};

export default BackgroundBottomRight;
