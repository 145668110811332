const NoChats = () => {
  return (
    <div className="flex flex-col justify-center items-center" style={{ height: "100%" }}>
      <label className="text-center text-base font-medium text-gray-600">No hay mensajes</label>
      <label className="text-center text-sm text-gray-400">Envía mensajes privados directamente con tus amigos.</label>
    </div>
  );
};

export default NoChats;
