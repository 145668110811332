import { TextComponent } from "../../../../../core/components/TextComponent";

interface FeauterProps {
  icon: string
  title: string
  description: string
}

export const Features = () => {
  return (
    <section className="bg-orange-100 py-36">
      <div className="container mx-auto grid grid-cols-1 lg:grid-cols-3 gap-8">
        <Feature 
          icon="1.nutrition copia"
          title="Agenda para contar tus macros y calorías"
          description="Planifica tus comidas y sigue tu progreso."
        />
        <Feature 
          icon="2.running-shoes copia"
          title="Herramienta que sigue tus pasos diarios"
          description="Monitorea tu actividad diaria fácilmente."
        />
        <Feature 
          icon="3.speech-bubble copia"
          title="Una comunidad donde compartir y aprender"
          description="Conéctate con personas que comparten tus metas."
        />
      </div>
    </section>
  );
}

function Feature({ icon, title, description }: FeauterProps) {
  return (
    <div className="text-center">
      <div className="flex justify-center text-orange-500 text-4xl mb-4">
        <img src={`/landing/${icon}.png`} className="w-32" />
      </div>
      <TextComponent variant="h1" className="text-xl font-bold mb-2">{title}</TextComponent>
      <p className="text-gray-600">{description}</p>
    </div>
  );
}

