import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import useScreen from "../../hooks/useScreen";
import MyCourses from "./components/MyCourses/MyCourses";
import RecommendedCourses from "./components/RecommendedCourses/RecommendedCourses";
import { useEffect } from "react";
import { getAllCourses, getCourseByUser } from "../../../store/slices/course/thunks";
import { Column } from "../../../core/components/Column";

export const CourseScreen = () => {

  const dispatch: AppDispatch = useDispatch();
  const { allCourses, courseByUser } = useSelector((state: RootState) => state.course);

  useEffect(() => {
    if (allCourses.length > 0) return;
    dispatch(getAllCourses());
  }, [allCourses]);

  useEffect(() => {
    if (courseByUser.length > 0) return;
    dispatch(getCourseByUser());
  }, [courseByUser]);

  return (
    <Column
      className={`flex flex-col`}
    >
      <MyCourses />
      <RecommendedCourses />
    </Column>
  );
};
