import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch, RootState } from "../../../store/store";
import { getFriends, getChatList } from "../../../store/slices/social/thunks";

import ListChatsComponent from "./components/chat/ListChatsComponent";
import ChatContainer from "./components/chat/ChatContainer";
import PostContainer from "./components/PostContainer";
import useScreen from "../../hooks/useScreen";
import { setShowListChats } from "../../../store/slices/social/socialSlice";
import { startGettingPosts } from '../../../store/slices/post/thunks';
import { Column } from "../../../core/components/Column";

export const SocialScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const { isCellphoneScreen, isSmallScreen } = useScreen();
  const [page, setPage] = useState<number>(1);

  const { chatSelected, showListChats } = useSelector(
    (state: RootState) => state.social
  );

  useEffect(() => {
    dispatch(getChatList());
    dispatch(getFriends());
  }, []);

  useEffect(() => {
    dispatch(startGettingPosts({limit: '10', page: page.toString()}))
  }, [])
  

  useEffect(() => {
    if (!isSmallScreen) dispatch(setShowListChats(false));
  }, [isSmallScreen]);

  return (
    <Column crossAxisAlignment="center" className="relative w-full min-h-screen overflow-x-hidden ">
    <div
      className={`flex w-3/4  flex-row xs:justify-between 2xl:justify-evenly pt-${
        isCellphoneScreen ? "5" : "3"
      } divide-x`}
    >
      {chatSelected !== null ? (
        <div
          className={`basis-${
            isSmallScreen ? "full" : "4/6"
          } xl:basis-4/6 2xl:basis-3/6 px-8`}
        >
          <ChatContainer />
        </div>
      ) : (
        <div
          className={`w-full basis-${
            isSmallScreen ? "full" : "4/6"
          } xl:basis-4/6 2xl:basis-5/6`}
        >
          {!isCellphoneScreen && !isSmallScreen || !showListChats ? (
            <PostContainer page={page} setPage={setPage} />
          ) : (
            <div className="px-3 sm:px-5 min-w-[22rem]">
              <ListChatsComponent />
            </div>
          )}
        </div>
      )}
      <div className={`lg:basis-2/6 px-5 ${isSmallScreen ? "hidden" : ""}`}>
        <ListChatsComponent />
      </div>
    </div>
    </Column>
  );
};
