import React, { useState } from "react";
import CustomCard from "../../home/components/CustomCard";
import { Column } from "../../../../core/components/Column";
import { TextComponent } from "../../../../core/components/TextComponent";
import { SizedBox } from "../../../../core/components/SizedBox";
import { BarComponent } from "../../../../core/components/BarComponent";
import { BlueButton } from "../../../../core/components/ButtonBlue";
import { Row } from "../../../../core/components/Row";
import { AppDispatch, RootState } from "../../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { saveUserAnswer } from "../../../../store/slices/course/thunks";
import { useAlert } from "../../../hooks/useAlert";

export const TestCoursScreen = () => {
    const { tests, savedAnswers } = useSelector((state: RootState) => state.course);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: string }>({});
    const navigate = useNavigate();
    const dispatch: AppDispatch = useDispatch();
    const { showAlert } = useAlert();
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const answerId = event.target.value;
        const currentQuestionId = tests?.questions[currentQuestionIndex]?.id;

        if (currentQuestionId) {
            setSelectedAnswers(prev => ({
                ...prev,
                [currentQuestionId]: answerId,
            }));
        }
    };

    const handleNextQuestion = () => {
        if (tests && selectedAnswers[tests.questions[currentQuestionIndex]?.id]) {
            setCurrentQuestionIndex(prevIndex => Math.min(prevIndex + 1, tests.questions.length - 1));
        } else {
            alert("Por favor selecciona una opción antes de continuar.");
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
        }
    };

    const handleFinish = () => {
        if (tests) {
            const formattedAnswers = Object.entries(selectedAnswers).map(([questionId, answerId]) => {
                const answer = tests.questions.find(q => q.id === questionId)?.answers.find(a => a.id === answerId);
                return {
                    question_id: questionId,
                    answer_id: answerId,
                    answer_text: answer ? answer.answer : "",
                };
            });
            dispatch(
                saveUserAnswer({id:tests.id,resp:formattedAnswers})).then( (response) => {
                if (response.meta.requestStatus === 'fulfilled') {
                  showAlert({
                    message: 'Test completado',
                    type: "success"
                  });
                  navigate('/test-result')
                }else{
                    showAlert({
                        message: 'Ha ocurrido un error, inténtalo más tarde',
                        type: "error"
                      });
                }
              })
        } else {
            alert("Por favor selecciona una opción antes de finalizar.");
        }
    };

    const currentQuestion = tests?.questions[currentQuestionIndex];

    return (
        <Column className="pl-16 pr-96 py-10">
            <TextComponent variant="h1" className="font-semibold">
                Pérdida de grasa
            </TextComponent>
            <SizedBox className="h-5" />
            <BarComponent 
                label={`Pregunta ${currentQuestionIndex + 1}/${tests?.questions.length}`} 
                percent={((currentQuestionIndex + 1) * 100) / (tests?.questions.length ?? 1)} 
            />
            <SizedBox className="h-8" />
            <TextComponent variant="h2" className="font-semibold">
                Test del curso
            </TextComponent>
            <SizedBox className="h-2" />
            <TextComponent variant="h5">
                Este test tiene {tests?.questions.length} preguntas y solo podrás retomarlo una sola vez.
            </TextComponent>
            <SizedBox className="h-10" />
            {currentQuestion ? (
                <div key={currentQuestion.id}>
                    <TextComponent variant="h3" className="font-semibold">
                        {currentQuestion.question}
                    </TextComponent>
                    <SizedBox className="h-5" />
                    <Column style={{ width: 480 }}>
                        {currentQuestion.answers.map((answer) => (
                            <div key={answer.id} className="mb-4">
                                <CustomCard
                                    title={answer.answer}
                                    value={answer.id}
                                    groupValue={selectedAnswers[currentQuestion.id] || null}
                                    onChange={handleChange}
                                />
                            </div>
                        ))}
                    </Column>
                    <SizedBox className="h-5" />
                </div>
            ) : (
                <TextComponent variant="h3">Cargando...</TextComponent>
            )}
            <Row className="gap-6">
                    <BlueButton 
                        label="Anterior" 
                        onClick={handlePreviousQuestion} 
                        disabled={currentQuestionIndex === 0}
                    />
                    <div>
                        <BlueButton 
                            label={currentQuestionIndex < (tests?.questions.length ?? 0) - 1 ? "Siguiente" : "Finalizar"} 
                            onClick={currentQuestionIndex < (tests?.questions.length ?? 0) - 1 ? handleNextQuestion : handleFinish} 
                            disabled={!currentQuestion || !selectedAnswers[currentQuestion.id]}
                        />
                    </div>
                </Row>
        </Column>
    );
    
};
