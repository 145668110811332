import React, { useState } from 'react';
import { TextComponent } from './TextComponent';

interface CustomInputProps {
  label?: string;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  obscureText?: boolean;
  small?: boolean;
  required?: boolean;
  hintText?: string;
  align?: 'left' | 'center' | 'right';
  className?: string;
  boxLabel?: string; // Nuevo parámetro
  [x: string]: any;
}

export const CustomInput = ({
  label,
  value,
  comunaEditable,
  onChange,
  onBlur,
  hintText,
  obscureText = false,
  small = false,
  required = false,
  align = 'left',
  className = '',
  boxLabel, // Nuevo parámetro
  ...props
}: CustomInputProps) => {
  const [isObscured, setIsObscured] = useState(obscureText);

  const toggleObscureText = () => {
    setIsObscured(!isObscured);
  };

  let alignClass = '';

  switch (align) {
    case 'left':
      alignClass = 'text-left';
      break;
    case 'center':
      alignClass = 'text-center';
      break;
    case 'right':
      alignClass = 'text-right';
      break;
    default:
      alignClass = 'text-left';
  }

  return (
    <div className={`flex flex-col ${className} ${small ? '' : 'w-full'}`} {...props}>
      {
        label?
        <TextComponent variant='h5'>
          {label}
          {required && <span className="text-red-500">*</span>}
        </TextComponent>
        :<></>
      }
      <div className="flex items-center">
        {boxLabel && (
          <div className={`${small ? 'py-2' : 'py-4'} px-4 py-2 border-t border-l border-b rounded-tl-lg rounded-bl-lg text-gray-700`}>
            {boxLabel}
          </div>
        )}
        <div className="relative flex-grow">
          { 
          ( value ? (
            <input
              type={isObscured ? 'password' : 'text'}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
              className={`${small ? 'w-130 py-2' : 'w-full py-4'} border ${boxLabel?' rounded-tr-lg rounded-br-lg':'rounded-lg'} ${alignClass} px-2 ${
                value ? 'font-bold text-blue' : 'font-normal'
              }`} 
              {...props}
            />) : (
            <input
              type={isObscured ? 'password' : 'text'}
              onChange={onChange}
              onBlur={onBlur}
              className={`${small ? 'w-130 py-2' : 'w-full py-4'} border ${boxLabel?' rounded-tr-lg rounded-br-lg':'rounded-lg'} ${alignClass} px-2 ${
                value ? 'font-bold text-blue' : 'font-normal'
              }`}
              {...props}
            />)
          )}



          {!value && (
            <div
            className={`${align==='center'?'justify-center':''} absolute inset-0 flex items-center px-2 text-gray-500 pointer-events-none`}
              style={{ fontFamily: 'inherit' }}
            >
              {hintText}
            </div>
          )}
          {obscureText && (
            <button
              type="button"
              onClick={toggleObscureText}
              className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
            >
              {isObscured ? '👁️' : '👁️‍🗨️'}
            </button>
          )}
        </div>
      </div>
    </div>
  );
};