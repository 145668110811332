import { Column } from "../../../../../core/components/Column"
import { Row } from "../../../../../core/components/Row"

export const Brands = () => {
  return (
    <section className="bg-orange-100 py-20">
        <Column crossAxisAlignment="center">
          <Column className="w-1/2">
            <Row
              mainAxisAlignment="between"
              crossAxisAlignment="center"
              className="p-2"
              responsiveCol={true}
            >
              <img src="/landing/logo-life-pro-nutrition.png" className="w-40 md:w-32" />
              <img src="/landing/logos_icns copia.png" className="w-40 md:w-32" />
              <img src="/landing/logo-fitgeneration copia.png" className="w-40 md:w-32" />
            </Row>
            <Row
              responsiveCol={true}
              mainAxisAlignment="center"
              crossAxisAlignment="center"
              className="p-2 gap-16"
            >
              <img src="/landing/logo-trainologym.png" className="w-48" />
              <img src="/landing/logo-powerexplosive-azul copia.png" className="w-48" />
            </Row>
          </Column>
        </Column>
    </section>
  )
}
