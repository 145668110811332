import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import PurchaseBy, { OrderType } from "../../squad-fit/components/PurchaseBy";
import useScreen from "../../squad-fit/hooks/useScreen";
import { cleanSelectedBook } from "../../store/slices/book/bookSlice";
import {
  createOrderWithPaypal,
  createOrderWithStripe,
} from "../../store/slices/book/thunks";
import { ButtonSquad } from "../../core/components";
import { ButtonType } from "../../core/interfaces/ButtonSquadInterface";

export const BookButton = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { isSmallScreen, isCellphoneScreen } = useScreen();
  const {
    userBooks,
    selectedBook,
    is_loading_order_with_paypal,
    is_loading_order_with_stripe,
  } = useSelector((state: RootState) => state.book);
  const [isOpenModalBuyBook, setIsOpenModalBuyBook] = useState(false);

  // useEffect(() => {
  //   if (!selectedBook) {
  //     navigate("/compras");
  //   }
  //   return () => {
  //     dispatch(cleanSelectedBook());
  //   };
  // }, []);

  const handleNavigate = () => {
    if (selectedBook) navigate(`/read-book/${selectedBook.id}`);
  };

  const createOrder = (type: OrderType) => {
    if (type === "stripe") {
      dispatch(
        createOrderWithStripe({ bookId: selectedBook?.id as string })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          window.open(result.payload as string, "_blank");
        }
      });
    } else {
      dispatch(
        createOrderWithPaypal({ bookId: selectedBook?.id as string })
      ).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          window.open(result.payload as string, "_blank");
        }
      });
    }
  };

  return (
    <>
      {isOpenModalBuyBook && (
        <div className="flex relative justify-center h-full min-[768px]:w-1/2 max-[768px]:w-full">
          <PurchaseBy
            className={`absolute z-30 bottom-[10px] bg-white rounded-xl p-2 w-2/3 max-[767px]:w-full shadow-xl`}
            createOrder={createOrder}
            is_loading_with_paypal={is_loading_order_with_paypal}
            is_loading_with_stripe={is_loading_order_with_stripe}
          />
        </div>
      )}
      {userBooks.some((element) => element.id === selectedBook?.id) ? (
        <ButtonSquad label={`Leer`} buttonType={ButtonType.OrangeFull} onClick={handleNavigate} />
      ) : (
        <ButtonSquad
          label={`Comprar ahora por \$${selectedBook?.price ?? 0}`}
          buttonType={ButtonType.OrangeFull}
          onClick={() => setIsOpenModalBuyBook((prev) => !prev)}
        />
      )}
    </>
  );
};
