import { useNavigate } from "react-router-dom";
import BookMiReceta from "../../../../../assets/images/book_mi_receta.png";
import { Book, UserBook } from "../../../../../store/interfaces/book/interfaceBook";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import { setSelectedBook, setSelectedUserBook } from "../../../../../store/slices/book/bookSlice";
import { Column } from "../../../../../core/components/Column";

interface CardBookProps {
  book: Book;
  variant?:1|2
}

const CardBook = ({ book,variant=1 }: CardBookProps) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const { userBooks } = useSelector((state: RootState) => state.book);

  const handleNavigate = () => {
    dispatch(setSelectedBook(book));
    if (userBooks.some((element) => element.id === book.id)) {
      dispatch(setSelectedUserBook(book as UserBook));
      navigate(`/read-book/${book.id}`);
    } else {
      navigate("/compras/book-info");
    }
    
  };
  if(variant==2){
    return <Column className="w-fit pt-3 cursor-pointer rounded-md" onClick={handleNavigate}>
      <img
        className="shadow-xl rounded-md w-40 h-56 object-cover"
        src={book.image ?? "https://via.placeholder.com/150"}
        alt="book"
      />
      <div className="mt-2">
      </div>
      <span className="text-sm font-semibold">{book.title}</span>
      <span className="text-xs text-gray-400">{book.subtitle}</span>
    </Column>;
  }

  return (
    
    <div className="w-[410px] h-[260px] max-[485px]:w-[calc(100vw-80px)] max-[485px]:h-[250px] block relative">
      <div className="absolute z-10 bg-[#FF690B] w-full h-full rounded-3xl opacity-10"></div>
      <div className="absolute z-20 top-10 left-10 max-w-52 max-[485px]:w-[calc(100vw-220px)]">
        <span className="text-gray-400 text-xs">{book.subtitle}</span>
        <h1 className="text-black text-2xl font-semibold max-[485px]:text-lg">{book.title}</h1>
        <span className="text-black text-sm">Precio: €{book.price}</span>
      </div>
      <div className="z-20 absolute flex flex-col justify-end items-center w-full h-full bottom-7">
        {userBooks.some((element) => element.id === book.id) ? (
          <span className="text-black text-sm font-semibold px-2 py-1 rounded-lg">
            Comprado
          </span>
        ) : (
          <button
            className="bg-white shadow-lg active:shadow-inner text-black text-sm font-semibold px-4 py-2 rounded-lg max-[485px]:w-[200px] w-[310px] h-[50px] cursor-pointer"
            onClick={handleNavigate}
          >
            Comprar ahora
          </button>
        )}
      </div>
      <div
        className="relative z-30 right-0 flex justify-end"
        style={{ marginRight: "-50px", marginTop: "-30px" }}
      >
        <img
          className="object-cover max-[485px]:w-[170px] rounded-lg"
          src={BookMiReceta}
          alt="book"
        />
      </div>
    </div>
  );
};

export default CardBook;
