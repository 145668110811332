import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import VideoItem from "./VideoItem";
import { TextComponent } from "../../../../../core/components/TextComponent";
import TestItem from "./TestItem";

const ListVideos = () => {
  const { courseSelected,tests } = useSelector((state: RootState) => state.course);
  return (
    <div className={`h-screen gap-3 border border-gray-400 bg-gray-100 overflow-auto z-10 py-2`}>
      <TextComponent variant="h4" className="px-8 py-6">
        Contenido del curso
      </TextComponent>
      <hr/>
      {
        courseSelected?.videos.map((video, index) => (
          <VideoItem key={video.video_id} video={video} index={index} />
        ))
      }
      {
        tests&&(<TestItem index={(courseSelected?.videos.length??0)} test={tests} />)
      }
    </div>
  );
};

export default ListVideos;
