import { Column } from "../../core/components/Column";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  height?: string;
  tooltip?: string;
  index: number;
}

export const CalorieBar = ({ height = '50%',tooltip,index,...props }: Props) => {
  return (
      <Column crossAxisAlignment="center" className="relative group" {...props}>
        <div className="h-36 w-4 bg-gray-200 rounded-full relative">
        <div 
          className={`absolute bottom-0 w-full ${index%2?'bg-blueSquat2':'bg-orange'} bg-black rounded-full`}
          style={{ height: height }}
        ></div>
        </div>
        {tooltip && (
          <div className="absolute bottom-full mb-2 hidden group-hover:flex flex-col items-center">
            <div className="w-32 p-2 bg-black text-white text-xs rounded-md shadow-lg">
              {tooltip}
            </div>
            <div className="h-2 w-2 bg-black transform rotate-45"></div>
          </div>
        )}
      </Column>
  )
}
