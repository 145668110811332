import * as Yup from 'yup';


export interface RedeemFormValues {
    code: string;
}
export const redeemValidationSchema = Yup.object({
    code: Yup.string().required('Ingrese un código'),
  }
);
  