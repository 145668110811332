import { createSlice } from '@reduxjs/toolkit';
import { registerUserWithEmailPassword, renewToken, startGettingUserInfo, startGettingUserInfoNoLoading, startLoginUserWithEmailPassword, startUpdateUserPassword, startUpdatingUserInfo } from './thunks';


interface AuthState {
  isLoading: boolean;
  isLoadingOnboarding: boolean;
  status: string;
  uid: string;
  email: string;
  weight: string;
  height: string;
  target_weight: string;
  username: string;
  firstName: string;
  lastName: string;
  phone: string;
  country: string;
  description: string;
  stepsPeerDay: string;
  strengthTraining: string;
  gender: string;
  trainingGoal: string;
  dailyActivity: string;
  birth?: string | null;
  configKcal: number;
  profilePicture?: string | null;
  errorMessage?: string | null;
  token?: string | null;
  statusLogin: number | null;
}

const initialState: AuthState = {
  isLoading: false,
  isLoadingOnboarding: false,
  status: 'not-authenticated',
  uid: '',
  email: '',
  username: '',
  stepsPeerDay: '',
  gender: '',
  strengthTraining: '',
  weight: '',
  height: '',
  target_weight: '',
  trainingGoal: '',
  dailyActivity: '',
  country: '',
  phone: '',
  firstName: '',
  lastName: '',
  description: '',
  birth: null,
  configKcal: 0,
  profilePicture: null,
  errorMessage: null,
  token: null,
  statusLogin: null,
}

// Definición del slice de Redux
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    onLogout: ( state ) => {
      state.status = 'not-authenticated';
      state.uid = '';
      state.email = '';
      state.username = '';
      state.phone = '';
      state.country = '';
      state.firstName = '';
      state.lastName = '';
      state.description = '';
      state.birth = null;
      state.configKcal = 0;
      state.profilePicture = null;
      state.errorMessage = null;
      state.token = null;
      state.statusLogin = null;
    },
    checkingCredentials: (state) => {
      state.status = 'checking';
    },
    clearErrorMessage: (state) => {
      state.errorMessage = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUserWithEmailPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUserWithEmailPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(registerUserWithEmailPassword.fulfilled, (state, action) => {
        const payload = action.payload;
        state.isLoading = false;
        state.status = 'authenticated';
        state.token = payload.token;
        state.statusLogin = payload.status_login;
      })
      .addCase(startLoginUserWithEmailPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startLoginUserWithEmailPassword.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(startLoginUserWithEmailPassword.fulfilled, (state, action) => {
        const payload = action.payload;
        state.isLoading = false;
        state.status = 'authenticated';
        state.token = payload.token;
        state.statusLogin = payload.status_login;
      })
      .addCase(startGettingUserInfo.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startGettingUserInfo.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(startGettingUserInfo.fulfilled, (state, action) => {
        state.status = 'authenticated';
        state.isLoading = false;
        const payload = action.payload;
        state.email = payload.email;
        state.uid = payload.id;
        state.weight = payload.weight;
        state.height = payload.height;
        state.target_weight = payload.target_weight;
        state.username = payload.username;
        state.stepsPeerDay = payload.steps_peer_day_id;
        state.strengthTraining = payload.strength_training_id;
        state.gender = payload.gender;
        state.trainingGoal = payload.training_goal_id;
        state.dailyActivity = payload.daily_activity_id;
        state.phone = payload.phone_number;
        state.country = payload.country;
        state.firstName = payload.firstName;
        state.lastName = payload.lastName;
        state.description = payload.description;
        state.birth = payload.birth??new Date().toISOString();
        state.configKcal = payload.config_kcal_goal;
        state.profilePicture = payload.profile_picture;
        state.statusLogin = payload.status_login;
        state.token = localStorage.getItem('userToken');
      })
      .addCase(startGettingUserInfoNoLoading.fulfilled, (state, action) => {
        state.status = 'authenticated';
        state.isLoading = false;
        const payload = action.payload;
        state.email = payload.email;
        state.uid = payload.id;
        state.weight = payload.weight;
        state.height = payload.height;
        state.target_weight = payload.target_weight;
        state.username = payload.username;
        state.stepsPeerDay = payload.steps_peer_day_id;
        state.strengthTraining = payload.strength_training_id;
        state.gender = payload.gender;
        state.trainingGoal = payload.training_goal_id;
        state.dailyActivity = payload.daily_activity_id;
        state.phone = payload.phone_number;
        state.country = payload.country;
        state.firstName = payload.firstName;
        state.lastName = payload.lastName;
        state.description = payload.description;
        state.birth = payload.birth??new Date().toISOString();
        state.configKcal = payload.config_kcal_goal;
        state.profilePicture = payload.profile_picture;
        state.statusLogin = payload.status_login;
        state.token = localStorage.getItem('userToken');
      })
      .addCase(startUpdatingUserInfo.fulfilled, (state, {payload}) => {
        state.firstName = payload.firstName;
        state.lastName = payload.lastName;
        state.email = payload.email;
        state.country = payload.country;
        state.phone = payload.phone;
        state.description = payload.description;
        state.birth = payload.birth??new Date().toISOString();
        state.configKcal = payload.config_kcal_goal;
        state.profilePicture = payload.profile_picture;
        state.isLoadingOnboarding = false;
      })
      .addCase(startUpdatingUserInfo.pending, (state) => {
        state.isLoadingOnboarding = true;
      })
      .addCase(startUpdatingUserInfo.rejected, (state) => {
        state.isLoadingOnboarding = false;
      })
      .addCase(startUpdateUserPassword.fulfilled, (state) => {
        state.isLoading = false;
        state.statusLogin = 1;
      })
      .addCase(renewToken.fulfilled, (state, action) => {
        state.token = action.payload.token;
      });
  },
});

// Exportar acciones y el reducer
export const {onLogout, checkingCredentials, clearErrorMessage } = authSlice.actions;
export default authSlice.reducer;
