import { Link } from "react-router-dom"
import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { InputType } from "../../../core/interfaces/InputSquadInterface"
import { GoogleLogo } from "../../../assets/logos/GoogleLogo"
import { AppleLogo } from "../../../assets/logos/AppleLogo"
import { useDispatch, useSelector } from "react-redux"
import { useMemo } from "react"
import { AppDispatch, RootState } from "../../../store/store"
import { registerUserWithEmailPassword, startGettingUserInfo } from "../../../store/slices/auth/thunks"
import { connectToServer } from "../../../socket-clients/social"
import CircularProgress from "../../components/CircularProgress"
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft"
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight"
import { useFormik } from "formik"
import * as yup from 'yup';
import { setToken } from "../../../api/SquatFitAPI"
import { getAllBooks, getBooksByUser } from "../../../store/slices/book/thunks"
import { startGettingDailyKcal, startGettingMeals, startGettingRecipes, startGettingWeeklyKcal } from "../../../store/slices/food/thunks"
import { startGettingPosts } from "../../../store/slices/post/thunks"
import { getRecipesPartialSystem, getRecipesSystem } from "../../../store/slices/recipe/thunks"
import { getAllRedeems } from "../../../store/slices/redeem/thunks"
import { getAllCourses } from "../../../store/slices/course/thunks"

interface FormData {
  email: string;
  password: string;
  displayName: string;
}

const formData: FormData = {
	email: '',
	password: '',
	displayName: '',
};

const regex: RegExp = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).{8,}$/;

const schemaValidation = yup.object({
  email: yup.string().email("El email no es válido").required("Email es requerido"),
  password: yup
    .string()
    .min(8, "La contraseña debe tener al menos 8 caracteres")
    .matches(regex, "La contraseña debe tener mayúsculas, minúsculas, símbolos y números")
    .required("Password es requerido"),
  displayName: yup.string().min(2).required("Usuario es requerido"),
});

export const RegisterScreen = () => {
  const { status, isLoading } = useSelector((state: RootState) => state.auth);
	const isAuthenticating = useMemo(() => status === 'checking', [status] );
  const { handleSubmit, values, handleChange, errors, setErrors } = useFormik<FormData>({
    initialValues: formData,
    validationSchema: schemaValidation,
    onSubmit: (values) => {
      dispatch(registerUserWithEmailPassword({ email: values.email, password: values.password, username: values.displayName }))
        .then(res => {
          if (res.meta.requestStatus === 'fulfilled') {
            dispatch(startGettingUserInfo());
            dispatch(startGettingRecipes());
            dispatch(getRecipesSystem());
            dispatch(getAllCourses());
            dispatch(getRecipesPartialSystem());
            dispatch(startGettingWeeklyKcal(new Date()));
            dispatch(startGettingMeals({ date: new Date() }));
            dispatch(startGettingDailyKcal({ date: new Date() }));
            dispatch(getAllRedeems());
            dispatch(getBooksByUser());
            dispatch(getAllBooks());
            dispatch(startGettingPosts({ limit: "10", page: "1" }));
            connectToServer((res.payload as any).token, dispatch);
          }
        });
    },
  });

  const dispatch: AppDispatch = useDispatch();

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        {
          isLoading === true
          ? <CircularProgress styleProps={{width: 4, height: 40}} />
          : <div className="flex flex-col justify-center items-center h-screen">
              <img src="/logo_color.png" className="h-40 max-w-full" alt="Logo Squat Fit"/>
              <form onSubmit={handleSubmit}>
                <div className="flex flex-col mt-4">
                  <LabelSquad children="Email"/>
                  <InputSquad 
                    inputType={InputType.Medium}
                    value={values.email}
                    name="email"
                    onChange={handleChange}
                    placeholder="correo@gmail.com"
                  />
                  {errors.email && <span className="text-red-500 text-xs">{errors.email}</span>}
                </div>
                <div className="flex flex-col mt-4">
                  <LabelSquad children="Usuario"/>
                  <InputSquad 
                    inputType={InputType.Medium}
                    name="displayName"
                    value={values.displayName}
                    onChange={handleChange}
                    placeholder="usuario01"
                  />
                  {errors.displayName && <span className="text-red-500 text-xs">{errors.displayName}</span>}
                </div>
                <div className="flex flex-col mt-4">
                  <LabelSquad children="Contraseña"/>
                  <InputSquad 
                    inputType={InputType.Medium}
                    value={values.password}
                    onChange={handleChange}
                    name="password"
                    type="password"
                  />
                  {errors.password && <span className="text-red-500 text-xs">{errors.password}</span>}
                </div>
                <div className="mt-4">
                  <ButtonSquad
                    full={true}
                    buttonType={ButtonType.OrangeFull}
                    disabled={isAuthenticating}
                    label="Acceder Ahora"
                    type="submit"
                  />
                </div>
                <div className="flex flex-col items-center">
                  <div className="mt-6">  
                    <label className="text-sm">
                      Iniciar sesíon con tus cuentas de
                    </label>
                  </div>
                  <div className="flex flex-row mt-4">
                    <button className="hover:bg-slate-200 py-2 px-4 rounded-lg">
                      <GoogleLogo />
                    </button>
                    <button className="hover:bg-slate-200 py-2 px-4 rounded-lg">
                      <AppleLogo />
                    </button>
                  </div>
                </div>
                <div className="flex flex-row ml-8 mt-4 mb-4">
                  <label className="text-xs font-light text-gray-500">
                    Ya tienes una cuenta? 
                  </label>
                  <label className="text-xs font-medium text-orange-500 ml-2">
                    <Link to='/auth/login'>
                      Iniciar sesión.
                    </Link>
                  </label>
                </div>
              </form>
              <div className="max-[800px]:hidden">
                <BackgroundTopLeft />
                <BackgroundBottomRight />
              </div>
            </div>
        }
      </div>
    </div>
  )
}
