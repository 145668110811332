import { WarningIcon } from "../../../../assets/icons/WarningIcon";

interface ModalProps {
  showModal: boolean;
  component: JSX.Element;
}

export const AccountModal = ({ showModal, component }: ModalProps) => {
  return (
    <div className={`fixed z-50 inset-0 overflow-y-auto ${showModal ? 'block' : 'hidden'}`} aria-labelledby="modal-headline" role="dialog" aria-modal="true">
      <div className="flex items-end max-[640px]:items-center justify-center min-h-screen pt-2 px-4 pb-20 text-center sm:block sm:p-0">
        <div className="w-full inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                <WarningIcon />
              </div>
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-headline">Eliminar cuenta</h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500 mb-2">
                    Ingresa el código de seguridad
                  </p>
                  {component}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
