import { Manager, Socket } from "socket.io-client";

import {
  HistoryMessage,
  ListChats,
  RequestFriend,
} from "../store/interfaces/social/interfaceSocial";

import {
  notifyNewFriendRequest,
  setMessageViewed,
  setNewMessage,
} from "../store/slices/social/thunks";
import { AppDispatch } from "../store/store";

let socket: Socket;

export const connectToServer = (token: string, dispatch: AppDispatch) => {
  const manager = new Manager(
    `${process.env.REACT_APP_SQUAT_FIT_API}socket.io/socket.io.js`,
    {
      extraHeaders: {
        authorization: `Bearer ${token}`,
        from: "web-client",
      },
    }
  );

  socket?.removeAllListeners();
  socket = manager.socket("/");

  addListeners(dispatch);
};

const addListeners = (dispatch: AppDispatch) => {
  socket.on("connect_error", (error: any) => {
    socket.close();
  });

  socket.on("connect_timeout", (timeout: any) => {
    socket.close();
  });

  socket.on("reconnect", (attempt: number) => {
    console.log("Reconnect to server");
  });

  socket.on("reconnect_attempt", (attempt: number) => {
    console.log("Reconnect attempt to server");
  });

  socket.on("reconnect_failed", () => {
    socket.close();
  });

  socket.on("reconnect_error", (error: any) => {
    socket.close();
  });

  socket.on("connect", () => {
    console.log("Connected to server");
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from server");
  });

  socket.on("error", onError);
  socket.on(
    "new_message",
    (payload: { message: HistoryMessage; chat: ListChats }) =>
      onNewMessage(payload, dispatch)
  );
  socket.on("updated_message", (message: HistoryMessage[]) =>
    onMessageRead(message, dispatch)
  );
  socket.on("new_friend", (payload: RequestFriend) =>
    onNewFriend(payload, dispatch)
  );
};

export const addMessage = (
  userId: string,
  friendId: string,
  messageText: string
) => {
  socket.emit("add_message", {
    userId: userId,
    friendId: friendId,
    messageText: messageText,
  });
};

export const addFriend = (userId: string) => {
  socket.emit("add_friend", {
    userId: userId,
  });
};

export const UpdateMessageViewed = (
  messagesId: string[],
  userId: string,
  friendId: string
) => {
  socket.emit("update_message_read", {
    messagesId: messagesId,
    userId: userId,
    friendId: friendId,
  });
};

const onNewMessage = (
  payload: {
    message: HistoryMessage;
    chat: ListChats;
  },
  dispatch: AppDispatch
) => {
  dispatch(setNewMessage(payload));
};

const onError = (error: string) => {
  console.error(error);
};

const onMessageRead = (message: HistoryMessage[], dispatch: AppDispatch) => {
  dispatch(setMessageViewed(message));
};

const onNewFriend = (payload: RequestFriend, dispatch: AppDispatch) => {
  dispatch(notifyNewFriendRequest(payload));
};