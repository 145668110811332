import React from 'react';

interface BlurDivProps {
  blurAmount?: number; // Permitir especificar la cantidad de desenfoque
  children: React.ReactNode; // Para renderizar contenido dentro del div
}

const BlurDiv: React.FC<BlurDivProps> = ({ blurAmount = 5, children }) => {
  const style = {
    filter: `blur(${blurAmount}px)`,
  };

  return <div style={style}>{children}</div>;
};

export default BlurDiv;
