import React, { ReactNode, useState } from 'react';

interface CarouselProps {
  children: ReactNode[];
}

const Carousel: React.FC<CarouselProps> = ({ children }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevious = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide ? children.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const goToNext = () => {
    const isLastSlide = currentIndex === children.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="relative w-full h-full overflow-hidden">
      <div
        className="flex transition-transform duration-300"
        style={{ transform: `translateX(-${currentIndex * 100}%) `}}
      >
        {children.map((child, index) => (
          <div className="relative left-[3.3rem] w-full flex-shrink-0" key={index}>
            {child}
          </div>
        ))}
      </div>
      <button
        onClick={goToPrevious}
        className="absolute top-72 left-0 transform -translate-y-1/2 rounded-full w-12 p-1"
      >
        <img src="/landing/arrow left.png" />
      </button>
      <button
        onClick={goToNext}
        className="absolute top-72 right-0 transform -translate-y-1/2 rounded-full w-12 p-1"
      >
        <img src="/landing/arrow right.png" />
      </button>
    </div>
  );
};

export default Carousel;