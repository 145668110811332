import React from 'react';
import useWindowSize from '../../squad-fit/hooks/useWindowsSize';

interface CustomRowProps {
  children: React.ReactNode;
  className?: string;
  flexible?: boolean;
  responsive?: boolean;
  responsiveCol?: boolean;
  mainAxisAlignment?: 'start' | 'center' | 'end' | 'between' | 'around' | 'evenly';
  crossAxisAlignment?: 'start' | 'center' | 'end' | 'stretch';
}

type RowProps = CustomRowProps & React.HTMLAttributes<HTMLDivElement>;

export const Row = ({
  children,
  className = '',
  flexible = false,
  responsive = false,
  responsiveCol = false,
  mainAxisAlignment = 'start',
  crossAxisAlignment = 'stretch',
  ...props
}: RowProps) => {
  const mainAxisClasses = {
    start: 'justify-start',
    center: 'justify-center',
    end: 'justify-end',
    between: 'justify-between',
    around: 'justify-around',
    evenly: 'justify-evenly',
  };

  const crossAxisClasses = {
    start: 'items-start',
    center: 'items-center',
    end: 'items-end',
    stretch: 'items-stretch',
  };
  const { width } = useWindowSize();
  const mainAxisClass = mainAxisClasses[mainAxisAlignment];
  const crossAxisClass = crossAxisClasses[crossAxisAlignment];
  const isMd = width >= 768;

  return (
    <div
      className={`${
        responsive && !responsiveCol
          ? 'grid grid-cols-1 md:grid-cols-2 lg:flex lg:flex-row gap-2'
          : `flex ${responsiveCol?`flex-col md:flex-row gap-2`:`flex-row ${mainAxisClass} ${crossAxisClass}`}`
      } ${!flexible ? 'w-full' : ''} ${isMd&&mainAxisClass} ${isMd&&crossAxisClass}   ${className}`}
      {...props}
    >
      {responsive
        ? React.Children.map(children, (child,index) => (
            <div className={`flex ${index % 2 === 0 ? 'md:justify-start' : 'md:justify-end'}`}>
              {child}
            </div>
          ))
        : children}
    </div>
  );
};
