import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonSquadXSmall } from "../../../core/components/ButtonSquadXSmall";
import { AppDispatch, RootState } from "../../../store/store";
import { ProfileInfo } from "./components/ProfileInfo";
import { ProfileNumbers } from "./components/ProfileNumbers";
import { UserImage } from "./components/UserImage";
import { followsFriend, getFriends } from "../../../store/slices/social/thunks";
import { addFriend } from "../../../socket-clients/social";
import MessageIcon from "../../../assets/icons/Message";
import { setSelectedChat } from "../../../store/slices/social/socialSlice";
import { TabProfileUser } from "./components/TabProfileUser";
import { startGettingPostFrom } from "../../../store/slices/post/thunks";
import { clearPostsFrom } from "../../../store/slices/post/PostSlice";
import { startGettingFriendRecipes } from "../../../store/slices/food/thunks";

const UserProfileScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();
  const [friendFinded, setFriendFinded] = useState(false);

  const { friendSelected, friends } = useSelector(
    (state: RootState) => state.friend
  );

  const { uid } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    if (!friendSelected) {
      navigate("/social");
    }
  }, [friendSelected]);

  useEffect(() => {
    dispatch(startGettingPostFrom(friendSelected?.id as string));
    dispatch(startGettingFriendRecipes(friendSelected?.id as string));
    return () => {
      dispatch(clearPostsFrom());
    };
  }, []);

  useEffect(() => {
    dispatch(followsFriend({ userId: friendSelected?.id as string }));
  }, []);

  useEffect(() => {
    findFriend();
  }, [friends])
  

  const findFriend = (): void => {
    const friend = friends.find(
      (friend:any) =>
        friend.id === friendSelected?.id &&
        friend.status !== "deleted" &&
        friend.status !== "rejected" &&
        friend.follows.includes(uid)
    );
    return friend ? setFriendFinded(true) : setFriendFinded(false);
  };

  const sendFriendRequest = () => {
    addFriend(friendSelected?.id as string);
    setFriendFinded(true);
    setTimeout(() => {
      dispatch(getFriends());
    }, 2000);
  };

  const sendAMessage = () => {
    const friend = friends.find((friend:any) => friend.id === friendSelected?.id);

    if (friend) {
      dispatch(
        setSelectedChat({
          friend_id: friend.friend_id as string,
          username: friend.username as string,
          profile_picture: friend.profile_picture as string,
          user_id: friend.id as string,
        })
      );

      navigate("/social");
    }
  };

  return (
    <div className="flex flex-col pt-16">
      <div className="flex flex-row justify-center">
        <div 
          className="flex flex-row max-[870px]:flex-col max-[740px]:w-[200px] max-[870px]:mb-10 max-[560px]:w-[100px] gap-4 max-[560px]:gap-2"
        >
          <div>
            <UserImage profile_picture={friendSelected?.profile_picture} />
          </div>
          <div className="flex flex-col items-between">
            <ProfileInfo
              username={friendSelected?.username}
              email={friendSelected?.email}
            />
            <p className="text-sm w-80 font-light pt-6">
              {friendSelected?.description ?? ""}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-between pl-5 w-[300px] max-[430px]:w-[200px]">
          <div className="flex flex-row pb-8">
            <ProfileNumbers />
          </div>
          <div className="flex flex-row justify-end pb-8">
            <ButtonSquadXSmall
              label={friendFinded ? "Siguiendo" : "Seguir"}
              onClick={!friendFinded ? sendFriendRequest : () => {}}
              />
            {friendFinded && (
              <div className="pl-2">
                <ButtonSquadXSmall label="Enviar mensaje" onClick={sendAMessage} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <TabProfileUser />
      </div>
    </div>
  );
};

export default UserProfileScreen;
