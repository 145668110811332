import { useEffect, useState } from "react";
import { DateTime } from "luxon";

interface CommentProps {
  text: string
  username: string
  picture: string
  dateComent: string
  limit: number
}

export const CommentConatiner = ({username, text, picture, dateComent, limit}: CommentProps) => {

  const [onReadMore, setOnReadMore] = useState(false);
  const [diffTime, setdiffTieme] = useState("");
  
  const handleReadMore = () => {
    setOnReadMore(prev => !prev)
  }

  useEffect(() => {
    setdiffTieme(diffNow());
  }, []);

  const diffNow = () => {

    const parsedDate = new Date(dateComent);
    const currentDate = new Date();
    const differenceInSeconds = Math.floor((currentDate.getTime() - parsedDate.getTime()) / 1000);
  
    if (differenceInSeconds < 60) {
      return `${differenceInSeconds} s`;
    }
  
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    if (differenceInMinutes < 60) {
      return `${differenceInMinutes} min`;
    }
  
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    if (differenceInHours < 24) {
      return `${differenceInHours} h`;
    }
  
    const differenceInDays = Math.floor(differenceInHours / 24);
    if (differenceInDays < 7) {
      return `${differenceInDays} d`;
    }
  
    if (differenceInDays < 30) {
      const weeksDifference = Math.floor(differenceInDays / 7);
      return `${weeksDifference} sem`;
    }
  
    const weeksDifference = Math.ceil(differenceInDays / 7);
    return `${weeksDifference} sem`;
  }

  return (
    <div className="flex flex-row items-center justify-between pt-4">
      <div className="flex flex-row">
        <img src={picture} className="h-8 w-8 rounded-full border border-gray-200"/>
        <div className="flex flex-col pl-2">
          <label className="text-xs font-normal">
            {username}
          </label>
          <p className="text-xs font-light mr-1">
            {onReadMore ?  text : text.substring(0, limit)}
            {
              text.length > 200 
              ? <button className="text-gray-500 font-medium ml-1 text-[11px]" onClick={handleReadMore}>{onReadMore ? 'Ver menos' : 'Ver más'}</button>
              : <div></div>
            }
          </p>
        </div>
      </div>
      <p className="text-[10px] font-normal text-gray-500 mt-2 mr-2">{diffTime}</p>
    </div>
  )
}
