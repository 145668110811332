import { useNavigate } from "react-router-dom";
import { Course } from "../../../../../store/interfaces/course/interfaceCourse";
import { TextComponent } from "../../../../../core/components/TextComponent";

interface RecomendCourseSmallCardProps {
  course: Course;
}

const RecomendCourseSmallCard = ({ course }: RecomendCourseSmallCardProps) => {
  const navigate = useNavigate();
  const handleWatchCourse = () => {
    navigate(`/course/${course.id}`); 
  };
  return (
    <div>
        <div
            className="block relative w-full h-32  cursor-pointer"
            onClick={handleWatchCourse}
            >
        <div className="absolute w-full h-full hover:opacity-10 hover:bg-black">
            
        </div>
        <img
            className="object-cover w-full h-32 hover:opacity-10 hover:bg-black cursor-pointer"
            src={course.image}
            alt="course"
        />
        </div>
        <TextComponent variant="h6" className="font-bold mt-2">{course.title}</TextComponent>
        <TextComponent variant="h7" className="">{course.tutor.firstName} {course.tutor.firstName}</TextComponent>
    </div>
  );
};

export default RecomendCourseSmallCard;
