import { useNavigate } from "react-router-dom";
import { ButtonSquad, LabelSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft";
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight";

export const EmailMessageScreen = () => {

  const navigate = useNavigate();

  const onSubmit = () => {
    navigate('/auth/temp-pass');
  }

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        <img src="/logo_color.png" className="h-40 max-w-full" alt=""/>
        <div className="mt-4">
          <h1 className="text-lg text-orange-400">Solicitud exitosa!</h1>
        </div>
        <div className="mb-3 mt-3">
          <LabelSquad 
            children="Hemos enviado una contraseña temporal a su email. Por favor revise su bandeja de entrada o lista de correo no deseado."
          />
        </div>
        <div className="mt-10">
          <form onSubmit={onSubmit}>
            <ButtonSquad
              full={true}
              buttonType={ButtonType.OrangeFull}
              label="Siguiente"
              type="submit"
            />
          </form>
        </div>
      </div>
      <div className="max-[800px]:hidden">
        <BackgroundTopLeft />
        <BackgroundBottomRight />
      </div>
    </div>
  )
}
