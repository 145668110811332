import * as Yup from 'yup';


export interface Step3FormValues {
    weight: string;
    height: string;
    target_weight: string;
  }
export const step3ValidationSchema = Yup.object({
    weight: Yup.string().required('Ingrese peso'),
    height: Yup.string().required('Ingrese altura'),
    target_weight: Yup.string().required('Ingrese peso'),
  }
);
  