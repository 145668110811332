
interface HeaderChatProps {
  name: string;
  profileImage: string;
}

const HeaderFloatingChat = ({ name, profileImage }: HeaderChatProps) => {
  return (
    <div className="flex flex-row justify-start pb-2">
      <img
        src={profileImage ? profileImage : "https://biiainsurance.com/wp-content/uploads/2015/05/no-image.jpg"}
        alt="profile"
        className="rounded-full w-10 h-10 object-cover"
      />
      <div className="flex flex-col self-center pl-2">
        <label className="text-sm cursor-pointer">{name ?? 'Sin nombre'}</label>
      </div>
    </div>
  );
};

export default HeaderFloatingChat;
