interface CheckProps {
  isChecked: boolean,
  handleCheckboxChange:(event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CheckBoxAdvice = ({isChecked, handleCheckboxChange}: CheckProps) => {
  return (
    <div className="flex flex-row items-center">
      <input
        type="checkbox"
        id="ingredientCheckbox"
        checked={isChecked}
        onChange={handleCheckboxChange}
        className="form-checkbox h-5 w-5 text-orange-600 rounded-lg mt-1"
      />
      <h3 className="text-sm pl-2">¿Estás de acuerdo con que la imagen sea utilizado de manera pública por tu coach?</h3>
    </div>
  )
}
