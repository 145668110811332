import { useNavigate } from "react-router-dom";
import { ButtonSquad } from "../../../../../core/components";
import { Column } from "../../../../../core/components/Column";
import { TextComponent } from "../../../../../core/components/TextComponent";
import { coachs } from "../../../../../core/constants/constatns";
import { ButtonType } from "../../../../../core/interfaces/ButtonSquadInterface";
import BeforeAfterComponent from "../BeforeAfterComponent";
import { ButtonGradient } from "./ButtonGradient";
import Carousel from "./Carrousel";

export const PromoSection = ({ handleOpen}:
  {
      handleOpen: () => void,
  }) => {
  const navigate = useNavigate();
  return (
    <Column
      className="md:flex-row md:justify-between py-36 px-12 bg-white"
      mainAxisAlignment="center"
      crossAxisAlignment="center"
    >
      <div className="relative flex-shrink-0 md:w-1/2 max-w-md">
        <div className="hidden md:block absolute w-[380px] ml-8">
          <img src="/landing/Imagen fondo1.png" />
        </div>
        <Carousel>
          {
            (coachs[0].clients?? []).map((e) => (
              <div key={e.description} className="w-3/4">
                <BeforeAfterComponent
                  beforeImage={e.beforeImage}
                  afterImage={e.afterImage}
                  description={e.description}
                />
              </div>
            ))
          }
        </Carousel>
      </div>

      <Column 
        className="mt-6 md:mt-0 md:ml-10 md:items-start text-center md:text-left"
        crossAxisAlignment="center"
      >
        <TextComponent variant="h2" className="text-2xl md:text-4xl font-bold mb-4">
          Mejora tu físico y <br /> también tu estilo de vida
        </TextComponent>
        <p className="text-gray-600 mb-6">
          Aprende a mantenerte sano y en forma; evita el efecto rebote y continúa progresando después de meses e incluso años.
        </p>
        <div className="flex space-x-4">
          {/* Buttons */}
          {/* <ButtonGradient
            buttonText="Registrarme"
          /> */}
          <ButtonSquad buttonType={ButtonType.BlueFull} label="Registrarme" onClick={()=>navigate('auth/register')} />
          <ButtonSquad buttonType={ButtonType.OrangeFull} label="Saber más" onClick={()=>handleOpen()} />
          {/* <button className="border border-orange-500 text-orange-500 py-2 px-4 rounded-md hover:bg-orange-100">
            Saber más
          </button> */}
        </div>
      </Column>
    </Column>
  );
};
