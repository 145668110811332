import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Book, ReadBook, UserBook } from "../../interfaces/book/interfaceBook";
import { Redeem } from "../../interfaces/book/redeem";
import { Recipe } from "../../interfaces/food/recipeInterface";
import { getRecipesPartialSystem, getRecipesSystem } from "./thunks";

interface RedeemState {
  recipesSystem: Recipe[];
  recipesPartial: Recipe[];
  loading:boolean;
}

const initialState: RedeemState = {
  recipesSystem: [],
  recipesPartial: [],
  loading: false,
};

const redeemSice = createSlice({
  name: "recipe",
  initialState,
  reducers: {
    cleanRedeems: (state) => {
      state.recipesSystem = [];
      state.recipesPartial = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecipesSystem.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getRecipesSystem.fulfilled,
        (state, action: PayloadAction<Recipe[]>) => {
          state.recipesSystem = action.payload;
          state.loading = false;
        }
      ).addCase(getRecipesSystem.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getRecipesPartialSystem.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getRecipesPartialSystem.fulfilled,
        (state, action: PayloadAction<Recipe[]>) => {
          state.recipesPartial = action.payload;
          state.loading = false;
        }
      ).addCase(getRecipesPartialSystem.rejected, (state) => {
        state.loading = false;
      })
    ;
  },
});

export const {
  cleanRedeems,
} = redeemSice.actions;
export default redeemSice.reducer;
