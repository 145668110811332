import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Column } from '../../../core/components/Column';
import { SizedBox } from '../../../core/components/SizedBox';
import Category from '../../../core/interfaces/Category';
import { startUpdatingUserInfo } from '../../../store/slices/auth/thunks';
import { RootState, AppDispatch } from '../../../store/store';
import { useAlert } from '../../hooks/useAlert';
import { FormikHelpers, Formik, Field, ErrorMessage, Form } from 'formik';
import { CustomInput } from '../../../core/components/CustomInput';
import { Row } from '../../../core/components/Row';
import CustomCard from '../home/components/CustomCard';
import { Step3FormValues, step3ValidationSchema } from '../home/interfaces/Step3Interface';
import { ButtonSquad } from '../../../core/components';
import { ButtonType } from '../../../core/interfaces/ButtonSquadInterface';


const strengths: Category[] = [
    {label: 'Ninguno', value: '0defb34f-69be-40c2-9190-de7b9aeafe2a' },
    {label: '1 o 2 días', value: '2e2fc039-46a7-4181-a4d1-c764b48f9fbb' },
    {label: '3 o 4 días', value: 'b356f4c9-ed56-4094-90e6-691f192fce65' },
    {label: '5 o 6 días', value: '6297372a-ca9b-4355-8e83-f4c6a9c58b01' },
];

const steps: Category[] = [
    {label: 'No lo cuento', value: 'bc75e3d0-c4ee-4fb1-ac57-a11700fc4868' },
    {label: 'Menos de 2.000', value: '64852506-b28b-4c4c-8388-b2147cf33e91' },
    {label: 'Entre 2.000 y 5.000', value: '8c15192b-e7cd-4a2b-9491-7f7ff4f379f6' },
    {label: 'Entre 6.000 y 9.000', value: 'a9fcddce-28c2-4489-b2cc-ec09ba05ee09' },
    {label: 'Entre 10.000 y 14.000', value: 'db28af0c-5695-4cc5-8363-3f78581ccb53' },
];

const _sexOptions : Category[] = [
    {label: 'Masculino', value: 'male' },
    {label: 'Femenino', value: 'female' },
];

interface ParKey {
    name: string;
    value: string;
    description?: string;
}
const trainingGoals: ParKey[] = [
    { description: 'Reducir la grasa corporal y aumentar la masa muscular.', name: 'Recomposición', value: 'a411cef6-36cf-4257-b331-a40ce1fab1dc' },
    { description: 'Mejorar la salud, sentirte mejor y tener más energía.', name: 'Perder Grasa', value: 'ec275da6-d1e2-4cde-8b50-ef80b4185ef9' },
    { description: 'Aumentar la fuerza y la masa muscular.', name: 'Ganar músculo', value: '9316551e-b4c2-4966-97d1-1c1bff17f0fc' },
];


const dailyActivities: ParKey[] = [
    { name: 'Sedentario', description: 'Mayormente sentado', value: 'a4b279ba-c2ac-493a-8717-53ee282d172c' },
    { name: '(1-3 días/semana)', description: 'Cardio ligero', value: '11419d7f-8e5f-44ce-aa52-df6bf27df4db' },
    { name: '(3-5 días/semana)', description: 'Cardio moderado', value: 'c523bf7f-f58a-482b-a0c4-25c30264f8cc' },
    { name: '(Camareros/Operadores)', description: 'Mucho cardio o Mayormente de pie', value: '88558780-91d6-4226-92bf-e054fbff055e' },
    { name: '(6-7 veces/semana)', description: 'Soy deportista de alto rendimiento', value: 'd93ebac2-1f50-406e-9160-92e98c3ed406' },
];

export const EditMetricsScreen = () => {
    const [selectedValue, setSelectedValue] = useState<ParKey | null>(null);
    const [setSelectedValues, setSelectedValuess] = useState<ParKey | null>(null);
    const [stepsOption, setStepsOption] = useState<Category|null>();
    const [strengthOption, setStrengthOption] = useState<Category|null>();
    const [genderOption, setGenderOption] = useState<Category|null>();

    const handleChange = (value:ParKey) => {
        setSelectedValue(value);
    };

    const handleChanges = (value:ParKey) => {
        setSelectedValuess(value);
    };
    
    useEffect(() => {
        if(gender!=null){
            setGenderOption(_sexOptions.find((e)=>e.value==gender));
        }
        if(strengthTraining!=null){
            setStrengthOption(strengths.find((e)=>e.value==strengthTraining));
        }
        if(stepsPeerDay!=null){
            setStepsOption(steps.find((e)=>e.value==stepsPeerDay));
        }
    }, [])

    

    const { weight, height, target_weight, uid, trainingGoal, dailyActivity,stepsPeerDay,strengthTraining,gender, isLoading } = useSelector((state: RootState) => state.auth);
    const initialValues: Step3FormValues = {
        weight: weight,
        height: height,
        target_weight: target_weight,
    };
    useEffect(() => {
        if(trainingGoal!=null){
            setSelectedValue(trainingGoals.find((e)=>e.value==trainingGoal)!);
        }
        if(dailyActivity!=null){
            setSelectedValuess(dailyActivities.find((e)=>e.value==dailyActivity)!);
        }
    }, [])


  const { showAlert } = useAlert();

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = (values: Step3FormValues, { setSubmitting }: FormikHelpers<Step3FormValues>) => {  
    
    const payload = {
      ...values,
      id: uid,
      'training_goal_id':selectedValue?.value,
      'daily_activity_id':setSelectedValues?.value,
      'steps_peer_day_id':stepsOption?.value,
      'gender':genderOption?.value,
      'strength_training_id':strengthOption?.value,
    }
    dispatch(startUpdatingUserInfo({userId: uid, userInfo: payload})).then( (response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        showAlert({
          message: 'Cambios guardados con éxito!',
          type: "success"
        });
        navigate('/calculator');
      }else{
        showAlert({
            message: 'Ha ocurrido un error, inténtelo más tarde',
            type: "error"
          });
      }
    })
    setSubmitting(false);
  }

    return <Formik
    initialValues={initialValues}
    validationSchema={step3ValidationSchema}
    validateOnBlur
    validateOnChange={false}
    onSubmit={handleSubmit}
>
{({ handleChange:handleForm, handleBlur }) => ((
    <Column crossAxisAlignment="center" className="relative w-full  min-h-screen overflow-x-hidden">
      <div className="w-full md:w-3/4 px-12 md:p-0 flex h-full grow flex-col">
    <Form>
        <Row responsiveCol={true} mainAxisAlignment="between" className="gap-6 md:gap-12 mt-6">
            <Column  className="text-center w-full">
                    <div className=" h3 font-bold">
                        ¿Cuál es tu objetivo?
                    </div>
                    <SizedBox className="h-6" />
                        <Column>
                            {trainingGoals.map((goal) => (
                                <div key={goal.value} className="mb-4">
                                    <CustomCard
                                        title={goal.name}
                                        value={goal.value}
                                        groupValue={selectedValue?.value}
                                        onChange={()=>handleChange(goal)}
                                        />
                                </div>
                            ))}
                        </Column>
                        <Column className="text-start w-full">
                            <h2 className='font-bold'> Cuéntanos más sobre ti.</h2>
                            <SizedBox className='h-5'/>
                            <div className=" grid grid-cols-1 lg:grid-cols-3">
                                {_sexOptions.map(item => (
                                    <div onClick={
                                        ()=>{
                                            setGenderOption(item);
                                        }
                                    } 
                                    key={item.value} className={`container px-4 py-2 w-max border ${genderOption?.value===item.value?'bg-purpleSquat1 text-white':''} border-gray-300 rounded-lg  mb-3`}>
                                        {item.label}
                                    </div>
                                ))}
                            </div>
                            <Field
                                as={CustomInput}
                                label="Peso actual"
                                name="weight"
                                hintText='ej: 56kg'
                                onChange={handleForm}
                                onBlur={handleBlur}
                                required
                            />
                            <ErrorMessage name="weight" component="div" className="text-red-500" />
                            <SizedBox className="h-4" />
                            <Field
                                as={CustomInput}
                                label="Altura"
                                name="height"
                                hintText='ej: 120cm'
                                onChange={handleForm}
                                onBlur={handleBlur}
                                required
                            />
                            <ErrorMessage name="height" component="div" className="text-red-500" />
                            <SizedBox className="h-4" />
                            <Field
                                as={CustomInput}
                                label="Peso deseado"
                                name="target_weight"
                                hintText='ej: 56kg'
                                onChange={handleForm}
                                onBlur={handleBlur}
                                required
                            />
                            <ErrorMessage name="target_weight" component="div" className="text-red-500" />
                            <SizedBox className="h-6" />
                            <h2 className='font-bold'>¿Con qué frecuencia entrenas fuerza?</h2>
                            <SizedBox className='h-5'/>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {strengths.map(item => (
                                    <div onClick={
                                        ()=>{
                                            setStrengthOption(item);
                                        }
                                    } 
                                    key={item.value} className={`container px-4 py-2 w-max border ${strengthOption?.value===item.value?'bg-purpleSquat1 text-white':''} border-gray-300 rounded-lg`}>
                                        {item.label}
                                    </div>
                                ))}
                            </div>
                        </Column>
                        </Column>
                        <Column className="text-center w-full">
                            <div className=" h3 font-bold">¿Cuántos pasos das en promedio por día?</div>
                            <SizedBox className='h-5'/>
                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                                {steps.map(item => (
                                    <div onClick={
                                        ()=>{
                                            setStepsOption(item);
                                        }
                                    } 
                                    key={item.value} className={`container px-4 py-2 w-max border ${stepsOption?.value===item.value?'bg-purpleSquat1 text-white':''} border-gray-300 rounded-lg`}>
                                        {item.label}
                                    </div>
                                ))}
                            </div>
                            <SizedBox className="h-4" />
                            <div className="h3 font-bold">
                                Tipo de actividad diaria
                            </div>
                            <SizedBox className="h-5"/>
                        <Column>
                            {dailyActivities.map((goal) => (
                                <div key={goal.value} className="mb-4">
                                    <CustomCard
                                        title={goal.description!}
                                        subtitle={goal.name}
                                        value={goal.value}
                                        groupValue={setSelectedValues?.value}
                                        onChange={()=>handleChanges(goal)}
                                        />
                                </div>
                            ))}
                        </Column>   
                        <SizedBox className="h-5"/>
                        <ButtonSquad loading={isLoading} buttonType={ButtonType.PurpleFull} label="Finalizar" onClick={()=>{}} />
                        <SizedBox className="h-5"/>
                    </Column>
                </Row>
            </Form>
        </div>
    </Column>
  )
)}
</Formik>
}