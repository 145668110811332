interface SubscriptionInterface{
    variant:'normal'|'selected'
}
export const SubscriptionIcon = ({variant}:SubscriptionInterface) => {
    if(variant=='selected'){
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2830_1490)">
            <path opacity="0.983" fillRule="evenodd" clipRule="evenodd" d="M7.8002 0.599609C8.0002 0.599609 8.1002 0.599609 8.3002 0.599609C8.5002 0.599609 8.6002 0.799609 8.8002 0.999609C9.4002 2.39961 10.0002 3.79961 10.6002 5.19961C12.1002 5.39961 13.6002 5.49961 15.1002 5.69961C15.4002 5.79961 15.6002 5.89961 15.8002 6.19961C15.8002 6.39961 15.8002 6.59961 15.8002 6.89961C15.6002 7.09961 15.4002 7.39961 15.1002 7.59961C14.1002 8.49961 13.1002 9.29961 12.1002 10.1996C12.5002 11.6996 12.8002 13.1996 13.1002 14.6996C13.0002 15.3996 12.6002 15.5996 11.9002 15.4996C10.6002 14.6996 9.2002 13.9996 7.9002 13.1996C6.6002 13.9996 5.2002 14.7996 3.9002 15.4996C3.2002 15.5996 2.9002 15.2996 2.7002 14.6996C3.0002 13.1996 3.4002 11.6996 3.7002 10.1996C2.7002 9.29961 1.7002 8.39961 0.700195 7.59961C0.700195 7.29961 0.500195 6.99961 0.200195 6.79961C0.200195 6.59961 0.200195 6.39961 0.200195 6.09961C0.300195 5.79961 0.500195 5.59961 0.900195 5.59961C2.4002 5.49961 3.9002 5.29961 5.4002 5.09961C6.0002 3.59961 6.7002 2.19961 7.3002 0.699609C7.5002 0.699609 7.6002 0.599609 7.8002 0.599609Z" fill="#1A19EC"/>
            </g>
            <defs>
            <clipPath id="clip0_2830_1490">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2830_1487)">
        <path opacity="0.919" fillRule="evenodd" clipRule="evenodd" d="M7.79978 0.400483C8.29978 0.300483 8.69978 0.500483 8.99978 0.900483C9.59978 2.30048 10.1998 3.60048 10.7998 4.90048C12.1998 5.10048 13.6998 5.20048 15.0998 5.40048C15.7998 5.60048 16.0998 6.00048 15.8998 6.80048C15.8998 6.90048 15.7998 7.10048 15.6998 7.20048C14.6998 8.10048 13.6998 9.00048 12.6998 9.80048C12.3998 10.0005 12.2998 10.2005 12.3998 10.6005C12.6998 11.9005 12.9998 13.3005 13.2998 14.6005C13.1998 15.4005 12.6998 15.8005 11.8998 15.6005C10.6998 14.9005 9.49978 14.3005 8.39978 13.5005C8.09978 13.3005 7.79978 13.3005 7.59978 13.5005C6.49978 14.2005 5.49978 14.8005 4.39978 15.4005C3.69978 15.7005 3.19978 15.6005 2.79978 14.9005C2.79978 14.8005 2.69978 14.6005 2.69978 14.4005C2.99978 13.1005 3.29978 11.7005 3.59978 10.4005C3.59978 10.2005 3.59978 10.1005 3.59978 9.90048C2.49978 8.90048 1.39978 8.00048 0.299781 7.00048C-0.00021863 6.60048 -0.00021863 6.10048 0.399781 5.60048C0.499781 5.60048 0.599781 5.50048 0.599781 5.50048C2.19978 5.30048 3.69978 5.10048 5.29978 5.00048C5.89978 3.70048 6.49978 2.30048 7.09978 1.00048C7.19978 0.700483 7.49978 0.500483 7.79978 0.400483ZM7.89978 2.60048C7.99978 2.60048 8.09978 2.70048 8.09978 2.80048C8.49978 3.90048 8.99978 4.90048 9.49978 6.00048C9.69978 6.30048 9.89978 6.40048 10.2998 6.50048C11.4998 6.60048 12.6998 6.70048 13.8998 6.90048C13.0998 7.70048 12.1998 8.50048 11.2998 9.20048C10.9998 9.60048 10.8998 10.0005 10.9998 10.5005C11.2998 11.6005 11.4998 12.7005 11.6998 13.8005C10.7998 13.3005 9.79978 12.8005 8.89978 12.2005C8.29978 11.8005 7.79978 11.8005 7.19978 12.2005C6.29978 12.8005 5.39978 13.3005 4.49978 13.9005C4.69978 12.8005 4.89978 11.7005 5.19978 10.6005C5.29978 10.1005 5.19978 9.60048 4.79978 9.20048C3.79978 8.30048 2.99978 7.60048 2.09978 6.80048C3.29978 6.60048 4.49978 6.50048 5.69978 6.40048C6.19978 6.30048 6.49978 6.00048 6.69978 5.50048C7.09978 4.50048 7.49978 3.50048 7.89978 2.60048Z" fill="#1A19EC"/>
        </g>
        <defs>
        <clipPath id="clip0_2830_1487">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
    </svg> 
  )
}
