import { useLocation } from "react-router-dom";
import { BarComponentStep } from "../../../../core/components/BarComponentSteps";
import { Column } from "../../../../core/components/Column";
import { Row } from "../../../../core/components/Row";
import { SizedBox } from "../../../../core/components/SizedBox";
import { OnBoardingFinishScreen } from "./OnBoardingFinishScreen";


//todo mover a profile
export const OnBoardingScreen  = () => {
    const location = useLocation();
    if(location.pathname=='/stepFinish'){
        return <OnBoardingFinishScreen/>;
    }
    return (
        <Column className="hidden md:block fixed top-0 left-0 bg-white w-1/4 h-screen shadow-2xl p-7">
            <div className="h2 font-bold font-sans text-gradient">
                Squat Fit
            </div>
            <SizedBox className="h-14"/>
            <div>
                <div className="h5">
                    Paso {
                        location.pathname==='/step1'?1:location.pathname==='/step2'?2:3
                    } de 3
                </div>
                <BarComponentStep percent={location.pathname==='/step1'?33:location.pathname==='/step2'?66:90} />
                <div className="h6 text-[#61788A]">
                    Información personal
                </div>
            </div>
            <SizedBox className="h-20"/>
            <Row>
                <img src="/check2-circle.png" className="h-10" alt=""/>
                <SizedBox className="w-3" />
                <div className="h5 pt-2 font-semibold">
                   Datos personales
                </div>
            </Row>
            <SizedBox className="h-10"/>
            <Row>
                <img src={`${location.pathname==='/step1'?'/check-circle.png':'/check2-circle.png'}`} className="h-10" alt=""/>
                <SizedBox className="w-3" />
                <div className="h5 pt-2 font-semibold">
                   Datos complementarios
                </div>
            </Row>
            <SizedBox className="h-10"/>
            <Row>
                <img src={`${location.pathname==='/step3'?'/check2-circle.png':'/check-circle.png'}`} className="h-10" alt=""/>
                <SizedBox className="w-3" />
                <div className="h5 pt-2 font-semibold">
                   Objetivos
                </div>
            </Row>
            <SizedBox className="h-14"/>
            <img src="/logo_color.png" className="h-40 max-w-36 place-self-center" alt=""/>
        </Column>
    );
};