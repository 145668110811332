import { Recipe } from "../../../../store/interfaces/food/recipeInterface";
import { Meals } from "../../../../store/slices/food/foodSlice";

interface MealCardProps {
  meal: Recipe;
}

const MealCard = ({ meal }: MealCardProps) => {
  return (
    <div className="flex items-center justify-between w-full ml-1 mt-6">
      <div className="flex flex-row items-center">
        <img src={meal.image} className="h-12 w-12 rounded-lg" />
        <div className="flex flex-col ml-4">
          <label className="text-md font-normal">{meal.name}</label>
          <label className="text-xs font-light text-gray-500">
          Preparación: {meal?.timeToPrepare} | Cocción: {meal?.timeToCook} | Kcal: {meal?.kcal}
          </label>
        </div>
      </div>
      <div className="flex ml-2">
        <label className="text-gray-400 text-sm font-normal">
          {meal.kcal} Kcal
        </label>
      </div>
    </div>
  );
};

export default MealCard;
