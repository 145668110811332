import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import CardCourse from "./components/RecommendedCourses/CardCourse";

export const HomeCoursesComponent = () => {
  const { allCourses, is_loading_all_courses } = useSelector((state: RootState) => state.course);

  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6`}
    >
      {is_loading_all_courses ? (
          <div className="flex animate-pulse mt-10 h-48 bg-slate-200 rounded-lg"></div>
        ) : (
          allCourses
            .map((course) => <CardCourse key={course.id} course={course} />)
        )}
    </div>
  );
};
