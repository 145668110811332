import { useDispatch } from "react-redux";
import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { InputType } from "../../../core/interfaces/InputSquadInterface"
import { AppDispatch } from "../../../store/store";
import { useState } from "react";
import { startRecoveryUserPassword } from "../../../store/slices/auth/thunks";
import { useNavigate } from "react-router-dom";
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft";
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight";

export const RecoverPassScreen = () => {

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		dispatch(startRecoveryUserPassword({ email })).then((res) => {
      if (res.payload === true) {
        navigate('/auth/code');
      }
    })
	};

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        <img src="/logo_color.png" className="h-40 max-w-full" alt="Logo Squat Fit"/>
        <form onSubmit={onSubmit}>
          <div className="mt-4">
            <LabelSquad children="Email"/>
            <InputSquad 
              inputType={InputType.Medium}
              value={email}
              onChange={handleChange}
              name="email"
            />
          </div>
          <div className="mt-4">
            <ButtonSquad
              full={true}
              buttonType={ButtonType.OrangeFull}
              label="Enviar código"
              type="submit"
            />
          </div>
        </form>
      </div>
      <div className="max-[800px]:hidden">
        <BackgroundTopLeft />
        <BackgroundBottomRight />
      </div>
    </div>
  )
}
