import { TextComponent } from "../../../../../core/components/TextComponent";
import { ReviewCard } from "./ReviewedCard";
import { Column } from '../../../../../core/components/Column';
import { Row } from "../../../../../core/components/Row";
import { coachs } from "../../../../../core/constants/constatns";
import Carousel from "./Carrousel";

export const Testimonials = () => {
  return (
    <section className="py-12">
      <div className="container mx-auto">
        <Column
          className="lg:flex-row"
          crossAxisAlignment="center"
        >
          <div className="lg:w-3/5">
            <TextComponent variant="h1" className="text-2xl font-bold">Consigue un cambio para siempre</TextComponent>
            <Column className="pt-4">
              <Row className="p-4">
                <div className="relative">
                  <TextComponent className="absolute top-4 left-2" variant="h2">83%</TextComponent>
                  <div className="bg-orange-400 rounded-full w-16 h-16" />
                </div>
                <Column className="pl-10">
                  <TextComponent variant="h3" className="font-semibold">
                    Logran resultados
                  </TextComponent>
                  <TextComponent variant="h5" className="font-medium">
                    en los primeros 6 meses
                  </TextComponent>
                </Column>
              </Row>
              <Row className="p-4">
                <div className="relative">
                  <TextComponent className="absolute top-4 left-2" variant="h2">10X</TextComponent>
                  <div className="bg-indigo-500 rounded-full w-16 h-16" />
                </div>
                <Column className="pl-10">
                  <TextComponent variant="h3" className="font-semibold">
                    Mejor rendimiento
                  </TextComponent>
                  <TextComponent variant="h5" className="font-medium">
                    y salud física y mental
                  </TextComponent>
                </Column>
              </Row>
              <Row className="p-4">
                <div className="relative">
                  <TextComponent className="absolute top-4 left-2" variant="h2">99%</TextComponent>
                  <div className="bg-gray-400 rounded-full w-16 h-16" />
                </div>
                <Column className="pl-10">
                  <TextComponent variant="h3" className="font-semibold">
                    Disfrutan del proceso
                  </TextComponent>
                  <TextComponent variant="h5" className="font-medium">
                    mientras aprenden
                  </TextComponent>
                </Column>
              </Row>
            </Column>
          </div>
          
          <div className="relative flex-shrink-0 md:w-1/2 max-w-md">
        <div className="hidden lg:block absolute w-[100px] top-[-40px] ml-8">
          <img src="/landing/Imagen fondo1.png" />
        </div>
            <Carousel>
              {
                (coachs[0].testimonials?? []).map((e) => (
                  <div key={e.name} className="">
                    <ReviewCard
                      imageSrc={e.image}
                      name={e.name}
                      title={e.title}
                      reviewText={e.testimonial}
                      rating="5.0"
                    />
                  </div>
                ))
              }
            </Carousel>
          </div>
        </Column>
      </div>
    </section>
  );
}
