import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { CalorieCard } from "./components/CalorieCard"
import { MealsList } from "./components/MealsList"
import { useEffect } from "react";
import { startGettingDailyKcal } from "../../../store/slices/food/thunks";
import { Column } from "../../../core/components/Column";
import { SizedBox } from "../../../core/components/SizedBox";
import { BooksScreen } from "../compras/BooksScreen";
import { ToolsScreen } from "../compras/ResumeScreen";

export const TodayScreen = () => {

  // const dispatch: AppDispatch = useDispatch();

  // const today = new Date();

  // useEffect(() => {
  //   dispatch(startGettingDailyKcal({date: today}));
  // }, [])
  

  return (
    <Column
    crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden my-6"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
        <CalorieCard />
        <SizedBox className="h-6"/>
        <MealsList />
        <BooksScreen/>
        <SizedBox className="h-6"/>
        <ToolsScreen/>
      </div>
    </Column>
  )
}
