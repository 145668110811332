import { useNavigate } from "react-router-dom";
import { CourseByUser } from "../../../../../store/interfaces/course/interfaceCourse";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { setCourseSelected } from "../../../../../store/slices/course/courseSilce";
import { TextComponent } from "../../../../../core/components/TextComponent";

interface MyCourseSmallCardProps {
  course: CourseByUser;
}

const MyCourseSmallCard = ({ course }: MyCourseSmallCardProps) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const handleWatchCourse = () => {
    dispatch(setCourseSelected(course));
    navigate(`/watch-course/${course.id}`); 
  };
  const totalVideos = course.videos.length;
  const viewedVideos = course.videos.filter(video => video.views?.is_viewed==true).length;
  const completionPercentage = totalVideos > 0 ? (viewedVideos / totalVideos) * 100 : 0;
  return (
    <div>
        <div
            className="block relative w-full h-32  cursor-pointer"
            onClick={handleWatchCourse}
            >
        <div className="absolute w-full h-full hover:opacity-10 hover:bg-black">
            
        </div>
        <img
            className="object-cover w-full h-32 hover:opacity-10 hover:bg-black cursor-pointer"
            src={course.image}
            alt="course"
        />
        </div>
        <TextComponent variant="h6" className="font-bold mt-2">{course.title}</TextComponent>
        <TextComponent variant="h7" className="">{course.tutor.firstName} {course.tutor.firstName}</TextComponent>
        <div className="h-1 w-full mt-4 bg-gray-300 rounded-full relative overflow-hidden">
            <div
                className="absolute top-0 left-0 bg-purpleSquat1 rounded-full h-full"
                style={{ width: `${completionPercentage}%` }}
                >
            </div>
        </div>
        <TextComponent variant="h7" className="">{completionPercentage.toFixed(0)} % completado</TextComponent>
    </div>
  );
};

export default MyCourseSmallCard;
