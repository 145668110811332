const CardMyBookSkeleton = () => {
  return (
    <div className="flex flex-row gap-3 hover:bg-gray-200 p-3 cursor-pointer mr-5 rounded-md">
      <div className="flex animate-pulse w-20 h-[7rem] bg-slate-200 rounded-md"></div>
      <div className="flex flex-col mt-2 gap-1">
        <span className="text-sm font-semibold">
          <div className="flex animate-pulse w-32 h-5 bg-slate-200 rounded-md"></div>
        </span>
        <span className="text-xs text-gray-400">
          <div className="flex animate-pulse w-20 h-4 bg-slate-200 rounded-md"></div>
        </span>
      </div>
    </div>
  );
};

export default CardMyBookSkeleton;
