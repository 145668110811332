import { useNavigate } from "react-router-dom";
import { UserBook } from "../../../../../store/interfaces/book/interfaceBook";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { setSelectedUserBook } from "../../../../../store/slices/book/bookSlice";

interface CardMyBookProps {
  book: UserBook;
}

const CardMyBook = ({ book }: CardMyBookProps) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const handleNavigate = () => {
    dispatch(setSelectedUserBook(book));
    navigate("/read-book/" + book.id, { replace: true });
  }

  return (
    <div className="flex flex-row gap-3 hover:bg-gray-200 p-3 cursor-pointer mr-5 rounded-md" onClick={handleNavigate}>
      <img
        className="shadow-xl rounded-md w-20 h-30 object-cover"
        src={book.image ?? "https://via.placeholder.com/150"}
        alt="book"
      />
      <div className="flex flex-col mt-2">
        <span className="text-sm font-semibold">{book.title}</span>
        <span className="text-xs text-gray-400">{book.subtitle}</span>
      </div>
    </div>
  );
};

export default CardMyBook;
