import { DateTime } from "luxon";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { setLastVideoSeen } from "../../store/slices/course/courseSilce";

const useLastVideoSeen = () => {

  const dispatch: AppDispatch = useDispatch();
  const { courseSelected, lastVideoSeen } = useSelector((state: RootState) => state.course);
  let maxViewedAt: DateTime<true> | DateTime<false>;
  let lastVideoSeenId: string;

  const handleSetLastVideoSeen = (videoId: string) => {
    dispatch(setLastVideoSeen(videoId));
    localStorage.setItem("lastVideoSeen", videoId);
  };

  const handleLastVideoSeen = () => {
    if (!courseSelected) {
      return;
    }

    if (!courseSelected.videos) {
      return;
    }

    if (courseSelected.videos.length === 1) {
      handleSetLastVideoSeen(courseSelected.videos[0].video_id);
      return;
    }

    if (courseSelected.videos.every((video) => video.views === null)) {
      handleSetLastVideoSeen(courseSelected.videos[0].video_id);
      return;
    }

    courseSelected?.videos.map((video) => {
      if (!video.views) {
        return;
      }
      const viewedAt = DateTime.fromISO(video.views.viewed_at);
      if (!maxViewedAt) {
        maxViewedAt = viewedAt;
        handleSetLastVideoSeen(video.video_id);
        return;
      }
      if (viewedAt > maxViewedAt) {
        maxViewedAt = viewedAt;
        handleSetLastVideoSeen(video.video_id);
      }
    });

    if (!lastVideoSeen) {
      return;
    }
  };

  return { handleLastVideoSeen, HandleSetLastVideoSeen: handleSetLastVideoSeen };
};

export default useLastVideoSeen;
