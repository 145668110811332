import { AddIcon } from "../../../../../assets/icons/AddIcon";
import { GraduationIcon } from "../../../../../assets/icons/GraduationIcon";
import { VictoryIcon } from "../../../../../assets/icons/VictoryIcon";
import { Column } from "../../../../../core/components/Column";
import { StadisticsCard } from "./StadisticsCard";

export const InitialPage = () => {
  return (
    <div className="flex flex-col lg:flex-row items-end justify-between px-8 pt-8 bg-white">
      <div className="lg:w-1/2">
      <Column className=" h-full">
        <h1 className="text-4xl lg:text-6xl font-bold text-gray-900 mb-4">La mejor plataforma del fitness</h1>
        <p className="text-lg text-gray-700 mb-20">
          Entra y consigue las herramientas que te ayudarán a lograr tu mejor versión
        </p>
          <div 
            className="my-12 grid grid-cols-2 md:grid-cols-3 gap-6"

          >
            <StadisticsCard
              icon={<VictoryIcon height="[40px]" width="[40px]"/>}
              text1="+1700"
              text2="vidas cambiadas"
            />
            <StadisticsCard
              icon={<AddIcon height="[40px]" width="[40px]"/>}
              text1="+1M"
              text2="seguidores en las redes"
            />
            <StadisticsCard
              icon={<GraduationIcon height="[40px]" width="[40px]"/>}
              text1="+250"
              text2="alumnos aprendiendo"
            />
          </div>

      </Column>
      </div>
      <div className="hidden lg:flex relative lg:w-1/2 justify-center">
        <div className="absolute bottom-0 right-0 w-[500px] h-[500px] bg-orange-500 rounded-full hidden lg:block"></div>
        <img
          src="/landing/IMG Maria y Hamlet.png"
          alt="Fitness Platform"
          className="w-full max-w-3xl z-10"
        />
      </div>
    </div>
    
  );
}