import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { createPaypalSub, createStripeSub, getAdviceByUser, getAllAdvicers, getAllSubs } from './thunks'
import { Coach, SubData, Subscription } from '../../interfaces/coach/interfaceCoach'

export interface CoachState {
  advisers: Coach[]
  subscriptions: Subscription[]
  subData: SubData
  coachSelected: Coach
  planSelected: Subscription
  is_loading_advice: boolean
  is_loading_advice_coach: boolean
  is_loading_advice_by_user: boolean
  is_loading_order_with_paypal: boolean;
  is_loading_order_with_stripe: boolean;
}

const subDataInitBody: SubData = {
  title: '',
  subtitle: '',
  date: '' ,
  date_expiration: '',
  status: '',
  purchase_from: '',
  progress: [],
  adviser_id: '',
  adviser_firstName: '',
  adviser_lastName: '',
  adviser_email: '',
  adviser_phone: '',
  adviser_whatsapp: '',
  adviser_description: '',
  adviser_profile_picture: '',
  suscription_name: '',
  suscription_description: '',
  suscription_duration: 0,
  suscription_type: ''
}
const coachInitBody: Coach = {
  id: '',
  firstName: '',
  lastName: '',
  profile_picture: '',
  description: '',
}
const subInitBody: Subscription = {
  id: '',
  adviser_id: '',
  name: '',
  description: '',
  price: '',
  duration: 0,
  type: ''
}

const initialState: CoachState = {
  advisers: [],
  subscriptions: [],
  subData: subDataInitBody,
  coachSelected: coachInitBody,
  planSelected: subInitBody,
  is_loading_advice: false,
  is_loading_advice_coach: false,
  is_loading_advice_by_user: false,
  is_loading_order_with_paypal: false,
  is_loading_order_with_stripe: false,
}

export const coachSlice = createSlice({
  name: 'coach',
  initialState,
  reducers: {
    onSelectCoach: (state, action: PayloadAction<Coach>) => {
      state.coachSelected = action.payload;
    },
    onStartAdvice: (state) => {
      if(state.subData){

        state.subData = {...state.subData,status_advice:{...state.subData.status_advice,status_advice:'Preparando'}};
      }
    },
    onSelectPlan: (state, action: PayloadAction<Subscription>) => {
      state.planSelected = action.payload;
    },
    cleanPlanSelected: (state) => {
      state.planSelected = subInitBody;
    },
    cleanSubs: (state) => {
      state.subscriptions = [];
    }
  },
  extraReducers: (builder) => {
    builder
    .addCase(getAllAdvicers.fulfilled, (state, action: PayloadAction<Coach[]>) => {
      state.advisers = action.payload;
      state.is_loading_advice_coach = false;
    })
    .addCase(getAllAdvicers.pending, (state) => {
      state.is_loading_advice_coach = true;
    })
    .addCase(getAdviceByUser.pending, (state) => {
      state.is_loading_advice_by_user = true;
    })
    .addCase(getAdviceByUser.fulfilled, (state, action: PayloadAction<SubData>) => {
      state.subData = action.payload;
      state.is_loading_advice_by_user = false;
    })
    .addCase(getAdviceByUser.rejected, (state) => {
      state.is_loading_advice_by_user = false;
    })
    .addCase(getAllSubs.fulfilled, (state, action: PayloadAction<Subscription[]>) => {
      state.subscriptions = action.payload;
      state.is_loading_advice = false;
    })
    .addCase(getAllSubs.pending, (state) => {
      state.is_loading_advice = true;
    })
    .addCase(createPaypalSub.pending, (state) => {
      state.is_loading_order_with_paypal = true;
    })
    .addCase(createPaypalSub.fulfilled, (state) => {
      state.is_loading_order_with_paypal = false;
    })
    .addCase(createPaypalSub.rejected, (state) => {
      state.is_loading_order_with_paypal = false;
    })
    .addCase(createStripeSub.pending, (state) => {
      state.is_loading_order_with_stripe = true;
    })
    .addCase(createStripeSub.fulfilled, (state) => {
      state.is_loading_order_with_stripe = false;
    })
    .addCase(createStripeSub.rejected, (state) => {
      state.is_loading_order_with_stripe = false;
    })
  }
})

export const { onSelectCoach, onSelectPlan, cleanPlanSelected, cleanSubs,onStartAdvice } = coachSlice.actions
export default coachSlice.reducer