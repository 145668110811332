import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components"
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { InputType } from "../../../core/interfaces/InputSquadInterface"
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../store/store";
import { startUpdateUserPassword } from "../../../store/slices/auth/thunks";
import { usePasswordPolicy } from "../../hooks/usePasswordPolicy";
import { useEncrypt } from "../../hooks/useEncrypt";
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft";
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight";

export const NewPasswordScreen = () => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const {
    hasMinLength,
    hasNumber,
    hasSpecialCharacter,
    hasUpperCase,
    validatePasswordHasEqual
   } = usePasswordPolicy();

  const [password, setPassword] = useState('');
  const [pass, setPass] = useState('');

  const { decryptPassword } = useEncrypt();

  const handdleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  const handdleChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPass(event.target.value);
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {

    event.preventDefault();

    if (password === pass) {
      const isOldPassword = decryptPassword(localStorage.getItem('encryptedPassword') as string)
      dispatch(startUpdateUserPassword({ temporalPass: isOldPassword, newPass: password})).then(() => {
        navigate('/');
      });
    }
  }

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        <img src="/logo_color.png" className="h-40 max-w-full" alt=""/>
        <form onSubmit={onSubmit}>
          <div className="mt-4">
            <LabelSquad children="Contraseña"/>
            <InputSquad 
              inputType={InputType.Medium}
              onChange={handdleChange}
              value={password}
              name="password"
              type="password"
            />
          </div>
          <div className="mt-4">
            <LabelSquad children="Repetir contraseña"/>
            <InputSquad 
              inputType={InputType.Medium}
              onChange={handdleChange2}
              value={pass}
              name="pass"
              type="password"
            />
          </div>
          <div className="mt-4">
            <ButtonSquad
              full={true}
              buttonType={ButtonType.OrangeFull}
              label="Cambiar contraseña"
              type="submit"
            />
          </div>
        </form>
        {
          password.length > 0 && (
            <div className="mt-4" style={{ fontSize: 12 }}>
              <ul>
                <li><i className="fa-solid fa-check" style={{ color: hasSpecialCharacter(password) ? "#2ECC71" : "gray" }}></i> &nbsp; Debe tener al menos un caracter especial</li>
                <li><i className="fa-solid fa-check" style={{ color: hasUpperCase(password) ? "#2ECC71" : "gray" }}></i> &nbsp; Debe tener al menos un mayúscula</li>
                <li><i className="fa-solid fa-check" style={{ color: hasNumber(password) ? "#2ECC71" : "gray" }}></i> &nbsp; Debe tener al menos un número</li>
                <li><i className="fa-solid fa-check" style={{ color: hasMinLength(password) ? "#2ECC71" : "gray" }}></i> &nbsp; Debe tener al menos 8 caracteres</li>
                <li><i className="fa-solid fa-check" style={{ color: validatePasswordHasEqual(password, pass) ? "#2ECC71" : "gray" }}></i> &nbsp; La contraseñas coinciden</li>
              </ul>
            </div>
          )
        }
      </div>
      <div className="max-[800px]:hidden">
        <BackgroundTopLeft />
        <BackgroundBottomRight />
      </div>
    </div>
  )
}
