import PaypalLogo from "../../assets/logos/PaypalLogo";
import StripeLogo from "../../assets/logos/StripeLogo";
import useScreen from "../hooks/useScreen";

export type OrderType = "stripe" | "paypal";

interface PurchaseByProps {
  createOrder: (type: OrderType) => void;
  is_loading_with_stripe: boolean;
  is_loading_with_paypal: boolean;
  className?: string;
}

const PurchaseBy = ({
  className,
  createOrder,
  is_loading_with_paypal,
  is_loading_with_stripe,
}: PurchaseByProps) => {
  const { isSmallScreen } = useScreen();
  return (
    <div
      className={
        className ??
        `  z-10 bg-black rounded-xl p-2 w-72 shadow-xl`
      }
    >
      <div className="flex flex-col gap-2">
        <button
          className={`flex justify-center bg-[#6772E5] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ${
            is_loading_with_stripe || is_loading_with_paypal
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          onClick={
            !is_loading_with_stripe && !is_loading_with_paypal
              ? () => createOrder("stripe")
              : () => {}
          }
        >
          <StripeLogo />
        </button>
        <button
          className={`flex justify-center bg-[#ffc439] hover:bg-yellow-500 text-white font-bold py-2 px-4 rounded ${
            is_loading_with_paypal || is_loading_with_stripe
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          onClick={
            !is_loading_with_paypal && !is_loading_with_stripe
              ? () => createOrder("paypal")
              : () => {}
          }
        >
          <PaypalLogo />
        </button>
      </div>
    </div>
  );
};

export default PurchaseBy;
