const MealCardSkeleton = () => {
  return (
    <div className="flex items-center justify-between w-full ml-1 mt-6">
      <div className="flex flex-row items-center">
        <div className="flex animate-pulse w-20 h-20 bg-slate-200 rounded-lg"></div>
        <div className="flex flex-col ml-4 gap-2">
          <label className="text-md font-normal">
            <div className="flex animate-pulse w-28 h-4 bg-slate-200 rounded-md"></div>
          </label>
          <label className="text-xs font-light text-gray-500">
            <div className="flex animate-pulse w-14 h-4 bg-slate-200 rounded-md"></div>
          </label>
        </div>
      </div>
      <div className="flex ml-2 mr-5">
        <label className="text-gray-400 text-sm font-normal">
          <div className="flex animate-pulse w-14 h-4 bg-slate-200 rounded-md"></div>
        </label>
      </div>
    </div>
  );
};

export default MealCardSkeleton;
