
interface IconPops {
  width: string
  height: string
}

export const AddIcon = ({height, width}: IconPops) => {
  return (
    <div>
      <img src="/landing/icon add.png" alt="Fitness" className={`rounded-lg h-${height} w-${width}`} />
    </div>
  )
}
