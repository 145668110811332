import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import MyCourseCard from "./MyCourseCard";
import UserHasNoCoursesCard from "./UserHasNoCoursesCard";
import MyCourseCardSkeleton from "./MyCourseCardSkeleton";
import { Column } from "../../../../../core/components/Column";
import { TextComponent } from "../../../../../core/components/TextComponent";

const MyCourses = () => {
  const { courseByUser, is_loading_course_by_user } = useSelector(
    (state: RootState) => state.course
  );

  return (
    <Column>
        <TextComponent variant="h3" className="font-semibold">Mis Cursos</TextComponent>
      <div
        className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
      >
        {is_loading_course_by_user ? (
          Array.from({ length: 4 }).map((_, index) => (
            <MyCourseCardSkeleton key={index} />
          ))
        ) : (
          courseByUser.map((course) => (
            <MyCourseCard key={course.id} course={course} />
          ))
        )}
        {courseByUser.length === 0 && !is_loading_course_by_user && (
          <UserHasNoCoursesCard />
        )}
      </div>
    </Column>
  );
};

export default MyCourses;
