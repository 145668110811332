import { useDispatch } from "react-redux";
import { AppDispatch } from "../../store/store";
import { acceptFriendRequest, rejectFriendRequest } from '../../store/slices/social/thunks';
import { toast } from "react-toastify";
import { useAlert } from "./useAlert";

const useFriendRequest = () => {
  const dispatch: AppDispatch = useDispatch();
  const { showAlert } = useAlert();

  const onAccept = (userId: string) => {
    toast.dismiss(userId);
    dispatch(acceptFriendRequest({ userId })).then(() => {
      showAlert({
        message: "Solicitud aceptada con éxito",
        type: "success",
      });
    });
  };

  const onReject = (userId: string) => {
    toast.dismiss(userId);
    dispatch(rejectFriendRequest({ userId })).then(() => {
      showAlert({
        message: "Solicitud rechazada con éxito",
        type: "success",
      });
    });
  };

  return {
    onAccept,
    onReject,
  };
};

export default useFriendRequest;
