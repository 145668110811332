import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Book, ReadBook, UserBook } from "../../interfaces/book/interfaceBook";
import {
  createOrderWithPaypal,
  createOrderWithStripe,
  getAllBooks,
  getBooksByUser,
  readBookById,
} from "./thunks";

interface BookState {
  books: Book[];
  userBooks: UserBook[];
  readBook: ReadBook | null;
  is_loading_all_books: boolean;
  is_loading_user_books: boolean;
  is_loading_read_book: boolean;
  selectedBook: Book | null;
  selectedUserBook: UserBook | null;
  is_loading_order_with_paypal: boolean;
  is_loading_order_with_stripe: boolean;
}

const initialState: BookState = {
  books: [],
  userBooks: [],
  readBook: null,
  is_loading_all_books: false,
  is_loading_user_books: false,
  is_loading_read_book: false,
  selectedBook: null,
  selectedUserBook: null,
  is_loading_order_with_paypal: false,
  is_loading_order_with_stripe: false,
};

const bookSlice = createSlice({
  name: "book",
  initialState,
  reducers: {
    setSelectedBook: (state, action: PayloadAction<Book>) => {
      state.selectedBook = action.payload;
    },
    cleanSelectedBook: (state) => {
      state.selectedBook = null;
    },
    setSelectedUserBook: (state, action: PayloadAction<UserBook>) => {
      state.selectedUserBook = action.payload;
    },
    cleanSelectedUserBook: (state) => {
      state.selectedUserBook = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBooks.pending, (state) => {
        state.is_loading_all_books = true;
      })
      .addCase(
        getAllBooks.fulfilled,
        (state, action: PayloadAction<Book[]>) => {
          state.books = action.payload;
          state.is_loading_all_books = false;
        }
      )
      .addCase(getBooksByUser.pending, (state) => {
        state.is_loading_user_books = true;
      })
      .addCase(
        getBooksByUser.fulfilled,
        (state, action: PayloadAction<UserBook[]>) => {
          state.userBooks = action.payload;
          state.is_loading_user_books = false;
        }
      )
      .addCase(readBookById.pending, (state) => {
        state.is_loading_read_book = true;
      })
      .addCase(
        readBookById.fulfilled,
        (state, action: PayloadAction<ReadBook>) => {
          state.readBook = action.payload;
          state.is_loading_read_book = false;
        }
      )
      .addCase(createOrderWithPaypal.pending, (state) => {
        state.is_loading_order_with_paypal = true;
      })
      .addCase(createOrderWithPaypal.fulfilled, (state) => {
        state.is_loading_order_with_paypal = false;
      })
      .addCase(createOrderWithPaypal.rejected, (state) => {
        state.is_loading_order_with_paypal = false;
      })
      .addCase(createOrderWithStripe.pending, (state) => {
        state.is_loading_order_with_stripe = true;
      })
      .addCase(createOrderWithStripe.fulfilled, (state) => {
        state.is_loading_order_with_stripe = false;
      })
      .addCase(createOrderWithStripe.rejected, (state) => {
        state.is_loading_order_with_stripe = false;
      });
  },
});

export const {
  setSelectedBook,
  setSelectedUserBook,
  cleanSelectedBook,
  cleanSelectedUserBook,
} = bookSlice.actions;
export default bookSlice.reducer;
