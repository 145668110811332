import { Column } from "../../../core/components/Column"
import { Row } from "../../../core/components/Row"
import { SizedBox } from "../../../core/components/SizedBox"
import { TextComponent } from "../../../core/components/TextComponent"
import { faqsAsesorias, faqsCursos } from "../../../core/constants/constatns"
import { FaqComponent } from "./components/FaqComponent"


export const FaqsScreen = () => {
  return (
    <Column
    crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden my-6"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
    <TextComponent variant="h1" className="font-bold">
        Preguntas frecuentes
    </TextComponent>
    <SizedBox className="h-6" />
    <Row className="gap-12" responsiveCol={true}>
        <Column className="md:w-1/2">
            <TextComponent variant="h2" className="font-semibold">
                Asesorías
            </TextComponent>
            <SizedBox className="h-6" />
            {
              faqsAsesorias.map((e,index)=>(
                <FaqComponent
                    key={index}
                    title={e.title}
                    subtitle={e.subtitle}
                />
              ))
            }
        </Column>
        <Column className="md:w-1/2">
            <TextComponent variant="h2" className="font-semibold">
                Cursos
            </TextComponent>
            <SizedBox className="h-6" />
            {
              faqsCursos.map((e,index)=>(
                <FaqComponent
                    key={index}
                    title={e.title}
                    subtitle={e.subtitle}
                />
              ))
            }
        </Column>
    </Row>
    </div>
    </Column>
  )
}
