import BackgroundTopLeftImage from "./background_top_left.png";

const BackgroundTopLeft = () => {
  return (
    <div className="absolute left-0 top-0">
      <img src={BackgroundTopLeftImage} className="w-80" />
    </div>
  );
};

export default BackgroundTopLeft;
