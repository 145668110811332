import { useEffect, useRef, useState } from "react";
import { ButtonSquad } from "../../../../../core/components";
import { ButtonType } from "../../../../../core/interfaces/ButtonSquadInterface";
import { Course } from "../../../../../store/interfaces/course/interfaceCourse";
import useScreen from "../../../../hooks/useScreen";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../../store/store";
import {
  createOrderWithPaypal,
  createOrderWithStripe,
  getCourseByUser,
  getCourseReview,
} from "../../../../../store/slices/course/thunks";
import PurchaseBy, { OrderType } from "../../../../components/PurchaseBy";
import { Column } from "../../../../../core/components/Column";
import { Row } from "../../../../../core/components/Row";
import { TextComponent } from "../../../../../core/components/TextComponent";
import { useNavigate, useParams } from "react-router-dom";
import {
  coachs,
  coursesLocal,
  faqsCursos,
} from "../../../../../core/constants/constatns";
import VideoComponent from "../VideoComponent";
import { SizedBox } from "../../../../../core/components/SizedBox";
import { StarComponent } from "../../../../../core/components/StarComponent";
import { FaqComponent } from "../../../profile/components/FaqComponent";

export const BuyCourseMainContainer = () => {
  const navigate = useNavigate();
  const { isSmallScreen } = useScreen();
  const dispatch: AppDispatch = useDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);

  const { is_loading_order_with_stripe, is_loading_order_with_paypal,review } =
    useSelector((state: RootState) => state.course);
  const [isOpenModalBuyCourse, setIsOpenModalBuyCourse] = useState(false);

  const handleClickOutside = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (wrapperRef.current && wrapperRef.current === event.target) {
      setIsOpenModalBuyCourse(false);
    }
  };

  const createOrder = (type: OrderType) => {
    if (type === "stripe") {
      dispatch(createOrderWithStripe({ courseId: course?.id as string })).then(
        (result) => {
          if (result.meta.requestStatus === "fulfilled") {
            window.open(result.payload as string, "_blank");
          }
        }
      );
    } else {
      dispatch(createOrderWithPaypal({ courseId: course?.id as string })).then(
        (result) => {
          if (result.meta.requestStatus === "fulfilled") {
            window.open(result.payload as string, "_blank");
          }
        }
      );
    }
  };
  const { allCourses, courseByUser } = useSelector((state: RootState) => state.course);
  const { id } = useParams<{ id: string }>();
  const [course, setCourse] = useState<Course | null>(null);

  useEffect(() => {
    if (courseByUser.length > 0) return;
    dispatch(getCourseByUser());
  }, [courseByUser]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(getCourseReview({courseId:id??''}))
    const find = allCourses.find((e) => e.id == id);
    if (find) {
      setCourse(find);
    }
  }, []);
  const handleNavigate = () => {
    navigate(`/watch-course/${course?.id}`);
  }
  return (
    <Column
      crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden"
    >
      <div className="w-full md:w-3/4 px-12 md:p-0 flex h-full grow flex-col">
      <Column
          className="flex min-h-[480px] gap-6 bg-cover bg-center bg-no-repeat  items-start justify-end px-4 pb-10 "
          style={{
            backgroundPosition: "right 0px",
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.6) 100%), url(${course?.image})`,
          }}
        >
          <img
            src={coursesLocal.find((e) => e.id === course?.id)?.contentImage}
            className="w-60 h-60"
          />
          <TextComponent
            variant="h1"
            className="text-white text-4xl font-black"
          >
            {course?.title}
          </TextComponent>
          <TextComponent
            variant="h2"
            className="text-white text-sm font-normal "
          >
            {course?.subtitle}
          </TextComponent>
          <>
            {isOpenModalBuyCourse && (
              <div className="flex relative justify-center min-[768px]:w-1/2 max-[768px]:w-full">
                <PurchaseBy
                  className={`absolute z-50 bottom-[0px] bg-white rounded-xl p-2 w-2/3 max-[767px]:w-full shadow-xl`}
                  createOrder={createOrder}
                  is_loading_with_paypal={is_loading_order_with_paypal}
                  is_loading_with_stripe={is_loading_order_with_stripe}
                />
              </div>
            )}
            {
              courseByUser.some((e) => e.id === course?.id) ? (
                <ButtonSquad
                  buttonType={ButtonType.PurpleFull}
                  label="Ir al curso"
                  onClick={handleNavigate}
                />
              ) : (
                <ButtonSquad
                  buttonType={ButtonType.PurpleFull}
                  label="Comprar ahora"
                  onClick={() => setIsOpenModalBuyCourse((prev) => !prev)}
                />
              )
            }
          </>
        </Column>
            <TextComponent variant="h3" className="font-bold pb-2 pt-4">Sobre el instructor</TextComponent>
            <Row responsiveCol={true} className="gap-4">
              <div className="rounded-full min-w-32 min-h-32 max-w-32 max-h-32">
                  <img src={coachs.find((e)=>e.firstName===course?.tutor.firstName)?.profile_picture} className="object-cover rounded-full min-w-32 min-h-32 max-w-32 max-h-32"/>
              </div>
              <Column className="justify-center">
                <TextComponent className=" text-[22px] font-bold ">{course?.tutor.firstName} {course?.tutor.lastName}</TextComponent>
                <TextComponent className="text-fadedAsh text-base font-normal">
                {coachs.find((e)=>e.firstName===course?.tutor.firstName)?.mision}
                </TextComponent>
              </Column>
            </Row>
            <Column className="flex-1 gap-2 mt-4 rounded-xl p-6 border border-[#d1e6e0]">
              <TextComponent className=" text-base font-medium">{course?.students} estudiantes</TextComponent>
            </Column>
            <TextComponent variant="h3" className="leading-tight pb-2 pt-4">Que Incluye</TextComponent>
            <TextComponent variant="h3" className="font-bold">Lo que Necesitas para Alcanzar el Objetivo</TextComponent>
            <Row className="grid grid-cols-1 md:grid-cols-4 gap-3 py-4">
              {
                coursesLocal.find((e)=>e.id===course?.id)?.tools?.map((e,index)=>(
                  <Column key={index} className="gap-1 rounded-lg border border-[#d1e6e0] bg-[#f8fbfb] p-4">
                    <TextComponent variant="h2" className=" text-base font-bold">{e.title}</TextComponent>
                    <TextComponent variant="h4" className="fadedAsh">{e.subtitle}</TextComponent>
                  </Column>
                ))
              }
            </Row>
            <TextComponent variant="h3" className="font-bold pb-2 pt-4">Contenido</TextComponent>
            {
              coursesLocal.find((e)=>e.id===course?.id)?.content?.map((e,index)=>(
                <FaqComponent
                  key={index}
                  color="text-purpleSquat1"
                  title={e.title}
                  subtitle={`${e.content.map((e)=>e).join('\n')}`}
              />

              ))
            }
            <SizedBox className="hidden md:block h-6"/>
            <TextComponent variant="h3" className="hidden md:block text-lg font-bold pt-4">Course Preview</TextComponent>
            <SizedBox className="hidden md:block h-6"/>
            <Column className="hidden md:block relative items-center justify-center w-full bg-white">
              <div className="relative w-full h-[450px] ">
                
                <Column crossAxisAlignment="center" mainAxisAlignment="center" className="w-full">
                <Column crossAxisAlignment="center" mainAxisAlignment="center" className="bg-black">
                  <VideoComponent url={course?.video_presentation ?? ''} />
                </Column>
                </Column>
                {
                  coursesLocal.find((e)=>e.id===course?.id)?.benefits?.map((e,index)=>(
                    <div key={index} className={`absolute ${index===0?'top-0 md:-left-20':' md:-right-20'} top-10 shadow-lg w-1/3 p-4 bg-white bg-opacity-70`}>
                      <h2 className="text-2xl font-bold mb-4">{e.title}</h2>
                      <ul className="list-disc pl-5">
                        {
                          e.content.map((e,index)=>(
                            <li key={index} className="h5">{e}</li>
                          ))
                        }
                      </ul>
                    </div>
                  ))
                }
              </div>
            </Column>
            <SizedBox className=" h-6"/>
            {
              (review?.reviews.length??0)>0&&<TextComponent variant="h3" className="font-bold pb-2">Reviews</TextComponent>
            }
            
            {
              review?.reviews.map((e,index)=>(
                <Column key={index} className="flex-col gap-8 overflow-x-hidden bg-[#f8fbfb] p-4">
                  <Column className="gap-3 bg-[#f8fbfb]">
                    <Column className="flex-1">
                      {/* <TextComponent className=" text-base font-medium">Liz D.</TextComponent> */}
                      <TextComponent className="fadedAsh text-sm font-normal">{e.createdAt.toUTCString()}</TextComponent>
                    </Column>
                    <Row className="gap-0.5">
                      <StarComponent state={e.rate>=1?0:1}/>
                      <StarComponent state={e.rate>=2?0:1}/>
                      <StarComponent state={e.rate>=3?0:1}/>
                      <StarComponent state={e.rate>=4?0:1}/>
                      <StarComponent state={e.rate>=5?0:1}/>
                    </Row>
                    <TextComponent className=" text-base font-normal">
                      {e.reviewText}
                    </TextComponent>
                  </Column>
                </Column>
              ))
            }
            
            <SizedBox className=" h-6"/>
            <TextComponent variant="h2" className="font-bold pb-3 pt-5">Preguntas frecuentes</TextComponent>
            {
              faqsCursos.map((e,index)=>(
                <FaqComponent
                    key={index}
                    color="text-purpleSquat1"
                    title={e.title}
                    subtitle={e.subtitle}
                />
              ))}
            {
              courseByUser.some((e) => e.id === course?.id) ? (
                <ButtonSquad
                  buttonType={ButtonType.PurpleFull}
                  label="Ir al curso"
                  onClick={handleNavigate}
                />
              ) : (
                <ButtonSquad
                  buttonType={ButtonType.PurpleFull}
                  label="Comprar ahora"
                  onClick={() => setIsOpenModalBuyCourse((prev) => !prev)}
                />
              )
            }
            <SizedBox className=" h-6"/>
      </div>
    </Column>
  );
};
