import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../store/store";
import { deleteAccount, startLogout } from "../../../../store/slices/auth/thunks";
import { useAlert } from "../../../hooks/useAlert";
import { useNavigate } from "react-router-dom";

interface Props {
  onCloseModal: () => void;
}

const initialState: any = {
  "one": "",
  "two": "",
  "three": "",
  "four": ""
};

export const DeleteCode = ({ onCloseModal }: Props) => {

  const dispatch: AppDispatch = useDispatch();
  const [code, setCode] = useState(initialState);
  const { showAlert } = useAlert();
  const navigate = useNavigate();

  const input1Ref = useRef<HTMLInputElement>(null);
  const input2Ref = useRef<HTMLInputElement>(null);
  const input3Ref = useRef<HTMLInputElement>(null);
  const input4Ref = useRef<HTMLInputElement>(null);

  const handleOnChange = (value: string, nextInputRef: React.RefObject<HTMLInputElement>) => {
    if (value.length === 1 && nextInputRef.current) {
      nextInputRef.current.focus();
    }
  };

  const handlePaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const pasteData = event.clipboardData.getData('text');
    setCode({
      "one": pasteData.charAt(0).toUpperCase(),
      "two": pasteData.charAt(1).toUpperCase(),
      "three": pasteData.charAt(2).toUpperCase(),
      "four": pasteData.charAt(3).toUpperCase()
    })
    input4Ref.current?.focus();
    (input4Ref.current as HTMLInputElement).value = pasteData.charAt(3).toUpperCase();
  };

  const onSendCode = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const finalCode: string = code.join('');
    dispatch(deleteAccount(finalCode)).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        dispatch(startLogout());
        localStorage.clear();
        navigate("/auth/login");
      } else if (res.meta.requestStatus === "rejected") {
        showAlert({
          type: "error",
          message: "El código ingresado es incorrecto",
        });
      }
    });
  }

  useLayoutEffect(() => {
    if (input1Ref.current) {
      input1Ref.current?.focus();
    }
  }, [input1Ref.current])

  return (
    <div className="flex flex-col justify-center items-center">
      <form onSubmit={onSendCode}>
        <div className="flex flex-row mt-4">
          <input
            ref={input1Ref}
            className="w-14 h-14 border-2 focus:ring-1 items-center justify-center text-center text-lg border-gray-80 rounded-lg"
            maxLength={1}
            type="text"
            value={code["one"]}
            onChange={(e) => {
              setCode((prev: any) => ({
                ...prev,
                "one": e.target.value.toUpperCase()
              }))
              handleOnChange(e.target.value, input2Ref);
            }
            }
            onPaste={handlePaste}
          />
          <input
            ref={input2Ref}
            className="w-14 h-14 border-2 items-center justify-center text-center text-lg border-gray-80 rounded-lg ml-8"
            maxLength={1}
            value={code["two"]}
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                input1Ref.current?.focus();
              }
            }}
            onChange={(e) => {
              setCode((prev: any) => ({
                ...prev,
                "two": e.target.value.toUpperCase()
              }))
              handleOnChange(e.target.value, input3Ref);
            }
            }
          />
          <input
            ref={input3Ref}
            className="w-14 h-14 border-2 items-center justify-center text-center text-lg border-gray-80 rounded-lg ml-8"
            maxLength={1}
            value={code["three"]}
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                input2Ref.current?.focus();
              }
            }}
            onChange={(e) => {
              setCode((prev: any) => ({
                ...prev,
                "three": e.target.value.toUpperCase()
              }))
              handleOnChange(e.target.value, input4Ref);
            }
            }
          />
          <input
            ref={input4Ref}
            className="w-14 h-14 border-2 items-center justify-center text-center text-lg border-gray-80 rounded-lg ml-8"
            maxLength={1}
            value={code["four"]}
            type="text"
            onKeyDown={(e) => {
              if (e.key === "Backspace") {
                setCode((prev: any) => ({
                  ...prev,
                  "four": ""
                }))
                input3Ref.current?.focus();
              }
            }}
            onChange={(e) => setCode((prev: any) => ({
              ...prev,
              "four": e.target.value.toUpperCase()
            }))}
          />
        </div>
        <div className="justify-center px-4 py-3 sm:px-6 sm:flex sm:flex-row">
          <button
            type="button"
            className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={() => {
              setCode(initialState);
              onCloseModal()
            }}
          >
            Cancelar
          </button>
          <button type="submit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">Eliminar</button>
        </div>
      </form>
    </div>
  )
}
