import React from 'react';
import { NutritionalValue } from '../../../../store/interfaces/food/recipeInterface';
import { Column } from '../../../../core/components/Column';

interface NutritionWidgetProps {
  title:string
  subtitle: string;
}

const NutritionWidget: React.FC<NutritionWidgetProps> = ({ title, subtitle }) => {
  
  return (
    <Column>
      <div className="w-full h-10 flex items-center justify-center bg-transparent border border-gray-300 rounded-t-lg">
        <span className="font-bold text-lg">{title}</span>
      </div>
      <div className="w-full h-10 flex items-center justify-center bg-transparent border border-gray-300 border-t-0 rounded-b-lg">
        <span className="text-lg">{subtitle}</span>
      </div>
    </Column>
  );
};

export default NutritionWidget;
