import { useDispatch, useSelector } from "react-redux";
import ListBookContainer from "./components/myPurchases/ListBookContainer";
import { AppDispatch, RootState } from "../../../store/store";
import { getBooksByUser } from "../../../store/slices/book/thunks";
import { useEffect } from "react";

const MyPurchases = () => {
  const dispatch: AppDispatch = useDispatch();
  const { userBooks } = useSelector((state: RootState) => state.book);

  useEffect(() => {
    if (userBooks.length === 0) {
      dispatch(getBooksByUser());
    }
  }, []);

  return (
    <div>
      <div className="flex flex-col ml-3">
        <div className="flex flex-col">
          <h1 className="font-semibold text-xl">Mis Compras</h1>
        </div>
        <ListBookContainer />
      </div>
    </div>
  );
};

export default MyPurchases;
