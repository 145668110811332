import MessageIcon from "../../../../assets/icons/Message";
import useScreen from "../../../hooks/useScreen";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { setShowListChats } from "../../../../store/slices/social/socialSlice";
import { PostCard } from "./post/PostCard";
import { NoPost } from "./post/NoPost";
import { useLayoutEffect, useRef, useState } from "react";
import { startGettingPosts } from "../../../../store/slices/post/thunks";
import { FriendsPost } from "../../../../store/interfaces/social/interfacePost";
import { PostCardSkeleton } from "./post/PostCardSkeleton";

interface PostContainerProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const PostContainer = ({ page, setPage }: PostContainerProps) => {
  const { postFeed, is_loading_post_feed } = useSelector(
    (state: RootState) => state.post
  );
  const { isCellphoneScreen, isSmallScreen } = useScreen();

  const dispatch: AppDispatch = useDispatch();
  const scrollPost = useRef<HTMLDivElement | null>(null);
  const [isOnBottom, setIsOnBottom] = useState(false);

  const scrollOnBottom = () => {
    if (scrollPost.current) {
      return (
        Math.round(scrollPost.current.scrollTop) +
          scrollPost.current.clientHeight ===
          scrollPost.current.scrollHeight ||
        Math.round(scrollPost.current.scrollTop) +
          scrollPost.current.clientHeight +
          1 ===
          scrollPost.current.scrollHeight
      );
    }
    return false;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (scrollPost.current) {
        if (
          scrollOnBottom() &&
          !isOnBottom &&
          !is_loading_post_feed &&
          postFeed.length > 0
        ) {
          dispatch(
            startGettingPosts({ limit: "10", page: (page + 1).toString() })
          ).then((res: any) => {
            if (res.meta.requestStatus === "fulfilled") {
              if (
                (res.payload as FriendsPost[]).some(
                  (post) => post.id === postFeed[postFeed.length - 1].id
                )
              )
                setIsOnBottom(true);
            }
          });
          setPage((prev) => prev + 1);
        }
      }
    };

    scrollPost.current?.addEventListener("scroll", handleScroll);

    return () => {
      scrollPost.current?.removeEventListener("scroll", handleScroll);
    };
  }, [isOnBottom, is_loading_post_feed]);

  const handleShowListChats = () => {
    dispatch(setShowListChats(true));
  };

  return (
    <div
      ref={scrollPost}
      className="flex flex-col w-full"
      style={{
        height: "calc(100vh - 100px)",
        overflow: "auto",
        overflowX: "hidden",
      }}
    >
      <div
        className={`flex justify-start items-center w-full ${
          isCellphoneScreen ? "px-5" : ""
        }`}
      >
        <div
          className={`flex ${
            isSmallScreen ? "max-w-[350px]" : "max-w-[370px]"
          } w-full pl-3`}
        >
          <label className="flex w-full text-lg font-medium">
            Últimos posts
          </label>
        </div>
        {isCellphoneScreen || isSmallScreen ? (
          <button className="cursor-pointer" onClick={handleShowListChats}>
            <MessageIcon />
          </button>
        ) : null}
      </div>
      {is_loading_post_feed && postFeed.length === 0 ? (
        Array.from({ length: 2 }).map((_, index) => (
          <PostCardSkeleton key={index} />
        ))
      ) : postFeed.length === 0 ? (
        <NoPost />
      ) : (
        <div className="flex flex-col justify-center items-center w-full">
          <div className="flex flex-col max-w-[500px]">
            {postFeed.map((post, index) => (
              <PostCard key={index} post={post} />
            ))}
          </div>
          {is_loading_post_feed ? (
            <div className="flex justify-center py-2">
              <div
                className="w-5 h-5 border-2 border-blue-500 rounded-full animate-spin"
                style={{ borderTopColor: "transparent" }}
              ></div>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default PostContainer;
