import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ButtonSquad, InputSquad, LabelSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { InputType } from "../../../core/interfaces/InputSquadInterface";
import { RootState, AppDispatch } from "../../../store/store";
import { useForm } from "../../hooks/useForm";
import { GoogleLogo } from "../../../assets/logos/GoogleLogo";
import { AppleLogo } from "../../../assets/logos/AppleLogo";
import {
  startGettingUserInfo,
  startLoginUserWithEmailPassword,
} from "../../../store/slices/auth/thunks";
import { useAlert } from "../../hooks/useAlert";
import { connectToServer } from "../../../socket-clients/social";
import { GoogleAuthProvider, OAuthProvider, getAuth, signInWithPopup } from "firebase/auth";
import CircularProgress from "../../components/CircularProgress";
import BackgroundTopLeft from "../../../assets/background/BackgroundTopLeft";
import BackgroundBottomRight from "../../../assets/background/BackgroundBottomRight";
import { getAllBooks, getBooksByUser } from "../../../store/slices/book/thunks";
import { startGettingDailyKcal, startGettingMeals, startGettingRecipes, startGettingWeeklyKcal } from "../../../store/slices/food/thunks";
import { startGettingPosts } from "../../../store/slices/post/thunks";
import { getRecipesPartialSystem, getRecipesSystem } from "../../../store/slices/recipe/thunks";
import { getAllRedeems } from "../../../store/slices/redeem/thunks";
import { connectToCoachServer } from "../../../socket-clients/server";
import { getAllCourses } from "../../../store/slices/course/thunks";

const formData = {
  email: "",
  password: "",
};

export const LoginScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const { showAlert } = useAlert();

  const { status, isLoading } = useSelector((state: RootState) => state.auth);
  const isAuthenticating = useMemo(() => status === "checking", [status]);
  const { formState, onInputChange } = useForm(formData);

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(
      startLoginUserWithEmailPassword({
        email: formState.email,
        password: formState.password,
        type: "local",
      })
    ).then((res) => {
      if (res.meta.requestStatus === "fulfilled") {
        connectToServer((res.payload as any).token, dispatch);
        connectToCoachServer((res.payload as any).token, dispatch);
        dispatch(startGettingUserInfo());
        dispatch(startGettingRecipes());
        dispatch(getRecipesSystem());
        dispatch(getAllCourses());
        dispatch(getRecipesPartialSystem());
        dispatch(startGettingWeeklyKcal(new Date()));
        dispatch(startGettingMeals({ date: new Date() }));
        dispatch(startGettingDailyKcal({ date: new Date() }));
        dispatch(getAllRedeems());
        dispatch(getBooksByUser());
        dispatch(getAllBooks());
        dispatch(startGettingPosts({ limit: "10", page: "1" }));
      }
      if (res.payload === 401) {
        showAlert({
          type: "error",
          message: "Usuario o contraseña incorrectos",
        });
      }
    });
  };

  const handleGoogleLogin = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const auth = getAuth();
      const res = await signInWithPopup(auth, provider);
      const credential = GoogleAuthProvider.credentialFromResult(res);
      const encrypt_google_access = credential?.idToken;
      dispatch(
        startLoginUserWithEmailPassword({
          email: "",
          password: "",
          type: "google",
          encrypt_google_access,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          connectToServer((res.payload as any).token, dispatch);
          dispatch(startGettingUserInfo());
          dispatch(startGettingRecipes());
          dispatch(getRecipesSystem());
          dispatch(getAllCourses());
          dispatch(getRecipesPartialSystem());
          dispatch(startGettingWeeklyKcal(new Date()));
          dispatch(startGettingMeals({ date: new Date() }));
          dispatch(startGettingDailyKcal({ date: new Date() }));
          dispatch(getAllRedeems());
          dispatch(getBooksByUser());
          dispatch(getAllBooks());
          dispatch(startGettingPosts({ limit: "10", page: "1" }));
        }
        if (res.payload === 401) {
          showAlert({
            type: "error",
            message: "Usuario o contraseña incorrectos",
          });
        }
      });
    } catch (error) {
      console.error({ error });
    }
  };

  const handleAppleLogin = async () => {
    try {
      const provider = new OAuthProvider('apple.com');
      const auth = getAuth();
      const res = await signInWithPopup(auth, provider);
      const credential = OAuthProvider.credentialFromResult(res);
      const encrypt_google_access = credential?.idToken;
      dispatch(
        startLoginUserWithEmailPassword({
          email: "",
          password: "",
          type: "apple",
          encrypt_google_access,
        })
      ).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          connectToServer((res.payload as any).token, dispatch);
          dispatch(startGettingUserInfo());
          dispatch(startGettingRecipes());
          dispatch(getRecipesSystem());
          dispatch(getAllCourses());
          dispatch(getRecipesPartialSystem());
          dispatch(startGettingWeeklyKcal(new Date()));
          dispatch(startGettingMeals({ date: new Date() }));
          dispatch(startGettingDailyKcal({ date: new Date() }));
          dispatch(getAllRedeems());
          dispatch(getBooksByUser());
          dispatch(getAllBooks());
          dispatch(startGettingPosts({ limit: "10", page: "1" }));
        }
        if (res.payload === 401) {
          showAlert({
            type: "error",
            message: "Usuario o contraseña incorrectos",
          });
        }
      });

    } catch (error) {
      console.error({ error });
    }
  }

  return (
    <div className="container mx-auto px-3">
      <div className="flex flex-col justify-center items-center h-screen">
        {
          isLoading === true
          ? <CircularProgress styleProps={{width: 40, height: 40}} />
          : <div className="flex flex-col justify-center items-center h-screen">
              <img src="/logo_color.png" className="h-40 max-w-full" alt="" />
              <form onSubmit={onSubmit}>
                <div className="mt-4">
                  <LabelSquad children="Usuario" />
                  <InputSquad
                    inputType={InputType.Medium}
                    onChange={onInputChange}
                    value={formState.email}
                    name="email"
                    placeholder="correo@gmail.com"
                  />
                </div>
                <div className="mt-4">
                  <LabelSquad children="Contraseña" />
                  <InputSquad
                    inputType={InputType.Medium}
                    onChange={onInputChange}
                    value={formState.password}
                    name="password"
                    type="password"
                  />
                </div>
                <div className="mt-4">
                  <ButtonSquad
                    full={true}
                    buttonType={ButtonType.OrangeFull}
                    disabled={isAuthenticating}
                    type="submit"
                    label="Acceder Ahora"
                  />
                </div>
              </form>
              <div className="mt-2">
                <Link to="/auth/recovery" className="text-xs">
                  Olvidaste tu contraseña?
                </Link>
              </div>
              <label className="mt-5 text-sm">
                Iniciar sesíon con tus cuentas de
              </label>
              <div className="flex flex-row mt-4">
                <button className="hover:bg-slate-200 py-2 px-4 rounded-lg" onClick={handleGoogleLogin}>
                  <GoogleLogo />
                </button>
                <button className="hover:bg-slate-200 py-2 px-4 rounded-lg" onClick={handleAppleLogin}>
                  <AppleLogo />
                </button>
              </div>
              <div className="flex flex-row mt-4">
                <label className="text-xs font-light text-gray-500">
                  No tienes una cuenta?
                </label>
                <label className="text-xs font-medium text-orange-500 ml-2">
                  <Link to="/auth/register">Registrate.</Link>
                </label>
              </div>
              <div className="max-[800px]:hidden">
                <BackgroundTopLeft />
                <BackgroundBottomRight />
              </div>
            </div>
        }
      </div>
    </div>
  );
};
