export interface Recipe {
    id?: string;
    name?: string;
    kcal?: number;
    timeToPrepare?: string;
    timeToCook?: string;
    image?: any;
    type?: string;
    materials?: Material[];
    ingredients?: Ingredient[];
    toppings?: Ingredient[];
    preparation?: Preparation[];
    nutritionalValue?: NutritionalValue|null;
  }
  
  export interface Material {
    id?: string;
    name?: string;
    link?: string;
  }
  
  export interface Ingredient {
    id?: string;
    title?: string;
    subtitle?: string;
    priority?: number;
  }
  
  export interface Preparation {
    id?: string;
    name?: string;
    priority?: number;
  }
  
  export interface NutritionalValue {
    id?: string;
    calories?: number;
    carbohydrates?: number;
    proteins?: number;
    fats?: number;
  }

  export const parseRecipe = (json: any): Recipe => {
    return {
      id: json["id"],
      name: json["name"] ?? json["recipe"],
      kcal: json["kcal"],
      timeToPrepare: json["time_to_prepare"],
      timeToCook: json["time_to_cook"],
      image: json["image"],
      type: typeof json["type"] === 'number' ? json["type"].toString() : json["type"],
      materials: json["materials"] ? json["materials"].map((x: any) => parseMaterial(x)) : [],
      ingredients: json["ingredients"] ? json["ingredients"].map((x: any) => parseIngredient(x)) : [],
      toppings: json["toppings"] ? json["toppings"].map((x: any) => parseIngredient(x)) : [],
      preparation: json["preparation"] ? json["preparation"].map((x: any) => parsePreparation(x)) : [],
      nutritionalValue: json["nutritional_value"] ? parseNutritionalValue(json["nutritional_value"]) : null,
    };
  };
  
  export const parseMaterial = (json: any): Material => {
    return {
      id: json["id"],
      name: json["name"],
      link: json["link"],
    };
  };
  
  export const parseIngredient = (json: any): Ingredient => {
    return {
      id: json["id"],
      title: json["title"],
      subtitle: json["subtitle"],
      priority: json["priority"],
    };
  };
  
  export const parsePreparation = (json: any): Preparation => {
    return {
      id: json["id"],
      name: json["name"],
      priority: json["priority"],
    };
  };
  
  export const parseNutritionalValue = (json: any): NutritionalValue => {
    return {
      id: json["id"],
      calories: json["calories"],
      carbohydrates: json["carbohydrates"],
      proteins: json["proteins"],
      fats: json["fats"],
    };
  };
  export const parseRecipes = (data: any[]): Recipe[] => {
    return data.map(parseRecipe);
  };