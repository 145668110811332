import { DateTime } from "luxon";

interface MessageSentedLabelProps {
  sent_at: string;
}

const MessageSentedLabel = ({ sent_at }: MessageSentedLabelProps) => {
  return (
    <label className="flex justify-center text-xs text-gray-400">
      {DateTime.fromISO(sent_at).toFormat("DD T")}
    </label>
  );
};

export default MessageSentedLabel;
