interface CircularProgressProps {
  styleProps?: React.CSSProperties | undefined;
}

const CircularProgress = ({ styleProps }: CircularProgressProps) => {
  return (
    <div
      className="w-5 h-5 absolute border-2 border-blue-500 rounded-full animate-spin"
      style={{ borderTopColor: "transparent", ...styleProps }}
    ></div>
  );
};

export default CircularProgress;
