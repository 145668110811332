import { Column } from '../../../core/components/Column'
import AboutUsImage from "../../../assets/images/about_us.png";
import { TextComponent } from '../../../core/components/TextComponent';
import AboutComponent from './components/AboutComponent';

export const AboutUs = () => {
  return (
    <Column
    crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden my-6"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
      <div className='relative'>
        <img
          className="object-cover w-full h-96 rounded-lg"
          src={AboutUsImage}
          alt="book"
        />
        <div className="absolute inset-0 bg-black bg-opacity-50 m-4 rounded-lg"></div>
        
      <TextComponent variant='h1' className='absolute inset-0 flex items-center justify-center font-bold text-white'>
        Sobre nosotros
      </TextComponent>
      </div>
      <div className='h-6'/>
      <TextComponent variant='h5'>
        Somos un equipo apasionado por el fitness y la salud, y estamos aquí para ayudarte a alcanzar tus metas de una manera divertida y sostenible.
        En Squat Fit, creemos que el entrenamiento y una buena dieta son claves no sólo para un cuerpo en forma sino también para una mente sana, y una vida equilibrada y feliz.
      </TextComponent>
      <div className='h-6'/>
      <TextComponent variant='h3' className='font-bold'>
        Conocenos
      </TextComponent>
      <div className='h-6'/>
      <AboutComponent title='Hamlet' url='hamletsquatfit' image='hamlet' />
      <div className='h-6'/>
      <AboutComponent title='María' url='mariasquatfit' image='maria' />
      <div className='h-6'/>
      <AboutComponent title='Cocina Fit' url='cocinasquatfit' image='squat' />
      <div className='h-6'/>
      <TextComponent variant='h3' className='font-bold'>
       Ingresa a nuestra comunidad
      </TextComponent>
      <div className='h-6'/>
      <AboutComponent title='Cocina Fit' url='youtube.com/c/MariaSquatFit1' image='squat_youtube' />
      <div className='h-6'/>
      </div>
    </Column>
  )
}
