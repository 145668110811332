import { Receiver } from "../../store/interfaces/social/interfaceSocial";

interface FriendRequestNotifyProps {
  sender?: Receiver;
  onAccept?: () => void;
  onReject?: () => void;
}

const FriendRequestNotify = ({ sender, onAccept, onReject }: FriendRequestNotifyProps) => {
  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-col pl-3">
        <label className="font-bold text-sm">{sender?.username}</label>
        <label className="text-xs">Solicitó seguirte</label>
      </div>
      <div className="flex flex-row ml-3 self-center gap-2">
        <button
          className="bg-orange-500 hover:bg-orange-400 text-white text-xs rounded-md w-12 h-7"
          onClick={onAccept}
        >
          <i className="fa-solid fa-user-plus"></i>
        </button>
        <button
          className="bg-gray-500 hover:bg-gray-400 text-white text-xs rounded-md w-12 h-7"
          onClick={onReject}
        >
          <i className="fa-solid fa-user-minus"></i>
        </button>
      </div>
    </div>
  );
};

export default FriendRequestNotify;
