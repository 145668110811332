import { useState, useEffect } from 'react';
import { AdviceCard } from "./components/AdviceCard";
import { SwitchPlan } from "./components/SwitchPlan";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../store/store";
import { Subscription } from "../../../store/interfaces/coach/interfaceCoach";
import { onSelectPlan } from "../../../store/slices/coach/CoachSlice";
import {
  createPaypalSub,
  createStripeSub,
  getAllSubs,
} from "../../../store/slices/coach/thunks";
import PurchaseBy, { OrderType } from "../../components/PurchaseBy";
import { AdviceCardSkeleton } from "./components/AdviceCardSkeleton";
import { useParams } from "react-router-dom";
import { Column } from "../../../core/components/Column";

export const CoachPlanScreen = () => {
  const { id, type:typeAdvice } = useParams<{ id: string, type:string }>();
  const dispatch: AppDispatch = useDispatch();
  const {
    subscriptions,
    planSelected,
    is_loading_advice,
    is_loading_order_with_paypal,
    is_loading_order_with_stripe,
  } = useSelector((state: RootState) => state.coach);

  const [plan, setPlan] = useState("Básico");
  const [isOpenModalBuyAdvice, setIsOpenModalBuyAdvice] = useState(false);

  const handleClick = (plan: Subscription) => {
    dispatch(onSelectPlan(plan));
  };

  const createOrder = (type: OrderType) => {
    if (type === "stripe") {
      dispatch(createStripeSub({subPlanId:id??'',type:typeAdvice??'Nutricional'})).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          window.open(result.payload as string, "_blank");
        }
      });
    } else {
      dispatch(createPaypalSub({subPlanId:id??'',type:typeAdvice??'Nutricional'})).then((result) => {
        if (result.meta.requestStatus === "fulfilled") {
          window.open(result.payload as string, "_blank");
        }
      });
    }
  };
  useEffect(() => {
    // dispatch(onSelectCoach(coach!));
    dispatch(getAllSubs(id??''));
  }, [])
  

  return (
    <div className="flex flex-col items-center">
      <SwitchPlan plan={plan} setPlan={setPlan} />
      <div className="grid max-[680px]:grid-cols-1 max-[1300px]:grid-cols-2 min-[1301px]:grid-cols-3 gap-5 justify-center mt-20">
        {is_loading_advice === true && subscriptions.length === 0
          ? Array.from({ length: 3 }).map((_, index) => (
              <AdviceCardSkeleton key={index} />
            ))
          : subscriptions.map((sub, index) =>
              plan !== "Pro" && sub.name !== "Asesoramiento Completo" ? (
                <AdviceCard
                  key={index}
                  advice={sub}
                  isSelected={planSelected}
                  onClickCard={() => handleClick(sub)}
                />
              ) : plan === "Pro" && sub.name === "Asesoramiento Completo" ? (
                <AdviceCard
                  key={index}
                  advice={sub}
                  isSelected={planSelected}
                  onClickCard={() => handleClick(sub)}
                />
              ) : (
                <></>
              )
            )}
      </div>
      <Column crossAxisAlignment="center" className="flex relative justify-center py-10 w-full">
        {id??'' !== "" ? (
          <ButtonSquad
            buttonType={ButtonType.BlueFull}
            label="Pagar"
            onClick={() => setIsOpenModalBuyAdvice((prev) => !prev)}
          />
        ) : (
          <div></div>
        )}
        {isOpenModalBuyAdvice && (
          <PurchaseBy
            className={`absolute z-30 bottom-[100px] bg-white rounded-xl p-2 w-2/3 max-[767px]:w-full max-w-[300px] shadow-xl`}
            createOrder={createOrder}
            is_loading_with_paypal={is_loading_order_with_paypal}
            is_loading_with_stripe={is_loading_order_with_stripe}
          />
        )}
      </Column>
    </div>
  );
};
