import { useEffect, useState } from "react";

const useScreen = () => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isCellphoneScreen, setIsCellphoneScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    const handleCellphoneResize = () => {
      setIsCellphoneScreen(window.innerWidth < 480);
    };

    handleResize();
    handleCellphoneResize();

    window.addEventListener("resize", handleResize);
    window.addEventListener("resize", handleCellphoneResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("resize", handleCellphoneResize);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return { isSmallScreen, isCellphoneScreen, isOpen, setIsOpen, toggleSidebar };
};

export default useScreen;
