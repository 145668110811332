import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { watchVideo } from '../../../../store/slices/course/thunks';
import { AppDispatch, RootState } from '../../../../store/store';
import useLastVideoSeen from '../../../hooks/useLastVideoSeen';
import useScreen from '../../../hooks/useScreen';
import useVideoContainer from "../../../hooks/useVideoContainer";
interface VideoInterface{
    url:string
}
const VideoComponent = ({url}:VideoInterface) => {
    const { isSmallScreen } = useScreen();
    const dispatch: AppDispatch = useDispatch();
    const { handleEnded, handleProgress, onReady, setIsReady } =
    useVideoContainer();
    const [videoURL, setVideoURL] = useState<string>("");
  
    
  
    return (
      <div
        className={`flex flex-col w-full  bg-black`}
      >
        {url !== "" ? (
          <div className="w-full  rounded-lg">
            <ReactPlayer
              style={{ borderRadius: "10px", display: "flex" }}
              url={url}
              controls
      
              height="100%"
              progressInterval={3000}
              onProgress={handleProgress}
              onEnded={handleEnded}
              onReady={onReady}
              config={{
                file: {
                  attributes: {
                    controlsList: "nodownload",
                  },
                },
              }}
            />
          </div>
        ) : (
          <div className="flex h-screen max-h-[calc(40vh)] sm:max-h-[calc(40vh)] md:max-h-[calc(50vh)] lg:max-h-[calc(50vh)] 2xl:max-h-[calc(70vh)] rounded-lg bg-gray-300"></div>
        )}
      </div>
    );
  };
  
  export default VideoComponent;
  