import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { Course, CourseByUser, View } from '../../interfaces/course/interfaceCourse'
import { createCourseReview, createOrderWithPaypal, createOrderWithStripe, getAllCourses, getAnswers, getCourseByUser, getCourseReview, getTests, markVideoAsViewed, saveUserAnswer, updateView } from './thunks'
import { TestAnswerModel, TestModel } from '../../interfaces/course/testInterface'
import { ReviewModel } from '../../interfaces/course/reviewInterface'

interface CounterState {
  allCourses: Course[],
  is_loading_all_courses: boolean,
  courseByUser: CourseByUser[],
  is_loading_course_by_user: boolean,
  courseSelected: CourseByUser | null,
  buyCourseSelected: Course | null,
  lastVideoSeen: string,
  is_loading_order_with_paypal: boolean,
  is_loading_order_with_stripe: boolean,
  loading: boolean,
  tests:TestModel|null,
  answers:TestAnswerModel[],
  review:ReviewModel|null,
  savedAnswers:TestAnswerModel[],
}

const initialState: CounterState = {
  allCourses: [],
  tests: null,
  answers: [],
  review: null,
  savedAnswers: [],
  is_loading_all_courses: false,
  loading: false,
  courseByUser: [],
  is_loading_course_by_user: false,
  courseSelected: null,
  buyCourseSelected: null,
  lastVideoSeen: localStorage.getItem('lastVideoSeen') || '',
  is_loading_order_with_paypal: false,
  is_loading_order_with_stripe: false,
}

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourseSelected: (state, action) => {
      state.courseSelected = action.payload
    },
    cleanCourseSelected: (state) => {
      state.courseSelected = null
    },
    setBuyCourseSelected: (state, action) => {
      state.buyCourseSelected = action.payload
    },
    cleanBuyCourseSelected: (state) => {
      state.buyCourseSelected = null
    },
    setLastVideoSeen: (state, action: PayloadAction<string>) => {
      state.lastVideoSeen = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCourses.pending, (state) => {
        state.is_loading_all_courses = true
      })
      .addCase(getAllCourses.fulfilled, (state, action: PayloadAction<Course[]>) => {
        state.allCourses = action.payload
        state.is_loading_all_courses = false
      })
      .addCase(getCourseByUser.pending, (state) => {
        state.is_loading_course_by_user = true
      })
      .addCase(getCourseByUser.fulfilled, (state, action: PayloadAction<CourseByUser[]>) => {
        state.courseByUser = action.payload
        state.is_loading_course_by_user = false
      })
      .addCase(updateView.fulfilled, (state, action: PayloadAction<View>) => {
        state.courseByUser = state.courseByUser.map((course) => {
          const newVideos = course.videos.map((video) => {
            if (video.video_id === action.payload.video_id) {
              return {
                ...video,
                views: action.payload,
              }
            }
            return video
          })

          return {
            ...course,
            videos: newVideos,
          }
        })
        state.courseSelected = state.courseSelected ? {
          ...state.courseSelected,
          videos: state.courseSelected.videos.map((video) => {
            if (video.video_id === action.payload.video_id) {
              return {
                ...video,
                views: action.payload,
              }
            }
            return video
          }),
        } : null
      })
      .addCase(markVideoAsViewed.fulfilled, (state, action: PayloadAction<View>) => {
        state.courseByUser = state.courseByUser.map((course) => {
          if (course.id === action.payload.video_id) {
            const newVideos = course.videos.map((video) => {
              if (video.video_id === action.payload.video_id) {
                return {
                  ...video,
                  views: action.payload,
                }
              }
              return video
            })

            return {
              ...course,
              videos: newVideos,
            }
          }
          return course
        })
        state.courseSelected = state.courseSelected ? {
          ...state.courseSelected,
          videos: state.courseSelected.videos.map((video) => {
            if (video.video_id === action.payload.video_id) {
              return {
                ...video,
                views: action.payload,
              }
            }
            return video
          }),
        } : null
      })
      .addCase(createOrderWithPaypal.pending, (state) => {
        state.is_loading_order_with_paypal = true
      })
      .addCase(createOrderWithPaypal.fulfilled, (state) => {
        state.is_loading_order_with_paypal = false
      })
      .addCase(createOrderWithStripe.pending, (state) => {
        state.is_loading_order_with_stripe = true
      })
      .addCase(createOrderWithStripe.fulfilled, (state) => {
        state.is_loading_order_with_stripe = false
      })
      .addCase(getTests.pending, (state) => {
        state.loading = true
      })
      .addCase(getTests.fulfilled, (state,action) => {
        state.tests = action.payload
        state.loading = false
      })
      .addCase(getAnswers.pending, (state) => {
        state.loading = true
      })
      .addCase(getAnswers.fulfilled, (state,action) => {
        state.answers = action.payload
        state.loading = false
      })
      .addCase(getCourseReview.pending, (state) => {
        state.loading = true
      })
      .addCase(getCourseReview.fulfilled, (state,action) => {
        state.review = action.payload
        state.loading = false
      })
      .addCase(createCourseReview.pending, (state) => {
        state.loading = true
      })
      .addCase(createCourseReview.fulfilled, (state) => {
        state.loading = false
      })
      .addCase(saveUserAnswer.pending, (state) => {
        state.loading = true
      })
      .addCase(saveUserAnswer.fulfilled, (state,action) => {
        state.savedAnswers = action.payload
        state.loading = false
      })

  },
})

export const {
  setCourseSelected,
  cleanCourseSelected,
  setBuyCourseSelected,
  cleanBuyCourseSelected,
  setLastVideoSeen
} = courseSlice.actions

export default courseSlice.reducer