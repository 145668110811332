import { Manager, Socket } from "socket.io-client";
import { AppDispatch } from "../store/store";
import { setMessageViewedCoach, setNewMessageCoach } from "../store/slices/floating-chat/thunks";

let socket: Socket;

export const connectToCoachServer = (token: string, dispatch: AppDispatch) => {
  const manager = new Manager(
    `${process.env.REACT_APP_SQUAT_FIT_API}/socket.io/socket.io.js`,
    {
      extraHeaders: {
        authorization: `Bearer ${token}`,
        from: 'user',
        platform: 'web'
      }
    }
  );

  socket?.removeAllListeners();
  socket = manager.socket('/messages-coach');

  addListeners(dispatch);
}

const addListeners = (dispatch: AppDispatch) => {
  socket.on("connect_error", (error: any) => {
    socket.close();
  });

  socket.on("connect_timeout", (timeout: any) => {
    socket.close();
  });

  socket.on("reconnect", (attempt: number) => {
    console.log("Reconnect to server");
  });

  socket.on("reconnect_attempt", (attempt: number) => {
    console.log("Reconnect attempt to server");
  });

  socket.on("reconnect_failed", () => {
    socket.close();
  });

  socket.on("reconnect_error", (error: any) => {
    socket.close();
  });

  socket.on("connect", () => {
    console.log("Connected to server");
  });

  socket.on("disconnect", (reason) => {
    console.log(`Disconnected from server - ${reason}`);
    socket.close();
  });

  socket.on("error", onError);
  socket.on(
    "new_message_coach",
    (payload: { message: any; chat: any }) =>
      onNewMessageCoach(payload, dispatch)
  );
  socket.on("updated_message_coach", (message: any) =>
    onMessageReadCoach(message, dispatch)
  );
};

export const addMessageCoach = (
  to: string,
  chat_id: string,
  message: string,
  type: string
) => {
  socket.emit("add_message_coach", {
    to,
    chat_id,
    message,
    type
  });
};

export const UpdateMessageViewedCoach = (
  messagesId: string[],
  chat_id: string
) => {
  socket.emit("update_message_coach_read", {
    messagesId: messagesId,
    chat_id,
  });
};

const onNewMessageCoach = (
  payload: {
    message: any;
    chat: any;
  },
  dispatch: AppDispatch
) => {
  dispatch(setNewMessageCoach(payload));
};

const onError = (error: string) => {
  console.error(error);
};

const onMessageReadCoach = (message: any, dispatch: AppDispatch) => {
  dispatch(setMessageViewedCoach(message));
};