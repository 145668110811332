import React, { useState } from 'react'

export const useRecipe = () => {
  const [recipePicture, setRecipePicture] = useState<File | null>(null);
  const [catSelected, setCatSelected] = useState('');

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; 
    if (file) {
      setRecipePicture(file); 
    }
  }

  return {
    recipePicture,
    handleFileChange,
    catSelected,
    setCatSelected
  }
}
