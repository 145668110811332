import { useState } from "react";
import { MyRecipesModal } from "./MyRecipesModal";
import { Recipe } from "../../../../store/interfaces/food/recipeInterface";

export const MyRecipeCard = ({ recipe }: {recipe: Recipe}) => {

  const [OnViewRecipe, setOnViewRecipe] = useState(false);

  const handleCloseModal = () => {
    setOnViewRecipe(false);
  };

  return (
    <div className="flex flex-col p-2">
      <div 
        className="container border-gray-300 border-2 h-52 w-52 max-[560px]:w-40 max-[560px]:h-40 rounded-xl"
        onClick={() => setOnViewRecipe(true)}
      >
        <img src={`${recipe.image}`} className="h-36 w-36 max-[560px]:w-24 max-[560px]:h-24 mt-5 ml-7 object-cover rounded-lg" alt=""/>
        <div className="flex flex-col items-center mt-2">
          <label className="font-normal text-sm ml-1">
          { recipe.name }
          </label>
        </div>
      </div>
      <MyRecipesModal recipe={recipe} isOpen={OnViewRecipe} onClose={handleCloseModal} />
    </div>
  )
}
