import { Column } from "../../../../../core/components/Column"
import { Row } from "../../../../../core/components/Row"
import { TextComponent } from "../../../../../core/components/TextComponent"

interface ButtonProps {
  icon: JSX.Element
  text1: string
  text2: string
}

export const StadisticsCard = ({icon, text1, text2}: ButtonProps) => {
  return (
    <div className="border-4 shadow-xl py-4 w-full">
      <Row
        mainAxisAlignment="center"
        crossAxisAlignment="center"
      >
        <Column className="p-2 w-1/3">
          {icon}
        </Column>
        <Column 
          className="ml-2 w-2/3"
        >
          <TextComponent variant="h4" className="font-bold">{text1}</TextComponent>
          <TextComponent variant="h5" className="font-medium">{text2}</TextComponent>
        </Column>
      </Row>
    </div>
  )
}
