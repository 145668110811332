interface BlueButtonProps {
  label: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
}

export const BlueButton = ({ label, onClick, className, disabled, loading }: BlueButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`relative btn-blue ${className} ${disabled ? 'btn-disabled' : ''}`}
      disabled={disabled || loading}
    >
      <span className="relative">
        {loading ? 'Cargando...' : label}
      </span>
    </button>
  );
};
