import ChatIcon from "@heroicons/react/24/solid/ChatBubbleOvalLeftIcon";

interface ButtonProps {
  onClick?: () => void;
  onOpenModal?: () => void;
}

export const ChatButton = ({ onClick, onOpenModal }: ButtonProps) => {
  const handleClick = () => {
    
    if (onOpenModal) {
      onOpenModal(); 
    }
    if (onClick) {
      onClick(); 
    }
  };

  return (
    <div className="flex fixed h-12 w-12 max-[730px]:w-10 max-[730px]:h-10 max-[500px]:right-10 right-20 bottom-12 max-[500px]:bottom-24" style={{ zIndex: 100 }}>
      <button 
        className="bg-[#1a1aec] h-full w-full text-white text-xl max-[730px]:text-sm rounded-full"
        onClick={handleClick}
      >
        <ChatIcon className="h-6 w-6 ml-3 max-[430px]:ml-2" />
      </button>
    </div>
  )
}
