import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { squatFitApi } from "../../../api/SquatFitAPI";
import {
  HistoryMessage,
  HistoryMessagePayload,
  ListChats,
  RequestFriend,
  SearchFriend,
  SearchMessagePayload,
} from "../../interfaces/social/interfaceSocial";
import { Like } from "../../interfaces/social/interfacePost";

export const getChatList = createAsyncThunk("social/getChatList", async () => {
  const response = await squatFitApi.get("/social/chats");

  return response.data;
});

export const getHistoryMessage = createAsyncThunk(
  "social/getHistoryMessage",
  async (payload: HistoryMessagePayload) => {
    const response = await squatFitApi.get("/social/historyMessages", {
      params: payload,
    });

    return response.data;
  }
);

export const getHistoryOldestMessage = createAsyncThunk(
  "social/getHistoryOldestMessage",
  async (payload: HistoryMessagePayload) => {
    const response = await squatFitApi.get("/social/historyMessages", {
      params: payload,
    });

    return response.data;
  }
);

export const searchMessage = createAsyncThunk(
  "social/searchMessage",
  async (payload: SearchMessagePayload) => {
    const response = await squatFitApi.get("/social/searchMessages", {
      params: payload,
    });

    return response.data;
  }
);

export const getFriends = createAsyncThunk("social/friends", async () => {
  const response = await squatFitApi.get("/social/friends");

  return response.data;
});

export const searchFriends = createAsyncThunk(
  "social/searchFriends",
  async (payload: { search: string; user_id: string }) => {
    const response = await squatFitApi.get<SearchFriend[]>(
      "/social/searchFriends",
      {
        params: {
          search: payload.search,
        },
      }
    );

    return response.data.filter((friend) => friend.id !== payload.user_id);
  }
);

export const followsFriend = createAsyncThunk(
  "social/followsFriend",
  async (payload: { userId: string }) => {
    const response = await squatFitApi.get("/social/follows", {
      params: payload,
    });

    return response.data;
  }
);

export const acceptFriendRequest = createAsyncThunk(
  "social/acceptFriendRequest",
  async (payload: { userId: string }) => {
    const response = await squatFitApi.post("/social/acceptFriend", payload);

    return response.data;
  }
);

export const rejectFriendRequest = createAsyncThunk(
  "social/rejectFriendRequest",
  async (payload: { userId: string }) => {
    const response = await squatFitApi.post("/social/rejectFriend", payload);

    return response.data;
  }
);

export const pendingFriendRequest = createAsyncThunk(
  "social/pendingFriendRequest",
  async () => {
    const response = await squatFitApi.get<RequestFriend[]>("/social/pendingRequestsFriends");

    return response.data;
  }
);

export const setNewMessage = createAction(
  "social/setNewMessage",
  (payload: { chat: ListChats; message: HistoryMessage }) => ({
    payload: payload,
  })
);

export const setMessageViewed = createAction(
  "social/setMessageViewed",
  (payload: HistoryMessage[]) => ({
    payload: payload,
  })
);

export const notifyNewFriendRequest = createAction(
  "social/notifyNewFriendRequest",
  (payload: RequestFriend) => ({
    payload: payload,
  })
);

export const newLike = createAsyncThunk(
  "social/newLike",
  async (post_id: string) => {
    const response = await squatFitApi.post<Like>("/social/addLike", {
      postId: post_id,
    });

    return response.data;
  }
);
