import { Subscription } from "../../../../store/interfaces/coach/interfaceCoach";

interface AdviceProps {
  onClickCard: React.MouseEventHandler<HTMLDivElement> | undefined;
  isSelected: Subscription;
  advice: Subscription;
}

export const AdviceCard = ({advice, onClickCard, isSelected}: AdviceProps) => {
  return (
    <div 
      className={`w-[290px] bg-white border ${isSelected.id === advice.id ? 'border-blueSquat1' : 'border-gray-300'} shadow-md rounded-2xl overflow-hidden`}
      onClick={onClickCard}
    >
      <div className="px-6 py-4">
        <div className="font-semibold text-xl mb-2">{advice.name}</div>
      </div>
      <div className="px-6 py-4">
        {advice.description}
      </div>
      <div className={`flex justify-center px-6 py-4 ${isSelected.id === advice.id  ? ' bg-blueSquat1 text-white' : 'bg-gray-200'}`}>
        {(parseFloat(advice.price) * 1.21).toFixed(2)} €
      </div>
    </div>
  )
}
