import { useNavigate } from "react-router-dom";
import { CourseByUser } from "../../../../../store/interfaces/course/interfaceCourse";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { setCourseSelected } from "../../../../../store/slices/course/courseSilce";

interface MyCourseCardProps {
  course: CourseByUser;
}

const MyCourseCard = ({ course }: MyCourseCardProps) => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();

  const handleWatchCourse = () => {
    dispatch(setCourseSelected(course));
    navigate(`/watch-course/${course.id}`); 
  };

  return (
    <div
      className="block relative w-full md:max-w-72 cursor-pointer"
      onClick={handleWatchCourse}
    >
      <div className="absolute z-20 w-full h-full rounded-lg hover:opacity-10 hover:bg-black"></div>
      <img
        className="object-cover w-full rounded-lg"
        src={course.image}
        alt="course"
      />
    </div>
  );
};

export default MyCourseCard;
