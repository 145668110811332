import { useNavigate } from "react-router-dom";
import { Course } from "../../../../../store/interfaces/course/interfaceCourse";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../store/store";
import { setBuyCourseSelected } from "../../../../../store/slices/course/courseSilce";

interface CardCourseProps {
  course: Course;
}

const CardCourse = ({ course }: CardCourseProps) => {

  const dispatch: AppDispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigate = () => {
    dispatch(setBuyCourseSelected(course));
    navigate(`/course/${course.id}`);
  }

  return (
    <div className="block relative rounded-lg shadow-lg h-80 w-full cursor-pointer" style={{ flex: "0 0 auto" }} onClick={handleNavigate}>
      <div className="flex flex-col justify-end pl-3 pb-2 absolute z-30 rounded-lg w-full h-full">
        <label className="text-white">{course.title}</label>
        <label className="text-sm text-gray-300">{course.tutor.firstName}</label>
      </div>
      <div className="absolute z-20 w-full h-full rounded-lg opacity-50 bg-gradient-to-b from-transparent from-50% via-gray-500 to-black"></div>
      <img
        className="absolute z-10 rounded-lg w-full h-full object-cover"
        src={course.image}
        alt="course"
      />
    </div>
  );
};

export default CardCourse;
