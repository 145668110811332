import { UserImage } from "./components/UserImage"
import { useDispatch, useSelector } from "react-redux"
import { AppDispatch, RootState } from "../../../store/store"
import { Column } from "../../../core/components/Column"
import { Row } from "../../../core/components/Row"
import { TextComponent } from "../../../core/components/TextComponent"
import { SizedBox } from "../../../core/components/SizedBox"
import { ButtonSquad, InputSquad } from "../../../core/components"
import { ItemComponent } from "../../../core/components/ItemComponent"
import { InputType } from "../../../core/interfaces/InputSquadInterface"
import { useNavigate } from "react-router-dom"
import { WarningModal } from "../social/components/post/WarningModal"
import { useState } from "react"
import { requestDeleteAccount, startLogout } from "../../../store/slices/auth/thunks"
import { CalorieChart } from "../home/components/CalorieChart"
import AddFriendsIcon from '@heroicons/react/24/outline/UserPlusIcon';
import AboutUsIcon from '@heroicons/react/24/outline/StarIcon';
import FAQIcon from '@heroicons/react/24/outline/QuestionMarkCircleIcon';
import ContactIcon from '@heroicons/react/24/outline/AtSymbolIcon';
import PrivacyIcon from '@heroicons/react/24/outline/ShieldCheckIcon';
import TermsIcon from '@heroicons/react/24/outline/InformationCircleIcon';
import LogoutIcon from '@heroicons/react/24/outline/ArrowRightStartOnRectangleIcon';
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface"
import { DeleteAccount } from "./components/DeleteAccount"
import { AccountModal } from "./components/AccountModal"
import { DeleteCode } from "./components/DeleteCode"
import DropdownMenu from "../../../core/components/MenuItem"

export const ProfileScreen = () => {
  const navigate = useNavigate();
  const [isWarning, setIsWarning] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();
  const handleCloseWarningModal = () => {
    setIsWarning(false);
  }
  const onConfirm = () => {
    localStorage.clear();
    dispatch(startLogout());
    navigate("/");
  };
  const purchases = ()=>{
    navigate("/libros");
  }
  const navigateEdit = ()=>{
    navigate("/editProfile");
  }
  const navigateFaqs = ()=>{
    navigate("/faqs");
  }
  const navigateTerms = ()=>{
    navigate("/terms");
  }
  const navigatePrivacy = ()=>{
    navigate("/privacy");
  }
  const navigateAbout = ()=>{
    navigate("/about");
  }

  const handleDelete = () => {
    setIsOpen(true);
    dispatch(requestDeleteAccount());
  }

  const { username, profilePicture } = useSelector((state: RootState) => state.auth);
  
  const menuItems = [
    { label: "Mis Compras", onClick: purchases },
    { label: "Sobre nosotros", onClick: navigateAbout },
    { label: "Preguntas frecuentes", onClick: navigateFaqs },
    { label: "Política de privacidad", onClick: navigatePrivacy },
    { label: "Términos y condiciones", onClick: navigateTerms },
    { label: "Cerrar sesión", onClick: ()=>{setIsWarning(true)} },
  ];
  return (
    <Column
    crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden my-6"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
      <Row responsiveCol={true}>
        <Column className="w-3/4 ">
          <Row>
            <UserImage profile_picture={profilePicture as string} />
            <SizedBox className="w-2" />
            <Column mainAxisAlignment="between">
              <TextComponent variant="h3" className="font-bold">
                {username}
              </TextComponent>
              {/* <Column>
                <TextComponent variant="h5">
                  1,200 calories,
                </TextComponent>
                <TextComponent variant="h5">
                  2,000 goal, USA
                </TextComponent>
              </Column> */}
            </Column>
          </Row>
          <SizedBox className="h-6" />
          <ButtonSquad
            buttonType={ButtonType.RoseFull} 
            full={true} label="Editar perfil" onClick={navigateEdit}
          />
          {/* <SizedBox className="h-6" />
          <BarComponent label="Calorías" subtitle="720/1200 calories" percent={50} />
          <SizedBox className="h-6" /> */}
        </Column>
        <SizedBox className="w-16" />
        <Column>
        <Row mainAxisAlignment="between">
          <TextComponent variant="h1" className="font-bold">
            Sobre mi
          </TextComponent>
          <DropdownMenu title="" menuItems={menuItems} />
        </Row>
          <SizedBox className="h-6" />
          <TextComponent variant="h5">
            Soy una mujer de 30 años a la que le gusta correr, hacer senderismo y nadar. También disfruto cocinar comidas saludables y probar nuevas recetas. Siempre estoy buscando formas de mejorar mi salud y mi estado físico, y estoy emocionado de ver lo que me depara el futuro.
          </TextComponent>
          {/* <SizedBox className="h-6" />
          <ItemComponent
            icon={<AddFriendsIcon className="h-6 w-6" />}
            title="Agregar amigos"
            subtitle="Conéctese con amigos para ver los perfiles de los demás y compartir su progreso."
            onClick={()=>{}} 
           /> */}
           <SizedBox className="h-6" />
          <SizedBox className="h-6" />
          <DeleteAccount 
            title="Eliminar cuenta"
            subtitle="Eliminar tu cuenta es irreversible. Todos tus datos se eliminarán permanentemente y no se podrán recuperar. Cualquier suscripción activa será cancelada inmediatamente. Si estás seguro de que quieres eliminar tu cuenta, presiona el botón a continuación."
            body={
              <Column>
                <SizedBox className="h-6" />
                <ButtonSquad
                  className="w-96" label="Eliminar cuenta" 
                  buttonType={ButtonType.RoseFull} 
                  onClick={() => handleDelete()}
                />
                <SizedBox className="h-6" />
                <TextComponent variant="h6">
                  Recibirás un correo electrónico para confirmar esta acción.
                </TextComponent>
                <SizedBox className="h-6" />
              </Column>
            }
          />
          {/* <TextComponent variant="h4" className="font-bold">
            Eliminar cuenta
          </TextComponent>
          <SizedBox className="h-6" />
          <TextComponent variant="h5">
            
          </TextComponent> */}
        </Column>
      </Row>
      <WarningModal 
        showModal={isWarning} 
        onCloseModal={handleCloseWarningModal}
        typeDelete={false}
        onConfirmItem={onConfirm}
      />
      <AccountModal 
        showModal={isOpen}
        component={
          <DeleteCode 
            onCloseModal={() => setIsOpen(false)}
          />
        }
      />
      </div>
    </Column>
  )
}
