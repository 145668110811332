import { useDispatch, useSelector } from "react-redux";
import { ArrowRight } from "../../../../../assets/icons/ArrowRight";
import { AppDispatch, RootState } from "../../../../../store/store";
import { cleanHistoryMessage, cleanSelectedChat } from "../../../../../store/slices/social/socialSlice";
import { useNavigate } from "react-router-dom";
import { setFriendSelected } from "../../../../../store/slices/social/friendSlice";

interface HeaderChatProps {
  user_id: string;
  name: string;
  profileImage: string;
}

const HeaderChat = ({ user_id, name, profileImage }: HeaderChatProps) => {

  const dispatch: AppDispatch = useDispatch();
  const { friends } = useSelector((state: RootState) => state.friend);
  const navigate = useNavigate();

  const clearSeltedChat = () => {
    dispatch(cleanSelectedChat());
    dispatch(cleanHistoryMessage());
  }

  const handleNavigateToProfile = () => {
    const friend = friends.find((friend) => friend.id === user_id);
    if (!friend) return;
    dispatch(setFriendSelected(friend));
    navigate(`/user-profile/${user_id}`);
  }

  return (
    <div className="flex flex-row justify-start pb-5">
      <button className="rotate-180 self-center pl-5" onClick={clearSeltedChat}>
        <ArrowRight width="30" height="30" />
      </button>
      <img
        src={profileImage || profileImage !== "" ? profileImage : "https://via.placeholder.com/50"}
        alt="profile"
        className="rounded-full w-16 h-16 object-cover"
      />
      <div className="flex flex-col self-center pl-5" onClick={handleNavigateToProfile}>
        <label className="text-lg cursor-pointer">{name}</label>
      </div>
    </div>
  );
};

export default HeaderChat;
