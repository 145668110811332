import React, { useEffect, useState } from "react";
import CustomCard from "../../home/components/CustomCard";
import { Column } from "../../../../core/components/Column";
import { TextComponent } from "../../../../core/components/TextComponent";
import { SizedBox } from "../../../../core/components/SizedBox";
import { BarComponent } from "../../../../core/components/BarComponent";
import { BlueButton } from "../../../../core/components/ButtonBlue";
import { Row } from "../../../../core/components/Row";
import { RootState } from "../../../../store/store";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonSquad } from "../../../../core/components";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";

export const ResultTestCoursScreen = () => {
    const navigate = useNavigate();
    const { tests,savedAnswers } = useSelector((state: RootState) => state.course);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
    const [selectedAnswers, setSelectedAnswers] = useState<{ [key: string]: string }>({});
    useEffect(() => {
        const initialAnswers = savedAnswers.reduce((acc, e) => {
            acc[e.question.id] = e.answer.id;
            return acc;
          }, {} as { [key: string]: string });
        setSelectedAnswers(initialAnswers)
    }, [])
    

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const answerId = event.target.value;
        const currentQuestionId = tests?.questions[currentQuestionIndex]?.id;

        if (currentQuestionId) {
            setSelectedAnswers(prev => ({
                ...prev,
                [currentQuestionId]: answerId,
            }));
        }
    };

    const handleNextQuestion = () => {
        if (tests && selectedAnswers[tests.questions[currentQuestionIndex]?.id]) {
            setCurrentQuestionIndex(prevIndex => Math.min(prevIndex + 1, tests.questions.length - 1));
        } else {
            alert("Por favor selecciona una opción antes de continuar.");
        }
    };

    const handlePreviousQuestion = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(prevIndex => prevIndex - 1);
        }
    };

    const handleFinish = () => {
        if (tests) {
            navigate('/test-result')
        } else {
            alert("Por favor selecciona una opción antes de finalizar.");
        }
    };

    const currentQuestion = tests?.questions[currentQuestionIndex];
    const corrects=savedAnswers.filter((e)=>e.answer.correct).length;
    const percent=((savedAnswers.filter((e)=>e.answer.correct).length*100)/savedAnswers.length);
    return (
        <Column className="pl-16 pr-96 py-10">
            <Row mainAxisAlignment="between">
                <TextComponent variant="h1" className="font-semibold">
                    Test del curso : <label className={`${percent>50?'text-green-500':'text-red-700'}`}>{corrects}</label>/{savedAnswers.length}
                </TextComponent>
                <ButtonSquad buttonType={ButtonType.PurpleSmall} label="Retomar" onClick={()=>navigate('/test')} />
            </Row>
            <SizedBox className="h-5" />
            <TextComponent variant="h6" className={`${percent>50?'text-green-500':'text-red-700'}`}>
                Tu puntajes es del {percent}%
            </TextComponent>
            <SizedBox className="h-5" />
            <BarComponent 
                label={`Pregunta ${currentQuestionIndex + 1}/${tests?.questions.length}`} 
                percent={((currentQuestionIndex + 1) * 100) / (tests?.questions.length ?? 1)} 
            />
            <SizedBox className="h-10" />
            {currentQuestion ? (
                <div key={currentQuestion.id}>
                    <TextComponent variant="h3" className="font-semibold">
                        {currentQuestion.question}
                    </TextComponent>
                    <SizedBox className="h-5" />
                    <Column style={{ width: 480 }}>
                        {currentQuestion.answers.map((answer,index) => (
                            <div key={answer.id} className="mb-4">
                                <CustomCard
                                    summary= {true}
                                    answer={savedAnswers[currentQuestionIndex].answer}
                                    title={answer.answer}
                                    value={answer.id}
                                    groupValue={savedAnswers[currentQuestionIndex].answer!=null?savedAnswers[currentQuestionIndex].answer.id:selectedAnswers[currentQuestion.id] || null}
                                    onChange={savedAnswers[currentQuestionIndex].answer!=null?()=>{}:handleChange}
                                />
                            </div>
                        ))}
                    </Column>
                    <div className={`${savedAnswers[currentQuestionIndex].answer.correct?'bg-green-200':'bg-red-200'} rounded-md px-4 py-2`}>
                        <TextComponent variant="h6" className="font-semibold">
                            {savedAnswers[currentQuestionIndex].answer.correct?'Esta es la respuesta correcta!':'Es incorrecto. Revisa el contenido del curso y busca la respuesta correcta'}
                        </TextComponent>
                    </div>
                    <SizedBox className="h-5" />
                </div>
            ) : (
                <TextComponent variant="h3">Cargando...</TextComponent>
            )}
            <Row mainAxisAlignment="between" className="gap-6">
                <BlueButton 
                    label="Anterior" 
                    onClick={handlePreviousQuestion} 
                    disabled={currentQuestionIndex === 0}
                />
                    <BlueButton 
                        className="w-max place-self-end" 
                        label={currentQuestionIndex < (tests?.questions.length ?? 0) - 1 ? "Siguiente" : "Salir"} 
                        onClick={currentQuestionIndex < (tests?.questions.length ?? 0) - 1 ? handleNextQuestion : handleFinish} 
                    />
            </Row>
        </Column>
    );
    
};
