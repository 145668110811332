import { useDispatch, useSelector } from 'react-redux';
import { Column } from './Column'
import { Row } from './Row'
import { TextComponent } from './TextComponent'
import { AppDispatch, RootState } from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { localBook } from '../constants/constatns';
import Calculator from "../../assets/images/calculator.png";
import { ButtonSquad } from '.';
import { ButtonType } from '../interfaces/ButtonSquadInterface';

export const ToolOfferComponent = () => {
    const navigate = useNavigate();
    const navigateCalculator = () => {
        navigate('/calculator-info');
    };
  return (
    <Column crossAxisAlignment='start'>
      <TextComponent variant="h2" className="font-bold pb-3 pt-5 text-center text-purpleSquat1">Herramientas</TextComponent>
      <div className="py-4">
        <Row responsiveCol={true} className="items-stretch justify-between gap-4 rounded-xl bg-white p-6 shadow-lg border border-gray-200">
          <Column mainAxisAlignment='between' className="gap-4 w-full">
            <Column className="gap-2">
              <TextComponent variant='h3' className="text-purpleSquat1 font-bold">Calcula tus calorías</TextComponent>
              <TextComponent variant="h4" className="text-gray-600 leading-tight">¿No sabes cuánto te corresponde comer según tu edad y peso? María te deja esta herramienta para averiguarlo</TextComponent>
            </Column>
            <ButtonSquad label="Calculadora" buttonType={ButtonType.PurpleFull} onClick={navigateCalculator} />
          </Column>
          <div className='w-2/4 hidden md:block relative'>
          <img
                className='w-full object-cover bg-center bg-no-repeat aspect-video bg-cover rounded-xl shadow-lg transform transition-transform duration-300 hover:scale-105'
                src={Calculator}
                onClick={navigateCalculator}
                alt="book"
            />
          </div>
        </Row>
      </div>
    </Column>
  )
}
