import React from 'react';
import BeforeAfterSliderWrapper from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';

interface BeforeAfterProps {
  beforeImage: string;
  afterImage: string;
  description?: string;
}

const BeforeAfterComponent: React.FC<BeforeAfterProps> = ({
  beforeImage,
  afterImage,
  description,
}) => {
  const FIRST_IMAGE = { imageUrl: beforeImage };
  const SECOND_IMAGE = { imageUrl: afterImage };

  return (<div>

    <div style={{ width: '100%', textAlign: 'center', margin: '20px auto' }}>
      <BeforeAfterSliderWrapper
        firstImage={FIRST_IMAGE}
        secondImage={SECOND_IMAGE}
        className="custom-before-after"
        />
    </div>
      {description && <p style={{ textAlign: 'center' }}>{description}</p>}
        </div>
  );
};

export default BeforeAfterComponent;
