import { EditImageIcon } from '../../../../assets/icons/EditImageIcon'

interface ProfileImageProps {
  profilePicture: File | null | string | undefined,
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void,
  circle?:boolean
}

export const EditUserImage = ({profilePicture,circle, handleFileChange}: ProfileImageProps) => {
  return (
    <div className="relative">
      {
        profilePicture === null || profilePicture === undefined || profilePicture === ""
        ? <div className={`h-44 w-44 ${circle?'rounded-full':'rounded-lg'} bg-gray-200`} />
        : (profilePicture instanceof File)
            ? <img src={URL.createObjectURL(profilePicture)} className={`h-44 w-44 ${circle?'rounded-full':'rounded-lg'} object-cover`}/>
            : <img src={profilePicture} className={`h-44 w-44 ${circle?'rounded-full':'rounded-lg'} object-cover`}/>
      }
      <label className="absolute top-32 left-32 cursor-pointer">
        <input type="file" accept="image/*" className="hidden" onChange={handleFileChange}/>
        <EditImageIcon />
      </label>
    </div>
  )
}
