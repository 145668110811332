import { useDispatch, useSelector } from "react-redux";
import CardBook from "./components/book/CardBook";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect } from "react";
import { getAllBooks, getBooksByUser } from "../../../store/slices/book/thunks";
import { TextComponent } from "../../../core/components/TextComponent";

export const ComprasScreen = () => {
  const dispatch: AppDispatch = useDispatch();
  const { books, userBooks, is_loading_all_books, is_loading_user_books } =
    useSelector((state: RootState) => state.book);

  useEffect(() => {
    if (books.length === 0) dispatch(getAllBooks());
  }, [books]);

  useEffect(() => {
    if (userBooks.length === 0) dispatch(getBooksByUser());
  }, []);

  return (
    <div className="flex flex-col">
      <div className="flex flex-col">
        <TextComponent className="text-md font-bold">
            Libros de cocina
        </TextComponent>
      </div>
      <div className="grid md:grid-cols-2 2xl:grid-cols-3 gap-10">
        {is_loading_all_books || is_loading_user_books ? (
          <div className="flex animate-pulse  h-[260px] bg-slate-200 rounded-3xl"></div>
        ) : (
          books.map((book) => <CardBook key={book.id} book={book} variant={2} />)
        )}
      </div>
    </div>
  );
};
