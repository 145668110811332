import React from 'react';

interface TextProps {
  children: React.ReactNode;
  variant?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'h7' | 'extraBig' | 'big';
  className?: string;
}

export const TextComponent= ({ children, variant = 'h3', className = '' ,...props}:TextProps) => {
  let baseClass = '';

  switch (variant) {
    case 'extraBig':
      baseClass = 'text-lg md:text-3xl lg:text-[60px]';/* 60px */
      break;
    case 'big':
      baseClass = 'text-3xl md:text-[45px]';/* 45px */
      break;
    case 'h1':
      baseClass = 'text-2xl md:text-3xl';/* 30px */
      break;
    case 'h2':
      baseClass = 'text-xl md:text-2xl';/* 24px */
      break;
    case 'h3':
      baseClass = 'text-lg md:text-xl';/* 20px */
      break;
    case 'h4':
      baseClass = 'text-lg';/* 18px */
      break;
    case 'h5':
      baseClass = 'text-base';/* 16px */
      break;
    case 'h6':
      baseClass = 'text-sm';/* 14px */
      break;
    case 'h7':
      baseClass = 'text-xs';/* 12px */
      break;
    default:
      baseClass = 'text-base sm:text-lg md:text-xl lg:text-2xl xl:text-3xl';
  }

  return <div className={`${baseClass} ${className}`} {...props}>{children}</div>;
};
