
export const PostIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.25" y="0.25" width="31.5" height="31.5" fill="white" stroke="black" strokeWidth="0.5"/>
    <line x1="10.9167" x2="10.9167" y2="32" stroke="black" strokeWidth="0.5"/>
    <line x1="21.5833" x2="21.5833" y2="32" stroke="black" strokeWidth="0.5"/>
    <line y1="21.0835" x2="32" y2="21.0835" stroke="black" strokeWidth="0.5"/>
    <line y1="10.4165" x2="32" y2="10.4165" stroke="black" strokeWidth="0.5"/>
    </svg>

  )
}
