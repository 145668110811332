import React, { useState } from "react";

interface MenuItem {
  label: string;
  onClick: () => void;
}

interface DropdownMenuProps {
  title?: string;
  menuItems: MenuItem[];
}

const DropdownMenu: React.FC<DropdownMenuProps> = ({ title = "Opciones", menuItems }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative inline-block text-left">
      {/* Botón para abrir/cerrar el menú */}
      <div>
        <button
          type="button"
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none"
          onClick={() => setIsOpen(!isOpen)}
        >
          {title} &#9776; {/* Simbolo de 3 rayitas */}
        </button>
      </div>

      {/* Menú desplegable */}
      {isOpen && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div className="py-1">
            {menuItems.map((item, index) => (
              <button
                key={index}
                onClick={item.onClick}
                className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 w-full text-left"
              >
                {item.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownMenu;
