import { useDispatch, useSelector } from "react-redux";
import { Column } from "../../../../core/components/Column";
import { Row } from "../../../../core/components/Row";
import { SizedBox } from "../../../../core/components/SizedBox";
import { AppDispatch, RootState } from "../../../../store/store";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Step1FormValues, step1ValidationSchema } from "../interfaces/Step1Interface";
import { useAlert } from "../../../hooks/useAlert";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { EditUserImage } from "../../profile/components/EditUserImage";
import { CustomInput } from "../../../../core/components/CustomInput";
import { Dropdown } from "../../../../core/components/DropDown";
import Category, { countries } from "../../../../core/interfaces/Category";
import { startUpdatingUserInfo } from "../../../../store/slices/auth/thunks";
import { ButtonSquad } from "../../../../core/components";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";



export const Step1Screen  = () => {
const [imageProfile, setImageProfile] = useState<File | null>(null);
const { firstName, email, lastName, birth,phone,country, description, uid, profilePicture,isLoadingOnboarding } = useSelector((state: RootState) => state.auth);
const initialValues: Step1FormValues = {
    lastName: lastName,
    firstName: firstName,
    phone_number: phone,
    email: email,
    birth: new Date(birth??'2000-01-01').toISOString().split('T')[0],
    description: description
};
useEffect(() => {
  if(country!=null){
    setSelectedOption({label:country,value:country});
  }
}, [])


  const { showAlert } = useAlert();

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();


  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]; 
    if (file) {
      setImageProfile(file); 
    }
  }
  const [selectedOption, setSelectedOption] = useState<Category|null>({label:'Selecciona',value:''});
  const handleSubmit = (values: Step1FormValues, { setSubmitting }: FormikHelpers<Step1FormValues>) => {  
    
    const payload = {
      ...values,
      id: uid,
      birth:values.birth,
      country:selectedOption?.value,
      profile_picture:imageProfile==null?null: imageProfile as File,
    }
    dispatch(startUpdatingUserInfo({userId: uid, userInfo: payload})).then( (response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        showAlert({
          message: 'Cambios guardados con éxito!',
          type: "success"
        });
        navigate('/step2');
      }
    })
    setSubmitting(false);
  }

    return <Formik
        initialValues={initialValues}
        validationSchema={step1ValidationSchema}
        validateOnBlur
        validateOnChange={false}
        onSubmit={handleSubmit}
    >
  {({ handleChange, handleBlur }) => ((
    <Form>
        <Column mainAxisAlignment="center" crossAxisAlignment="center" className="h-screen">
            <div className="pb-6">
            <EditUserImage circle={true} profilePicture={imageProfile ?? profilePicture} handleFileChange={handleFileChange} />
            </div>
            <SizedBox className="h-5"/>
            <Row responsiveCol={true} mainAxisAlignment="center">
                <Column className="w-full">
                    <Field
                        as={CustomInput}
                        label="Primer Nombre"
                        name="firstName"
                        hintText='ej: María'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="firstName" component="div" className="text-red-500" />
                </Column>
                <SizedBox className="w-6" />
                <Column className="w-full">
                    <Field
                        as={CustomInput}
                        label="Apellido Materno"
                        name="lastName"
                        hintText='ej: Perez'
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="lastName" component="div" className="text-red-500" />
                </Column> 
            </Row>
            <SizedBox className="h-6" />
            <Row>
                <Column className="w-full">
                    <Field
                        as={CustomInput}
                        label="Fecha de nacimiento"
                        name="birth"
                        type="date"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="birth" component="div" className="text-red-500" />
                </Column> 
                <SizedBox className="w-6" />
                <Column className="w-full">
                    <Dropdown
                        small={false}
                        label="País"
                        options={countries}
                        selectedOption={selectedOption}
                        onSelect={setSelectedOption}
                        className="w-full"
                    />
                </Column> 
            </Row>
            <SizedBox className="h-6" />
            <Row>
                <Column className="w-full">
                    <Field
                        as={CustomInput}
                        label="Correo electrónico"
                        name="email"
                        hinText="example@gmail.com"
                        type="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="email" component="div" className="text-red-500" />
                </Column>
                <SizedBox className="w-6" />
                <Column className="w-full">
                    <Field
                        as={CustomInput}
                        label="Número de teléfono"
                        name="phone_number"
                        hinText="example@gmail.com"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="phone_number" component="div" className="text-red-500" />
                </Column>
            </Row>
            <SizedBox className="h-6" />
            <Column className="w-full">
                <Field
                    as={CustomInput}
                    label="Sobre mí"
                    name="description"
                    hinText="Escribe algo sobre ti..."
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                />
                <ErrorMessage name="description" component="div" className="text-red-500" />
            </Column>
            <SizedBox className="h-20"/>
            <ButtonSquad loading={isLoadingOnboarding} buttonType={ButtonType.OrangeFull} className="w-96" label="Continuar" onClick={()=>{}} />
            <SizedBox className="h-20"/>
        </Column>
        </Form>
  )
)}
</Formik>
}