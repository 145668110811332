import React from "react"

interface Props extends React.LabelHTMLAttributes<HTMLLabelElement> {}

export const LabelSquad = ({children, ...props}: Props) => {
  return (
    <label 
      className="text-sm font-normal leading-6 text-gray-400 block"
      {...props}
    >
      {children}
    </label>
  )
}
