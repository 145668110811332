interface DiarioInterface{
    variant:'normal'|'selected'
}
export const DiarioIcon = ({variant}:DiarioInterface) => {
    if(variant=='selected'){
        return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2830_1473)">
            <path opacity="0.984" fillRule="evenodd" clipRule="evenodd" d="M10 0.300156C10.9 0.200156 11.2 0.600156 11.1 1.50016C10.8 1.90016 10.4 2.00016 9.90002 2.00016C9.20002 2.10016 8.90002 2.60016 8.90002 3.30016C9.60002 2.80016 10.4 2.50016 11.3 2.50016C12.6 2.60016 13.6 3.20016 14.3 4.30016C15.2 6.20016 15.5 8.10016 15.1 10.1002C14.8 12.0002 13.9 13.6002 12.5 14.9002C11.5 15.7002 10.4 15.9002 9.20002 15.5002C8.80002 15.3002 8.50002 15.1002 8.10002 14.9002C7.70002 15.1002 7.30002 15.4002 6.80002 15.6002C5.70002 15.9002 4.60002 15.6002 3.70002 14.9002C2.20002 13.4002 1.20002 11.6002 0.900018 9.50016C0.600018 7.70016 0.900018 6.10016 1.60002 4.50016C2.70002 2.70016 4.30002 2.20016 6.30002 2.70016C6.60002 2.80016 6.90002 3.00016 7.10002 3.10016C7.40002 1.50016 8.40002 0.600156 10 0.300156Z" fill="#D8688E"/>
            </g>
            <defs>
            <clipPath id="clip0_2830_1473">
            <rect width="16" height="16" fill="white"/>
            </clipPath>
            </defs>
        </svg>
    }
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_2830_1470)">
        <path opacity="0.938" fillRule="evenodd" clipRule="evenodd" d="M10.0998 0C10.9998 0 11.2998 0.4 11.1998 1.2C10.8998 1.6 10.4998 1.8 9.9998 1.7C9.2998 1.9 8.8998 2.4 8.8998 3.1C9.5998 2.6 10.3998 2.3 11.2998 2.3C12.5998 2.4 13.6998 3 14.3998 4.1C15.3998 6 15.5998 8 15.1998 10.1C14.7998 12.1 13.9998 13.7 12.4998 15.1C11.4998 15.9 10.3998 16.1 9.0998 15.8C8.6998 15.6 8.2998 15.4 7.9998 15.2C7.5998 15.4 7.0998 15.7 6.6998 15.9C5.4998 16.2 4.3998 15.9 3.4998 15.2C1.8998 13.6 0.999798 11.7 0.699798 9.5C0.399797 7.7 0.599797 6 1.3998 4.3C2.5998 2.5 4.1998 1.9 6.2998 2.5C6.5998 2.6 6.8998 2.8 7.1998 3C7.3998 1.3 8.3998 0.3 10.0998 0ZM4.3998 3.9C4.8998 3.9 5.3998 3.9 5.8998 4.1C6.2998 4.3 6.6998 4.5 6.9998 4.8C7.8998 5.3 8.7998 5.2 9.5998 4.7C11.3998 3.4 12.7998 3.8 13.5998 5.9C14.2998 8.5 13.8998 11 12.3998 13.2C12.0998 13.5 11.7998 13.8 11.4998 14.1C11.0998 14.4 10.5998 14.5 10.1998 14.4C9.7998 14.2 9.3998 14 8.9998 13.7C8.5998 13.5 8.0998 13.5 7.6998 13.6C7.2998 13.8 6.8998 14 6.5998 14.3C5.9998 14.5 5.3998 14.3 4.8998 13.9C3.5998 12.7 2.7998 11.2 2.5998 9.5C2.0998 8.4 2.0998 7.4 2.3998 6.3C2.5998 5.6 2.8998 4.9 3.3998 4.4C3.6998 4.2 3.9998 4 4.3998 3.9Z" fill="#D8688E"/>
        </g>
        <defs>
        <clipPath id="clip0_2830_1470">
        <rect width="16" height="16" fill="white"/>
        </clipPath>
        </defs>
    </svg>
  )
}
