export default interface Category {
    label: string;
    value?: string;
}
export const countries: Category[] = [
    { label: 'Afganistán', value: 'Afganistán' },
    { label: 'Albania', value: 'Albania' },
    { label: 'Argelia', value: 'Argelia' },
    { label: 'Andorra', value: 'Andorra' },
    { label: 'Angola', value: 'Angola' },
    { label: 'Antigua y Barbuda', value: 'Antigua y Barbuda' },
    { label: 'Argentina', value: 'Argentina' },
    { label: 'Armenia', value: 'Armenia' },
    { label: 'Australia', value: 'Australia' },
    { label: 'Austria', value: 'Austria' },
    { label: 'Azerbaiyán', value: 'Azerbaiyán' },
    { label: 'Bahamas', value: 'Bahamas' },
    { label: 'Bahréin', value: 'Bahréin' },
    { label: 'Bangladesh', value: 'Bangladesh' },
    { label: 'Barbados', value: 'Barbados' },
    { label: 'Belarús', value: 'Belarús' },
    { label: 'Bélgica', value: 'Bélgica' },
    { label: 'Belice', value: 'Belice' },
    { label: 'Benín', value: 'Benín' },
    { label: 'Bután', value: 'Bután' },
    { label: 'Bolivia', value: 'Bolivia' },
    { label: 'Bosnia y Herzegovina', value: 'Bosnia y Herzegovina' },
    { label: 'Botsuana', value: 'Botsuana' },
    { label: 'Brasil', value: 'Brasil' },
    { label: 'Brunéi', value: 'Brunéi' },
    { label: 'Bulgaria', value: 'Bulgaria' },
    { label: 'Burkina Faso', value: 'Burkina Faso' },
    { label: 'Burundi', value: 'Burundi' },
    { label: 'Cabo Verde', value: 'Cabo Verde' },
    { label: 'Camboya', value: 'Camboya' },
    { label: 'Camerún', value: 'Camerún' },
    { label: 'Canadá', value: 'Canadá' },
    { label: 'República Centroafricana', value: 'República Centroafricana' },
    { label: 'Chad', value: 'Chad' },
    { label: 'Chile', value: 'Chile' },
    { label: 'China', value: 'China' },
    { label: 'Colombia', value: 'Colombia' },
    { label: 'Comoras', value: 'Comoras' },
    { label: 'Congo', value: 'Congo' },
    { label: 'Costa Rica', value: 'Costa Rica' },
    { label: 'Croacia', value: 'Croacia' },
    { label: 'Cuba', value: 'Cuba' },
    { label: 'Chipre', value: 'Chipre' },
    { label: 'República Checa', value: 'República Checa' },
    { label: 'Dinamarca', value: 'Dinamarca' },
    { label: 'Yibuti', value: 'Yibuti' },
    { label: 'Dominica', value: 'Dominica' },
    { label: 'República Dominicana', value: 'República Dominicana' },
    { label: 'Ecuador', value: 'Ecuador' },
    { label: 'Egipto', value: 'Egipto' },
    { label: 'El Salvador', value: 'El Salvador' },
    { label: 'Guinea Ecuatorial', value: 'Guinea Ecuatorial' },
    { label: 'Eritrea', value: 'Eritrea' },
    { label: 'Estonia', value: 'Estonia' },
    { label: 'Eswatini', value: 'Eswatini' },
    { label: 'Etiopía', value: 'Etiopía' },
    { label: 'Fiyi', value: 'Fiyi' },
    { label: 'Finlandia', value: 'Finlandia' },
    { label: 'Francia', value: 'Francia' },
    { label: 'Gabón', value: 'Gabón' },
    { label: 'Gambia', value: 'Gambia' },
    { label: 'Georgia', value: 'Georgia' },
    { label: 'Alemania', value: 'Alemania' },
    { label: 'Ghana', value: 'Ghana' },
    { label: 'Grecia', value: 'Grecia' },
    { label: 'Granada', value: 'Granada' },
    { label: 'Guatemala', value: 'Guatemala' },
    { label: 'Guinea', value: 'Guinea' },
    { label: 'Guinea-Bisáu', value: 'Guinea-Bisáu' },
    { label: 'Guyana', value: 'Guyana' },
    { label: 'Haití', value: 'Haití' },
    { label: 'Honduras', value: 'Honduras' },
    { label: 'Hungría', value: 'Hungría' },
    { label: 'Islandia', value: 'Islandia' },
    { label: 'India', value: 'India' },
    { label: 'Indonesia', value: 'Indonesia' },
    { label: 'Irán', value: 'Irán' },
    { label: 'Irak', value: 'Irak' },
    { label: 'Irlanda', value: 'Irlanda' },
    { label: 'Israel', value: 'Israel' },
    { label: 'Italia', value: 'Italia' },
    { label: 'Jamaica', value: 'Jamaica' },
    { label: 'Japón', value: 'Japón' },
    { label: 'Jordania', value: 'Jordania' },
    { label: 'Kazajistán', value: 'Kazajistán' },
    { label: 'Kenia', value: 'Kenia' },
    { label: 'Kiribati', value: 'Kiribati' },
    { label: 'Kosovo', value: 'Kosovo' },
    { label: 'Kuwait', value: 'Kuwait' },
    { label: 'Kirguistán', value: 'Kirguistán' },
    { label: 'Laos', value: 'Laos' },
    { label: 'Letonia', value: 'Letonia' },
    { label: 'Líbano', value: 'Líbano' },
    { label: 'Lesoto', value: 'Lesoto' },
    { label: 'Liberia', value: 'Liberia' },
    { label: 'Libia', value: 'Libia' },
    { label: 'Liechtenstein', value: 'Liechtenstein' },
    { label: 'Lituania', value: 'Lituania' },
    { label: 'Luxemburgo', value: 'Luxemburgo' },
    { label: 'Madagascar', value: 'Madagascar' },
    { label: 'Malaui', value: 'Malaui' },
    { label: 'Malasia', value: 'Malasia' },
    { label: 'Maldivas', value: 'Maldivas' },
    { label: 'Malí', value: 'Malí' },
    { label: 'Malta', value: 'Malta' },
    { label: 'Islas Marshall', value: 'Islas Marshall' },
    { label: 'Mauritania', value: 'Mauritania' },
    { label: 'Mauricio', value: 'Mauricio' },
    { label: 'México', value: 'México' },
    { label: 'Micronesia', value: 'Micronesia' },
    { label: 'Moldavia', value: 'Moldavia' },
    { label: 'Mónaco', value: 'Mónaco' },
    { label: 'Mongolia', value: 'Mongolia' },
    { label: 'Montenegro', value: 'Montenegro' },
    { label: 'Marruecos', value: 'Marruecos' },
    { label: 'Mozambique', value: 'Mozambique' },
    { label: 'Myanmar', value: 'Myanmar' },
    { label: 'Namibia', value: 'Namibia' },
    { label: 'Nauru', value: 'Nauru' },
    { label: 'Nepal', value: 'Nepal' },
    { label: 'Países Bajos', value: 'Países Bajos' },
    { label: 'Nueva Zelanda', value: 'Nueva Zelanda' },
    { label: 'Nicaragua', value: 'Nicaragua' },
    { label: 'Níger', value: 'Níger' },
    { label: 'Nigeria', value: 'Nigeria' },
    { label: 'Corea del Norte', value: 'Corea del Norte' },
    { label: 'Macedonia del Norte', value: 'Macedonia del Norte' },
    { label: 'Noruega', value: 'Noruega' },
    { label: 'Omán', value: 'Omán' },
    { label: 'Pakistán', value: 'Pakistán' },
    { label: 'Palaos', value: 'Palaos' },
    { label: 'Palestina', value: 'Palestina' },
    { label: 'Panamá', value: 'Panamá' },
    { label: 'Papúa Nueva Guinea', value: 'Papúa Nueva Guinea' },
    { label: 'Paraguay', value: 'Paraguay' },
    { label: 'Perú', value: 'Perú' },
    { label: 'Filipinas', value: 'Filipinas' },
    { label: 'Polonia', value: 'Polonia' },
    { label: 'Portugal', value: 'Portugal' },
    { label: 'Catar', value: 'Catar' },
    { label: 'Rumania', value: 'Rumania' },
    { label: 'Rusia', value: 'Rusia' },
    { label: 'Ruanda', value: 'Ruanda' },
    { label: 'San Cristóbal y Nieves', value: 'San Cristóbal y Nieves' },
    { label: 'Santa Lucía', value: 'Santa Lucía' },
    { label: 'San Vicente y las Granadinas', value: 'San Vicente y las Granadinas' },
    { label: 'Samoa', value: 'Samoa' },
    { label: 'San Marino', value: 'San Marino' },
    { label: 'Santo Tomé y Príncipe', value: 'Santo Tomé y Príncipe' },
    { label: 'Arabia Saudita', value: 'Arabia Saudita' },
    { label: 'Senegal', value: 'Senegal' },
    { label: 'Serbia', value: 'Serbia' },
    { label: 'Seychelles', value: 'Seychelles' },
    { label: 'Sierra Leona', value: 'Sierra Leona' },
    { label: 'Singapur', value: 'Singapur' },
    { label: 'Eslovaquia', value: 'Eslovaquia' },
    { label: 'Eslovenia', value: 'Eslovenia' },
    { label: 'Islas Salomón', value: 'Islas Salomón' },
    { label: 'Somalia', value: 'Somalia' },
    { label: 'Sudáfrica', value: 'Sudáfrica' },
    { label: 'Corea del Sur', value: 'Corea del Sur' },
    { label: 'Sudán del Sur', value: 'Sudán del Sur' },
    { label: 'España', value: 'España' },
    { label: 'Sri Lanka', value: 'Sri Lanka' },
    { label: 'Sudán', value: 'Sudán' },
    { label: 'Surinam', value: 'Surinam' },
    { label: 'Suecia', value: 'Suecia' },
    { label: 'Suiza', value: 'Suiza' },
    { label: 'Siria', value: 'Siria' },
    { label: 'Taiwán', value: 'Taiwán' },
    { label: 'Tayikistán', value: 'Tayikistán' },
    { label: 'Tanzania', value: 'Tanzania' },
    { label: 'Tailandia', value: 'Tailandia' },
    { label: 'Timor Oriental', value: 'Timor Oriental' },
    { label: 'Togo', value: 'Togo' },
    { label: 'Tonga', value: 'Tonga' },
    { label: 'Trinidad y Tobago', value: 'Trinidad y Tobago' },
    { label: 'Túnez', value: 'Túnez' },
    { label: 'Turquía', value: 'Turquía' },
    { label: 'Turkmenistán', value: 'Turkmenistán' },
    { label: 'Tuvalu', value: 'Tuvalu' },
    { label: 'Uganda', value: 'Uganda' },
    { label: 'Ucrania', value: 'Ucrania' },
    { label: 'Emiratos Árabes Unidos', value: 'Emiratos Árabes Unidos' },
    { label: 'Reino Unido', value: 'Reino Unido' },
    { label: 'Estados Unidos', value: 'Estados Unidos' },
    { label: 'Uruguay', value: 'Uruguay' },
    { label: 'Uzbekistán', value: 'Uzbekistán' },
    { label: 'Vanuatu', value: 'Vanuatu' },
    { label: 'Ciudad del Vaticano', value: 'Ciudad del Vaticano' },
    { label: 'Venezuela', value: 'Venezuela' },
    { label: 'Vietnam', value: 'Vietnam' },
    { label: 'Yemen', value: 'Yemen' },
    { label: 'Zambia', value: 'Zambia' },
    { label: 'Zimbabue', value: 'Zimbabue' },
  ];