import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";

export const ProfileNumbers = () => {
  const { friendFollows } = useSelector((state: RootState) => state.friend);

  return (
    <div className="flex flex-row justify-evenly w-[300px] max-[430px]:w-[180px]">
      <div className="flex flex-col items-center font-medium max-[560px]:text-sm">
        <label>{friendFollows?.posts ?? 0}</label>
        Posts
      </div>

      <div className="flex flex-col items-center font-medium max-[560px]:text-sm max-[560px]:pl-3">
        <label>{friendFollows?.followers ?? 0}</label>
        Seguidores
      </div>

      <div className="flex flex-col items-center font-medium max-[560px]:text-sm max-[560px]:pl-3">
        <label>{friendFollows?.follows ?? 0}</label>
        Seguidos
      </div>
    </div>
  );
};
