import { RootState } from '../../store/store';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';

const StatusLoginRoutes = () => {

  const { statusLogin } = useSelector((state: RootState) => state.auth);

  return (statusLogin === 0) ? <Navigate to="/newPass" replace /> : <Outlet />;
}

export default StatusLoginRoutes