import { useSelector } from "react-redux";
import { RootState } from '../../../../store/store';
import { TextComponent } from "../../../../core/components/TextComponent";
import { Row } from "../../../../core/components/Row";
import { SizedBox } from "../../../../core/components/SizedBox";
import { Column } from "../../../../core/components/Column";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  percent?: number ;
  rest?: number ;
}

export const GoalBar = ({ percent = 0,rest=0 }:Props) => {

  const { configKcal } = useSelector((state: RootState) => state.auth);
  const { dayli_kcal } = useSelector((state: RootState) => state.food);
  
  return (
    <Column>
      <Row mainAxisAlignment="between">
        <TextComponent variant="h6" className=" font-semibold">
          Has alcanzado el {percent}% de tu meta diaria.
        </TextComponent>
        <TextComponent variant="h7" className=" text-gray-600">
          {dayli_kcal} de {configKcal} calorías
        </TextComponent>
      </Row>
      <SizedBox className="h-4"/>
      <div className="h-2 w-full bg-gray-300 rounded-full relative overflow-hidden">
        <div
          className="absolute top-0 left-0 bg-gray-950 rounded-full h-full"
          style={{ width: `${percent}%` }}
        >
      </div>
    </div>
    <SizedBox className="h-2"/>
    <TextComponent variant="h7">
        {rest} calorías restantes
      </TextComponent>
    </Column>
  )
}
