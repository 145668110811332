import { useEffect, useState } from "react";
import { Column } from "../../../../core/components/Column";
import CustomCard from "../components/CustomCard";
import { ButtonSquad } from "../../../../core/components";
import { SizedBox } from "../../../../core/components/SizedBox";
import { Row } from "../../../../core/components/Row";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { Step3FormValues, step3ValidationSchema } from "../interfaces/Step3Interface";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../../store/store";
import { useAlert } from "../../../hooks/useAlert";
import { useNavigate } from "react-router-dom";
import { startUpdatingUserInfo } from "../../../../store/slices/auth/thunks";
import { CustomInput } from "../../../../core/components/CustomInput";
import { ButtonType } from "../../../../core/interfaces/ButtonSquadInterface";

interface ParKey {
    name: string;
    value: string;
    description?: string;
}
const trainingGoals: ParKey[] = [
    { description: 'Reducir la grasa corporal y aumentar la masa muscular.', name: 'Recomposición', value: 'a411cef6-36cf-4257-b331-a40ce1fab1dc' },
    { description: 'Mejorar la salud, sentirte mejor y tener más energía.', name: 'Perder Grasa', value: 'ec275da6-d1e2-4cde-8b50-ef80b4185ef9' },
    { description: 'Aumentar la fuerza y la masa muscular.', name: 'Ganar músculo', value: '9316551e-b4c2-4966-97d1-1c1bff17f0fc' },
];


const dailyActivities: ParKey[] = [
    { name: 'Sedentario', description: 'Mayormente sentado', value: 'a4b279ba-c2ac-493a-8717-53ee282d172c' },
    { name: '(1-3 días/semana)', description: 'Cardio ligero', value: '11419d7f-8e5f-44ce-aa52-df6bf27df4db' },
    { name: '(3-5 días/semana)', description: 'Cardio moderado', value: 'c523bf7f-f58a-482b-a0c4-25c30264f8cc' },
    { name: '(Camareros/Operadores)', description: 'Mucho cardio o Mayormente de pie', value: '88558780-91d6-4226-92bf-e054fbff055e' },
    { name: '(6-7 veces/semana)', description: 'Soy deportista de alto rendimiento', value: 'd93ebac2-1f50-406e-9160-92e98c3ed406' },
];

export const Step3Screen = () => {
    const [selectedValue, setSelectedValue] = useState<ParKey | null>(null);
    const [setSelectedValues, setSelectedValuess] = useState<ParKey | null>(null);

    const handleChange = (value:ParKey) => {
        setSelectedValue(value);
    };

    const handleChanges = (value:ParKey) => {
        setSelectedValuess(value);
    };

    

    const { weight, height, target_weight, uid, trainingGoal, dailyActivity, isLoadingOnboarding } = useSelector((state: RootState) => state.auth);
    const initialValues: Step3FormValues = {
        weight: weight,
        height: height,
        target_weight: target_weight,
    };
    useEffect(() => {
        if(trainingGoal!=null){
            setSelectedValue(trainingGoals.find((e)=>e.value==trainingGoal)!);
        }
        if(dailyActivity!=null){
            setSelectedValuess(dailyActivities.find((e)=>e.value==dailyActivity)!);
        }
    }, [])


  const { showAlert } = useAlert();

  const dispatch: AppDispatch = useDispatch();

  const navigate = useNavigate();

  const handleSubmit = (values: Step3FormValues, { setSubmitting }: FormikHelpers<Step3FormValues>) => {  
    
    const payload = {
      ...values,
      id: uid,
      'training_goal_id':selectedValue?.value,
      'daily_activity_id':setSelectedValues?.value,
    }
    dispatch(startUpdatingUserInfo({userId: uid, userInfo: payload})).then( (response) => {
      if (response.meta.requestStatus === 'fulfilled') {
        showAlert({
          message: 'Cambios guardados con éxito!',
          type: "success"
        });
        navigate('/stepFinish');
      }
    })
    setSubmitting(false);
  }

    return <Formik
    initialValues={initialValues}
    validationSchema={step3ValidationSchema}
    validateOnBlur
    validateOnChange={false}
    onSubmit={handleSubmit}
>
{({ handleChange:handleForm, handleBlur }) => ((
<Form>
        <Row responsiveCol={true} mainAxisAlignment="between" className="gap-6 md:gap-12 mt-6">
        <Column  className="text-center w-full">
            <div className=" h3 font-bold">
                ¿Cuál es tu objetivo?
            </div>
            <SizedBox className="h-6" />
                <Column>
                    {trainingGoals.map((goal) => (
                        <div key={goal.value} className="mb-4">
                            <CustomCard
                                title={goal.name}
                                value={goal.value}
                                groupValue={selectedValue?.value}
                                onChange={()=>handleChange(goal)}
                                />
                        </div>
                    ))}
                </Column>

                <Column className="text-start w-full">
                    <Field
                        as={CustomInput}
                        label="Peso actual"
                        name="weight"
                        hintText='ej: 56kg'
                        onChange={handleForm}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="weight" component="div" className="text-red-500" />
                    <SizedBox className="h-4" />
                    <Field
                        as={CustomInput}
                        label="Altura"
                        name="height"
                        hintText='ej: 120cm'
                        onChange={handleForm}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="height" component="div" className="text-red-500" />
                    <SizedBox className="h-4" />
                    <Field
                        as={CustomInput}
                        label="Peso deseado"
                        name="target_weight"
                        hintText='ej: 56kg'
                        onChange={handleForm}
                        onBlur={handleBlur}
                        required
                    />
                    <ErrorMessage name="target_weight" component="div" className="text-red-500" />
                <SizedBox className="h-6" />
            </Column>
        </Column>
        <Column className="text-center w-full">
        <div className="h3 font-bold">
                    Tipo de actividad diaria
            </div>
            
            <SizedBox className="h-5"/>
            <Column>
                    {dailyActivities.map((goal) => (
                        <div key={goal.value} className="mb-4">
                            <CustomCard
                                title={goal.description!}
                                subtitle={goal.name}
                                value={goal.value}
                                groupValue={setSelectedValues?.value}
                                onChange={()=>handleChanges(goal)}
                                />
                        </div>
                    ))}
            </Column>   
            <SizedBox className="h-5"/>
            <ButtonSquad loading={isLoadingOnboarding} buttonType={ButtonType.OrangeFull} className=" " label="Finalizar" onClick={()=>{}} />
            <SizedBox className="h-5"/>
        </Column>
        </Row>
        </Form>
  )
)}
</Formik>
}