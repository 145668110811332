import React from 'react';

interface SizedBoxProps {
  width?: string; 
  height?: string;
  className?: string;
  children?: React.ReactNode;
}

export const SizedBox = ({ width, height, className = '', children }:SizedBoxProps) => {
  const widthClass = width ? `${width}` : '';
  const heightClass = height ? `${height}` : '';
  const combinedClasses = `${widthClass} ${heightClass} ${className}`;

  return <div className={combinedClasses}>{children}</div>;
};
