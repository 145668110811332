import { useEffect, useState } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { getCourseByUser } from "../../store/slices/course/thunks";
import { CourseByUser } from '../../store/interfaces/course/interfaceCourse';

const ValidateUserHasCourse = () => {
  const { id } = useParams();
  const dispatch: AppDispatch = useDispatch();
  const { courseByUser } = useSelector((state: RootState) => state.course);
  const [userHasCourse, setUserHasCourse] = useState<CourseByUser | null>(null);
  const [loadComponent, setLoadComponent] = useState(false);

  useEffect(() => {
    if (courseByUser.length === 0) {
      dispatch(getCourseByUser()).then((res) => {
        if (res.meta.requestStatus === "fulfilled") {
          const course = (res.payload as CourseByUser[]).find((c) => c.id === id);
          setUserHasCourse(course ?? null);
          setLoadComponent(true);
        }
      });
    } else {
      const course = courseByUser.find((c) => c.id === id);
      setUserHasCourse(course ?? null);
      setLoadComponent(true);
    }
  }, []);

  return loadComponent ? (userHasCourse ? <Outlet /> : <Navigate to="/course" />): null;
}

export default ValidateUserHasCourse