import { Column } from "../../../core/components/Column";
import { Row } from "../../../core/components/Row";
import { TextComponent } from "../../../core/components/TextComponent";
import { SizedBox } from "../../../core/components/SizedBox";
import { HomeCoursesComponent } from "../course/HomeCoursesComponent";
import { ButtonSquad } from "../../../core/components";
import { ButtonType } from "../../../core/interfaces/ButtonSquadInterface";
import { coachs } from "../../../core/constants/constatns";
import { useNavigate } from "react-router-dom";
import { CalorieChart } from "./components/CalorieChart";
import { BookOffer } from "../../../core/components/BookOfferComponent";
import { ToolOfferComponent } from "../../../core/components/ToolOfferComponent";

const LeadingHomeScreen = () => {
  const navigate = useNavigate();
  const toCoach=(id:string)=>{
    navigate(`/coach/${id}`);
  }

  return (
    <Column
    crossAxisAlignment="center"
      className="relative w-full  min-h-screen overflow-x-hidden mt-6"
    >
      <div className="w-full md:w-3/4 px-6 md:p-0 flex h-full grow flex-col">
        <Column>  
        <Row>
        <Column className="w-full" mainAxisAlignment="center">
          <CalorieChart />
        </Column>
        </Row>
            <SizedBox className="h-10"/>
            <BookOffer/>
            <Column className="gap-10 py-10">
              <TextComponent variant="h1" className="font-bold">
                Asesorías
              </TextComponent>
            </Column>
            {coachs.map((coach, index) => (
              <Row key={coach.id} responsiveCol={true} className="gap-4 py-6">
                  
                  <div className="rounded-full min-w-32 min-h-32 max-w-32 max-h-32">
                    <img src={coach.profile_picture} className="object-cover rounded-full min-w-32 min-h-32 max-w-32 max-h-32"/>
                  </div>
                  <Column className="flex flex-col justify-center">
                    <TextComponent variant="h2" className="font-bold leading-tight">
                      {coach.firstName} {coach.lastName}
                    </TextComponent>
                    <TextComponent className="text-fadedAsh text-base font-normal leading-normal">
                      {coach.homeDescription}
                    </TextComponent>
                    <ButtonSquad buttonType={ButtonType.RoseSmall} label={coach.homeButton} onClick={()=>toCoach(coach.id)} />
                  </Column>
                </Row>
            ))}
          </Column>
          <SizedBox className="h-10"/>
          <TextComponent variant="h1" className="font-bold">
            Los cursos que más te ayudan según tu objetivo
          </TextComponent>
          <SizedBox className="h-10"/>
          <HomeCoursesComponent/>
          <SizedBox className="h-10"/>
          <ToolOfferComponent/>
      </div>
    </Column>
  );
};

export default LeadingHomeScreen;
