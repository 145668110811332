interface ProfileInfoProps {
  username?: string;
  email?: string;
}

export const ProfileInfo = ({ username, email }: ProfileInfoProps) => {
  return (
    <div className='flex flex-col'>
      <label className="text-md max-[560px]:text-sm font-normal">{username}</label>
      <label className="text-xs font-normal max-[560px]:font-light">{email}</label>
    </div>
  )
}
