const SendIcon = ({width = '27', height = '27'}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.8692 13.504H4.18428M3.93811 14.7456L2.50324 19.42C1.71738 21.9802 1.32445 23.2602 1.60643 24.0484C1.8513 24.7331 2.37724 25.252 3.02623 25.4496C3.77355 25.677 4.90229 25.1231 7.15978 24.0153L21.6324 16.9126C23.8359 15.8311 24.9376 15.2905 25.2781 14.5393C25.574 13.8868 25.574 13.121 25.2781 12.4684C24.9376 11.7174 23.8359 11.1767 21.6324 10.0953L7.13482 2.98042C4.88413 1.87587 3.7588 1.32359 3.01222 1.55015C2.36385 1.7469 1.83796 2.26451 1.59224 2.94781C1.30928 3.73461 1.69801 5.01191 2.4755 7.56651L3.94089 12.3815C4.07442 12.8202 4.14119 13.0397 4.16754 13.264C4.19093 13.4632 4.19069 13.6647 4.16684 13.8637C4.13995 14.088 4.07267 14.3071 3.93811 14.7456Z"
        stroke="#FF690B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SendIcon;
