import { useEffect, useState } from "react";
import { ReadBook, Tab } from "../../../../../store/interfaces/book/interfaceBook";
import { TextComponent } from "../../../../../core/components/TextComponent";

interface SidebarMenuProps {
  data: ReadBook;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}

const SidebarMenu = ({ data, pageNumber, setPageNumber }: SidebarMenuProps) => {
  const [expanded, setExpanded] = useState<{ [key: string]: boolean }>({});
  const [tabSelected, setTabSelected] = useState<string | null>(null);

  const handleToggle = (tab: Tab) => {
    setExpanded((prev) => ({
      ...prev,
      [tab.tab_id]: !prev[tab.tab_id],
    }));

    if (tab.pages.length === 0) {
      setPageNumber(tab.tab_value[0]);
    }
  };

  const handleTabSelected = () => {
    data.tabs.map((tab) => {
      if (tab.tab_value[0] === pageNumber - 1) {
        setTabSelected(tab.tab_id);
        setExpanded((prev) => ({
          ...prev,
          [tab.tab_id]: true,
        }));
        return;
      }
      tab.pages.forEach((page) => {
        if (page.page_value[0] === pageNumber - 1) {
          setTabSelected(tab.tab_id);
          setExpanded((prev) => ({
            ...prev,
            [tab.tab_id]: true,
          }));
          return;
        }
      });
    });
  }

  useEffect(() => {
    if (data !== null) {
      handleTabSelected()
    }
  }, [pageNumber, data]);

  return data !== null ? (
    <div className="w-full min-h-screen bg-gray-100 p-5">
      <div className="flex flex-col">
      <TextComponent variant="h4" className="px-8 py-6">
        Contenido del libro
      </TextComponent>
      <hr/>
        {data.tabs.map((tab) => (
          <div key={tab.tab_id}>
            <button
              onClick={() => handleToggle(tab)}
              className={`text-left w-full py-2 px-4 mt-2 text-sm font-semibold rounded-lg ${tabSelected === tab.tab_id ? "bg-orange-500 text-white" : "bg-gray-300 text-black"}`}
            >
              {tab.tab_title}
            </button>
            {expanded[tab.tab_id] && (
              <div className={`pl-4 ${tab.pages.length > 0 ? "mt-3" : ""}`}>
                {tab.pages.map((page) => (
                  <div
                    key={page.page_id}
                    className={`py-1 text-gray-700 text-xs hover:bg-gray-300 cursor-pointer rounded-lg pl-3 my-1 ${ (pageNumber - 1) === page.page_value[0] && 'bg-gray-300'}`}
                    onClick={() => setPageNumber(page.page_value[0])}
                  >
                    {page.page_title}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  ): null;
};

export default SidebarMenu;
